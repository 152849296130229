import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Avatar,
    Button,
    Typography,
    Menu,
    MenuItem,
    IconButton,
    Pagination,
    CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Swal from 'sweetalert2';
import { primaryColor } from '../constant';
import { post_data } from '../api';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Switch from '@mui/material/Switch';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
        '& .MuiCheckbox-root': {
            color: '#a3a3a3',
        },
    },
    stageButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        '&.closedOwn': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.analysis': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.qualification': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.closedLost': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
    },
    avatarGroup: {
        display: 'flex',
        alignItems: 'center',
    },
    avatarName: {
        marginLeft: '8px',
        fontSize: 14
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});

const SubCompanyTable = ({
    data,
    tableHeader, getClientData,
    setLoading, totalData, loading, currentPage, setCurrentPage, itemsPerPage, totalPages,
    refresh, setRefresh
}) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [rowsData, setRowsData] = useState(data || []);
    const [switchLoaders, setSwitchLoaders] = useState({});


    useEffect(() => {
        setRowsData(data || [])
    }, [data])

    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);

    const handleCheckboxToggle = (index) => {
        const updatedRows = [...rowsData];
        updatedRows[index].checked = !updatedRows[index].checked;
        setRowsData(updatedRows);
    };

    const handleMenuOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(index);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentRow(null);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);

    const handleDelete = async (row) => {
        setAnchorEl(null);
        setCurrentRow(null);
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: primaryColor,
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = await post_data(`sub-client/delete-sub-client/${row?._id}`, {})
                    if (data.status) {
                        toast.success("Sub Client Deleted Successfully");
                        getClientData();
                        setRefresh(!refresh)
                    }
                    else {
                        toast.error("Something Went Wrong");
                    }
                } catch (error) {
                }
            }
        });

    };


    const handleNavigate = () => {
        navigate('/company/dashboard/subcompanyform')
        window.scrollTo(0, 0)
    }

    const handleSwitch = async (item) => {
        setSwitchLoaders(prev => ({ ...prev, [item?._id]: true }));
        try {
            let payload = { isSubClient: !item?.isSubClient }
            const data = await post_data(`sub-client/update-sub-client/${item?._id}`, payload)
            if (data.status) {
                toast.success("Sub Client updated Successfully");
                getClientData();
                setRefresh(!refresh)
            }
            else {
                toast.error("Something Went Wrong");
            }
        } catch (error) {
        }
        setSwitchLoaders(prev => ({ ...prev, [item?._id]: false }));
    }
    console.log("data", rowsData)
    return (
        <TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
            <Table className={classes.root}>
                <TableHead>
                    <TableRow>
                        {
                            tableHeader?.map((item) => {
                                return (
                                    <TableCell style={{ borderRight: '1px solid gainsboro', padding: '16px 10px' }}>{item}</TableCell>
                                )
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rowsData?.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ borderRight: '1px solid gainsboro', textAlign: 'center',width:'5%' }}>{startEntry + index}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.uniqueClientId}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.companyName}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.contactNo}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.email}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro', width: '30%' }}>
                                    {[
                                        row?.address?.street,
                                        row?.address?.city,
                                        row?.address?.state,
                                        row?.address?.zipCode
                                    ]
                                        .filter(Boolean)
                                        .join(' ')}
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}> {switchLoaders[row?._id] ?
                                    <CircularProgress size={20} />
                                    :
                                    <Switch onChange={() => handleSwitch(row)} checked={row?.isSubClient} />
                                }</TableCell>

                                {/* <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                <div className={classes.avatarGroup}>
                    <Avatar alt={row?.owner?.name} src={row?.owner?.img} />
                    <Typography className={classes.avatarName}>
                        {row?.owner?.name}
                    </Typography>
                </div>
            </TableCell> */}
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                    <IconButton
                                        className={classes.moreIcon}
                                        onClick={(event) => handleMenuOpen(event, index)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>

                                    {/* Options menu */}
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl) && currentRow === index}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem onClick={() => {
                                            handleMenuClose();
                                            localStorage.setItem('subClientId', row?._id);
                                            navigate('/company/dashboard/subcompanydetails');
                                        }}>View Details</MenuItem>
                                        <MenuItem onClick={() => {
                                            handleMenuClose();
                                            localStorage.setItem('subClientId', row?._id);
                                            handleNavigate();
                                        }}>
                                            Edit
                                        </MenuItem>
                                        <MenuItem onClick={() => {
                                            handleMenuClose();
                                            handleDelete(row);
                                        }}>
                                            Delete
                                        </MenuItem>
                                    </Menu>
                                </TableCell>
                            </TableRow>
                        ))
                    }

                </TableBody>
            </Table>
            {data?.length > 0 && (
                <div
                    style={{
                        borderTop: "1px solid gainsboro",
                        padding: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: 500,
                            fontSize: 15,
                            color: "black",
                        }}
                    >{`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}</Typography>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}
        </TableContainer>
    );
};

export default SubCompanyTable;
