import FAQSection from "../../components/FAQSection";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

export default function Faq() {
    return (<>
        <Header />
        <FAQSection />
        {/* <Features /> */}
        <Footer />
    </>)
}