'use client';

import styled from 'styled-components';
import { useState } from 'react';
import { TextField, Button, IconButton, InputAdornment, Grid } from '@mui/material';
import { login } from '../../../redux/slices/user-slice';
import { useDispatch } from 'react-redux';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { toast } from 'react-toastify';
import { post_data } from '../../../api';
import { primaryColor } from '../../../constant';
import { useNavigate, useParams } from 'react-router-dom';

const Container = styled.div`
  width: 100vw; 
  height: 100vh;
  background: white;
`;

const LoginBox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const FormSection = styled.div`
  width: 25%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  box-sizing: border-box;
`;

const ImageSection = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0;
`;

const Title = styled.h2`
  margin-bottom: 1px;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
`;

const Subtitle = styled.p`
  margin-bottom: 20px;
  font-size: 14px;
  color: #666;
  text-align: center;
`;

const ErrorText = styled.small`
  margin-bottom: 10px;
  color: red;
`;

const ResetPassword = () => {
    const navigate = useNavigate();
    const { token } = useParams();  // Corrected use of useParams
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [loader, setLoader] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const dispatch = useDispatch();

    const handleError = (label, value) => {
        setErrors((prev) => ({ ...prev, [label]: value }));
    };

    const validate = () => {
        let error = false;

        if (!password) {
            error = true;
            handleError('password', 'Password is required');
        }

        if (!confirmPassword) {
            error = true;
            handleError('confirmPassword', 'Confirm password is required');
        }

        if (password && confirmPassword && password !== confirmPassword) {
            error = true;
            handleError('confirmPassword', 'Passwords do not match');
        }

        return error;
    };

    const handleResetPassword = async () => {
        const error = validate();
        if (!error) {
            try {
                setLoader(true);
                const data = await post_data('super-admin/reset-password', { new_password: password, token });
                if (data.status) {
                    toast.success('Password reset successfully');
                    setPassword('');
                    setConfirmPassword('');
                    dispatch(login(data?.data?.user));
                    localStorage.setItem('authToken', data?.data?.token);
                    navigate('/admin/dashboard');
                } else {
                    toast.error('Password reset failed');
                }
                setLoader(false);
            } catch (error) {
                console.log('Error:', error);
                setLoader(false);
                toast.error('Something went wrong');
            }
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowPassword2(!showPassword2);
    };

    return (
        <Container>
            <LoginBox>
                <FormSection>
                    <Title>Reset Your Password!</Title>
                    <Subtitle>Please enter your new password and confirm it</Subtitle>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Enter New Password"
                                type={showPassword ? 'text' : 'password'}
                                variant="outlined"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onFocus={() => setErrors({ ...errors, password: '' })}
                                autoComplete="off"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={togglePasswordVisibility} edge="end">
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {errors.password && <ErrorText>{errors.password}</ErrorText>}
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Confirm New Password"
                                type={showPassword2 ? 'text' : 'password'}
                                variant="outlined"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                onFocus={() => setErrors({ ...errors, confirmPassword: '' })}
                                autoComplete="off"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={toggleConfirmPasswordVisibility} edge="end">
                                                {showPassword2 ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {errors.confirmPassword && <ErrorText>{errors.confirmPassword}</ErrorText>}
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                style={{ marginTop: 20, background: primaryColor }}
                                disabled={loader}
                                onClick={handleResetPassword}
                            >
                                {loader ? 'Resetting...' : 'Reset Password'}
                            </Button>
                        </Grid>
                    </Grid>


                </FormSection>
            </LoginBox>
        </Container >
    );
};

export default ResetPassword;
