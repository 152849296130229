import { Modal, Box, Typography, TextField, Button, Grid } from '@mui/material';
import { primaryColor } from '../constant';
import { useEffect, useState } from 'react';
import { post_data } from '../api';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { login } from "../../src/redux/slices/user-slice";

const BankDetail = ({ open, setOpen, user_data }) => {
    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
    };
    const [formData, setformData] = useState({
        accountHolderName: '',
        accountNumber: '',
        bankName: '',
        ifsc: '',
        branchName: ''
    })

    useEffect(() => {
        if (user_data?._id) {
            setformData({
                accountHolderName: user_data?.bankDetails?.accountHolderName,
                accountNumber: user_data?.bankDetails?.accountNumber,
                bankName: user_data?.bankDetails?.bankName,
                ifsc: user_data?.bankDetails?.ifsc,
                branchName: user_data?.bankDetails?.branchName
            });
        }
    }, [user_data])



    const [errors, setErrors] = useState({});

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 450,
            border: 'none',
            bgcolor: 'white',
            boxShadow: 12,
            textAlign: 'center',
            maxHeight: '80vh',
            overflow: 'auto',
        },
        heading: {
            fontWeight: 600,
            fontSize: 27,
            marginBottom: '10px',
        },
        headingTypography: {
            fontWeight: 500,
            fontSize: 22,
            marginBottom: '10px',
            textAlign: 'left',
            p: 3,
            borderBottom: '1px solid gainsboro'
        }
    };

    const validation = () => {
        let error = {};
        let hasError = false;

        if (!formData?.accountHolderName) {
            error.accountHolderName = 'Please enter account holder name';
            hasError = true;
        }

        if (!formData?.accountNumber) {
            error.accountNumber = 'Please enter account number';
            hasError = true;
        }

        if (!formData?.bankName) {
            error.bankName = 'Please enter bank name';
            hasError = true;
        }

        if (!formData?.ifsc) {
            error.ifsc = 'Please enter ifsc code';
            hasError = true;
        }

        if (!formData?.branchName) {
            error.branchName = 'Please enter branch name';
            hasError = true;
        }

        if (hasError) {
            setErrors(error);
        } else {
            setErrors({});
        }

        return hasError;
    }

    const handleSubmit = async () => {
        const error = validation();

        const bankDetails = {
            accountHolderName: formData?.accountHolderName,
            accountNumber: formData?.accountNumber,
            bankName: formData?.bankName,
            ifsc: formData?.ifsc,
            branchName: formData?.branchName
        }

        if (!error) {
            const result = await post_data(`super-admin/update-super-admin/${user_data?._id}`, { bankDetails });

            if (result.status === true) {
                dispatch(login(result?.data));
                toast.success(result.message);
                handleClose();
            } else {
                toast.error(result?.response?.data?.message || "Something went wrong");
            }
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={styles.modalBox}>
                <Typography id="logout-modal-title" variant="h6" sx={styles.headingTypography}>
                    Bank Details
                </Typography>
                <Box sx={{ padding: '2% 6% 6% 6%' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Account Holder Name"
                                variant="outlined"
                                value={formData?.accountHolderName}
                                onChange={(e) => setformData({ ...formData, accountHolderName: e.target.value })}
                                onFocus={() => setErrors({ ...errors, accountHolderName: '' })}
                                error={errors?.accountHolderName}
                                helperText={errors?.accountHolderName}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Account Number"
                                variant="outlined"
                                value={formData?.accountNumber}
                                onChange={(e) => setformData({ ...formData, accountNumber: e.target.value })}
                                onFocus={() => setErrors({ ...errors, accountNumber: '' })}
                                error={errors?.accountNumber}
                                helperText={errors?.accountNumber}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="IFSC Code"
                                variant="outlined"
                                value={formData?.ifsc}
                                onChange={(e) => setformData({ ...formData, ifsc: e.target.value })}
                                onFocus={() => setErrors({ ...errors, ifsc: '' })}
                                error={errors?.ifsc}
                                helperText={errors?.ifsc}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Bank Name"
                                variant="outlined"
                                value={formData?.bankName}
                                onChange={(e) => setformData({ ...formData, bankName: e.target.value })}
                                onFocus={() => setErrors({ ...errors, bankName: '' })}
                                error={errors?.bankName}
                                helperText={errors?.bankName}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Branch Name"
                                variant="outlined"
                                value={formData?.branchName}
                                onChange={(e) => setformData({ ...formData, branchName: e.target.value })}
                                onFocus={() => setErrors({ ...errors, branchName: '' })}
                                error={errors?.branchName}
                                helperText={errors?.branchName}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                style={{ background: `${primaryColor}`, color: '#fff', width: '', margin: '20px 15px 20px 0px', textTransform: "capitalize", padding: '9px 20px' }}
                                onClick={handleSubmit}
                                fullWidth
                            >
                                Save
                            </Button>
                        </Grid>

                    </Grid>
                </Box>
            </Box>
        </Modal>
    );
}


export default BankDetail;