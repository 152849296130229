import { Box, Button, Menu, MenuItem, Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as XLSX from 'xlsx';
import { formatDate, formatPrice, primaryColor } from "../../constant";
import EmptyPage from "../EmptyPage";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const SalesTransactionModal = ({ open, setOpen, data, fromDate, toDate, isRental }) => {
    const contentRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const Menuopen = Boolean(anchorEl);

    const styles = {
        invoice: {
            padding: "4%",
            height: 470,
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #000",
            paddingBottom: "10px",
            marginBottom: "5%",
        },
        table: {
            width: "100%",
            borderCollapse: "collapse",
        },
        th: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        td: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        footer: {
            marginTop: "20px",
            borderTop: "2px solid #000",
            marginTop: "5%",
            paddingTop: "10px",
            display: "flex",
            justifyContent: "space-between",
        },
        text: {
            fontSize: 14,
        },
    };

    const handleDownloadPdf = async () => {
        if (contentRef.current) {
            const customPageWidth = 397;
            const margin = 10;
            const rowHeight = 10;
            const headerHeight = 20;

            const totalRows = data?.length || 0;
            const contentHeight = totalRows * rowHeight + headerHeight;

            const pdf = new jsPDF({
                orientation: "landscape",
                unit: "mm",
                format: [customPageWidth, contentHeight + margin * 2],
            });

            const tableStartY = margin;
            let positionY = tableStartY;

            pdf.setFontSize(12);
            pdf.text("S No.", margin + 30, positionY);
            pdf.text("Invoice no.", margin + 70, positionY);
            pdf.text("Invoice To", margin + 110, positionY);
            pdf.text("Order Id", margin + 140, positionY);
            pdf.text("Product Id", margin + 180, positionY);
            pdf.text("Product Name", margin + 220, positionY);
            pdf.text("Amount", margin + 260, positionY);
            pdf.text("Status", margin + 300, positionY);
            pdf.text("Date", margin + 340, positionY);

            positionY += rowHeight;

            data.forEach((item, i) => {
                pdf.setFontSize(10);
                pdf.text(`${i + 1}` || "-", margin + 30, positionY);
                pdf.text(item?.invoiceNumber || "-", margin + 70, positionY);
                pdf.text(item?.userId?.name || "-", margin + 110, positionY);
                pdf.text(item?.orderUniqueId || "-", margin + 150, positionY);
                pdf.text(item?.productId?.map(p => p?.uniqueProductId).join(", ") || '-', margin + 190, positionY);
                pdf.text(item?.productId?.map(p => p?.name).join(", ") || '-', margin + 220, positionY);
                pdf.text(formatPrice(item?.amount) || '', margin + 270, positionY);
                pdf.text(item?.orderStatus || '-', margin + 300, positionY);
                pdf.text(formatDate(item?.createdAt) || '', margin + 340, positionY);

                positionY += rowHeight;
            });

            pdf.save("invoice.pdf");
        }
    };

    const handleDownloadExcel = () => {
        if (data && data.length > 0) {
            const excelData = data.map(item => ({
                // 'S No.': item?.length || '',
                'Invoice no.': item?.invoiceNumber || '',
                'Invoice To': item?.userId?.name || '-',
                'Order Id': item?.orderUniqueId || '',
                'Product Id': item?.productId?.map(p => p?.uniqueProductId).join(", "),
                'Product Name': item?.productId?.map(p => p?.name).join(", "),
                'Amount': formatPrice(item?.amount) || '',
                'Status': item?.orderStatus || '-',
                'Date': formatDate(item?.createdAt) || '',
            }));

            const worksheet = XLSX.utils.json_to_sheet(excelData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Invoices");
            XLSX.writeFile(workbook, "invoices.xlsx");
        }
    };

    const styleModal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 900,
        maxHeight: '90%',
        borderRadius: 2,
        overflowY: 'auto',
        bgcolor: "white",
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        border: "none",
        boxShadow: 24,
        padding: '0 0 5%',
    };

    const button = {
        padding: '5px',
        fontSize: 13,
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '15%',
        marginTop: '20px',
        boxShadow: 'none',
        marginLeft: '2%'
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
        >
            <Box sx={styleModal}>
                {
                    data?.length === 0 ? (
                        <EmptyPage />
                    ) : (
                        <>
                            <Button
                                variant="contained"
                                onClick={handleClick}
                                style={button}
                            >
                                Download <ArrowDropDownIcon />
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={Menuopen}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <MenuItem onClick={handleDownloadPdf}>Download as PDF</MenuItem>
                                <MenuItem onClick={handleDownloadExcel}>Download as Excel</MenuItem>
                            </Menu>

                            <div ref={contentRef} id="invoice" style={styles.invoice}>
                                <div style={styles.header}>
                                    <h2 style={{ fontWeight: 600, margin: 0 }}>Sales Details</h2>
                                    <h2 style={{ fontWeight: 600, margin: 0 }}>SALES INVOICE</h2>
                                </div>

                                <table style={{ ...styles.table, ...styles.text, margin: "3% 0" }}>
                                    <thead style={{ textAlign: "left" }}>
                                        <tr>
                                            <th style={styles.th}>S.No</th>
                                            <th style={styles.th}> Order Id</th>
                                            <th style={styles.th}>Product Id</th>
                                            <th style={styles.th}>Product Name</th>
                                            <th style={styles.th}>Amount</th>
                                            <th style={styles.th}>Status</th>
                                            <th style={styles.th}>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data?.map((item, index) => (
                                                <tr key={index}>
                                                    <td style={styles.td}>{index + 1}</td>
                                                    <td style={styles.td}>{item?.orderUniqueId || '-'}</td>
                                                    <td style={styles.td}>{item?.productId?.map(p => p?.uniqueProductId).join(", ") || '-'}</td>
                                                    <td style={styles.td}>{item?.productId?.map(p => p?.name).join(", ") || '-'}</td>
                                                    <td style={styles.td}>{formatPrice(item?.amount)}</td>
                                                    <td style={styles.td}>{item?.orderStatus || '-'}</td>
                                                    <td style={styles.td}>{formatDate(item?.createdAt)}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div style={{ ...styles.footer, ...styles.text }}>
                                    <p>All Invoices are from {formatDate(fromDate)} to {formatDate(toDate)} period.</p>
                                    <p>For GPS</p>
                                </div>
                            </div>
                        </>
                    )
                }
            </Box>
        </Modal>
    );
};

export default SalesTransactionModal;
