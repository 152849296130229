
import { Button, Grid } from "@mui/material";
import { createButtonBg } from "../constant";
import { IoMdAdd } from "react-icons/io";
import { useEffect, useState } from "react";
import { get_data, post_data } from "../api"
import EmptyPage from "./EmptyPage";
import { useNavigate } from "react-router-dom";
import SearchComponent from "./SearchComponent";
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ClientsTransactionModal from "./models/ClientsTransactionModal";
import SubCompanyTable from "./SubCompanyTable";

export default function SubCompany() {

    const page = { width: '100%', marginBottom: '2%' };
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [value, setValue] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [open, setOpen] = useState(false)
    const [fromDate, setFromDate] = useState([])
    const [toDate, setToDate] = useState([])
    const [clientsData, setClientData] = useState([])

    const itemsPerPage = 20;
    const count = data?.length;

    const handleSearch = async () => {
        if (value.length > 0) {
            try {
                const data = await get_data(`sub-client/search-sub-client/${value}?pageNumber=${currentPage}`)
                if (data.status) {
                    setData(data?.data?.subClients)
                    setTotalPages(data?.data?.totalPages)
                    setTotalData(data?.data?.totalSubClients)
                }
            }
            catch (error) {
                console.log('client serarch error : ', error)
            }

        } else {
            getClientData(currentPage)
        }
    }


    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }, []);


    const tableHeader = [
        "S No.", "Sub Company Id", "Sub Company Name", "Contact", "Email", "Address", 'Status', "Action",
    ]

    const getClientData = async () => {
        try {
            const data = await get_data(`sub-client/get-all-sub-clients-by-client?pageNumber=${currentPage}`);
            if (data.status) {
                setData(data?.data?.subClients);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalSubClients);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentPage) {
            localStorage.removeItem('clientId')
            getClientData(currentPage);
            localStorage.removeItem('subClientId')
        }
    }, [currentPage])

    const handleNavigate = () => {
        localStorage.setItem('createClient', false);
        navigate('/company/dashboard/create-sub-company')
        window.scrollTo(0, 0)
    }


    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>

                <div style={{ display: 'flex', marginBottom: '1%', width: '100%' }}>
                    <div>
                        <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                    </div>
                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        <Button
                            onClick={handleNavigate}
                            startIcon={<IoMdAdd style={{ color: 'white' }} />}
                            variant="contained"
                            style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                        >
                            Create
                        </Button>
                    </div>
                </div>

                {
                    data?.length === 0 ?
                        <>
                            <EmptyPage />
                        </>
                        :
                        <>
                            <SubCompanyTable
                                data={data}
                                tableHeader={tableHeader}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                count={count}
                                totalData={totalData}
                                totalPages={totalPages}
                                loading={loading}
                                setLoading={setLoading}
                                getClientData={getClientData}
                                refresh={refresh}
                                setRefresh={setRefresh}
                            />

                            <ClientsTransactionModal
                                open={open}
                                setOpen={setOpen}
                                fromDate={fromDate}
                                toDate={toDate}
                                data={clientsData}
                            />
                        </>
                }
            </Grid>
        </>
    )
}