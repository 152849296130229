import * as React from 'react';
import { useState } from 'react';
import { TextField, Button, Checkbox, FormControlLabel, Box, Grid, Typography, IconButton, Autocomplete, Accordion, AccordionSummary, AccordionDetails, OutlinedInput, InputAdornment, FormControl, InputLabel } from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import imageCompression from "browser-image-compression";
import ImageIcon from '@mui/icons-material/Image';
import { primaryColor } from '../constant';
import CloseIcon from '@mui/icons-material/Close';
import { get_data, post_data, serverURL } from '../api';
import Swal from 'sweetalert2';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { City, State } from 'country-state-city';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AdminChangPasswordModals from './tables/AdminChangPasswordModals';
import { useSelector } from 'react-redux';

export default function SubCompanyForm() {

    const location = useLocation()
    const { isLoggedIn, user_data } = useSelector(state => state.user);
    const Update = location?.state?.title || ''
    const [rowsData, setRowsData] = useState(location?.state?.row || '');
    const [errors, setErrors] = useState({});
    const [images, setImages] = useState([]);
    const [newImages, setNewImages] = useState([])
    const [compressedImages, setCompressedImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [countryCode] = useState('IN');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [sitesCityList, setSitesCityList] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)

    // const [showConfrimPassword, setShowConfrimPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const [formData, setFormData] = useState(location?.state?.row || {
        sites: [{
            address: {}
        }]
    } || location?.state?.row);

    const getCilentDetails = async () => {
        let result = await get_data(`sub-client/get-sub-client/${localStorage.getItem('subClientId')}`)
        if (result?.status == true) {
            setFormData((prev) => {
                return {
                    ...prev,
                    id: result?.data?._id,
                    companyName: result?.data?.companyName,
                    contactNo: result?.data?.contactNo,
                    email: result?.data?.email,
                    resContactNo: result?.data?.resContactNo,
                    street: result?.data?.address?.street,
                    city: result?.data?.address?.city,
                    state: result?.data?.address?.state,
                    zipCode: result?.data?.address?.zipCode,
                    sites: result?.data?.sites?.map((item, i) => {
                        return item
                    })
                }

            })
        }
    }

    React.useEffect(() => {
        if (localStorage.getItem('subClientId')) {
            getCilentDetails()
            setIsUpdate(true)
        } else if (localStorage.getItem('createSubClient')) {
            setIsUpdate(false)
        }
    }, [])

    const fetchStates = async () => {
        setStateList(State.getStatesOfCountry(countryCode));
    }
    React.useEffect(() => {
        if (formData?.state) {
            setCityList(City.getCitiesOfState(countryCode, formData?.state));
        }
    }, [formData?.state, countryCode]);


    React.useEffect(() => {
        fetchStates()
    }, [countryCode]);



    const handleStateChange = (event, newValue) => {
        if (newValue) {
            setState(newValue.isoCode);
            const fetchedCities = City.getCitiesOfState(countryCode, newValue.isoCode || state);
            setCityList(fetchedCities);
            setCity(''); // Reset city when state changes
        }
    };

    const handleCityChange = (event, newValue) => {
        if (newValue) {
            setCity(newValue.name);
        }
    };

    const handleSiteStateChange = (index, event, newValue) => {
        if (newValue) {
            const fetchedCities = City.getCitiesOfState(countryCode, newValue.isoCode);
            setSitesCityList(fetchedCities);

            setFormData((prev) => {
                const updatedSite = prev?.sites?.map((site, idx) => {
                    if (index === idx) {
                        return {
                            ...site,
                            address: {
                                ...site?.address,
                                state: newValue?.isoCode, // Update the state
                                city: '' // Reset the city
                            }
                        };
                    }
                    return site;
                });
                return { ...prev, sites: updatedSite };
            });
        }
    };

    const handleSiteCityChange = (index, event, newValue) => {
        if (newValue) {
            setFormData((prev) => {
                const updatedSite = prev?.sites?.map((site, idx) => {
                    if (index === idx) {
                        return {
                            ...site,
                            address: {
                                ...site?.address,
                                city: newValue.name // Update the city
                            }
                        };
                    }
                    return site;
                });
                return { ...prev, sites: updatedSite };
            });
        }
    };

    const validateForm = () => {
        const newErrors = {};
        let error = false
        if (!formData.companyName) {
            newErrors.companyName = 'Company name is required';
        }
        if (!formData.contactNo) {
            newErrors.contactNo = 'Contact No is required';
        }
        if (!formData.email) {
            newErrors.email = 'Email is required';
        }
        if (!formData.resContactNo) {
            newErrors.resContactNo = 'res Contact No is required';
        }

        if (!formData.street) {
            newErrors.street = 'street is required';
        }
        if (!state) {
            newErrors.state = 'state is required';
        }
        if (!city) {
            newErrors.city = 'city is required';
        }
        if (!formData.zipCode) {
            newErrors.zipCode = 'zipCode is required';
        }

        if (!password) {
            setPasswordError("Please input password")
            error = true
        }

        if (!confirmPassword) {
            setConfirmPasswordError("please input confirm password")
            error = true
        }

        if (password != confirmPassword) {
            setConfirmPasswordError("Password doesn't match")
            error = true
        }

        setErrors(newErrors);
        return error
    };

    const handleSitesInputChange = (index, field, value) => {
        setFormData((prev) => {
            let updatedSites = prev?.sites?.map((form, idx) => {
                if (index === idx) {
                    let newAddress = {
                        ...form?.address, [field]: value
                    }
                    return { ...form, address: newAddress }
                }
                return form
            })
            return { ...prev, sites: updatedSites }
        });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: false })
    };

    const handleSubmit = async (event) => {

        event.preventDefault();
        if (!validateForm()) {
            setLoading(true)
            let body = {
                ...formData,
                address: {
                    street: formData?.street,
                    city,
                    state,
                    zipCode: formData?.zipCode
                },
                clientId: user_data?._id,
                password
            }
            try {
                const result = await post_data('sub-client/create-sub-client', body);
                if (result.status === true) {
                    setLoading(false)
                    toast.success("Sub Company created successfully!");
                    setFormData('')
                } else {
                    setLoading(false)
                    if (result?.response?.data?.message === "Email Already Exists") {
                        toast.error("Email Already Exists!");
                    } else if (result?.response?.data?.message === "Phone Number Already Exists") {
                        toast.error("Phone Number Already Exists!");
                    } else {
                        toast.error("Something went wrong!");
                    }
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    const handleUpdate = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            setLoading(true)
            let body = {
                ...formData,
                address: {
                    street: formData?.street,
                    city,
                    state,
                    zipCode: formData?.zipCode
                }

            }
            try {
                const response = await post_data(`sub-client/update-sub-client/${localStorage.getItem('subClientId')}`, body, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Set headers for file upload
                    },
                });
                if (response?.status) {
                    setLoading(false)
                    toast.success("Sub client updated successfully!");
                    // setFormData('')
                } else {
                }
            } catch (error) {
                setLoading(false)
                toast.error("Something went wrong!");
            }
        }
    }



    const buttonStyles = {
        borderColor: primaryColor,
        color: 'white',
        background: primaryColor,
        textTransform: 'capitalize',
        borderRadius: 0,
        fontWeight: 400,
        padding: '1% 3.5%',
        boxShadow: 'none',
        '&:hover': {
            borderColor: primaryColor,
            boxShadow: 'none',
        },
        '&:focus': {
            outline: 'none',
            boxShadow: 'none',
            borderColor: primaryColor,
        }
    };

    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const addForm = () => {
        setFormData(prevState => ({
            ...prevState,
            sites: [
                ...prevState.sites,
                {
                    address: {}
                }
            ]
        }));
    };
    return (
        <div>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <Grid container spacing={2} style={{ width: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                <Grid item md={8}>
                    <Box
                        component="form"
                        onSubmit={isUpdate ? handleUpdate : handleSubmit}
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        margin="auto"
                        padding="35px"
                        bgcolor="white"
                        borderRadius={2}
                    >
                        <TextField
                            label="Company Name"
                            name="companyName"
                            value={formData?.companyName || ""}
                            onChange={handleInputChange}
                            fullWidth
                            variant="outlined"
                            style={{ borderRadius: 0 }}
                            error={!!errors?.companyName}
                            helperText={errors?.companyName}
                            onFocus={() => handleError('', 'companyName')}
                        />

                        <div style={{ display: 'flex', gap: 10 }}>
                            <TextField
                                 
                                label="Contact Number"
                                name="contactNo"
                                value={formData?.contactNo || ""}
                                onChange={handleInputChange}
                                fullWidth
                                variant="outlined"
                                style={{ borderRadius: 0 }}
                                error={!!errors?.contactNo}
                                helperText={errors?.contactNo}
                                onFocus={() => handleError('', 'contactNo')}
                            />

                            <TextField
                                type='email'
                                label="Email Id"
                                name="email"
                                value={formData?.email || ""}
                                onChange={handleInputChange}
                                fullWidth
                                variant="outlined"
                                style={{ borderRadius: 0 }}
                                error={!!errors?.email}
                                helperText={errors?.email}
                                onFocus={() => handleError('', 'email')}
                            />

                            <TextField
                                 
                                label="resContact Number"
                                name="resContactNo"
                                value={formData?.resContactNo || ""}
                                onChange={handleInputChange}
                                fullWidth
                                variant="outlined"
                                style={{ borderRadius: 0 }}
                                error={!!errors?.resContactNo}
                                helperText={errors?.resContactNo}
                                onFocus={() => handleError('', 'resContactNo')}
                            />
                        </div>

                        <Grid item xs={12}>
                            <Typography component="h1">Address</Typography>
                        </Grid>


                        <div style={{ display: 'flex', gap: 10 }}>
                            <Grid item xs={4}>
                                <Autocomplete
                                    id="state-select"
                                    options={stateList}
                                    value={stateList.find((s) => s?.isoCode === state || formData?.state) || null}
                                    onChange={handleStateChange}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField
                                        error={Boolean(errors?.state)}
                                        helperText={errors?.state}
                                        onFocus={() => handleError('', 'state')}
                                        {...params} label="Select State" fullWidth />}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    id="city-select"
                                    options={cityList}
                                    value={cityList.find((c) => c?.name === city || formData?.city) || null}
                                    onChange={handleCityChange}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField
                                        error={Boolean(errors?.city)}
                                        helperText={errors?.city}
                                        onFocus={() => handleError('', 'city')}
                                        {...params} label="Select City" fullWidth />}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="Zip Code"
                                     
                                    value={formData?.zipCode || ''}
                                    error={Boolean(errors.zipCode)}
                                    helperText={errors.zipCode}
                                    onFocus={() => handleError('', 'zipCode')}
                                    onChange={handleInputChange}
                                    fullWidth
                                    name='zipCode'
                                />
                            </Grid>
                        </div>

                        <Grid item xs={12}>
                            <TextField
                                label="Street"
                                error={Boolean(errors?.street)}
                                helperText={errors?.street}
                                onFocus={() => handleError('', 'street')}
                                value={formData?.street || ''}
                                onChange={handleInputChange}
                                fullWidth
                                name='street'
                            />
                        </Grid>


                        <Grid item xs={12}>
                            {formData?.sites?.map((form, index) => {
                                return (
                                    <Accordion style={{ marginTop: 10, marginBottom: 10 }}>
                                        <AccordionSummary
                                            expandIcon={<ArrowDownwardIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <Typography>Add Sites {index + 1}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2} >
                                                <Grid item xs={12}>
                                                    <TextField
                                                        label="Street"
                                                        error={Boolean(errors?.street)}
                                                        helperText={errors?.street}
                                                        onFocus={() => handleError('', 'street')}
                                                        value={formData?.sites[index]?.address?.street || ''}
                                                        onChange={(e) => handleSitesInputChange(index, 'street', e.target.value)}
                                                        fullWidth
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Autocomplete
                                                        id="state-select"
                                                        options={stateList}
                                                        value={stateList.find((s) => s?.isoCode === formData?.sites[index]?.address?.state) || null}
                                                        onChange={(e, n) => handleSiteStateChange(index, e, n)}
                                                        getOptionLabel={(option) => option.name}
                                                        renderInput={(params) => <TextField
                                                            error={Boolean(errors?.street)}
                                                            helperText={errors?.street}
                                                            onFocus={() => handleError('', 'street')}
                                                            {...params} label="Select State" fullWidth />}
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Autocomplete
                                                        id="city-select"
                                                        options={sitesCityList}
                                                        value={sitesCityList.find((c) => c?.name === formData?.sites[index]?.address?.city) || null}
                                                        onChange={(e, n) => handleSiteCityChange(index, e, n)}
                                                        getOptionLabel={(option) => option.name}
                                                        renderInput={(params) => <TextField
                                                            error={Boolean(errors?.street)}
                                                            helperText={errors?.street}
                                                            onFocus={() => handleError('', 'street')}
                                                            {...params} label="Select City" fullWidth />}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        label="Zip Code"
                                                         
                                                        value={formData?.sites[index]?.address?.zipCode || ''}
                                                        error={Boolean(errors.zipCode)}
                                                        helperText={errors?.zipCode}
                                                        onFocus={() => handleError('', 'zipCode')}
                                                        onChange={(e) => handleSitesInputChange(index, 'zipCode', e.target.value)}
                                                        fullWidth
                                                    />
                                                </Grid>

                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>)
                            })}


                            <Grid item xs={12} sm={6}>
                                <Button onClick={addForm} variant="contained" style={buttonStyles}>
                                    Add
                                </Button>
                            </Grid>

                            {isUpdate ? <Grid item xs={12} sm={6}>
                                <AdminChangPasswordModals isUpdate={false} formData={formData} title={'subCompany'} />
                            </Grid> : <></>}

                        </Grid>

                        {isUpdate ? <></> :
                            <>
                                <div style={{ display: 'flex', gap: 10 }}>
                                    <TextField
                                        label="Password"
                                        variant="outlined"
                                        type={showPassword ? "text" : "password"}
                                        fullWidth
                                        value={password}
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                            setPasswordError("");
                                        }}
                                        error={!!passwordError}
                                        helperText={passwordError}
                                        style={{ ...styles.input }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        label="Confirm Password"
                                        variant="outlined"
                                        type={showConfirmPassword ? "text" : "password"}
                                        fullWidth
                                        value={confirmPassword}
                                        onChange={(e) => {
                                            setConfirmPassword(e.target.value);
                                            setConfirmPasswordError("");
                                        }}
                                        error={!!confirmPasswordError}
                                        helperText={confirmPasswordError}
                                        style={styles.input}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                        edge="end"
                                                    >
                                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            </>
                        }
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={loading}
                            style={{
                                backgroundColor: primaryColor,
                                color: 'white',
                                width: '25%',
                                borderRadius: 0,
                                boxShadow: 'none',
                                padding: '10px 0',
                                fontSize: '16px',
                            }}
                        >
                            {
                                isUpdate ?
                                    <>
                                        {
                                            loading ? 'Update...' : 'Update'
                                        }
                                    </>
                                    :
                                    <>
                                        {
                                            loading ? 'Submit...' : 'Submit'
                                        }
                                    </>
                            }
                        </Button>
                    </Box>

                </Grid>

                {/* <Grid item md={5}>
                    <Box component="label" sx={dropzoneStyle}>
                        <input
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={(e) => {
                                const image = Array.from(e.target.files);
                                if (Update) {
                                    setFormData({ ...formData, uploadNewImage: [...(formData.uploadNewImage || []), ...image] });
                                    setNewImages([...newImages, ...image]);
                                    handleCompressImage(image)
                                } else {
                                    setFormData({ ...formData, images: [...(formData.images || []), ...image] });
                                    e.target.value = ''; // Clear the input for the next upload      
                                }
                            }}

                            style={{ display: 'none' }}
                        />
                        <ImageIcon sx={{ fontSize: 48, color: primaryColor }} />
                        <Typography variant="h6" sx={{ color: primaryColor, marginTop: '16px' }}>
                            Choose your Images
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#999999' }}>
                            Accepts all Formats
                        </Typography>
                    </Box>

                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '3%' }}>
                        {formData.images?.map((image, index) => (
                            <Box key={index} sx={imagesBox} position="relative">
                                {Update ? <img
                                    src={`${serverURL}/uploads/products-images/${image}`}
                                    alt={`Uploaded preview ${index}`}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                /> : <img
                                    src={URL.createObjectURL(image)}
                                    alt={`Uploaded preview ${index}`}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                />}

                                <IconButton
                                    onClick={() => {
                                        const updatedImages = formData.images.filter((_, i) => i !== index);
                                        setFormData({ ...formData, images: updatedImages }); // Update formData with the filtered images
                                    }}
                                    sx={{
                                        position: 'absolute',
                                        top: '4px',
                                        right: '4px',
                                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 255, 255, 1)',
                                        },
                                    }}
                                >
                                    <CloseIcon sx={{ fontSize: '16px', color: '#f44336' }} />
                                </IconButton>
                            </Box>
                        ))}
                    </div>
                </Grid> */}
            </Grid>
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
    },
    rightSide: {
        backgroundColor: '#fff',
        width: '100%'
    },
    loginHeading: {
        fontSize: '1.5rem',
        color: primaryColor,
        marginBottom: '20px',
        fontWeight: 500,
        textAlign: 'center'
    },
    loginText: {
        textAlign: 'center',
        fontSize: '1rem',
        marginTop: 0,
        color: '#2a2a2a',
        marginBottom: '20px',
    },
    input: {
        marginBottom: '10px',
    },
    signInButton: {
        padding: '9px 20px',
        backgroundColor: primaryColor,
        color: '#fff',
        borderRadius: 0,
        width: '100%',
        marginTop: '4%',
        boxShadow: 'none',
        fontSize: '1rem',
        fontWeight: 400,
        cursor: 'pointer',
    },
};