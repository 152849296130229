import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch } from "react-redux";
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { post_data } from "../../../api";
import { primaryColor } from "../../../constant";
import { toast, Toaster } from 'react-hot-toast';
import { login } from "../../../redux/slices/user-slice";
import { useNavigate } from "react-router-dom";

export default function VendorLogin() {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(500));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [loader, setLoader] = useState(false);

    const validate = () => {
        let valid = true;
        setEmailError("");
        setPasswordError("");

        if (!email) {
            setEmailError("Email is required");
            valid = false;
        }
        if (!password) {
            setPasswordError("Password is required");
            valid = false;
        } else if (password.length < 4) {
            setPasswordError("Password must be at least 6 characters");
            valid = false;
        }

        return valid;
    };
    const handleNavigate = () => {
        navigate('/vendor/dashboard/clients');
        window.scrollTo(0, 0)
    }
    const handleSubmit = async () => {
        if (validate()) {
            try {
                setLoader(true);
                const data = await post_data("vendor/vendor-login", { email, password });
                setLoader(false);

                if (data?.status === true) {
                    dispatch(login(data?.data?.user));
                    localStorage.setItem("authToken", data?.data?.token);
                    toast.success('Login Successfully', { duration: 4000, position: 'top-right' });
                    setEmail('');
                    setPassword('');
                    handleNavigate()

                } else {
                    if (data?.response?.data?.message === 'Incorrect Password') {
                        toast.error('Incorrect Password', { duration: 4000, position: 'top-right' });
                    } else {
                        toast.error('Incorrect Email', { duration: 4000, position: 'top-right' });
                    }
                }
            } catch (error) {
                setLoader(false);
                toast.error('Something went wrong. Please try again.', { duration: 4000, position: 'top-right' });
            }
        }
    };

    return (
        <div style={styles.container}>
            <Toaster position="top-center" reverseOrder={false} />
            <div style={{ ...styles.rightSide, padding: isMobile ? "20px" : '10px', width: isMobile ? '100%' : '45%' }}>
                <div style={{ width: isMobile ? '100%' : isTablet ? "95%" : '60%', margin: 'auto' }}>
                    <h2 style={styles.loginHeading}>Login</h2>
                    <p style={styles.loginText}>Fill in the details below to login to your account.</p>

                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailError("");
                        }}
                        error={!!emailError}
                        helperText={emailError}
                        style={styles.input}
                    />

                    <TextField
                        label="Password"
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        fullWidth
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                            setPasswordError("");
                        }}
                        error={!!passwordError}
                        helperText={passwordError}
                        style={styles.input}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={loader}
                        style={styles.signInButton}
                    >
                        {loader ? 'Logging In...' : 'Login'}
                    </Button>

                    <p style={styles.signupText}>
                        Don't have an account?
                        <span onClick={() => { navigate('/vendor/signup'); window.scrollTo(0, 0) }} style={styles.signupLink}>
                            Create Account
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: 'white',
    },
    rightSide: {
        backgroundColor: '#fff',
        width: '100%',
    },
    loginHeading: {
        fontSize: '1.5rem',
        color: primaryColor,
        marginBottom: '20px',
        fontWeight: 500,
        textAlign: 'center',
    },
    loginText: {
        textAlign: 'center',
        fontSize: '1rem',
        marginTop: 0,
        color: '#2a2a2a',
        marginBottom: '20px',
    },
    input: {
        marginTop: '3%',
        marginBottom: '10px',
    },
    signInButton: {
        padding: '10px 20px',
        backgroundColor: primaryColor,
        color: '#fff',
        width: '100%',
        marginTop: '4%',
        boxShadow: 'none',
        fontSize: '1rem',
        fontWeight: 400,
        cursor: 'pointer',
    },
    signupText: {
        marginTop: '5%',
        textAlign: 'center',
    },
    signupLink: {
        textDecoration: 'underline',
        cursor: 'pointer',
        marginLeft: '5px',
    },
};
