import React, { useState } from 'react';
import { Modal, Box, Typography, Table, TableHead, TableBody, Button } from '@mui/material';
import { formatDate, primaryColor } from '../constant';
import UninstallGpsStatusModal from './models/UninstallGpsStatusModal';

const ReturnDetailModel = ({ open, setOpen, data, refresh, setRefresh, getReturnData }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    const handleClose = () => {
        setOpen(false);
    };

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 1100,
            border: 'none',
            bgcolor: 'white',
            boxShadow: 12,
            textAlign: 'center',
            maxHeight: '80vh',
            overflow: 'auto',
        },
        heading: {
            fontWeight: 600,
            fontSize: 27,
            marginBottom: '10px',
        },
        row: {
            display: 'flex',
            width: '100%',
            gap: '10px',
            margin: '4% 0',
            fontWeight: 400,
            fontSize: 16,
        },
        dataLabel: {
            fontWeight: 400,
            fontSize: 16,
            width: '50%',
            textAlign: 'left'
        },
        dataValue: {
            fontWeight: 400,
            fontSize: 16,
            marginLeft: 'auto',
            width: '50%',
            textAlign: 'right'
        },
        icon: {
            width: 22,
        },
        headingTypography: {
            fontWeight: 500,
            fontSize: 22,
            marginBottom: '10px',
            textAlign: 'left',
            p: 3,
            borderBottom: '1px solid gainsboro'
        }
    };

    const DataRow = ({ label, value }) => (
        <div style={styles.row}>
            <span style={styles.dataLabel}>{label}</span>
            <span style={styles.dataValue}>{value}</span>
        </div>
    );



    const getInstallDate = (vehicleNumber) => {
        let installDate;
        if (vehicleNumber) {
            data?.requests?.gps?.map((item) => {
                if (item?.vehicleDetails?.regnNumber == vehicleNumber) {
                    installDate = item.DateOfInstall;
                }
            })
        }

        if (!installDate) {
            return "-";
        }
        return formatDate(installDate || "-");
    }

    const handleGpsStatusChange = (item) => {
        if (item?.status === 'Installed') {
            setSelectedItem(item); // Update the selected item
            setModalOpen(true); // Then open the modal
        }
    };


    return (
        <>
            <UninstallGpsStatusModal open={modalOpen} setOpen={setModalOpen} detailOpen={setOpen} getUninstallData={getReturnData} type={'returns'} clientId={data?.clientId?._id} item={selectedItem} refresh={refresh} setRefresh={setRefresh} />
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={styles.modalBox}>
                    <Typography id="logout-modal-title" variant="h6" sx={styles.headingTypography}>
                        Return&apos;s Details
                    </Typography>
                    <Box sx={{ padding: '2% 6% 6% 6%' }}>
                        <DataRow label="Company Name" value={data?.clientId?.companyName || '-'} />
                        <DataRow label="Company ID" value={data?.clientId?.uniqueClientId || '-'} />
                        <DataRow label="Comapny Phone" value={data?.clientId?.contactNo || '-'} />
                        <DataRow label="Return ID" value={data?.uniqueReturnId || '-'} />
                        <DataRow label="Request ID" value={data?.requestId || '-'} />
                        <DataRow label="Date" value={formatDate(data?.createdAt) || "-"} />
                        <DataRow label="Return Reason" value={data?.returnReason || '-'} />
                        <div>
                            <Table style={{
                                border: '.5px solid grey',
                                fontSize: '.85rem',
                                borderRadius: '10px'
                            }}
                                cellSpacing={0}

                            >
                                <TableHead style={{
                                    border: '.5px solid grey',
                                }}>
                                    <tr style={{
                                        border: '.5px solid grey',
                                    }}>
                                        <td style={{
                                            border: '.5px solid grey',
                                            padding: '5px',
                                            fontWeight: 500
                                        }}>Vehicle Number</td>

                                        <td style={{
                                            border: '.5px solid grey',
                                            padding: '5px',
                                            fontWeight: 500
                                        }}>IMEI</td>
                                        <td style={{
                                            border: '.5px solid grey',
                                            padding: '5px',
                                            fontWeight: 500
                                        }}>Sim Number</td>
                                        <td style={{
                                            border: '.5px solid grey',
                                            padding: '5px',
                                            fontWeight: 500
                                        }}>Device Unique Id</td>
                                        <td style={{
                                            border: '.5px solid grey',
                                            padding: '5px',
                                            fontWeight: 500
                                        }}>Install Date</td>

                                        <td style={{
                                            border: '.5px solid grey',
                                            padding: '5px',
                                            fontWeight: 500
                                        }}>Return Date</td>
                                        <td style={{
                                            border: '.5px solid grey',
                                            padding: '5px',
                                            fontWeight: 500
                                        }}>Status</td>
                                    </tr>
                                </TableHead>
                                <TableBody >
                                    {data?.details?.map((item, index) => (
                                        <tr style={{
                                            border: '.5px solid grey',
                                            padding: '5px',
                                            fontSize: '.8rem'
                                        }}>
                                            <td style={{
                                                border: '.5px solid grey',
                                                padding: '5px',
                                                fontSize: '.8rem'
                                            }}>{item?.vehicleNumber || "-"}</td>

                                            <td style={{
                                                border: '.5px solid grey',
                                                padding: '5px',
                                                fontSize: '.8rem'
                                            }}>{item?.imei || "-"}</td>
                                            <td style={{
                                                border: '.5px solid grey',
                                                padding: '5px',
                                                fontSize: '.8rem'
                                            }}>{item?.simNumber || "-"}</td>
                                            <td style={{
                                                border: '.5px solid grey',
                                                padding: '5px',
                                                fontSize: '.8rem'
                                            }}>{item?.deviceUniqueId || "-"}</td>
                                            <td style={{
                                                border: '.5px solid grey',
                                                padding: '5px',
                                                fontSize: '.8rem'
                                            }}>{item?.dateOfInstall ? formatDate(item?.dateOfInstall) : "-" || "-"}</td>

                                            <td style={{
                                                border: '.5px solid grey',
                                                padding: '5px',
                                                fontSize: '.8rem'
                                            }}>{item?.dateOfUninstall ? formatDate(item?.dateOfUninstall) : "-" || "-"}</td>
                                            <td style={{
                                                border: '.5px solid grey',
                                                padding: '5px',
                                                fontSize: '.4rem'
                                            }}>
                                                <Button variant='contained'
                                                    style={{ fontSize: '.6rem' }}
                                                    onClick={() => handleGpsStatusChange(item)} >
                                                    {item?.status === "UnInstalled" ? "Returned" : item?.status}
                                                </Button>
                                            </td>
                                        </tr >
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </Box>
                </Box>
            </Modal>
        </>

    );
};

export default ReturnDetailModel;
