
import { Button, Grid } from "@mui/material";
import { createButtonBg } from "../../../constant";
import { IoMdAdd } from "react-icons/io";
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../../api"
import EmptyPage from "../../../components/EmptyPage";
import ClientTable from "../../../components/tables/ClientTable";
import { useNavigate } from "react-router-dom";
import SearchComponent from "../../../components/SearchComponent";
import AdminClientModal from "../../../components/tables/AdminChangPasswordModals";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ClientsTransactionModal from "../../../components/models/ClientsTransactionModal";

const rangePickerStyle = {
    display: 'flex',
    cursor: 'pointer',
    fontSize: 14,
    background: 'white',
    padding: '0% 0% 0% 0%',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    color: '#7E8299',
    cursor: 'pointer'
};

export default function Client() {

    const page = { width: '100%', marginBottom: '2%' };
    const navigate = useNavigate();
    const [createClientOpen, setCreateClientOpen] = useState(false)
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [value, setValue] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [open, setOpen] = useState(false)
    const [fromDate, setFromDate] = useState([])
    const [toDate, setToDate] = useState([])
    const [clientsData, setClientData] = useState([])

    const itemsPerPage = 15;
    const count = data?.length;

    const handleSearch = async () => {
        if (value.length > 0) {
            try {
                const data = await get_data(`client/search-clients/${value}?pageNumber=${currentPage}`)
                if (data.status) {
                    setData(data?.data?.clients)
                    setTotalPages(data?.data?.totalPages)
                    setTotalData(data?.data?.totalClients)
                }
            }
            catch (error) {
                console.log('client serarch error : ', error)
            }

        } else {
            getClientData(currentPage)
        }
    }


    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }, []);


    const tableHeader = [
        "S No.", "Company Id", "Company Name", "Contact", "Total GPS", "Active GPS", "In active GPS", "No Data","Stock","Address", 'Status', "Date", "Action",
    ]

    const getClientData = async () => {
        try {
            const data = await get_data(`client/get-all-clients?pageNumber=${currentPage}`);
            if (data.status) {
                setData(data?.data?.clients);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalClients);
            }
        } catch (error) {
            setLoading(false);
        }
    }




    useEffect(() => {
        if (currentPage) {
            localStorage.removeItem('clientId')
            getClientData(currentPage);
        }
    }, [currentPage])

    const handleNavigate = () => {
        // setCreateClientOpen(true)
        localStorage.setItem('createClient', false);
        navigate('/admin/dashboard/clients/create')
        window.scrollTo(0, 0)
    }


    const handleDateChange = (dates) => {
        if (dates && dates?.length === 2) {
            setDateRange(dates);
        }
    };

    const fetchExportData = async (search) => {
        try {

            const fromDate = moment(startDate).toISOString();
            const toDate = moment(endDate).toISOString();

            let response = await post_data('client/get-all-client-by-date', {
                from: fromDate,
                to: toDate
            })

            if (response?.status === true) {
                setClientData(response?.data?.client)
                setData(response?.data?.client)
                // search ? setOpen(false) : setOpen(true)
                setOpen(true)
                setFromDate(response?.data?.fromDate)
                setToDate(response?.data?.toDate)
            } else {

            }
        } catch (error) {
        }
    };


    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>

                <div style={{ display: 'flex', marginBottom: '1%', width: '100%' }}>
                    <div>
                        <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                    </div>
                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        <div style={{ ...rangePickerStyle, border: 'none', }}>
                            <DatePicker
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                dateFormat="dd-MM-yyyy"
                                maxDate={new Date()}
                                customInput={
                                    <button style={{ border: 'none', background: '#fff', cursor: 'pointer', }}>
                                        {startDate && endDate ? `${moment(startDate).format('DD-MM-YYYY')} to ${moment(endDate).format('DD-MM-YYYY')}` : 'Select Date Range'}
                                    </button>
                                }
                            />
                        </div>

                        <Button
                            onClick={fetchExportData}
                            variant="contained"
                            style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                        >
                            Export
                        </Button>

                        <Button
                            onClick={handleNavigate}
                            startIcon={<IoMdAdd style={{ color: 'white' }} />}
                            variant="contained"
                            style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                        >
                            Create
                        </Button>
                    </div>
                </div>

                {
                    data?.length === 0 ?
                        <>
                            <EmptyPage />
                        </>
                        :
                        <>
                            <ClientTable
                                data={data}
                                tableHeader={tableHeader}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                count={count}
                                totalData={totalData}
                                totalPages={totalPages}
                                loading={loading}
                                setLoading={setLoading}
                                getClientData={getClientData}
                                refresh={refresh}
                                setRefresh={setRefresh}
                            />

                            <ClientsTransactionModal
                                open={open}
                                setOpen={setOpen}
                                fromDate={fromDate}
                                toDate={toDate}
                                data={clientsData}
                            />
                        </>
                }
            </Grid>
        </>
    )
}