import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { useState, useEffect } from "react";
import { createButtonBg, primaryColor } from '../../constant';
import { post_data } from '../../api';
import { Toaster, toast } from 'react-hot-toast';
import { Country, State, City } from 'country-state-city';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
};

export default function BuyersModals({ setOpen, open, editData, isUpdate, getAllBuyers }) {

    const [countryCode, setCountryCode] = useState('IN');
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [name, setName] = useState('');
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [street, setStreet] = useState('')
    const [state, setState] = useState('')
    const [city, setCity] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [errors, setErrors] = useState({});

    useEffect(() => {
        // Sync state with `editeBrandData` when it's passed or changes
        if (isUpdate && editData) {
            setName(editData?.name || '');
            setEmail(editData?.email || '');
            setPhone(editData?.phone || '');
            setStreet(editData?.address?.street || '');
            setState(editData?.address?.state || '');
            setCity(editData?.address?.city || '');
            setZipCode(editData?.address?.zipCode || '');
        } else {
            setName('');
            setEmail('');
            setPhone('');
            setStreet('');
            setState('');
            setCity('');
            setZipCode('');

        }
    }, [editData, isUpdate]);

    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const validation = () => {
        let error = false;
        if (!name) {
            error = true;
            handleError('Please input Brand Name...', 'name');
        }
        if (!email) {
            error = true;
            handleError('Please input Email ...', 'email');
        }
        if (!phone) {
            error = true;
            handleError('Please input Phone ...', 'phone');
        }
        if (!street) {
            error = true;
            handleError('Please input Street...', 'street');
        }
        if (!state) {
            error = true;
            handleError('Please input State ...', 'state');
        }
        if (!city) {
            error = true;
            handleError('Please input City ...', 'city');
        }
        if (!zipCode) {
            error = true;
            handleError('Please input ZipCode...', 'zipCode');
        }
        return error;
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleSubmit = async () => {
        const hasError = validation();
        if (!hasError) {
            try {
                let data;
                if (isUpdate) {
                    data = await post_data(`buyers/update-buyer/${editData?._id}`, { name, email, phone, address: { street, state, city, zipCode } });
                    setOpen(false);
                } else {
                    data = await post_data(`buyers/create-buyer`, { name, email, phone, address: { street, state, city, zipCode } });
                    setOpen(false);
                }

                if (data?.status === true) {
                    setName('');
                    setEmail('');
                    setPhone('');
                    setStreet('');
                    setState('');
                    setCity('');
                    setZipCode('');
                    toast.success(data?.message || 'Operation successful!');
                    getAllBuyers();
                } else {
                    toast.error('Something went wrong!');
                }
            } catch (error) {
                toast.error('An error occurred. Please try again.');
            }
        }
    };



    useEffect(() => {
        setStateList(State.getStatesOfCountry(countryCode));
    }, [countryCode]);

    useEffect(() => {
        const fetchedCities = City.getCitiesOfState(countryCode, state);
        setCityList(fetchedCities);
    }, [state, countryCode]);


    const handleStateChange = (event, newValue) => {
        if (newValue) {
            const stateCode = newValue.isoCode;
            const countryCodes = countryCode;

            const fetchedCities = City.getCitiesOfState(countryCodes, stateCode);
            setCityList(fetchedCities);
            setState(stateCode);
            setCity('')
        }
    };

    const handleCityChange = (event, newValue) => {
        if (newValue) {
            setCity(newValue.name);
        }
    };


    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            {isUpdate ? 'Edit Buyers' : 'Create Buyers'}
                        </Typography>
                        <Grid container spacing={2} style={{ marginTop: 10 }}>
                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.name)}
                                    helperText={errors.name}
                                    onFocus={() => handleError('', 'name')}
                                    value={name}
                                    label="Name"
                                    onChange={(e) => setName(e.target.value)}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.email)}
                                    helperText={errors.email}
                                    onFocus={() => handleError('', 'email')}
                                    value={email}
                                    label="Email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.phone)}
                                    helperText={errors.phone}
                                    onFocus={() => handleError('', 'phone')}
                                    value={phone}
                                    label="Phone"
                                    onChange={(e) => {
                                        const newValue = e.target.value; if (/^\d*$/.test(newValue)) {
                                            const newValue = e.target.value.replace(/\D/g, '').slice(0, 10)
                                            setPhone( newValue)
                                            setErrors((prev) => ({ ...prev, phone: '' }));
                                        } else {
                                            setErrors((prev) => ({ ...prev, phone: 'Only numbers are allowed' }));
                                        }
                                    }}
                                    // onChange={(e) => setPhone(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.street)}
                                    helperText={errors.street}
                                    onFocus={() => handleError('', 'street')}
                                    value={street}
                                    label="street"
                                    onChange={(e) => setStreet(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    fullWidth
                                    // style={{ marginBottom: 10 }}
                                    id="state-select"
                                    options={stateList}
                                    value={stateList.find(s => s.isoCode === state) || null}
                                    onChange={handleStateChange}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            {...props}
                                            value={option.isoCode}
                                            key={option.isoCode}
                                        >
                                            {option.name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={Boolean(errors.state)}
                                            helperText={errors.state}
                                            onFocus={() => handleError('', 'state')}
                                            label="Select State"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>

                                <Autocomplete
                                    fullWidth
                                    id="city-select"
                                    options={cityList}
                                    value={cityList.find(c => c.name === city) || null}
                                    onChange={handleCityChange}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            {...props}
                                            value={option.name}
                                            key={option.name}
                                        >
                                            {option.name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            error={Boolean(errors.city)}
                                            helperText={errors.city}
                                            onFocus={() => handleError('', 'city')}
                                            {...params}
                                            label="Select City"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.zipCode)}
                                    helperText={errors.zipCode}
                                    onFocus={() => handleError('', 'zipCode')}
                                    value={zipCode}
                                    label="Zip Code"
                                    onChange={(e) => {
                                        const newValue = e.target.value; if (/^\d*$/.test(newValue)) {
                                            const newValue = e.target.value.replace(/\D/g, '').slice(0, 10)
                                            setZipCode( newValue)
                                            setErrors((prev) => ({ ...prev, zipCode: '' }));
                                        } else {
                                            setErrors((prev) => ({ ...prev, zipCode: 'Only numbers are allowed' }));
                                        }
                                    }}
                                    // onChange={(e) => setZipCode(e.target.value)}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
                                <Button
                                    variant="outlined"
                                    style={{
                                        textTransform: 'capitalize',
                                        color: primaryColor,
                                        border: `1px solid ${primaryColor}`,
                                        borderRadius: 0
                                    }}
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    style={{
                                        textTransform: 'capitalize',
                                        color: '#fff',
                                        background: primaryColor,
                                        borderRadius: 0
                                    }}
                                    onClick={handleSubmit}
                                    fullWidth
                                >
                                    {isUpdate ? 'Edit' : 'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
            {/* <Toaster /> */}
        </div>
    );
}
