import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import { formatDate, formatPrice } from '../../constant';

const InvoiceDetailsModals = ({ open, setOpen, data }) => {
    const handleClose = () => {
        setOpen(false);
    };

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 450,
            border: 'none',
            bgcolor: 'white',
            borderRadius: '10px',
            boxShadow: 12,
            textAlign: 'center',
        },
        heading: {
            fontWeight: 600,
            fontSize: 27,
            marginBottom: '10px',
        },
        row: {
            display: 'flex',
            width: '100%',
            gap: '10px',
            margin: '4% 0',
            fontWeight: 400,
            fontSize: 16,
        },
        dataLabel: {
            fontWeight: 400,
            fontSize: 16,
            width: '50%',
            textAlign: 'left'
        },
        dataValue: {
            fontWeight: 400,
            fontSize: 16,
            marginLeft: 'auto',
            width: '50%',
            textAlign: 'right'
        },
        icon: {
            width: 22,
        },
        headingTypography: {
            fontWeight: 500,
            fontSize: 22,
            marginBottom: '10px',
            textAlign: 'left',
            p: 3,
            borderBottom: '1px solid gainsboro'
        }
    };

    // const userAddress = data?.address
    //     ? `${data.address.street}, ${data.address.city}, ${data.address.zipCode}, ${data.address.state}`
    //     : '-';

    const DataRow = ({ label, value }) => (
        <div style={styles.row}>
            <span style={styles.dataLabel}>{label}</span>
            <span style={styles.dataValue}>{value}</span>
        </div>
    );

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={styles.modalBox}>
                <Typography id="logout-modal-title" variant="h6" sx={styles.headingTypography}>
                    Invoice&apos;s Details
                </Typography>

                <Box sx={{ padding: '2% 6% 6% 6%' }}>
                    <DataRow label="Invoice No." value={data?.invoiceNumber || '-'} />
                    <DataRow label="Transaction Number" value={data?.transactionNumber || '-'} />
                    {/* <DataRow label="Client ID" value={data?.clientId?.uniqueClientId || '-'} />
                    <DataRow label="Client Name" value={data?.clientId?.companyName || '-'} />*/}
                    <DataRow label="Status" value={data?.mode || '-'} />
                    <DataRow label="Amount" value={formatPrice(data?.paymentAmount) || '-'} />
                    <DataRow label="Date" value={formatDate(data?.createdAt) || "-"} />

                    {/* <DataRow label="Verified Phone" value={data?.isPhoneVerified ? <img src='/images/check-icon.svg' style={styles.icon} alt="Verified" /> : '-'} />
                    <DataRow label="Verified Email" value={data?.isEmailVerified ? <img src='/images/check-icon.svg' style={styles.icon} alt="Verified" /> : '-'} />
                    <DataRow label="Premium User" value={data?.isMembership ? <img src='/images/check-icon.svg' style={styles.icon} alt="Verified" /> : '-'} /> */}
                    {/* <DataRow label="Aadhar Verified" value={data?.isAadharVerified ? <img src='/images/check-icon.svg' style={styles.icon} alt="Verified" /> : '-'} /> */}
                </Box>
            </Box>
        </Modal>
    );
};

export default InvoiceDetailsModals;
