import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Menu,
    MenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddSiteModal from '../models/AddSiteModal';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
        '& .MuiCheckbox-root': {
            color: '#a3a3a3',
        },
    },
    stageButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        '&.closedOwn': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.analysis': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.qualification': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.closedLost': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
    },
    avatarGroup: {
        display: 'flex',
        alignItems: 'center',
    },
    avatarName: {
        marginLeft: '8px',
        fontSize: 14,
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});

const tableHeader = ['S No.', 'Site Name', 'Address'];

const SiteTableName = ({
    siteData,
    index,
    setSiteData,
    isUpdate,
    vandorTableOpen,
    setSiteTableOpen,
    screen,
    formData,
    setFormData,
    // handleSiteSubmit,
}) => {

    console.log('index', index);
    console.log('formData vendor table', formData?.gps[index]?.siteDetails)
    const classes = useStyles();

    const [rowsData, setRowsData] = useState(siteData || []);
    const [updateData, setUpdateData] = useState([]);
    const [siteUpdateOpen, setSiteUpdateOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);

    const handleCheckboxToggle = (index) => {
        setRowsData((prevRows) => {
            const updatedRows = [...prevRows];
            updatedRows[index].checked = !updatedRows[index].checked;
            return updatedRows;
        });
    };

    const handleMenuOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(index);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentRow(null);
    };


    const handleUpdateModel = (row) => {
        setSiteUpdateOpen(true);
        setUpdateData(row)
        setAnchorEl(null);
        setCurrentRow(null);
    };
    const handleDelete = () => {
        // setFormData((prevFormData) => {
        //     console.log('prevFormData', prevFormData);
        // })
    }
    const handleSiteSubmit = (newSite) => {
        setSiteData([newSite])
        setRowsData([newSite]);
    }

    return (
        <TableContainer
            style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}
        >
            <Table className={classes.root}>
                <TableHead>
                    <TableRow>
                        {tableHeader.map((item, index) => (
                            <TableCell
                                key={index}
                                style={{ borderRight: '1px solid gainsboro', padding: '16px 10px' }}
                            >
                                {item}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key={index}>
                        <TableCell
                            style={{ borderRight: '1px solid gainsboro', textAlign: 'center' }}
                        >
                            {index + 1}
                        </TableCell>
                        <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                            {formData?.gps[index]?.siteDetails?.name}
                        </TableCell>
                        <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                        {`${formData?.gps?.[index]?.siteDetails?.state ?? ''} ${formData?.gps?.[index]?.siteDetails?.city ?? ''} ${formData?.gps?.[index]?.siteDetails?.street ?? ''} ${formData?.gps?.[index]?.siteDetails?.zipCode ?? ''}`.trim()}
                        </TableCell>
                        <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                            <IconButton
                                className={classes.moreIcon}
                                onClick={(event) => handleMenuOpen(event, index)}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl) && currentRow === index}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={() => handleUpdateModel(formData?.gps[index]?.siteDetails)}>Edit</MenuItem>
                                <MenuItem onClick={handleDelete}>Delete</MenuItem>
                            </Menu>
                            <AddSiteModal
                                isUpdate={true}
                                siteUpdateOpen={siteUpdateOpen}
                                handleSiteSubmit={handleSiteSubmit}
                                setSiteUpdateOpen={setSiteUpdateOpen}
                                updateData={updateData}
                                setSiteTableOpen={setSiteTableOpen}
                                setUpdateData={setUpdateData}
                                screen="vendorTable"
                                index={index}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SiteTableName;
