import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Box,
  TextField,
  Button,
  Typography,
  Stack,
  styled,
  Grid,
} from "@mui/material";
import { get_data, post_data } from "../api";
import toast from "react-hot-toast";
import { formatDate, formatTime, primaryColor } from "../constant";

const StyledQuillWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  "& .ql-toolbar": {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const PrivacyPolicyForm = () => {
  const [formData, setFormData] = useState({
    title: "",
    content: "",
  });
  const [isEdit, setIsEdit] = useState(false)
  const [lastUpdateDate,setLastUpdateDate]=useState(new Date())
  const [editData, setEditData] = useState({})
  const fetchingData = async () => {
    let result = await get_data(`legel/get-all-legels`)
    if (result?.status) {

      let temp = result?.data?.find((item) => item?.title === 'Privacy Policy')
      if (temp) {
        setIsEdit(true)
        setEditData(temp)
        setLastUpdateDate(temp?.updatedAt)
        setFormData({ ...formData, title: temp?.title, content: temp?.content })
      }
    }
  }

  useEffect(function () {
    fetchingData()
  }, [])

  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Privacy Policy Data:", formData);
    // Add your submission logic here
    let result
    if (isEdit) {
      result = await post_data(`legel/update-legel/${editData?._id}`, formData)
    } else {
      result = await post_data(`legel/create-legel`, formData)
    }

    if (result?.status) {
      toast.success('Privacy Policy updated successfully');
    } else {
      toast.error(result?.message);
    }

  };

  return (
    <Box
      sx={{
        p: 4,
        maxWidth: '90%',
        mx: "auto",
        borderRadius: 2,
        backgroundColor: '#fff',
        height: 800
      }}
    >
      <Typography variant="h5" component="h2" gutterBottom>
        Privacy Policy
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>

          <Grid item xs={9}>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              value={formData.title}
              onChange={(e) => handleChange("title", e.target.value)}
              disabled
            />
            <ReactQuill
              theme="snow"
              value={formData.content}
              onChange={(value) => handleChange("content", value)}
              placeholder="Write your privacy policy content here..."
              style={{ height: 600, marginTop: '4%' }}
            />
          </Grid>

          <Grid item xs={3}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              style={{ background: primaryColor, boxShadow: 'none', borderRadius: 0 }}
              fullWidth
            >
              {isEdit ? 'Update' : 'Submit'}
            </Button>
            <div style={{
              fontSize:'.9rem',
              marginTop:'5px',
              
            }}>Last Update Date and time :- {formatDate(lastUpdateDate)} at {formatTime(lastUpdateDate)}</div>
          </Grid>


        </Grid>
      </form>
    </Box>
  );
};

export default PrivacyPolicyForm;