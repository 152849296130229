import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import { createButtonBg, primaryColor } from "../../../constant";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../../api";
import EmptyPage from "../../../components/EmptyPage";
import SalesTable from "../../../components/tables/SalesTable";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import SearchComponent from "../../../components/SearchComponent";
import SalesTransactionModal from "../../../components/models/SalesTransactionModal";
import RentalTable from "../../../components/tables/RentalTable";
import toast, { Toaster } from "react-hot-toast";
import InvoiceTransactionModal from "../../../components/models/InvoiceTransactionModal";


const dummyData = [
    { ordersId: "ORD12346", name: "Smartphone", amounts: "$800", itemId: "ITM9877" },
    { ordersId: "ORD12347", name: "Headphones", amounts: "$150", itemId: "ITM9878" },
    { ordersId: "ORD12348", name: "Tablet", amounts: "$500", itemId: "ITM9879" },
    { ordersId: "ORD12349", name: "Monitor", amounts: "$300", itemId: "ITM9880" }
]

const rangePickerStyle = {
    display: 'flex',
    cursor: 'pointer',
    fontSize: 14,
    background: 'white',
    padding: '0% 0% 0% 0%',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    color: '#7E8299',
    cursor: 'pointer'
};



export default function Sales() {
    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [title, setTitle] = useState('Ecommerce')
    const [value, setValue] = useState('')
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [open, setOpen] = useState(false)
    const [fromDate, setFromDate] = useState([])
    const [toDate, setToDate] = useState([])
    const [salesData, setSalesData] = useState([])
    const [isRental, setIsRental] = useState(false)

    const getAllOrders = async () => {
        try {
            let Result = await get_data(`order/get-all-sales?pageNumber=${currentPage}`)
            if (Result.status == true) {
                setLoading(false)
                setData(Result?.data?.sales)
                setTotalData(Result?.data?.totalSales)
                setTotalPages(Result?.data?.totalPages)
                // console.log(Result)
            }
        } catch (error) {
            setLoading(false);
        }
    }


    useEffect(() => {
        if (currentPage) {
            getAllOrders()
        }
    }, [currentPage])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }, []);


    const itemsPerPage = 15;
    const count = data?.length;


    const tableHeader = [
        "S No.", 'Order Id', 'Product Id', "Name", "Product Name", "Amount", "Shipping Addess", "Status"
    ]

    const tableHeader2 = ["S No.", 'Invoice number', 'Invoice name', "Email", 'transaction number', 'paymentAmount', "Date"]

    const handleNavigate = () => {
        // navigate('/admin/dashboard/orders/create')
        // window.scrollTo(0, 0)
    }
    const handleTitleChange = async (e) => {
        setTitle(e.target.value)
        if (e.target.value === "Ecommerce") {
            let Result = await get_data(`order/get-all-sales?pageNumber=${currentPage}`)
            console.log("fff",Result)
            if (Result.status == true) {
                setIsRental(false)
                setData(Result?.data?.sales)
                setTotalData(Result?.data?.totalSales)
                setTotalPages(Result?.data?.totalPages)
            }
        } else {
            let Result = await get_data(`invoice/get-all-sales?pageNumber=${currentPage}`)
            console.log("fff",Result)
            if (Result.status == true) {
                setIsRental(true)
                setData(Result?.data?.sales)
                setTotalData(Result?.data?.totalSales)
                setTotalPages(Result?.data?.totalPages)
            }
        }
    }


    const handleSearch = async () => {
        setLoading(true);
        if (value.length > 0) {
            let data
            if (isRental) {
                data = await get_data(`invoice/search-invoice/${value}?pageNumber=${currentPage}`)
                if (data.status) {
                    setData(data?.data?.invoices)
                    setTotalData(data?.data?.totalInvoices)
                    setTotalPages(data?.data?.totalPages)
                }
            } else {
                data = await get_data(`order/search-orders/${value}?pageNumber=${currentPage}`)
                if (data.status) {
                    setData(data?.data?.orders)
                    setTotalData(data?.data?.totalOrders)
                    setTotalPages(data?.data?.totalPages)
                }
            }

        } else {
            getAllOrders(currentPage);
        }
        setLoading(false);
    }


    const handleDateChange = (dates) => {
        if (dates && dates?.length === 2) {
            setDateRange(dates);
        }
    };

    // useEffect(() => {
    //     if (startDate && endDate) {
    //         let search = true
    //         fetchExportData(search);
    //     }
    // }, [startDate, endDate]);



    const fetchExportData = async (search) => {
        try {
            const fromDate = moment(startDate).toISOString();
            const toDate = moment(endDate).toISOString();
            let response
            if (isRental) {
                response = await post_data('invoice/get-all-sales-by-date', {
                    from: fromDate,
                    to: toDate
                })
                if (response?.status === true) {
                    setSalesData(response?.data?.invoice)
                    setData(response?.data?.invoice)
                    // search ? setOpen(false) : setOpen(true)
                    setOpen(true)
                    setFromDate(response?.data?.fromDate)
                    setToDate(response?.data?.toDate)
                } else {
                    toast.error('Error! Something went wrong!')
                }
            } else {
                response = await post_data('order/get-all-sales-by-date', {
                    from: fromDate,
                    to: toDate
                })
                if (response?.status === true) {
                    setSalesData(response?.data?.salesRecords)
                    setData(response?.data?.salesRecords)
                    // search ? setOpen(false) : setOpen(true)
                    setOpen(true)
                    setFromDate(response?.data?.fromDate)
                    setToDate(response?.data?.toDate)
                } else {
                    toast.error('Error! Something went wrong!')
                }
            }

        } catch (error) {
        }
    };

    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />

            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>


                <div style={{ display: 'flex', marginBottom: '1%', width: '100%' }}>
                    <div style={{ gap: 10, display: 'flex' }}>
                        <FormControl sx={{ minWidth: 120, width: 150 }} size="small">
                            <InputLabel id="demo-select-small-label">Select</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                fullWidth
                                label="Select"
                                defaultChecked="Ecommerce"
                                value={title || 'Ecommerce'}
                                onChange={handleTitleChange}
                            >
                                <MenuItem value={'Ecommerce'}>Ecommerce</MenuItem>
                                <MenuItem value={'Rental'}>Rental</MenuItem>
                            </Select>
                        </FormControl>
                        <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />

                    </div>
                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        <div style={{ ...rangePickerStyle, border: 'none', }}>
                            <DatePicker
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                dateFormat="dd-MM-yyyy"
                                maxDate={new Date()}
                                customInput={
                                    <button style={{ border: 'none', background: '#fff', cursor: 'pointer', }}>
                                        {startDate && endDate ? `${moment(startDate).format('DD-MM-YYYY')} to ${moment(endDate).format('DD-MM-YYYY')}` : 'Select Date Range'}
                                    </button>
                                }
                            />
                        </div>
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: primaryColor,
                                color: '#ffff',
                                marginLeft: 'auto',
                                padding: '5px 25px',
                                cursor: 'pointer'
                            }}
                            onClick={fetchExportData}
                        >
                            Export
                        </Button>

                    </div>

                </div>

                {/* <div style={{ gap: 10, display: 'flex', justifyContent: 'right', marginBottom: '1%', width: '100%' }}>

                 
                </div> */}

                {
                    data?.length === 0 ?
                        <>
                            <EmptyPage />
                        </>
                        :
                        <>
                            {isRental ? <>
                                <RentalTable
                                    data={data}
                                    tableHeader={tableHeader2}
                                    itemsPerPage={itemsPerPage}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    count={count}
                                    totalData={totalData}
                                    totalPages={totalPages}
                                    loading={loading}
                                    setLoading={setLoading}
                                    getAllOrders={getAllOrders}

                                />
                                <InvoiceTransactionModal
                                    open={open}
                                    isRental={isRental}
                                    setOpen={setOpen}
                                    fromDate={fromDate}
                                    toDate={toDate}
                                    data={salesData}
                                />
                            </>
                                : <>
                                    <SalesTable
                                        data={data}
                                        tableHeader={tableHeader}
                                        itemsPerPage={itemsPerPage}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        count={count}
                                        totalData={totalData}
                                        totalPages={totalPages}
                                        loading={loading}
                                        setLoading={setLoading}
                                        getAllOrders={getAllOrders}
                                    />

                                    <SalesTransactionModal
                                        isRental={isRental}
                                        open={open}
                                        setOpen={setOpen}
                                        fromDate={fromDate}
                                        toDate={toDate}
                                        data={salesData}
                                    />
                                </>
                            }




                        </>
                }
            </Grid>
        </>
    )
}