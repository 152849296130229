import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import { formatDate } from '../../constant';
// import { serverURL } from '../api';

const VendorClientDetailModal = ({ open, setOpen, data }) => {
    const handleClose = () => {
        setOpen(false);
    };

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 555,
            border: 'none',
            bgcolor: 'white',
            borderRadius: '10px',
            boxShadow: 12,
            textAlign: 'center',
            maxHeight: '80vh',
            overflow: 'auto',
        },
        heading: {
            fontWeight: 600,
            fontSize: 27,
            marginBottom: '10px',
        },
        row: {
            display: 'flex',
            width: '100%',
            gap: '10px',
            margin: '4% 0',
            fontWeight: 400,
            fontSize: 16,
        },
        dataLabel: {
            fontWeight: 400,
            fontSize: 16,
            width: '50%',
            textAlign: 'left'
        },
        dataValue: {
            fontWeight: 400,
            fontSize: 16,
            marginLeft: 'auto',
            width: '50%',
            textAlign: 'right',
            wordBreak: 'break-all',
            wordWrap: 'wrap',
        },
        icon: {
            width: 22,
        },
        headingTypography: {
            fontWeight: 500,
            fontSize: 22,
            marginBottom: '10px',
            textAlign: 'left',
            p: 3,
            borderBottom: '1px solid gainsboro'
        }
    };

    const DataRow = ({ label, value }) => (
        <div style={styles.row}>
            <span style={styles.dataLabel}>{label}</span>
            <span style={styles.dataValue}>{value}</span>
        </div>
    );

    const address = data?.address;
    const formattedAddress = address
        ? `${address.street || ''}, ${address.landmark || ''}, ${address.city || ''}, ${address.state || ''}, ${address.zipCode || ''}`
        : "N/A";

    console.log("DATA DATE", data)
    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={styles.modalBox}>
                <Typography id="logout-modal-title" variant="h6" sx={styles.headingTypography}>
                    Company&apos;s Details
                </Typography>
                <Box sx={{ padding: '2% 6% 6% 6%' }}>
                    <DataRow label="Company Id" value={data?.uniqueClientId || '-'} />
                    <DataRow label="Company Name" value={data?.companyName || '-'} />
                    <DataRow label="email" value={data?.email || '-'} />
                    <DataRow label="Phone" value={data?.contactNo || '-'} />
                    <DataRow label="Address" value={`${formattedAddress}`} />
                    <DataRow label="Date" value={data?.createdAt && !isNaN(new Date(data.createdAt).getTime()) ? formatDate(data.createdAt) : "--/--/--"} />
                    {/* <DataRow
                        label="Images"
                        value={
                            data?.images && data?.images.length > 0
                                ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 5 }}>
                                    {data?.images.map((image, index) => (
                                        <img key={index} src={`${serverURL}/uploads/vendor-images/${image}`} alt={`image-${index}`} style={{ maxWidth: '40px', marginRight: '10px' }} />
                                    ))} </div> : '-'
                        }
                    /> */}

                </Box>
            </Box>
        </Modal>
    );
};

export default VendorClientDetailModal;
