import * as React from 'react';
import { useState } from 'react';
import { TextField, Button, Box, Grid } from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import { primaryColor } from '../constant';
import { get_data, post_data } from '../api';
import { useLocation, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

export default function CreateInventory() {
    const location = useLocation();
    const isUpadate = location?.state?.isUpadate || '';
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState(location?.state?.row || {
        name: '',
        totalStock: 0,
        activeStock: 0,
        nonActiveStock: ''
    });

    let navigate = useNavigate();

    const getCilentDetails = async () => {
        let result = await get_data(`inventory/get-inventory/${localStorage.getItem('inventoryId')}`);
        if (result?.status === true) {
            setFormData({
                companyId: result?.data?._id,
                name: result?.data?.name,
                nonActiveStock: result?.data?.nonActiveStock || 0,
                totalStock: result?.data?.totalStock || 0,
                activeStock: result?.data?.activeStock,
            });
        }
    };

    React.useEffect(() => {
        if (localStorage.getItem('inventoryId')) {
            getCilentDetails();
            // setIsUpdate(true);
        }
    }, []);

    const validateForm = () => {
        const newErrors = {};
        // if (!formData.name.trim()) {
        //     newErrors.name = 'Inventory name is required';
        // }
        // if (!formData.totalStock) {
        //     newErrors.totalStocky = 'totalStock quantity is required';
        // }
        // if (!formData.activeStock) {
        //     newErrors.activeStock = 'activeStock quantity is required';
        // }
        // if (typeof formData.status === 'undefined' || formData.status === '') {
        //     newErrors.status = 'Inventory status is required';
        // }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            setLoading(true);
            try {
                const response = await post_data('inventory/create-inventory', formData);
                if (response.status) {
                    setLoading(false);
                    toast.success("Inventory created successfully!");
                    setFormData({ name: '', totalStock: 0, activeStock: 0, nonActiveStock: 0 });
                    navigate('/admin/dashboard/inventory');
                } else {
                    setLoading(false);
                    toast.error("Something went wrong!");
                }
            } catch (error) {
                setLoading(false);
                toast.error("An error occurred!");
            }
        }
    };
    const handleUpdate = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            setLoading(true);
            try {
                const response = await post_data(`inventory/update-inventory/${localStorage.getItem('inventoryId')}`, formData);
                if (response.status) {
                    setLoading(false);
                    toast.success("Inventory updated successfully!");
                    navigate('/admin/dashboard/inventory');
                } else {
                    setLoading(false);
                    toast.error("Something went wrong!");
                }
            } catch (error) {
                setLoading(false);
                toast.error("An error occurred!");
            }
        }
    };

    const handleDescriptionChange = (value) => {
        setFormData({ ...formData, description: value });
    };

    const modules = React.useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', "strike"],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['image', "link"],
                ['clean'],
            ]
        }
    }), []);

    return (
        <div>
            <Toaster position="top-right" reverseOrder={false} />
            <Box
                component="form"
                onSubmit={isUpadate ? handleUpdate : handleSubmit}
                display="flex"
                flexDirection="column"
                gap={2}
                margin="auto"
                padding="35px"
                bgcolor="white"
                borderRadius={2}
            >
                <Grid container spacing={2} style={{ width: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                    {/* <Grid item md={4}>
                        <TextField
                            label="Name"
                            name="name"
                            value={formData?.name || ""}
                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                            fullWidth
                            variant="outlined"
                            error={!!errors?.name}
                            helperText={errors?.name}
                        />
                    </Grid> */}
                    <Grid item md={4}>
                        <TextField
                             
                            label="Total Stock"
                            name="totalStock"
                            value={formData?.totalStock || ""}
                            onChange={(e) => setFormData({ ...formData, totalStock: e.target.value })}
                            fullWidth
                            variant="outlined"
                            error={!!errors?.totalStock}
                            helperText={errors?.totalStock}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                             
                            label="Active Stock"
                            name="activeStock"
                            value={formData?.activeStock}
                            onChange={(e) => setFormData({ ...formData, activeStock: e.target.value })}
                            fullWidth
                            variant="outlined"
                            error={!!errors?.activeStock}
                            helperText={errors?.activeStock}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                             
                            label="Non Active Stock"
                            name="nonActiveStock"
                            value={formData?.nonActiveStock}
                            onChange={(e) => setFormData({ ...formData, nonActiveStock: e.target.value })}
                            fullWidth
                            variant="outlined"
                            error={!!errors?.nonActiveStock}
                            helperText={errors?.nonActiveStock}
                        />
                    </Grid>
                    {/* <Grid item md={12}>
                        <ReactQuill
                            value={formData.description}
                            modules={modules}
                            style={{ height: 150, marginBottom: '10%' }}
                            onChange={handleDescriptionChange}
                            theme="snow"
                            placeholder="Enter Inventory description"
                        />
                    </Grid> */}
                    <Grid item md={12}>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={loading}
                            style={{
                                backgroundColor: primaryColor,
                                color: 'white',
                                width: '25%',
                                borderRadius: 0,
                                boxShadow: 'none',
                                padding: '10px 0',
                                fontSize: '16px',
                            }}
                        >
                            {loading ? (isUpadate ? 'Updating...' : 'Submitting...') : (isUpadate ? 'Update' : 'Submit')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}
