import * as React from 'react';
import { Button, Grid, Typography } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useState } from "react";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoInstagram } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

export default function Footer({ setScreenLoading }) {
    const navigate = useNavigate()
    const [dense, setDense] = useState(false);
    const theme = useTheme();
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'));
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));

    const currentYear = new Date().getFullYear();

    const legal = [
        {
            title: 'Privacy Policy',
            link: '/privacy-policy'
        },
        {
            title: 'Terms and Conditions',
            link: '/terms'
        },
        {
            title: 'Return & Cancellation Policy',
            link: '/return-policy'
        },
        {
            title: 'Parts Policy',
            link: '/parts-policy'
        },
        {
            title: 'Feedback Policy',
            link: '/feedback-policy'
        },
        // {
        //     title: 'Shipping Policy',
        //     link: '/shipping-policy'
        // },
        // {
        //     title: 'Network Policy',
        //     link: '/network-policy'
        // },
        // {
        //     title: 'AMC Policy',
        //     link: '/amc-policy'
        // },
        // {
        //     title: 'Insurance Policy',
        //     link: '/insurance-policy'
        // },
    ];
    const legal2 = [
        {
            title: 'Shipping Policy',
            link: '/shipping-policy'
        },
        {
            title: 'Network Policy',
            link: '/network-policy'
        },
        {
            title: 'AMC Policy',
            link: '/amc-policy'
        },
        {
            title: 'Insurance Policy',
            link: '/insurance-policy'
        }
    ]

    const help = [
        {
            title: 'Contact us',
            link: '/contact'
        },
        {
            title: 'FAQ',
            link: '/faq'
        }
    ];

    const services = [
        {
            title: 'Cars',
            link: '/create/ad/cars'
        },
        {
            title: 'Bikes',
            link: '/create/ad/bikes'
        },
        {
            title: 'Auto Parts',
            link: '/create/ad/auto-parts'
        },
        {
            title: 'Spare Vehicles',
            link: '/create/ad/spare-vehicles'
        }
    ];

    const explore = [
        {
            title: 'Home',
            link: '/'
        },
        {
            title: 'Blog',
            link: '/blog'
        },
        {
            title: 'Partner',
            link: '/partner'
        }
    ];


    const handleItemClick = (item) => {
        navigate(`${item.link}`)
        window.scroll(0, 0)
    };

    return (
        <div>
            <div className='footer-section' style={{ padding: matches_md ? '6% 3%' : '3% 8%' }}>

                <Grid container spacing={1}>
                    <Grid item md={2} style={{ width: '50%' }}>
                        <img src="/images/oredogps-logo.avif" style={{ width: 60, cursor: 'pointer' }} />

                        <p className='footer-txt'>
                            support@oredogps.com <br />
                            +91 8790200520 <br /><br />
                            6-67, Yerrakunta, Chandrayangutta, Hyderabad, Telangana 500005
                        </p>
                    </Grid>

                    <Grid item md={2} style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
                        <div>
                            <Typography style={{ fontWeight: 600, fontSize: 18, marginBottom: 5 }}>
                                Explore
                            </Typography>
                            <List dense={dense} style={{ marginTop: '2%' }}>
                                {
                                    explore.map((item, i) => {
                                        return (
                                            <ListItem key={i} style={{ paddingLeft: 0, padding: '0', cursor: 'pointer' }}>
                                                <ListItemText><span className='footer-txt'>{item?.title}</span></ListItemText>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        </div>
                    </Grid>

                    <Grid item md={2} style={{ width: '30%', marginTop: matches_md ? '5%' : 0 }}>
                        <div>
                            <Typography style={{ fontWeight: 600, fontSize: 18, marginBottom: 5 }}>
                                Buy & Sell
                            </Typography>
                            <List dense={dense} style={{ marginTop: '2%' }}>
                                {
                                    services.map((item, i) => {
                                        return (
                                            <ListItem key={i} style={{ paddingLeft: 0, padding: '0' }}>
                                                <ListItemText><span className='footer-txt'>{item?.title}</span></ListItemText>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        </div>
                    </Grid>

                    <Grid item md={2} style={{ width: '30%', marginTop: matches_md ? '5%' : 0 }}>
                        <div>
                            <Typography style={{ fontWeight: 600, fontSize: 18, marginBottom: 5 }}>
                                Help
                            </Typography>
                            <List dense={dense} style={{ marginTop: '2%' }}>
                                {
                                    help.map((item, i) => {
                                        return (
                                            <ListItem key={i} style={{ paddingLeft: 0, padding: '0', cursor: 'pointer' }}>
                                                <ListItemText onClick={() => handleItemClick(item)}><span className='footer-txt'>{item?.title}</span></ListItemText>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List></div>
                    </Grid>


                    <Grid item md={2.5} style={{ width: '30%', marginTop: matches_md ? '5%' : 0 }}>
                        <div>
                            <Typography style={{ fontWeight: 600, fontSize: 18, marginBottom: 5 }}>
                                Legal
                            </Typography>
                            <List dense={dense} style={{ marginTop: '2%' }}>
                                {
                                    legal.map((item, i) => {
                                        return (
                                            <ListItem key={i} style={{ paddingLeft: 0, padding: '0', cursor: 'pointer' }}>
                                                <ListItemText onClick={() => handleItemClick(item)}><span className='footer-txt' >{item?.title}</span></ListItemText>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        </div>
                    </Grid>
                    <Grid item md={1.5} style={{ width: '30%', marginTop: matches_md ? '5%' : 0 }}>
                        <div>
                            <List dense={dense} style={{ marginTop: '15%' }}>
                                {
                                    legal2.map((item, i) => {
                                        return (
                                            <ListItem key={i} style={{ paddingLeft: 0, padding: '0', cursor: 'pointer' }}>
                                                <ListItemText onClick={() => handleItemClick(item)}><span className='footer-txt' >{item?.title}</span></ListItemText>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        </div>
                    </Grid>
                </Grid>

                <hr style={{ opacity: '30%', margin: '1% 0' }} />

                <center>
                    <div style={{ display: 'flex' }}>
                        <div className='footer-txt' style={{ margin: 0, divadding: 0 }}>
                            © {currentYear} Oredo GPS Solutions PVT LTD. All rights reserved.
                        </div>
                        <div className='footer-txt' style={{ margin: 0, marginLeft: 'auto', display: 'flex', gap: 10, color: 'gray' }}>
                            <FaFacebookF style={{ cursor: 'pointer' }} />
                            <FaXTwitter style={{ cursor: 'pointer' }} />
                            <IoLogoInstagram style={{ cursor: 'pointer' }} />
                            <FaLinkedin style={{ cursor: 'pointer' }} />
                        </div>
                    </div>
                </center>

            </div>
        </div>
    )
}