import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Avatar,
    Button,
    Typography,
    Menu,
    MenuItem,
    IconButton,
    Pagination,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TableLoader from './TableLoader';
import Swal from 'sweetalert2';
import { post_data } from '../../api';
import toast, { Toaster } from 'react-hot-toast';
import { primaryColor } from '../../constant';
import AddAdminSubVendorModals from '../models/AddAdminSubVendorModals';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
        '& .MuiCheckbox-root': {
            color: '#a3a3a3',
        },
    },
    stageButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        '&.closedOwn': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.analysis': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.qualification': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.closedLost': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
    },
    avatarGroup: {
        display: 'flex',
        alignItems: 'center',
    },
    avatarName: {
        marginLeft: '8px',
        fontSize: 14
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});

const AdminSubVendorsTable = ({ data, tableHeader, getAllSubVendors,refresh, setRefresh, setLoading, totalData, loading, currentPage, setCurrentPage, itemsPerPage, totalPages }) => {

    const classes = useStyles();
    const [rowsData, setRowsData] = useState(data || []);
    const [updateData, setUpdateData] = useState('');
    const [editOpen, setEditOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);

    const handleCheckboxToggle = (index) => {
        const updatedRows = [...rowsData];
        updatedRows[index].checked = !updatedRows[index].checked;
        setRowsData(updatedRows);
    };

    const handleMenuOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(index);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentRow(null);
    };
    const handleMenuDelete = (row) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: primaryColor,
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = await post_data(`sub-vendor/delete-sub-vendor/${row?._id}`)
                    if (data.status) {
                        toast.success("Vendor Deleted Successfully");
                       setRefresh(!refresh)
                    }
                    else {
                        toast.error("Something Went Wrong");
                    }
                } catch (error) {
                }
            }
        });

        setAnchorEl(null);
        setCurrentRow(null);
    };

    const handleMenuEdit = (row) => {
        setUpdateData(row)
        setEditOpen(true)
        setAnchorEl(null);
        setCurrentRow(null);
    };
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);
    console.log('updateData', updateData)
    return (
        <TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>

            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <Table className={classes.root}>
                <TableHead>
                    <TableRow>
                        {
                            tableHeader?.map((item) => {
                                return (
                                    <TableCell style={{ borderRight: '1px solid gainsboro', padding: '16px 10px' }}>{item}</TableCell>
                                )
                            })
                        }
                    </TableRow>
                </TableHead>

                {loading ?
                    <div>
                        <TableLoader />
                    </div>
                    :
                    <TableBody>
                        {rowsData.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ borderRight: '1px solid gainsboro', textAlign: 'center' }}>{index + 1}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.uniqueVendorId || '-'}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.name || '-'}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.phone || '-'}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.email || '-'}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.address?.state || '-'}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.address?.city || '-'}</TableCell>

                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                    <div className={classes.avatarGroup}>
                                        <Avatar alt={row?.owner?.name} src={row?.owner?.img} />
                                        <Typography className={classes.avatarName}>
                                            {row?.owner?.name}
                                        </Typography>
                                    </div>
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                    <IconButton
                                        className={classes.moreIcon}
                                        onClick={(event) => handleMenuOpen(event, index)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>

                                    {/* Options menu */}
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl) && currentRow === index}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem onClick={handleMenuClose}>View Details</MenuItem>
                                        <MenuItem onClick={() => handleMenuEdit(row)}>Edit</MenuItem>
                                        <MenuItem onClick={() => handleMenuDelete(row)}>Delete</MenuItem>
                                    </Menu>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                }
            </Table>

            <AddAdminSubVendorModals AdminSubVendorsTable={AdminSubVendorsTable} updateData={updateData} editOpen={editOpen} setEditOpen={setEditOpen} isUpdate={true} />
            {data?.length > 0 && (
                <div
                    style={{
                        borderTop: "1px solid gainsboro",
                        padding: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: 500,
                            fontSize: 15,
                            color: "black",
                        }}
                    >{`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}</Typography>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}

        </TableContainer>
    );
};

export default AdminSubVendorsTable;
