// // import { Avatar, Button } from '@mui/material';
// // import React, { useRef, useState } from 'react';
// // import { FaCamera } from "react-icons/fa";
// // import { serverURL, post_data } from '../../api';
// // import Swal from 'sweetalert2';
// // import { useDispatch } from 'react-redux';
// // import { login, logout } from '../../redux/slices/user-slice';
// // import { primaryColor } from '../../constant';
// // import { useNavigate } from 'react-router-dom';
// // import { AiOutlineLogout } from "react-icons/ai";

// // const ProfileCard = ({ user_data }) => {
// //     const dispatch = useDispatch()
// //     const navigate = useNavigate()
// //     const [profile, setProfile] = useState('');
// //     const fileInputRef = useRef(null);
// //     const [profileLocal, setProfileLocal] = useState('');

// //     const handleIconClick = () => {
// //         fileInputRef.current.click();
// //     };

// //     const Toast = Swal.mixin({
// //         toast: true,
// //         position: "top",
// //         showConfirmButton: false,
// //         timer: 3000,
// //         timerProgressBar: true,
// //     });

// //     const handleUploadPicture = async () => {
// //         const formData = new FormData();
// //         formData.append("profile_picture", profile);

// //         const data = await post_data(`customer/create-profile-picture/${user_data?._id}`, formData);
// //         if (data?.status) {
// //             dispatch(login(data?.data));
// //             Toast.fire({
// //                 icon: "success",
// //                 text: "Profile Uploaded",
// //                 color: "green"
// //             })
// //         }
// //         else {
// //             Toast.fire({
// //                 icon: "error",
// //                 text: "Something Went Wrong",
// //                 color: "red"
// //             })
// //         }
// //         setProfile('');
// //         setProfileLocal('');
// //     }


// //     const handleUpdatePicture = async () => {
// //         const formData = new FormData();
// //         formData.append("profile_picture", profile);

// //         const data = await post_data(`customer/update-profile-picture/${user_data?._id}`, formData);

// //         if (data?.status) {
// //             dispatch(login(data?.data));
// //             Toast.fire({
// //                 icon: "success",
// //                 text: "Profile Updated",
// //                 color: "green"
// //             })
// //         }
// //         else {
// //             Toast.fire({
// //                 icon: "error",
// //                 text: "Something Went Wrong",
// //                 color: "red"
// //             })
// //         }
// //         setProfile('');
// //         setProfileLocal('');
// //     }

// //     const handleLogout = async () => {
// //         Swal.fire({
// //             title: 'Are you sure?',
// //             text: "You won't be able to revert this!",
// //             icon: 'warning',
// //             showCancelButton: true,
// //             confirmButtonColor: ` #d33`,
// //             cancelButtonColor: `${primaryColor}`,
// //             confirmButtonText: 'Yes, Logout !'

// //         }).then(async (result) => {
// //             if (result.isConfirmed) {
// //                 localStorage.removeItem('authToken')
// //                 dispatch(logout());
// //                 navigate('/')
// //             }
// //         })

// //     }


// //     return (
// //         <div className="profile-card">
// //             <div className="profile-header" style={{ cursor: 'pointer' }}>
// //                 {user_data?.profile_picture ?
// //                     <div>
// //                         <div className="icon-container" onClick={handleIconClick}>
// //                             <Avatar className="user-icon" src={profileLocal ? profileLocal : `${serverURL}/uploads/profile-picture/${user_data?.profile_picture}`} style={{ height: '100px', width: '100px' }} />
// //                             <FaCamera className="camera-icon" />
// //                             <input
// //                                 type="file"
// //                                 ref={fileInputRef}
// //                                 style={{ display: 'none' }}
// //                                 onChange={(e) => {
// //                                     setProfile(e.target.files[0]);
// //                                     setProfileLocal(URL.createObjectURL(e.target.files[0]))
// //                                 }}
// //                             />
// //                         </div>
// //                     </div>
// //                     :
// //                     <div className="icon-container" onClick={handleIconClick}>
// //                         <Avatar className="user-icon" src={profileLocal} sx={{ height: '100px', width: '100px' }} />
// //                         <FaCamera className="camera-icon" />
// //                         <input
// //                             type="file"
// //                             ref={fileInputRef}
// //                             style={{ display: 'none' }}
// //                             onChange={(e) => {
// //                                 setProfile(e.target.files[0]);
// //                                 setProfileLocal(URL.createObjectURL(e.target.files[0]))
// //                             }}
// //                         />
// //                     </div>}
// //                 {profileLocal && <div>
// //                     <Button style={{ color: "#5DB761" }} onClick={user_data?.profile_picture ? handleUpdatePicture : handleUploadPicture}>{user_data?.profile_picture ? "Update" : "Upload"}</Button>
// //                     <Button style={{ color: "#5DB761" }} onClick={() => {
// //                         setProfile('');
// //                         setProfileLocal('');
// //                     }}>Cancel</Button>
// //                 </div>}
// //             </div>

// //             <div className="profile-info">
// //                 <h2 style={{ fontWeight: 600, fontSize: 25 }}>{user_data?.name}</h2>
// //                 <p>{user_data?.name === '' ? '' : user_data?.name}</p>
// //             </div>
// //             <div style={{ marginTop: 20 }}>
// //                 <Button variant="outlined" onClick={handleLogout} color='#ff4d4d' style={{ color: '#ff4d4d', borderRadius: '0px', boxShadow: 'none', gap: 10 }}  >
// //                     <AiOutlineLogout />
// //                     LogOut
// //                 </Button>
// //             </div>
// //         </div>
// //     );
// // };

// // export default ProfileCard;


// import React, { useRef, useState } from 'react';
// import { Avatar, Button, Box, Typography } from '@mui/material';
// import { FaCamera } from "react-icons/fa";
// import { AiOutlineLogout } from "react-icons/ai";
// import Swal from 'sweetalert2';
// import { useDispatch } from 'react-redux';
// import { login, logout } from '../../redux/slices/user-slice';
// import { serverURL, post_data } from '../../api';
// import { primaryColor } from '../../constant';
// import { useNavigate } from 'react-router-dom';

// const ProfileCard = ({ user_data }) => {
//     const dispatch = useDispatch()
//     const [profile, setProfile] = useState('');
//     const fileInputRef = useRef(null);
//     const [profileLocal, setProfileLocal] = useState('');

//     const handleIconClick = () => {
//         fileInputRef.current.click();
//     };

//     const Toast = Swal.mixin({
//         toast: true,
//         position: "top",
//         showConfirmButton: false,
//         timer: 3000,
//         timerProgressBar: true,
//     });

//     const handleUploadOrUpdatePicture = async () => {
//         const formData = new FormData();
//         formData.append("profile_picture", profile);

//         const apiEndpoint = user_data?.profile_picture
//             ? `customer/update-profile-picture/${user_data?._id}`
//             : `customer/create-profile-picture/${user_data?._id}`;

//         const data = await post_data(apiEndpoint, formData);
//         if (data?.status) {
//             dispatch(login(data?.data));
//             Toast.fire({
//                 icon: "success",
//                 text: user_data?.profile_picture ? "Profile Updated" : "Profile Uploaded",
//                 color: "green"
//             });
//         } else {
//             Toast.fire({
//                 icon: "error",
//                 text: "Something Went Wrong",
//                 color: "red"
//             });
//         }
//         setProfile('');
//         setProfileLocal('');
//     }


//     const handleUpdatePicture = async () => {
//         const formData = new FormData();
//         formData.append("profile_picture", profile);

//         const data = await post_data(`customer/update-profile-picture/${user_data?._id}`, formData);

//         if (data?.status) {
//             dispatch(login(data?.data));
//             Toast.fire({
//                 icon: "success",
//                 text: "Profile Updated",
//                 color: "green"
//             })
//         }
//         else {
//             Toast.fire({
//                 icon: "error",
//                 text: "Something Went Wrong",
//                 color: "red"
//             })
//         }
//         setProfile('');
//         setProfileLocal('');
//     }

//     const handleLogout = () => {
//         Swal.fire({
//             title: 'Are you sure?',
//             text: "You won't be able to revert this!",
//             icon: 'warning',
//             showCancelButton: true,
//             confirmButtonColor: '#d33',
//             cancelButtonColor: primaryColor,
//             confirmButtonText: 'Yes, Logout!'
//         }).then((result) => {
//             if (result.isConfirmed) {
//                 localStorage.removeItem('authToken');
//                 dispatch(logout());
//                 navigate('/');
//             }
//         });
//     };

//     return (
//         <div className="profile-card">
//             <div className="profile-header">
//                 <div>
//                     <div className="icon-container">
//                         <Avatar className="user-icon" src={""} />
//                     </div>
//                 </div>
//             </div>
//             <div className="profile-info">
//                 <h2 style={{ fontWeight: 600, fontSize: 25 }}>{user_data?.name}</h2>
//                 <p>{user_data?.name === '' ? '' : user_data?.name}</p>
//             </div>
//         </div>
//     );
// };

// export default ProfileCard;

import React, { useRef, useState } from 'react';
import { Avatar, Button, Box, Typography } from '@mui/material';
import { FaCamera } from "react-icons/fa";
import { AiOutlineLogout } from "react-icons/ai";
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { login, logout } from '../../redux/slices/user-slice';
import { serverURL, post_data } from '../../api';
import { primaryColor } from '../../constant';
import { useNavigate } from 'react-router-dom';

const ProfileCard = ({ user_data }) => {
    const dispatch = useDispatch()
    const [profile, setProfile] = useState('');
    const fileInputRef = useRef(null);
    const [profileLocal, setProfileLocal] = useState('');

    // const handleIconClick = () => {
    //     fileInputRef.current.click();
    // };

    // const Toast = Swal.mixin({
    //     toast: true,
    //     position: "top",
    //     showConfirmButton: false,
    //     timer: 3000,
    //     timerProgressBar: true,
    // });

    // const handleUploadOrUpdatePicture = async () => {
    //     const formData = new FormData();
    //     formData.append("profile_picture", profile);

    //     const apiEndpoint = user_data?.profile_picture
    //         ? `customer/update-profile-picture/${user_data?._id}`
    //         : `customer/create-profile-picture/${user_data?._id}`;

    //     const data = await post_data(apiEndpoint, formData);
    //     if (data?.status) {
    //         dispatch(login(data?.data));
    //         Toast.fire({
    //             icon: "success",
    //             text: user_data?.profile_picture ? "Profile Updated" : "Profile Uploaded",
    //             color: "green"
    //         });
    //     } else {
    //         Toast.fire({
    //             icon: "error",
    //             text: "Something Went Wrong",
    //             color: "red"
    //         });
    //     }
    //     setProfile('');
    //     setProfileLocal('');
    // }


    // const handleUpdatePicture = async () => {
    //     const formData = new FormData();
    //     formData.append("profile_picture", profile);

    //     const data = await post_data(`customer/update-profile-picture/${user_data?._id}`, formData);

    //     if (data?.status) {
    //         dispatch(login(data?.data));
    //         Toast.fire({
    //             icon: "success",
    //             text: "Profile Updated",
    //             color: "green"
    //         })
    //     }
    //     else {
    //         Toast.fire({
    //             icon: "error",
    //             text: "Something Went Wrong",
    //             color: "red"
    //         })
    //     }
    //     setProfile('');
    //     setProfileLocal('');
    // }

    // const handleLogout = () => {
    //     Swal.fire({
    //         title: 'Are you sure?',
    //         text: "You won't be able to revert this!",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#d33',
    //         cancelButtonColor: primaryColor,
    //         confirmButtonText: 'Yes, Logout!'
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             localStorage.removeItem('authToken');
    //             dispatch(logout());
    //             navigate('/');
    //         }
    //     });
    // };

    return (
        <div className="profile-card">
            <div className="profile-header">
                <div>
                    <div className="icon-container">
                        <Avatar className="user-icon" src={""} />
                    </div>
                </div>
            </div>
            <div className="profile-info">
                <h2 style={{ fontWeight: 600, fontSize: 25 }}>{user_data?.name}</h2>
                <p>{user_data?.name === '' ? '' : user_data?.name}</p>
            </div>
        </div>
    );
};

export default ProfileCard;
