import React from 'react';

import { useMediaQuery } from '@mui/material';
import ProfileCard from './ProfileCard';
import PersonalInformation from './PersonalInformation';

const UserProfile = ({ user_data }) => {

    const isTablet = useMediaQuery('(max-width: 960px)');
    const isMobile = useMediaQuery('(max-width: 600px)');
    const isDesktop = useMediaQuery('(min-width: 768px)');
   

    const containerStyle = {
        display: 'flex',
        overflowX: 'hidden',
        overflowY: 'auto',
        padding: isMobile || isTablet ? '20px 10px' : '30px',
        maxWidth: isMobile || isTablet ? '90%' : '70%',
        margin: 'auto',
        flexDirection: isMobile || isTablet ? 'column' : 'row', // Change direction based on screen size
    };

    const innerContainerStyle = {
        width: '100%',
        display: 'flex',
        gap: '3%',
        flexDirection: isMobile || isTablet ? 'column' : 'row', // Change direction based on screen size
    };

    const profileCardStyle = {
        flex: 1,
        marginBottom: isMobile || isTablet ? '20px' : '0', // Add margin on mobile and tablet
    };

    const personalInfoStyle = {
        flex: 2,
    };

    return (
        <div style={containerStyle}>
            <div style={{ width: '95%' }}>
                <div style={innerContainerStyle}>
                    {/* <div style={profileCardStyle}>
                        <ProfileCard user_data={user_data} />
                    </div> */}
                    <div style={personalInfoStyle}>
                        <PersonalInformation user_data={user_data} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserProfile;
