import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Menu,
    MenuItem,
    IconButton,
    Avatar,
    Typography,
    Pagination,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Swal from 'sweetalert2';
import { post_data, serverURL } from '../../api'; // Ensure serverURL is correctly imported
import { useNavigate } from 'react-router-dom';
import TableLoader from './TableLoader';
import EmptyPage from '../EmptyPage';
import { primaryColor } from '../../constant';
import { Toaster, toast } from 'react-hot-toast';
import VendorClientDetailModal from '../models/VendorClientDetailModal';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
    },
    stageButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        '&.closedOwn': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.analysis': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.qualification': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.closedLost': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
        '&.true': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});

const ProductsTable = ({ data, tableHeader, getAllClients, setLoading, totalData, loading, currentPage, setCurrentPage, itemsPerPage, totalPages }) => {

    const classes = useStyles();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const [open, setOpen] = useState(false);
    const [viewData, setViewData] = useState('');

    const handleMenuOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(index);
    };

    const handleMenuClose = (row) => {
        setAnchorEl(null);
        setCurrentRow(null);
    };

    const handleEdit = (row) => {
        // navigate('/admin/dashboard/products/create', { state: { row, title: 'update' } });
        setViewData(row)
        setOpen(true)
        window.scrollTo(0, 0);
        setAnchorEl(null);
        setCurrentRow(null);
    };

    const handleDelete = async (row) => {
        setAnchorEl(null);
        setCurrentRow(null);

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: primaryColor,
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = await post_data(`client/delete-client/${row?._id}`, {})
                    if (data.status) {
                        toast.success("company Deleted Successfully");
                        getAllClients();
                    }
                    else {
                        toast.error("Something Went Wrong");
                    }
                } catch (error) {
                }
            }
        });

    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);

    return (
        <>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
                <Table className={classes.root}>
                    <TableHead>
                        <TableRow>
                            {tableHeader?.map((item, idx) => (
                                <TableCell key={idx} style={{ borderRight: '1px solid gainsboro', padding: '16px 10px' }}>
                                    {item}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {loading ?
                        <div>
                            <TableLoader />
                        </div>
                        :
                        <TableBody>
                            {
                                data?.map((row, index) => {
                                    return (
                                        <>
                                            <TableRow key={index} >
                                                <TableCell style={{ borderRight: '1px solid gainsboro', textAlign: 'center', padding: '1.5% 0' }}>{startEntry + index}</TableCell>
                                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.companyName}</TableCell>
                                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.uniqueClientId}</TableCell>
                                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.contactNo}</TableCell>
                                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.email}</TableCell>
                                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.address?.city || '-'}, {row?.address?.state || '-'}</TableCell>

                                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                                    <IconButton
                                                        className={classes.moreIcon}
                                                        onClick={(event) => handleMenuOpen(event, index)}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>

                                                    {/* Options menu */}
                                                    <Menu
                                                        anchorEl={anchorEl}
                                                        open={Boolean(anchorEl) && currentRow === index}
                                                        onClose={handleMenuClose}
                                                    >
                                                        <MenuItem onClick={() => handleEdit(row)}>View Details</MenuItem>
                                                        {/* <MenuItem onClick={() => handleDelete(row)}>Delete</MenuItem> */}
                                                    </Menu>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    )
                                })
                            }
                        </TableBody>
                    }

                    <VendorClientDetailModal data={viewData} open={open} setOpen={setOpen} />
                </Table>

                {data?.length > 0 && (
                    <div
                        style={{
                            borderTop: "1px solid gainsboro",
                            padding: "2%",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            style={{
                                fontWeight: 500,
                                fontSize: 15,
                                color: "black",
                            }}
                        >{`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}</Typography>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </div>
                )}

            </TableContainer>
        </>
    );
};

export default ProductsTable;
