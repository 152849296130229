import { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { get_data } from "../../api";
import parse from 'html-react-parser';
import { formatDate, formatTime } from "../../constant";

export default function NetworkPolicy() {

    const [data, setData] = useState({})

    const fetchData = async () => {
        let result = await get_data(`legel/get-all-legels`)
        if (result?.status) {
            let temp = result?.data?.find((item) => item?.title === 'Network Policy')
            if (temp) {
                setData(temp)
            }
        }
    }

    useEffect(function () {
        fetchData()
    }, [])

    return (
        <>
            <Header />
            <div style={{ background: "linear-gradient(to bottom, #f4f4f4, white)", padding: '4% 8%' }}>
                <h2 style={{ color: 'black', margin: 0, fontWeight: 500, fontSize: 40, textAlign: 'left' }}>
                    Network Policy
                </h2>
            </div>
            <div style={{ padding: '0 8%' }}>
                <div style={{
                    fontSize: '.9rem',
                    marginTop: '5px',

                }}>Last Update Date and time :- {formatDate(data?.updatedAt)} at {formatTime(data?.updatedAt)}</div>
                <div style={{ lineHeight: '1.6' }}>
                    {data?.content ? parse(data.content) : "Content not available"}
                </div>

            </div>
            <Footer />
        </>
    )
}