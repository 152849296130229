import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, Box, CircularProgress } from '@mui/material';
import { get_data } from '../api';
import { primaryColor } from '../constant';

const SubCompanyReturnComponent = ({ clientId }) => {
    const [returnData, setReturnData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchReturnData = async () => {
        try {
            const data = await get_data("return/get-all-return-by-sub-client/" + clientId);
            if (data.status) {
                setReturnData(data?.data?.returns || []);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchReturnData();
    }, [clientId]);




    return (
        <Grid container spacing={3}>
            {returnData?.map((returnItem) => (
                <Grid item xs={12} key={returnItem._id}>
                    <Card>
                        <CardContent>
                            <Box mb={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="h6" sx={{ color: primaryColor }}>Return ID: {returnItem.uniqueReturnId}</Typography>
                                <Typography>{new Date(returnItem.createdAt).toLocaleDateString()}</Typography>
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Typography>Request ID: {returnItem.requestId}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography>Return Reason: {returnItem.returnReason || 'N/A'}</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default SubCompanyReturnComponent;
