import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button, Autocomplete } from '@mui/material';
import { formatDate, primaryColor } from '../constant';
import { useSelector } from 'react-redux';
import { post_data } from '../api';
import toast from 'react-hot-toast';

const GpsReqAssignModal = ({ open, setOpen, data }) => {
    const { user_data } = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);
    const [gpsList, setGpsList] = useState([])
    const handleClose = () => {
        setOpen(false);
    };

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            border: 'none',
            bgcolor: 'white',
            boxShadow: 12,
            textAlign: 'center',
            maxHeight: '80vh',
            overflow: 'auto',
        },
        headingTypography: {
            fontWeight: 500,
            fontSize: 22,
            marginBottom: '10px',
            textAlign: 'left',
            p: 3,
            borderBottom: '1px solid gainsboro'
        }
    };

    const [gpsUniqueIds, setGpsUniqueIds] = useState(data?.detail?.map((item) => item?.gpsUniqueId) || []);

    const handleGpsUniqueIdChange = (index, value) => {
        const updatedGpsUniqueIds = [...gpsUniqueIds];
        updatedGpsUniqueIds[index] = value;
        setGpsUniqueIds(updatedGpsUniqueIds);
    };

    const handleAssign = async (item, index) => {
        const gpsUniqueId = gpsUniqueIds[index];
        setLoading(true);

        const res = await post_data('vehicles/update-gps-uniqueId', {
            gpsUniqueId,
            id: item?._id,
            vehicleNumber: item?.vehicleNumber?.regnNumber
        });

        if (res?.status === true) {
            const payload = {
                clientId: user_data?._id,
                customerDetails: {
                    companyName: user_data?.companyName,
                    vendorName: '',
                    contactNo: user_data?.contactNo,
                    resContactNo: user_data?.resContactNo,
                    address: {
                        street: user_data?.address?.street,
                        landmark: user_data?.address?.landmark,
                        city: user_data?.address?.city,
                        state: user_data?.address?.state,
                        zipCode: user_data?.address?.zipCode
                    },
                },
                gps: [item]
            }

            const result = await post_data('request/create-request', payload);

            if (result?.status === true) {
                toast.success('Request created successfully');
                handleClose();
            } else {
                toast.error(result?.message);
            }

            setLoading(false);
        } else {
            setLoading(false);
            toast.error(res?.message);
        }
    };
    console.log("hhhhhhhhhhhhhhhhh", data?.detail)
    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={styles.modalBox}>
                <Typography id="logout-modal-title" variant="h6" sx={styles.headingTypography}>
                    Assign GPS
                </Typography>
                <Box sx={{ padding: '2% 6% 6% 6%' }}>
                    {data?.detail?.map((item, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', justifyContent: 'space-between', gap: '10px' }}>
                            <div>{item?.vehicleDetails?.regnNumber}</div>
                            <Autocomplete
                                id="gps-select"
                                options={data?.detail}
                                fullWidth
                                name="uniqueId"
                                size="small"
                                style={{
                                    border: 'none',
                                    outline: 'none',
                                    color: "#666666",
                                    width: '50%'
                                }}
                                // value={gps.find(s => s.isoCode === formData?.detail[index]?.driverDetails?.address?.country || '') || null}
                                // onChange={(event, newValue) => handleDriverCountryChange(index, event, newValue)}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        // error={!!errors[index]?.country} helperText={errors[index]?.country} onFocus={() => handleError('', 'country', index)}
                                        {...params}
                                        label="Select GPS"
                                        fullWidth
                                    />
                                )}
                            />
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: primaryColor,
                                    color: '#ffff',
                                }}
                                onClick={() => handleAssign(item, index)}
                            >
                                {loading ? 'Loading...' : 'Assign'}
                            </Button>
                        </div>
                    ))}
                </Box>
            </Box>
        </Modal>
    );
};

export default GpsReqAssignModal;
