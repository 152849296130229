import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Autocomplete from '@mui/material/Autocomplete';
import { Box, useMediaQuery, useTheme, Typography, Avatar } from '@mui/material';
import { Country, State, City } from 'country-state-city';
import { useNavigate } from "react-router-dom";
import { primaryColor } from "../constant";
import { post_data, serverURL } from "../api";
import OtpComponent from "./OtpComponent";
import toast, { Toaster } from "react-hot-toast";
import { FaRegFileAlt } from "react-icons/fa";

let dropzoneStyle = {
    border: '2px dashed ' + primaryColor,
    borderRadius: '8px',
    padding: '40px',
    textAlign: 'center',
    width: '100%',
    margin: 'auto',
    backgroundColor: '#f9f9f9',
    cursor: 'pointer',
    transition: '0.3s',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
}

let imagesBox = {
    position: 'relative',
    width: 130,
    height: 130,
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
}


export default function CompanySignup() {

    const theme = useTheme();
    const matches1 = useMediaQuery(theme.breakpoints.down('md'));
    const matches3 = useMediaQuery(theme.breakpoints.down(500));

    const navigate = useNavigate()

    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [email, setEmail] = useState("");
    const [gst, setGst] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [countryList, setCountryList] = useState([]);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [nameError, setNameError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [gpsError, setGpsError] = useState("");
    const [stateError, setStateError] = useState("");
    const [cityError, setCityError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [loader, setLoader] = useState(false);
    const [otpForm, setOtpForm] = useState(false);
    const [emailForm, setEmailForm] = useState(true);
    const [images, setImages] = useState({ bytes: '', filename: '' })
    const [images2, setImages2] = useState({ bytes: '', filename: '' })
    const [images3, setImages3] = useState({ bytes: '', filename: '' })
    const [images4, setImages4] = useState({ bytes: '', filename: '' })
    const [images5, setImages5] = useState({ bytes: '', filename: '' })
    const [imageError, setImageError] = useState('');
    const [errors, setErrors] = useState({})

    // console.log("images, images2, images3, images4, images5", images, images2, images3, images4, images5)

    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }))
    }

    const validate = () => {
        let valid = true;
        setEmailError("");
        setNameError("");
        setGpsError("")
        setPasswordError("");
        setConfirmPasswordError("");

        if (!email) {
            setEmailError("Email is required");
            valid = false;
        }
        if (!name) {
            setNameError("Name is required");
            valid = false;
        }

        if (!state) {
            setStateError("State is required");
            valid = false;
        }
        if (!phone) {
            setPhoneError("Phone number is required");
            valid = false;
        }
        if (!city) {
            setCityError("City is required");
            valid = false;
        }
        if (images.length === 0 || images.length > 5) {
            setImageError("Documents and Images is required");
            valid = false;
        }
        if (!password) {
            setPasswordError("Password is required");
            valid = false;
        }
        if (!confirmPassword) {
            setConfirmPasswordError("Confirm Password is required");
            valid = false;
        } else if (confirmPassword !== password) {
            setConfirmPasswordError("Passwords do not match");
            valid = false;
        }
        if (images.filename.length === 0) {
            handleError('Input Document', 'document')
            valid = false;
        }


        return valid;
    };

    const handleSubmit = async () => {
        if (validate()) {
            try {
                setLoader(true);
                const data = await post_data("client/send-otp-for-client-signup", { phone, email, gst, images, images2, images3, images4, images5, password, country, state, city });
                setLoader(false);
                if (data?.status === true) {
                    setOtpForm(true);
                    setEmailForm(false);
                    toast.success(data?.message)
                }
                else {
                    if (data?.response?.data?.message === 'Client Already Exists') {
                        toast.error('Clients in this Email Alrady Exists')
                    }
                    else if (data?.response?.data?.message === 'Phone Number Already Exists') {
                        toast.error('Clients in this Number Alrady Exists')
                    }

                }
            } catch (error) {
                toast.error("Something went wrong")
                setLoader(false);
            }
        }
    };

    useEffect(() => {
        setCountryList(Country.getAllCountries());
    }, []);

    const handleCountryChange = (event, newValue) => {
        if (newValue) {
            setCountry(newValue.isoCode);
            // setCountryError(false)
            const countryCode = newValue.isoCode;
            const fetchedStates = State.getStatesOfCountry(countryCode);
            setStateList(fetchedStates);
            setCityList([]);
            // setFormData(prev => ({ ...prev, country: countryCode, state: '', city: '' }));
        }
    };

    // useEffect(() => {
    //     setStateList(State.getStatesOfCountry(countryCode));
    // }, [countryCode]);


    const handleStateChange = (event, newValue) => {
        if (newValue) {
            const stateCode = newValue.isoCode;
            setStateError("");
            const fetchedCities = City.getCitiesOfState(country, stateCode);
            setCityList(fetchedCities);
            setState(stateCode);
            setCity('')
        }
    };

    const handleCityChange = (event, newValue) => {
        if (newValue) {
            setCityError("");
            setCity(newValue.name);
        }
    };

    const handleDocumant = (event) => {
        setImages({ bytes: event.target.files[0], filename: URL.createObjectURL(event.target.files[0]) })
    }
    const handleDocumant2 = (event) => {
        setImages2({ bytes: event.target.files[0], filename: URL.createObjectURL(event.target.files[0]) })
    }
    const handleDocumant3 = (event) => {
        setImages3({ bytes: event.target.files[0], filename: URL.createObjectURL(event.target.files[0]) })
    }
    const handleDocumant4 = (event) => {
        setImages4({ bytes: event.target.files[0], filename: URL.createObjectURL(event.target.files[0]) })
    }
    const handleDocumant5 = (event) => {
        setImages5({ bytes: event.target.files[0], filename: URL.createObjectURL(event.target.files[0]) })
    }
    return (
        <div style={styles.container}>
            <div style={{ ...styles.rightSide, padding: matches3 ? "20px" : '10px', width: matches3 ? '100%' : '70%' }}>
                <div style={{ width: matches3 ? '100%' : matches1 ? "95%" : '60%', margin: 'auto' }}>
                    {emailForm && (
                        <>
                            <h2 style={styles.loginHeading}>Sign up</h2>
                            <p style={styles.loginText}>
                                Fill in the details below to create your account.
                            </p>
                            <TextField
                                label="Name"
                                variant="outlined"
                                fullWidth
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                    setNameError("");
                                }}
                                error={!!nameError}
                                helperText={nameError}
                                style={styles.input}
                            />


                            <div style={{ display: 'flex', gap: 10 }}>
                                <TextField
                                    label="Phone Number"
                                    variant="outlined"
                                    fullWidth
                                    value={phone}
                                    onChange={(e) => {
                                        setPhone(e.target.value);
                                        setPhoneError("");
                                    }}
                                    error={!!phoneError}
                                    helperText={phoneError}
                                    style={{ ...styles.input, width: '100%' }}
                                />

                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setEmailError("");
                                    }}
                                    error={!!emailError}
                                    helperText={emailError}
                                    style={{ ...styles.input, width: '100%' }}
                                />
                            </div>
                            <TextField
                                label="Gst (optional)"
                                variant="outlined"
                                fullWidth
                                value={gst}
                                onChange={(e) => {
                                    setGst(e.target.value);
                                    setGpsError("");
                                }}
                                // error={!!gpsError}
                                // helperText={gpsError}
                                style={{ ...styles.input, width: '100%' }}
                            />

                            <Autocomplete
                                style={{ marginBottom: 10 }}
                                id="state-country"
                                fullWidth
                                options={countryList}
                                value={countryList.find(s => s.isoCode === country) || null}
                                onChange={handleCountryChange}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        {...props}
                                        value={option.isoCode}
                                        key={option.isoCode}
                                    >
                                        {option.name}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={!!stateError}
                                        helperText={stateError}
                                        // onFocus={() => handleError('', 'state')}
                                        label="Select Country"
                                        fullWidth
                                    />
                                )}
                            />
                            <div style={{ display: 'flex', gap: 10 }}>
                                <Autocomplete
                                    style={{ marginBottom: 10 }}
                                    id="state-select"
                                    fullWidth
                                    options={stateList}
                                    value={stateList.find(s => s.isoCode === state) || null}
                                    onChange={handleStateChange}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            {...props}
                                            value={option.isoCode}
                                            key={option.isoCode}
                                        >
                                            {option.name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={!!stateError}
                                            helperText={stateError}
                                            // onFocus={() => handleError('', 'state')}
                                            label="Select State"
                                            fullWidth
                                        />
                                    )}
                                />


                                <Autocomplete
                                    id="city-select"
                                    fullWidth
                                    options={cityList}
                                    value={cityList.find(c => c.name === city) || null}
                                    onChange={handleCityChange}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            {...props}
                                            value={option.name}
                                            key={option.name}
                                        >
                                            {option.name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            error={!!cityError}
                                            helperText={cityError}
                                            // onFocus={() => handleError('', 'city')}
                                            {...params}
                                            label="Select City"
                                            fullWidth
                                        />
                                    )}
                                />
                            </div>
                            <div style={{ display: 'flex', gap: 10, marginBottom: 10 }}>
                                <TextField
                                    label="Password"
                                    variant="outlined"
                                    type={showPassword ? "text" : "password"}
                                    fullWidth
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        setPasswordError("");
                                    }}
                                    error={!!passwordError}
                                    helperText={passwordError}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    label="Confirm Password"
                                    variant="outlined"
                                    type={showConfirmPassword ? "text" : "password"}
                                    fullWidth
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value);
                                        setConfirmPasswordError("");
                                    }}
                                    error={!!confirmPasswordError}
                                    helperText={confirmPasswordError}
                                    // style={styles.input}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div style={{ display: 'flex', gap: 10, marginBottom: 10 }}>
                                <div style={{ width: '50%' }}>
                                    <Button onFocus={() => handleError('', 'documant')} component="label" variant="contained" style={{ ...styles.documentButton }} >
                                        Document
                                        <input onChange={handleDocumant} hidden type="file" accept="image/*,application/pdf,application/msword" multiple />
                                    </Button>
                                </div>
                                <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {images?.filename ? <img
                                        src={images.filename}
                                        alt="image"
                                        style={{
                                            width: 30,
                                            height: 30,
                                            borderRadius: "8px", // To make the image rounded
                                            marginLeft: 8,
                                        }}
                                    /> :
                                        <FaRegFileAlt fontSize={30} color={primaryColor} src={images.filename} alt="image" variant="rounded" />}
                                </div>
                            </div>
                            {errors?.documant ? <div style={{ color: '#d32f2f', fontSize: 13, marginLeft: 10, marginTop: 5 }}>{errors.documant}</div> : ''}


                            <div style={{ display: 'flex', gap: 10, marginBottom: 10 }}>
                                <div style={{ width: '50%' }}>
                                    <Button onFocus={() => handleError('', 'documant2')} component="label" variant="contained" style={{ ...styles.documentButton }} >
                                        Document
                                        <input onChange={handleDocumant2} hidden type="file" accept="image/*,application/pdf,application/msword" multiple />
                                    </Button>
                                </div>
                                <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {images2?.filename ? <img
                                        src={images2.filename}
                                        alt="image"
                                        style={{
                                            width: 30,
                                            height: 30,
                                            borderRadius: "8px", // To make the image rounded
                                            marginLeft: 8,
                                        }}
                                    /> :
                                        <FaRegFileAlt fontSize={30} color={primaryColor} src={images2.filename} alt="image" variant="rounded" />}

                                </div>
                            </div>
                            {errors?.documant2 ? <div style={{ color: '#d32f2f', fontSize: 13, marginLeft: 10, marginTop: 5 }}>{errors.documant2}</div> : ''}

                            <div style={{ display: 'flex', gap: 10, marginBottom: 10 }}>
                                <div style={{ width: '50%' }}>
                                    <Button onFocus={() => handleError('', 'documant3')} component="label" variant="contained" style={{ ...styles.documentButton }} >
                                        Document
                                        <input onChange={handleDocumant3} hidden type="file" accept="image/*,application/pdf,application/msword" multiple />
                                    </Button>
                                </div>
                                <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {images3?.filename ? <img
                                        src={images3.filename}
                                        alt="image"
                                        style={{
                                            width: 30,
                                            height: 30,
                                            borderRadius: "8px", // To make the image rounded
                                            marginLeft: 8,
                                        }}
                                    /> : <FaRegFileAlt fontSize={30} color={primaryColor} src={images3.filename} alt="image" variant="rounded" />}
                                </div>
                            </div>
                            {errors?.documant3 ? <div style={{ color: '#d32f2f', fontSize: 13, marginLeft: 10, marginTop: 5 }}>{errors.documant3}</div> : ''}



                            <div style={{ display: 'flex', gap: 10, marginBottom: 10 }}>
                                <div style={{ width: '50%' }}>
                                    <Button onFocus={() => handleError('', 'documant4')} component="label" variant="contained" style={{ ...styles.documentButton }} >
                                        Document
                                        <input onChange={handleDocumant4} hidden type="file" accept="image/*,application/pdf,application/msword" multiple />
                                    </Button>
                                </div>
                                <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {images4?.filename ? <img
                                        src={images4.filename}
                                        alt="image"
                                        style={{
                                            width: 30,
                                            height: 30,
                                            borderRadius: "8px", // To make the image rounded
                                            marginLeft: 8,
                                        }}
                                    /> :
                                        <FaRegFileAlt fontSize={30} color={primaryColor} src={images4.filename} alt="image" variant="rounded" />}
                                </div>
                            </div>
                            {errors?.documant4 ? <div style={{ color: '#d32f2f', fontSize: 13, marginLeft: 10, marginTop: 5 }}>{errors.documant4}</div> : ''}


                            <div style={{ display: 'flex', gap: 10, marginBottom: 10 }}>
                                <div style={{ width: '50%' }}>
                                    <Button onFocus={() => handleError('', 'documant5')} component="label" variant="contained" style={{ ...styles.documentButton }} >
                                        Document
                                        <input onChange={handleDocumant5} hidden type="file" accept="image/*,application/pdf,application/msword" multiple />
                                    </Button>
                                </div>
                                <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {images5?.filename ? <img
                                        src={images5.filename}
                                        alt="image"
                                        style={{
                                            width: 30,
                                            height: 30,
                                            borderRadius: "8px", // To make the image rounded
                                            marginLeft: 8,
                                        }}
                                    /> :
                                        <FaRegFileAlt fontSize={30} color={primaryColor} src={images5.filename} alt="image" variant="rounded" />}
                                </div>
                            </div>
                            {errors?.documant5 ? <div style={{ color: '#d32f2f', fontSize: 13, marginLeft: 10, marginTop: 5 }}>{errors.documant5}</div> : ''}

                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                disabled={loader}
                                style={styles.signInButton}
                            >
                                {loader ? 'Sign Up...' : ' Sign Up'}
                            </Button>
                            <p style={{ marginTop: '5%', textAlign: 'center' }} onClick={() => { navigate('/company/login'); window.scrollTo(0, 0) }}>
                                Already have an account?
                                <span style={{ textDecoration: 'underline', cursor: 'pointer' }}> Login</span>
                            </p>
                        </>
                    )}
                    {otpForm && (
                        <OtpComponent
                            handleResend={handleSubmit}
                            type="client_signup"
                            email={email}
                            gst={gst}
                            images={images}
                            images2={images2}
                            images3={images3}
                            images4={images4}
                            images5={images5}
                            password={password}
                            fullName={name}
                            phone={phone}
                            country={country}
                            state={state}
                            city={city}
                            setOpen={setOtpForm}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
    },
    rightSide: {
        backgroundColor: '#fff',
        width: '100%'
    },
    loginHeading: {
        fontSize: '1.5rem',
        color: primaryColor,
        marginBottom: '20px',
        fontWeight: 500,
        textAlign: 'center'
    },
    loginText: {
        textAlign: 'center',
        fontSize: '1rem',
        marginTop: 0,
        color: '#2a2a2a',
        marginBottom: '20px',
    },
    input: {
        marginBottom: '10px',
    },
    signInButton: {
        backgroundColor: primaryColor,
        color: '#fff',
        borderRadius: 0,
        width: '100%',
        marginTop: '4%',
        boxShadow: 'none',
        fontSize: '1rem',
        fontWeight: 400,
        cursor: 'pointer',
    },
    documentButton: {
        border: '1px dashed ' + primaryColor,
        color: primaryColor,
        borderRadius: 0,
        width: '100%',
        marginTop: '4%',
        background: 'white',
        boxShadow: 'none',
        fontSize: '1rem',
        fontWeight: 400,
        cursor: 'pointer',
    },
};