import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import { serverURL } from '../api';

const DriverDetailModal = ({ open, setOpen, data }) => {
    const handleClose = () => {
        setOpen(false);
    };

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            border: 'none',
            bgcolor: 'white',
            borderRadius: '10px',
            boxShadow: 12,
            textAlign: 'center',
            maxHeight: '80vh',
            overflow: 'auto',
        },
        heading: {
            fontWeight: 600,
            fontSize: 27,
            marginBottom: '10px',
        },
        row: {
            display: 'flex',
            width: '100%',
            gap: '10px',
            margin: '4% 0',
            fontWeight: 400,
            fontSize: 16,
        },
        dataLabel: {
            fontWeight: 400,
            fontSize: 16,
            width: '50%',
            textAlign: 'left'
        },
        dataValue: {
            fontWeight: 400,
            fontSize: 16,
            marginLeft: 'auto',
            width: '50%',
            textAlign: 'right',
            wordBreak: 'break-all',
            wordWrap: 'wrap',
        },
        icon: {
            width: 22,
        },
        headingTypography: {
            fontWeight: 500,
            fontSize: 22,
            marginBottom: '10px',
            textAlign: 'left',
            p: 3,
            borderBottom: '1px solid gainsboro'
        }
    };

    const DataRow = ({ label, value }) => (
        <div style={styles.row}>
            <span style={styles.dataLabel}>{label}</span>
            <span style={styles.dataValue}>{value}</span>
        </div>
    );

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={styles.modalBox}>
                <Typography id="logout-modal-title" variant="h6" sx={styles.headingTypography}>
                    Driver&apos;s Details
                </Typography>
                <Box sx={{ padding: '2% 6% 6% 6%' }}>
                    <DataRow label="Name" value={data?.fullName || '-'} />
                    <DataRow label="Father Name" value={data?.fatherName || '-'} />
                    <DataRow label="Phone" value={data?.contactNo || '-'} />
                    <DataRow label="Residential Phone" value={data?.resContactNo || '-'} />
                    <DataRow label="Aadhar" value={data?.aadhar || '-'} />
                    <DataRow label="Pan" value={data?.pan || '-'} />
                    <DataRow label="Badge No" value={data?.badgeNo || '-'} />
                    <DataRow label="Shift" value={data?.shift || '-'} />
                    <DataRow label="Address" value={`${data?.address?.street} ${data?.address?.landmark}, ${data?.address?.city}, ${data?.address?.state}, ${data?.address?.zipCode}` || "-"} />
                </Box>
            </Box>
        </Modal>
    );
};

export default DriverDetailModal;
