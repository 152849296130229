import React, { useEffect, useState } from 'react';
import { Grid, Pagination, Typography, useTheme, useMediaQuery } from '@mui/material';
import { CiFilter } from "react-icons/ci";
import { useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { primaryColor } from '../../constant';
import Filters from '../../components/Filters';
import FilterResults from '../../components/FilterResults';
import { get_data } from '../../api';

export default function Store() {

    const { user_data } = useSelector(state => state.user);

    const theme = useTheme();
    const matches1 = useMediaQuery(theme.breakpoints.down('md'));
    const matches2 = useMediaQuery(theme.breakpoints.down(600));
    const matches3 = useMediaQuery(theme.breakpoints.down(400));

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState({ right: false });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);

    const fetchAllGpsData = async () => {
        setLoading(true)
        let result = await get_data(`product/get-all-products-for-store?pageNumber=${currentPage}`)
        console.log("dddddd", result)
        if (result?.status == true) {
            setData(result?.data?.products)
            setTotalPages(result?.data?.totalPages);
            setTotalData(result?.data?.totalProducts);
            setLoading(false)
        }
    }

    useEffect(function () {
        if (currentPage) {
            fetchAllGpsData()
        }
    }, [currentPage])


    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }, []);


    const itemsPerPage = 15;
    const count = data?.length;

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen({ ...open, [anchor]: open });
    };

    return (
        <>
            <Header />
            <div style={{ background: '#EFF5F7' }}>
                <div style={styles.searchContainer}>
                    {matches1 ?
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', padding: '5px 15px' }}>
                            <div>All Gps</div>
                            <React.Fragment key={'right'}>
                                <Button onClick={toggleDrawer('right', true)} variant="outlined" style={{ border: '1px solid ' + primaryColor, marginLeft: 10, padding: '8px', color: primaryColor }}> <CiFilter style={{ width: matches3 ? 28 : 34, height: matches3 ? 28 : 34, color: primaryColor, }} /></Button>
                                <Drawer
                                    anchor={'right'}
                                    open={open['right']}
                                    onClose={toggleDrawer('right', false)}
                                >
                                    <Filters />
                                </Drawer>
                            </React.Fragment>
                        </div>
                        : <></>}
                </div>
                <Grid container spacing={2} style={{ padding: '3% 2%' }}>
                    {/* <Grid item md={2.5} sm={0}>
                        {matches1 ? <></> : matches3 ? <></> :
                            <Filters />
                        }
                    </Grid> */}

                    <Grid item md={12} sm={12} xs={12}>
                        <FilterResults
                            user_data={user_data}
                            itemsPerPage={itemsPerPage}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            count={count}
                            totalData={totalData}
                            totalPages={totalPages}
                            data={data} />
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </>
    );
}

const styles = {
    searchContainer: {
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        width: '100%',

    },
    separator: {
        width: '1px',
        height: '20px',
        backgroundColor: primaryColor,
        marginRight: '10px',
    },
};
