import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Avatar,
    Button,
    Typography,
    Menu,
    MenuItem,
    IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
        '& .MuiCheckbox-root': {
            color: '#a3a3a3',
        },
    },
    stageButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        '&.closedOwn': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.analysis': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.qualification': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.closedLost': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
    },
    avatarGroup: {
        display: 'flex',
        alignItems: 'center',
    },
    avatarName: {
        marginLeft: '8px',
        fontSize: 14
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});

const InvoicesTable = ({
    data,
    tableHeader
}) => {

    const classes = useStyles();

    const [rowsData, setRowsData] = useState(data || []);

    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);

    const handleCheckboxToggle = (index) => {
        const updatedRows = [...rowsData];
        updatedRows[index].checked = !updatedRows[index].checked;
        setRowsData(updatedRows);
    };

    const handleMenuOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(index);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentRow(null);
    };


    return (
        <TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
            <Table className={classes.root}>
                <TableHead>
                    <TableRow>
                        {
                            tableHeader?.map((item) => {
                                return (
                                    <TableCell style={{ borderRight: '1px solid gainsboro', padding: '16px 10px' }}>{item}</TableCell>
                                )
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rowsData.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell style={{ borderRight: '1px solid gainsboro', textAlign: 'center' }}>{index + 1}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row.dealName}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row.amount}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                <Button style={{ fontSize: 11, fontWeight: 500 }}
                                    className={`${classes.stageButton} ${row.stage
                                        .toLowerCase()
                                        .replace(' ', '')}`}
                                >
                                    {row.stage}
                                </Button>
                            </TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row.closingDate}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row.contactName}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                <div className={classes.avatarGroup}>
                                    <Avatar alt={row.owner.name} src={row.owner.img} />
                                    <Typography className={classes.avatarName}>
                                        {row.owner.name}
                                    </Typography>
                                </div>
                            </TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                <IconButton
                                    className={classes.moreIcon}
                                    onClick={(event) => handleMenuOpen(event, index)}
                                >
                                    <MoreVertIcon />
                                </IconButton>

                                {/* Options menu */}
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl) && currentRow === index}
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem onClick={handleMenuClose}>View Details</MenuItem>
                                    <MenuItem onClick={handleMenuClose}>Edit</MenuItem>
                                    <MenuItem onClick={handleMenuClose}>Delete</MenuItem>
                                </Menu>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default InvoicesTable;
