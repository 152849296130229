import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography, Select, MenuItem, InputLabel, FormControl, Button, FormHelperText, useMediaQuery, Autocomplete } from '@mui/material';
import { get_data, post_data } from "../../api"
import { login, logout } from '../../redux/slices/user-slice';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { primaryColor } from '../../constant';
import { useNavigate } from 'react-router-dom';
import { Country, State, City } from 'country-state-city';
import { AiOutlineLogout } from "react-icons/ai";

const PersonalInformation = ({ user_data }) => {

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });


  const dispatch = useDispatch();
  const navigate = useNavigate()
  const countryCode = 'IN'
  const [cities, setCitites] = useState([]);
  const [states, setStates] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [open, setOpen] = useState(false);
  const [openChangeEmail, setOpenChangeEmail] = useState(false);
  const [openChangeNumber, setOpenChangeNumber] = useState(false);

  const isDesktop = useMediaQuery('(min-width: 768px)');

  const [formValues, setFormValues] = useState({
    fullName: '',
    phoneNumber: '',
    email: '',
    street: '',
    city: '',
    state: '',
    zipCode: '',
  });

  useEffect(() => {
    setFormValues({
      fullName: user_data?.name || '',
      email: user_data?.email || '',
      street: user_data?.address?.[0]?.street || '',
      city: user_data?.address?.[0]?.city || '',
      state: user_data?.address?.[0]?.state || '',
      zipCode: user_data?.address?.[0]?.zip_code || '',
      phoneNumber: user_data?.phone || '',
    });
  }, [user_data]);

  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const nameParts = name.split('.');

    if (nameParts.length > 1) {
      setFormValues({
        ...formValues,
        [nameParts[0]]: {
          ...formValues[nameParts[0]],
          [nameParts[1]]: value
        }
      });
    } else {
      if (name === 'phoneNumber') {
        const phoneValue = value.replace(/\D/g, '');
        setFormValues({
          ...formValues,
          [name]: phoneValue
        });
      } else {
        setFormValues({
          ...formValues,
          [name]: value
        });
      }
    }

    setFormErrors({
      ...formErrors,
      [name]: ''
    });
  };


  const handleSubmit = () => {
    // const errors = {};
    // if (!formValues.fullName) errors.fullName = 'Full Name is required';
    // if (!formValues.phoneNumber) {
    //   errors.phoneNumber = 'Phone Number is required';
    // } else if (formValues.phoneNumber.length !== 10) {
    //   errors.phoneNumber = 'Phone Number must be 10 digits';
    // }
    // if (!formValues.street) errors.street = 'Street is required';
    // if (!formValues.city) errors.city = 'City is required';
    // if (!formValues.state) errors.state = 'State is required';
    // if (!formValues.zipCode) errors.zipCode = 'Zip Code is required';
    // setFormErrors(errors);

    // if (Object.keys(errors).length === 0) {
    setLoading(true);
    setTimeout(() => {
      handleSave();
    }, 1000);
    // }
  };


  const handleSave = async () => {
    const payload = {
      name: formValues?.fullName,
      address: [
        {
          street: formValues?.street,
          city: formValues?.city,
          state: formValues?.state,
          zip_code: formValues?.zipCode,
        }
      ],
    };

    try {
      const response = await post_data(`user/update-user/${user_data?._id}`, payload);
      if (response?.status) {
        setLoading(false);
        dispatch(login(response?.data));
        Toast.fire({
          icon: 'success',
          title: "Profile Updated Successfully",
          color: "green"
        });
      } else {
        setLoading(false);
        Toast.fire({
          icon: 'warning',
          title: "Something Went Wrong",
          color: "red"
        });
      }
    } catch (e) {
      setLoading(false);
      Toast.fire({
        icon: 'error',
        title: "An error occurred",
        color: "red"
      });
    }
  };

  const button = {
    padding: '13px 20px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: primaryColor,
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    // width: '100%',
    marginTop: '3%',
    boxShadow: 'none'
  };

  const disbaledBtn = {
    opacity: '70%'
  };

  const handleClick = () => {
    setOpen(true);
  };


  useEffect(() => {
    setStateList(State.getStatesOfCountry(countryCode));
  }, [countryCode]);

  useEffect(() => {
    if (formValues?.state) {
      const fetchedCities = City.getCitiesOfState(countryCode, formValues?.state);
      setCityList(fetchedCities);
    }
  }, [formValues?.state, countryCode]);


  const handleStateChange = (event, newValue) => {
    if (newValue) {
      const stateCode = newValue.isoCode;
      const countryCodes = countryCode;

      const fetchedCities = City.getCitiesOfState(countryCodes, stateCode);
      setCityList(fetchedCities);
      setFormValues((prevValues) => ({ ...prevValues, state: stateCode, city: '', }));
    }
  };

  const handleCityChange = (event, newValue) => {
    if (newValue) {
      setFormValues({ ...formValues, city: newValue.name });
    }
  };

  const handleLogout = async () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: ` #d33`,
      cancelButtonColor: `${primaryColor}`,
      confirmButtonText: 'Yes, Logout !'

    }).then(async (result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('authToken')
        dispatch(logout());
        navigate('/')
      }
    })
  }

  return (
    <Box sx={{ padding: '30px', borderRadius: '10px', background: 'white', border: '1px solid #E0E0E0' }}>

      <Box sx={{ textAlign: 'right' }}>
        <Button variant="outlined" onClick={handleLogout} color='#ff4d4d' style={{ color: '#ff4d4d', borderRadius: '0px', boxShadow: 'none', gap: 10 }}  >
          <AiOutlineLogout />
          Logout
        </Button>
      </Box>

      <Typography variant="h4" gutterBottom style={{ fontWeight: 500, fontSize: 20, marginBottom: '3%' }}>
        Personal Information
      </Typography>


      {/* First row */}
      <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px', flexDirection: isDesktop ? 'row' : 'column' }}>


        <div style={{ width: isDesktop ? '50%' : '100%' }}>
          <TextField
            fullWidth
            label="Full Name"
            name="fullName"
            variant="outlined"
            value={formValues.fullName}
            onChange={handleChange}
            error={!!formErrors.fullName}
            helperText={formErrors.fullName}
          />
        </div>
        <div style={{ width: isDesktop ? '50%' : '100%' }}>
          <TextField
            fullWidth
            label="Phone Number"
            name="phoneNumber"
            type="tel"
            variant="outlined"
            disabled
            value={formValues.phoneNumber}
            onChange={handleChange}
            inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
            error={!!formErrors.phoneNumber}
            helperText={formErrors.phoneNumber}
          />
          <div onClick={() => navigate('/admin/change-phone', { state: "isUser" })} style={{ color: primaryColor, textDecoration: 'none', cursor: 'pointer' }}>Change number</div>
        </div>
      </Box>

      {/* Second row */}
      <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px', flexDirection: isDesktop ? 'row' : 'column' }}>
        <div style={{ width: isDesktop ? '50%' : '100%' }}>
          <TextField
            fullWidth
            label="Email Address"
            name="email"
            type="email"
            variant="outlined"
            disabled
            value={formValues.email}
            onChange={handleChange}
          />
          <div style={{ display: 'flex', gap: 20 }}>
            {user_data?.email && user_data?.is_email_verified === false && (
              <div onClick={handleClick} style={{ color: primaryColor, textDecoration: 'none', cursor: 'pointer' }}>Verify email</div>
            )}
            {
              user_data?.email !== '' && (
                <div onClick={() => navigate('/admin/change-email', { state: "isUser" })} style={{ color: primaryColor, textDecoration: 'none', cursor: 'pointer' }}>Change email</div>
              )
            }
          </div>
        </div>
        {/* 
        <div style={{ width: isDesktop ? '50%' : '100%' }}>
          <TextField
            fullWidth
            label="Gst"
            name="gst"
            variant="outlined"
            value={formValues.gst}
            onChange={handleChange}
            error={!!formErrors.gst}
            helperText={formErrors.gst}
          />
        </div> */}
      </Box>

      {/* Third row */}
      <Typography variant="h4" gutterBottom style={{ fontWeight: 500, fontSize: 20, marginBottom: '3%' }}>
        Address
      </Typography>
      <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
        <TextField
          fullWidth
          label="Street"
          name="street"
          variant="outlined"
          value={formValues.street}
          onChange={handleChange}
          error={!!formErrors.street}
          helperText={formErrors.street}
        />
      </Box>

      {/* Fourth row */}
      <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px', flexDirection: isDesktop ? 'row' : 'column' }}>
        <Autocomplete
          style={{ marginBottom: 10 }}
          id="state-select"
          fullWidth
          options={stateList}
          value={stateList.find(s => s.isoCode === formValues?.state) || null}
          onChange={handleStateChange}
          autoHighlight
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <Box
              component="li"
              {...props}
              value={option.isoCode}
              key={option.isoCode}
            >
              {option.name}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select State"
              fullWidth
            />
          )}
        />

        <Autocomplete
          id="city-select"
          fullWidth
          options={cityList}
          value={cityList.find(c => c.name === formValues?.city) || null}
          onChange={handleCityChange}
          autoHighlight
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <Box
              component="li"
              {...props}
              value={option.name}
              key={option.name}
            >
              {option.name}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select City"
              fullWidth
            />
          )}
        />

        <TextField
          fullWidth
          label="Zip Code"
          name="zipCode"
           
          variant="outlined"
          value={formValues.zipCode}
          onChange={handleChange}
          error={!!formErrors.zipCode}
          helperText={formErrors.zipCode}
        />
      </Box>


      <Button variant="outlined" onClick={handleSubmit} style={loading ? { ...button, ...disbaledBtn } : button}>
        {loading ? 'Update...' : 'Update'}
      </Button>
    </Box>
  );
};

export default PersonalInformation;
