
import { Box, Button, Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { formatDate, formatPrice, primaryColor } from "../../constant";
import * as XLSX from "xlsx";
import EmptyPage from "../EmptyPage";

const AdminVendorsTransactionModal = ({ open, setOpen, data, fromDate, toDate }) => {

    const contentRef = useRef(null);

    const styles = {
        invoice: {
            padding: "4%",
            height: 470,
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #000",
            paddingBottom: "10px",
            marginBottom: "5%",
        },
        brand: {
            textAlign: "left",
        },
        info: {
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "10px",
            paddingBottom: "10px",
        },
        infoSection: {
            textAlign: "left",
        },
        table: {
            width: "100%",
            borderCollapse: "collapse",
        },
        th: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        td: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        footer: {
            marginTop: "20px",
            borderTop: "2px solid #000",
            marginTop: "5%",
            paddingTop: "10px",
            display: "flex",
            justifyContent: "space-between",
        },
        terms: {
            textAlign: "left",
        },
        payment: {
            textAlign: "left",
        },
        summary: {
            marginTop: "20px",
            textAlign: "right",
        },
        text: {
            fontSize: 14,
        },
    };



    const styleModal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 900,
        maxHeight: '90%',
        borderRadius: 2,
        overflowY: 'auto',
        bgcolor: "white",
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        border: "none",
        boxShadow: 24,
        padding: '0 0 5%',
    };

    const button = {
        padding: '5px',
        fontSize: 13,
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '15%',
        marginTop: '20px',
        boxShadow: 'none',
        marginLeft: '2%'
    }

    const handleDownloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(
            data.map((item) => ({
                "Vendor Id": item?.uniqueVendorId || "-",
                "Name": item?.name || "-",
                "Email": item?.email || "-",
                'Date': formatDate(item?.createdAt),
                "Phone": item?.phone || "-",
                "Company": item?.clientId?.map((item) => <div>{item?.companyName}</div>) || '-',
                "Company ID": item?.clientId?.map((item) => <div>{item?.uniqueClientId}</div>) || '-',
                "Address": `${item?.address?.street} ${item?.address?.landmark} ${item?.address?.city} ${item?.address?.state}` || "-",
            }))
        );

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sales");

        // Export the workbook as an Excel file
        XLSX.writeFile(workbook, "Vendor_Record.xlsx");
    };
    console.log('data vandor ka', data)
    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
            >
                <Box sx={styleModal}>
                    {
                        data?.length === 0 ? (
                            <EmptyPage />
                        ) : (
                            <>
                                <Button variant="contained" onClick={() => { handleDownloadExcel() }} style={button}>Download</Button>
                                <div ref={contentRef} id="invoice" style={styles.invoice}>
                                    <div style={styles.header}>
                                        <div style={styles.brand}>
                                            <h2 style={{ fontWeight: 600, margin: 0, }}>Vendor's Details</h2>
                                            {/* <p style={{ fontSize: 11, opacity: "70%" }}>
                                    Services with a Lifestyle
                                </p> */}
                                        </div>
                                        <h2 style={{ fontWeight: 600, margin: 0 }}>Vendor Record</h2>
                                    </div>

                                    <table style={{ ...styles.table, ...styles.text, margin: "3% 0" }}>
                                        <thead style={{ textAlign: "left" }}>
                                            <tr>
                                                <th>Vendor Id</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th>Company</th>
                                                <th>Company Id</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data?.map((item, i) => {
                                                    return (
                                                        <>
                                                            <tr style={{ margin: 0, borderBottom: '1px solid gainsboro' }}>
                                                                <td style={{ padding: '1% 0' }}>{item?.uniqueVendorId || '-'}</td>
                                                                <td style={{ padding: '1% 0' }}>{item?.name || '-'}</td>
                                                                <td style={{ padding: '1% 0' }}>{item?.email || '-'}</td>
                                                                <td style={{ padding: '1% 0' }}>{item?.phone || '-'}</td>
                                                                <td style={{ padding: '1% 0' }}>{item?.clientId?.map((item) => <div>{item?.companyName}</div>) || '-'}</td>
                                                                <td style={{ padding: '1% 0' }}>{item?.clientId?.map((item) => <div>{item?.uniqueClientId || '-'}</div>)}</td>
                                                                <td style={{ padding: '1% 0' }}>{formatDate(item?.createdAt)}</td>
                                                            </tr>
                                                        </>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <div style={{ ...styles.footer, ...styles.text }}>
                                        <div style={styles.terms}>
                                            <p>All Invoices are from {formatDate(fromDate)} to {formatDate(toDate)} period.</p>
                                            <p>For GPS</p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </Box>
            </Modal>
        </>
    );
};

export default AdminVendorsTransactionModal;