import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, Box, CircularProgress, Pagination } from '@mui/material';
import { get_data } from '../api';
import { primaryColor } from '../constant';
import SearchComponent from './SearchComponent';

const ClientReturnComp = ({ clientId }) => {
    const [returnData, setReturnData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [value, setValue] = useState('')


    const itemsPerPage = 15;
    const count = returnData?.length;

    const handleSearch = async () => {
        if (value.length > 0) {
            try {
                const data = await get_data(`return/search-return-by-admin/${value}/${clientId}?pageNumber=${currentPage}`)
                if (data.status) {
                    setReturnData(data?.data?.returns || []);
                    setTotalPages(data?.data?.totalPages)
                    setTotalData(data?.data?.totalReturns)
                }
            }
            catch (error) {
                console.log('Requests serarch error : ', error)
            }

        } else {
            fetchReturnData(currentPage)
        }
    }


    const fetchReturnData = async () => {
        try {
            const data = await get_data(`return/get-all-return-by-client/${clientId}?pageNumber=${currentPage}`);

            if (data.status) {
                setReturnData(data?.data?.returns || []);
                setTotalPages(data?.data?.totalPages)
                setTotalData(data?.data?.totalReturns)
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (clientId || currentPage) {
            fetchReturnData(currentPage);
        }

    }, [clientId, currentPage]);

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);


    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };



    return (
        <Grid container spacing={3}>
            {
                returnData?.length > 0  && <>
                    <Grid item xs={12}>
                        <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                    </Grid>
                </>
            }

            {returnData?.length > 0 ? <>
                {returnData?.map((returnItem) => (
                    <Grid item xs={12} key={returnItem._id}>
                        <Card>
                            <CardContent>
                                <Box mb={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant="h6" sx={{ color: primaryColor }}>Return ID: {returnItem.uniqueReturnId}</Typography>
                                    <Typography>{new Date(returnItem.createdAt).toLocaleDateString()}</Typography>
                                </Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography>Request ID: {returnItem.requestId}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Typography>Return Reason: {returnItem.returnReason || 'N/A'}</Typography>
                                    </Grid>


                                    <Grid item xs={12} >
                                        <Typography>Details</Typography>
                                    </Grid>
                                    {returnItem?.details && returnItem?.details?.map((item) => (
                                        <>
                                            <Grid item xs={12} sm={6}>
                                                <Typography> Vehicle Number: {item?.vehicleNumber || 'N/A'}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography> Device Unique ID: {item?.deviceUniqueId || 'N/A'}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography> Imei Number: {item?.imei || 'N/A'}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography> Sim Number: {item?.simNumber || 'N/A'}</Typography>
                                            </Grid>
                                        </>
                                    ))
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}

            </> : <>
                <Grid item xs={12}>
                    <Typography style={{ display: 'flex', justifyContent: 'center' }}>No Return details found for this client.</Typography>
                </Grid>
            </>}
            <Grid item xs={12}>
                {returnData?.length > 0 && (
                    <div
                        style={{
                            borderTop: "1px solid gainsboro",
                            padding: "2%",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            style={{
                                fontWeight: 500,
                                fontSize: 15,
                                color: "black",
                            }}
                        >{`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}</Typography>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </div>
                )}
            </Grid>
        </Grid>
    );
};

export default ClientReturnComp;
