import { Box, Button, Divider, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { primaryColor } from "../constant";

export default function RequestDetails2({ detailsData }) {
    const navigate = useNavigate();

    const renderDetailField = (label, value) => (
        <div style={{ display: 'flex', marginTop: 5 }}>
            <Typography variant="body2" color="textSecondary" style={{ width: '35%' }}>
                {label}:
            </Typography>
            <Typography variant="body2" style={{ width: '65%' }}>
                {value || "N/A"}
            </Typography>
        </div>
    );
    console.log('detailsData', detailsData)
    return (
        <Box
            sx={{
                backgroundColor: 'white',
                borderRadius: 1,
                border: '1px solid gainsboro',
                padding: 3,
                marginY: 2,
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 1 }}>
                <Typography variant="h6" fontWeight="500">
                    Gps Request Details
                </Typography>
                {/* Uncomment if needed */}
                {/* <Button
                    onClick={() => navigate('/vendor/dashboard/vehicles/create', { state: { row: detailsData, title: 'update' } })}
                    sx={{
                        backgroundColor: primaryColor,
                        color: '#fff',
                        textTransform: 'capitalize',
                        padding: '4px 10px',
                        borderRadius: 0,
                        '&:hover': {
                            backgroundColor: primaryColor,
                        },
                    }}
                >
                    Edit
                </Button> */}
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                {/* <Typography variant="body2" style={{ marginRight: 1, textTransform: "capitalize" }}>
                    Status:
                </Typography>
                {detailsData?.status && <Button
                    sx={{
                        backgroundColor: '#e1ffd1',
                        color: 'green',
                        fontSize: 13,
                        textTransform: "capitalize",
                        borderRadius: 50,
                        padding: '2px 10px',
                        minWidth: 'auto',
                    }}
                >
                    {detailsData?.status || "Unknown Status"}
                </Button> */}
            </div>

            <div style={{ width: '100%' }}>
                {detailsData?.gps?.map((item, index) => {
                    console.log('detailditem', item)
                    const address = item?.driverDetails?.address;
                    const formattedAddress = address
                        ? `${address.street || ''}, ${address.landmark || ''}, ${address.city || ''}, ${address.state || ''}, ${address.zipCode || ''}`.trim()
                        : "N/A";

                    return (
                        <Box key={index} sx={{ marginBottom: 3 }}>
                            <Divider sx={{ marginBottom: 2 }} />
                            <div>
                                <Button
                                    sx={{
                                        backgroundColor: '#e1ffd1',
                                        color: 'green',
                                        fontSize: 13,
                                        textTransform: "capitalize",
                                        borderRadius: 50,
                                        padding: '2px 10px',
                                        minWidth: 'auto',
                                    }}
                                >
                                    {item?.gpsStatus || "Pending"}
                                </Button>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="h6" fontWeight="500">
                                    Driver Details
                                </Typography>
                                <div
                                    style={{
                                        textAlign: 'center',
                                        borderRadius: '50%',
                                        backgroundColor: '#e1ffd1',
                                        width: 30,
                                        height: 30,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {index + 1}
                                </div>
                            </div>
                            {renderDetailField("Full Name", item?.driverDetails?.fullName)}
                            {renderDetailField("Father Name", item?.driverDetails?.fatherName)}
                            {renderDetailField("Contact No", item?.driverDetails?.contactNo)}
                            {renderDetailField("Res. Contact No", item?.driverDetails?.resContactNo)}
                            {renderDetailField("Aadhar No", item?.driverDetails?.aadhar)}
                            {renderDetailField("PanCard No", item?.driverDetails?.pan)}
                            {renderDetailField("Badge No", item?.driverDetails?.badgeNo)}
                            {renderDetailField("Shift", item?.driverDetails?.shift)}
                            {renderDetailField("Address", formattedAddress)}

                            <Divider sx={{ marginY: 2 }} />

                            <Typography variant="h6" fontWeight="500">
                                Vehicle Details
                            </Typography>
                            {renderDetailField("Owner Name", item?.vehicleDetails?.regnOwnerName)}
                            {renderDetailField("Owner Contact No", item?.vehicleDetails?.regnOwnerContactNo)}
                            {renderDetailField("Vehicle No", item?.vehicleDetails?.regnNumber)}
                            {renderDetailField("Chassis Number", item?.vehicleDetails?.chassisNumber)}
                            {/* {renderDetailField("Type Of Vehicle", item?.vehicleDetails?.typeOfVehicle)}
                            {renderDetailField("Vehicle Class", item?.vehicleDetails?.vehicleClass)} */}
                            {renderDetailField("Plate", item?.vehicleDetails?.plate)}
                        </Box>
                    );
                })}
            </div>
        </Box>
    );
}
