import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Table, TableHead, TableBody, TextField } from '@mui/material';
import { formatDate, formatPrice } from '../constant';
import toast from 'react-hot-toast';
import { post_data } from '../api';

const OrderDetailModel = ({ open, setOpen, data, getAllOrders }) => {
    const [simMobileNo, setSimMobileNo] = useState(data?.productId);
    const handleClose = () => {
        setOpen(false);
    };
    console.log("data...", data)
    useEffect(() => {
        setSimMobileNo(data?.productId);
    }, [data?.productId]);

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 550,
            height: '70vh',
            border: 'none',
            bgcolor: 'white',
            borderRadius: '10px',
            boxShadow: 12,
            textAlign: 'center',
            overflow: 'auto'
        },
        heading: {
            fontWeight: 600,
            fontSize: 27,
            marginBottom: '10px',
        },
        row: {
            display: 'flex',
            width: '100%',
            gap: '10px',
            margin: '4% 0',
            fontWeight: 400,
            fontSize: 16,
        },
        dataLabel: {
            fontWeight: 400,
            fontSize: 16,
            width: '50%',
            textAlign: 'left'
        },
        dataValue: {
            fontWeight: 400,
            fontSize: 16,
            marginLeft: 'auto',
            width: '50%',
            textAlign: 'right'
        },
        icon: {
            width: 22,
        },
        headingTypography: {
            fontWeight: 500,
            fontSize: 22,
            marginBottom: '10px',
            textAlign: 'left',
            p: 3,
            borderBottom: '1px solid gainsboro'
        }
    };

    const shippingAddress = data?.shippingAddress
        ? `${data.shippingAddress.street}, ${data.shippingAddress.city}, ${data.shippingAddress.zipCode}, ${data.shippingAddress.state}`
        : '-';

    const DataRow = ({ label, value }) => (
        <div style={styles.row}>
            <span style={styles.dataLabel}>{label}</span>
            <span style={styles.dataValue}>{value}</span>
        </div>
    );


    const handleUpdateSimMobileNo = async (e, id) => {
        if (e.key === 'Enter') {
            const result = await post_data(`product/update-product/${id}`, { simMobileNo: e.target.value });
            if (result?.status === true) {
                toast.success("Sim Mobile No. Updated Successfully");
                getAllOrders();
                handleClose();
            } else {
                toast.error("Something went wrong");
            }
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={styles.modalBox}>
                <Typography id="logout-modal-title" variant="h6" sx={styles.headingTypography}>
                    Order&apos;s Details
                </Typography>
                <Box sx={{ padding: '2% 6% 6% 6%' }}>

                    <DataRow label="User UniqueId" value={data?.userId?.uniqueUserId || '-'} />
                    <DataRow label="User Name" value={data?.userId?.name || '-'} />
                    <DataRow label="Product UniqueId" value={data?.productId?.map((item) => item?.uniqueProductId + " , ") || '-'} />
                    <DataRow label="Product Name" value={data?.productId?.map((item) => <div>{item?.name}</div>) || '-'} />
                    <DataRow label="Phone" value={<TextField sx={{ width: '200px', height: '30px', '& .MuiInputBase-root': { height: '30px' } }} value={data?.userId?.phone} />} />
                    <DataRow label="IMEI NO" value={data?.productId?.map((item, index)=>{
                                        return index == data?.productId?.length-1 ?  `${item?.imeiNo || '-'}`: `${item?.imeiNo || '-'}, `
                    }) || '-'} />
                    <DataRow label="SIM NO" value={data?.productId?.map((item, index)=>{
                                        return index == data?.productId?.length-1 ?  `${item?.simNo || '-'}`: `${item?.simNo || '-'}, `
                    }) || '-'} />
                    <DataRow label="Network" value={data?.network || '-'} />
                    <DataRow label="Amount Before Gst" value={formatPrice(data?.amountBeforGst) || '-'} />
                    <DataRow label="Amount" value={formatPrice(data?.amount) || '-'} />
                    <DataRow label="Address" value={shippingAddress || '-'} />
                    <DataRow label="Status" value={data?.orderStatus || '-'} />
                    {/* <DataRow label="Status" value={data?.status || '-'} /> */}
                    <DataRow label="Date" value={formatDate(data?.createdAt) || '-'} />
                    <DataRow label="Transaction Number" value={data?.transactionNumber || '-'} />
                    <DataRow label="InVoice Number" value={data?.invoiceNumber || '-'} />

                    {/* <DataRow label="Verified Phone" value={data?.isPhoneVerified ? <img src='/images/check-icon.svg' style={styles.icon} alt="Verified" /> : '-'} />
                    <DataRow label="Verified Email" value={data?.isEmailVerified ? <img src='/images/check-icon.svg' style={styles.icon} alt="Verified" /> : '-'} />
                    <DataRow label="Premium User" value={data?.isMembership ? <img src='/images/check-icon.svg' style={styles.icon} alt="Verified" /> : '-'} /> */}
                    {/* <DataRow label="Aadhar Verified" value={data?.isAadharVerified ? <img src='/images/check-icon.svg' style={styles.icon} alt="Verified" /> : '-'} /> */}
                </Box >
            </Box >
        </Modal >
    );
};

export default OrderDetailModel;
