import * as React from 'react';
import { useState } from 'react';
import { TextField, Button, useMediaQuery, useTheme, Box, Grid, Typography, IconButton, MenuItem, FormControl, FormLabel, RadioGroup, Radio } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import imageCompression from "browser-image-compression";
import ImageIcon from '@mui/icons-material/Image';
import { primaryColor } from '../constant';
import CloseIcon from '@mui/icons-material/Close';
import { post_data, serverURL } from '../api';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

export default function CreateAccessories() {
    const theme = useTheme();
    const matches1 = useMediaQuery(theme.breakpoints.down('md'));
    const matches3 = useMediaQuery(theme.breakpoints.down(500));
    const location = useLocation()
    let navigate = useNavigate()
    const Update = location?.state?.title || ''
    const [rowsData, setRowsData] = useState(location?.state?.row || '');
    const [errors, setErrors] = useState({});
    const [images, setImages] = useState([]);
    const [newImages, setNewImages] = useState(location?.state?.row?.images || [])
    const [compressedImages, setCompressedImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isImageUpdate, setIsImageUpdate] = useState(false);
    const [uploadNewImage, setUploadNewImage] = useState([]);

    const [formData, setFormData] = useState(location?.state?.row || {
        title: '',
        description: '',
        price: '',
        images: [],
        uploadNewImage: []
    } || location?.state?.row);

    console.log('update', Update)

    const dropzoneStyle = {
        border: '2px dashed ' + primaryColor,
        borderRadius: '8px',
        padding: '40px',
        textAlign: 'center',
        width: '100%',
        margin: 'auto',
        backgroundColor: '#f9f9f9',
        cursor: 'pointer',
        transition: '0.3s',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
    }

    const imagesBox = {
        position: 'relative',
        width: 130,
        height: 130,
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    }

    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.title.trim()) {
            newErrors.title = 'Accessories Title is Required';
        }

        if (!formData.price.trim()) {
            newErrors.price = 'Price is required';
        } else if (!/^\d+$/.test(formData.price)) {
            newErrors.price = 'Price must be a number';
        }

        if (!formData.description.trim()) {
            newErrors.description = 'Description is required';
        }

        if (formData.images.length === 0) {
            newErrors.images = 'images is required';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === "price") {
            if (value.length <= 10) {
                setFormData({ ...formData, [name]: value.replace(/\D/g, '').slice(0, 10) });
                setErrors({ ...errors, [name]: false })
            }
            return
        }
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: false })
    };

    const handleImageChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: false })
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormData({ ...formData, [name]: checked });
    };
    const handleDescriptionChange = (value) => {
        setFormData({ ...formData, description: value });
    };

    const handleSubmit = async (event) => {

        event.preventDefault();
        if (validateForm()) {
            setLoading(true)
            const payload = new FormData();
            Object.keys(formData).forEach((key) => {
                if (key === 'images') {
                    formData.images.forEach((image) => {
                        payload.append('images', image); // Append each image file
                    });
                } else {
                    payload.append(key, formData[key]);
                }
            });
            try {
                const response = await post_data('accessory/create-accessory', payload, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (response.status === true) {
                    setLoading(false)
                    toast.success("Accessories created successfully!");
                    navigate('/admin/dashboard/sales/accessories')
                } else {
                    setLoading(false)
                    toast.error("Something went wrong!");
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    const handleUpdate = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            setLoading(true)
            if(formData?.uploadNewImage?.length == 0){
            var body = { ...formData }
            try {
                const response = await post_data(`accessory/update-accessory/${body?._id}`, body, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Set headers for file upload
                    },
                });
                if (response.status === true) {
                    setLoading(false)
                    toast.success("Accessories updated successfully!");
                    navigate('/admin/dashboard/sales/accessories')
                } else {
                }
            } catch (error) {
                setLoading(false)
                toast.error("Something went wrong!");
            }
        }else{
            const payload = new FormData();
            Object.keys(formData).forEach((key) => {
                if (key === 'images') {
                    const updateImageURL = [];

                    newImages?.forEach((image) => {
                        if (image instanceof File) {
                            payload.append('images', image);
                        }
                        else if (typeof image === 'string') {
                            updateImageURL.push(image);
                        }
                    })

                    payload.append('updateImageURL', JSON.stringify(updateImageURL));

                } else  if (key === 'uploadNewImage') {
                    // formData.uploadNewImage.forEach((image) => {
                    //     payload.append('images', image); // Append each image file
                    // });
                }                 
                else {
                    payload.append(key, formData[key]);
                }
            });
            try {
                const response = await post_data(`accessory/update-accessory-with-images/${formData?._id}`, payload, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Set headers for file upload
                    },
                });
                if (response.status === true) {
                    setLoading(false)
                    toast.success("Accessories update successfully!");
                    // navigate('/admin/dashboard/sales/accessories')
                } else {
                    setLoading(false)
                    toast.error("Something went wrong!");
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
        }
    }

    const handleCompressImage = async () => {
        const compressedImageArray = [];
        for (let i = 0; i < images.length; i++) {
            const image = images[i];
            const options = {
                maxWidthOrHeight: 800,
                useWebWorker: true,
            };
            try {
                const compressedImage = await imageCompression(image, options);
                const compressedFile = new File(
                    [compressedImage],
                    image.name,
                    { type: image.type, lastModified: Date.now() }
                );
                compressedImageArray.push(compressedFile);
            } catch (error) {
            }
        }
        if (Update) {
            setCompressedImages([...newImages, ...compressedImageArray]);
        } else {
            setCompressedImages([...compressedImageArray]);
        }
    };

    React.useEffect(() => {
        handleCompressImage()
    }, [newImages, images])


    const modules = React.useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', "strike"],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                ['image', "link", "video"],
                ['clean'],
                ['code-block'],
                [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] }]
            ],
            imageResize: {
                displaySize: true
            }
        },
    }), []);

    return (
        <>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <Grid container spacing={2}>
                <Grid item sx={12} md={7}>

                    <Box
                        component="form"
                        onSubmit={Update ? handleUpdate : handleSubmit}
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        margin="auto"
                        padding="35px"
                        bgcolor="white"
                        borderRadius={2}
                    >
                        <Grid container spacing={2}>
                            <Grid item sx={12} md={12}>
                                <TextField
                                    label="Title"
                                    name="title"
                                    value={formData?.title}
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="outlined"
                                    style={{ borderRadius: 0 }}
                                    error={!!errors?.title}
                                    helperText={errors?.title}
                                    onFocus={() => handleError('', 'title')}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    label="Price"
                                    name="price"
                                    //  
                                    value={formData?.price}
                                    onChange={handleInputChange}
                                    fullWidth
                                    variant="outlined"
                                    style={{ borderRadius: 0 }}
                                    error={!!errors?.price}
                                    helperText={errors?.price}
                                    onFocus={() => handleError('', 'price')}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <ReactQuill
                                    value={formData.description}
                                    modules={modules}
                                    error={!!errors?.description}
                                    helperText={errors?.description}
                                    onFocus={() => handleError('', 'description')}
                                    style={{ height: matches1 ? 100 : 150, marginBottom: '10%' }}
                                    onChange={handleDescriptionChange}
                                    theme="snow"
                                    placeholder="Enter Accessories description"
                                />
                                {!!errors?.description ? <span style={{ color: '#EA2024', fontSize: 12 }}>{errors?.description}</span> : <></>}

                            </Grid>
                            <Grid item md={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={loading}
                                    style={{
                                        backgroundColor: primaryColor,
                                        color: 'white',
                                        width: matches1 ? '40%' : '25%',
                                        borderRadius: 0,
                                        boxShadow: 'none',
                                        padding: '10px 0',
                                        fontSize: '16px',
                                    }}
                                >
                                    {
                                        Update ?
                                            <>
                                                {
                                                    loading ? 'Update...' : 'Update'
                                                }
                                            </>
                                            :
                                            <>
                                                {
                                                    loading ? 'Submit...' : 'Submit'
                                                }
                                            </>
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>

                </Grid>
                {console.log("formData.images", formData.images)}
                <Grid item xs={12} md={matches1 ? 12 : 5}>
                    <Box component="label" sx={dropzoneStyle}>
                        <input
                            type="file"
                            accept="image/*"
                            multiple
                            onFocus={() => handleError('', 'images')}
                            onChange={(e) => {
                                const image = Array.from(e.target.files);
                                if (Update === 'update') {
                                    setUploadNewImage([...image]);
                                    setFormData({ ...formData, uploadNewImage: [...(formData.uploadNewImage || []), ...image] });
                                    setNewImages([...newImages, ...image]);
                                    handleCompressImage(image)
                                    handleError('', 'images')
                                } else {
                                    setFormData({ ...formData, images: [...(formData.images || []), ...image] });
                                    setImages([...images, ...image]);
                                    e.target.value = '';
                                    handleError('', 'images') // Clear the input for the next upload      
                                }
                            }}
                            style={{ display: 'none' }}
                        />
                        <ImageIcon sx={{ fontSize: 48, color: primaryColor }} />
                        <Typography variant="h6" sx={{ color: primaryColor, marginTop: '16px' }}>
                            Choose your Images
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#999999' }}>
                            Accepts all Formats
                        </Typography>
                    </Box>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: "10px", marginTop: '3%' }}>
                        {formData?.images?.length > 0 && Update === 'update' ?
                            newImages?.map((image, index) => {
                                let src;
                                if (typeof image === 'string') {
                                    src = `${serverURL}/uploads/accessories-images/${image}`;
                                } else if (image instanceof File) {
                                    src = URL.createObjectURL(image);
                                } else {
                                    src = '';
                                }

                                return src && <Box
                                    key={index}
                                    sx={{
                                        position: 'relative',
                                        width: '150px',
                                        height: '150px',
                                        borderRadius: '8px',
                                        overflow: 'hidden',
                                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    <img
                                        src={src}
                                        alt={`Uploaded preview ${index}`}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                        }}
                                    />
                                    <IconButton
                                        onClick={() => {
                                            const updatedNewImages = newImages.filter((_, i) => i !== index);
                                            setNewImages(updatedNewImages);
                                            setIsImageUpdate(true);
                                        }}
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 255, 255, 1)',
                                            },
                                        }}
                                    >
                                        <CloseIcon sx={{ fontSize: '16px', color: '#f44336' }} />
                                    </IconButton>
                                </Box>
                            })
                            :
                            images?.map((image, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        position: 'relative',
                                        width: '150px',
                                        height: '150px',
                                        borderRadius: '8px',
                                        overflow: 'hidden',
                                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    <img
                                        src={URL.createObjectURL(image)}
                                        alt={`Uploaded preview ${index}`}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                        }}
                                    />
                                    <IconButton
                                        onClick={() => {
                                            const updatedImages = images.filter((_, i) => i !== index);
                                            setFormData({ ...formData, documents: updatedImages });
                                            setImages(updatedImages);
                                        }}
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 255, 255, 1)',
                                            },
                                        }}
                                    >
                                        <CloseIcon sx={{ fontSize: '16px', color: '#f44336' }} />
                                    </IconButton>
                                </Box>
                            ))}
                    </div>

                    {/* <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '3%' }}>
                        {formData.images?.map((image, index) => (
                            <Box key={index} sx={imagesBox} position="relative">
                                {Update  === 'update' ? <img
                                    src={`${serverURL}/uploads/accessories-images/${image}`}
                                    alt={`Uploaded preview ${index}`}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                /> : <img
                                    src={URL.createObjectURL(image)}
                                    alt={`Uploaded preview ${index}`}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                />}

                                <IconButton
                                    onClick={() => {
                                        const updatedImages = formData.images.filter((_, i) => i !== index);
                                        setFormData({ ...formData, images: updatedImages }); // Update formData with the filtered images
                                    }}
                                    sx={{
                                        position: 'absolute',
                                        top: '4px',
                                        right: '4px',
                                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 255, 255, 1)',
                                        },
                                    }}
                                >
                                    <CloseIcon sx={{ fontSize: '16px', color: '#f44336' }} />
                                </IconButton>
                            </Box>
                        ))}
                    </div> */}
                    {!!errors?.images ? <span style={{ color: '#EA2024', fontSize: 12 }}>{errors?.images}</span> : <></>}
                </Grid>
            </Grid>
        </>
    );
}