
import { Button, Grid } from "@mui/material";
// import RequestsTable from "../../../components/RequestsTable";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { baseURL, get_data, post_data } from "../api";
import EmptyPage from "../components/EmptyPage";
import toast, { Toaster } from "react-hot-toast";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { createButtonBg, primaryColor, primaryColorHover } from "../constant";
import SearchComponent from "../components/SearchComponent";
// import RequestsTransactionModal from "../../../components/models/RequestsTransactionModal";
import ClientRequestTabel from "./tables/ClientRequestTabel";
import ClientsRequestTransactionModal from "./models/ClientsRequestTransactionModal";
import { useSelector } from "react-redux";

const rangePickerStyle = {
    display: 'flex',
    cursor: 'pointer',
    fontSize: 14,
    background: 'white',
    padding: '0% 0% 0% 0%',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    color: '#7E8299',
    cursor: 'pointer'
};


export default function ClientRequests() {
    const { isLoggedIn, user_data } = useSelector(state => state.user);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate()
    const [value, setValue] = useState('')
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [open, setOpen] = useState(false)
    const [fromDate, setFromDate] = useState([])
    const [toDate, setToDate] = useState([])
    const [requestsData, setRequestsData] = useState([])
    const [excelData, setExcelData] = useState([]);
    const [fileName, setFileName] = useState('');
    const [refresh, setRefresh] = useState(false);

    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };

    const getAllRequests = async (page) => {
        try {
            let data
            user_data?.isSubClient ?
                data = await get_data(`request/get-all-request-by-sub-client?pageNumber=${page || currentPage}`) :
                data = await get_data(`request/get-all-request-by-client/${user_data?._id}?pageNumber=${page || currentPage}`);

            if (data.status) {
                setLoading(false);
                setData(data?.data?.requests)
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalRequests);
                if (page) {
                    setCurrentPage(page);
                }
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentPage) {
            if (value.length > 0) {
                handleSearch(currentPage);
            } else {
                getAllRequests(currentPage);
            }
        }
    }, [currentPage, refresh])

    const itemsPerPage = 20;
    const count = data?.length;

    const tableHeader = [
        "S No.", "Request ID", "GPS Devices", "Unique Ids", "Vehicles no.", "Drivers", "Installer", "Date"
    ]


    const handleSearch = async (page) => {
        setLoading(true);
        if (value.length > 0) {
            let data
            user_data?.isSubClient ?
                data = await post_data(`request/search-request-for-sub-client/${value}?pageNumber=${page || 1}`) :
                data = await post_data(`request/search-request-for-client/${value}?pageNumber=${page || 1}`)
            console.log('kljl', data)
            if (data.status === true) {
                setData(data?.data?.requests)
                setTotalData(data?.data?.totalRequests)
                setTotalPages(data?.data?.totalPages)
                setCurrentPage(page || 1);
            }
        } else {
            getAllRequests(1);
        }
        setLoading(false);
    }


    const handleDateChange = (dates) => {
        if (dates && dates?.length === 2) {
            setDateRange(dates);
        }
    };

    const fetchExportData = async () => {
        try {
            const fromDate = moment(startDate).toISOString();
            const toDate = moment(endDate).toISOString();
            let response
            user_data?.isSubClient ?
                response = await post_data('request/get-all-request-by-date-sub-client', {
                    from: fromDate,
                    to: toDate
                }) :
                response = await post_data('request/get-all-request-by-date-client', {
                    from: fromDate,
                    to: toDate
                })
            // console.log("XXXXXXXXX", response)

            if (response?.status) {
                setRequestsData(response?.data?.requests)
                setOpen(true)
                setFromDate(response?.data?.fromDate)
                setToDate(response?.data?.toDate)
            } else {

            }
        } catch (error) {
        }
    };

    const handleSubmitExcel = async (event) => {
        event.preventDefault();
        // if (!validate()) {
        setLoading(true)
        const respons = await post_data("request/create-excel-request", excelData)
        if (respons.status === true) {
            toast.success("Excel data successfully submitted.");
            setRefresh(!refresh)
            setExcelData([])
            setFileName('')
        } else {
            toast.error("Something went wrong!");
        }
        setRefresh(!refresh)
        setLoading(false)
    }


    const downloadCSV = () => {
        const userAgent = window.navigator.userAgent;
        let fileType = '';
        let fileName = '';

        if (userAgent.includes('Mac')) {
            fileType = 'numbers';
            fileName = 'request_record_file.numbers';
        } else if (userAgent.includes('Windows')) {
            fileType = 'xlsx';
            fileName = 'request_record_file.xlsx';
        } else {
            fileType = 'xlsx';
            fileName = 'request_record_file.xlsx';
        }

        const link = document.createElement('a');
        link.href = `${baseURL}/images/${fileName}`;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];// Get the file
        setFileName(file.name);
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0]; // Read first sheet
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet); // Convert sheet to JSON

            setExcelData(jsonData);
        };

        reader.readAsArrayBuffer(file);
    };


    return (
        <>  <Toaster position="top-right" reverseOrder={false} />
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <div style={{ display: 'flex', marginBottom: '1%', width: '100%' }}>

                    <div style={{ display: 'flex', gap: 10 }}>
                        <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />

                    </div>
                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10, width: '100%' }}>


                        <div style={{ display: 'flex', marginBottom: '1%', width: '100%' }}>
                            <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>

                                <Grid item sx={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: "10px" }}>
                                    {
                                        excelData.length === 0 &&
                                        <>
                                            <Button
                                                onClick={downloadCSV}
                                                variant='outlined'
                                                sx={{
                                                    borderColor: primaryColor,
                                                    color: primaryColor,
                                                    textTransform: 'capitalize',
                                                    borderRadius: 0,
                                                    fontWeight: 400,
                                                    boxShadow: 'none',
                                                    '&:hover': {
                                                        borderColor: primaryColor,
                                                        boxShadow: 'none',
                                                    },
                                                    '&:focus': {
                                                        outline: 'none',
                                                        boxShadow: 'none',
                                                        borderColor: primaryColor,
                                                    }
                                                }}
                                            >
                                                Download Excel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                component="label"
                                                sx={{
                                                    background: primaryColor,
                                                    color: 'white',
                                                    borderRadius: 0,
                                                    fontWeight: 400,
                                                    textTransform: 'capitalize',
                                                    boxShadow: 'none',
                                                    '&:hover': {
                                                        backgroundColor: primaryColorHover,
                                                        boxShadow: 'none',
                                                    },
                                                    '&:focus': {
                                                        outline: 'none',
                                                        boxShadow: 'none',
                                                        backgroundColor: primaryColor,
                                                    },
                                                }}
                                            >
                                                Upload Excel
                                                <input
                                                    type="file"
                                                    accept=".xlsx, .xls"
                                                    hidden
                                                    onChange={handleFileUpload}
                                                />
                                            </Button>
                                        </>
                                    }
                                    {/* Show file name after upload */}
                                    {fileName && <p>Uploaded File: {fileName}</p>}

                                    {excelData.length > 0 && (
                                        <Button
                                            variant="contained"
                                            sx={{
                                                background: primaryColor,
                                                color: 'white',
                                                borderRadius: 0,
                                                fontWeight: 400,
                                                textTransform: 'capitalize',
                                                boxShadow: 'none',
                                                '&:hover': {
                                                    backgroundColor: primaryColorHover,
                                                    boxShadow: 'none',
                                                },
                                                '&:focus': {
                                                    outline: 'none',
                                                    boxShadow: 'none',
                                                    backgroundColor: primaryColor,
                                                }
                                            }}
                                            onClick={handleSubmitExcel}
                                        >
                                            Submit
                                        </Button>
                                    )}


                                </Grid>
                            </div>
                        </div>

                        <div style={{ ...rangePickerStyle, border: 'none', }}>
                            <DatePicker
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                dateFormat="dd-MM-yyyy"
                                maxDate={new Date()}
                                customInput={
                                    <button style={{ border: 'none', background: '#fff', cursor: 'pointer', }}>
                                        {startDate && endDate ? `${moment(startDate).format('DD-MM-YYYY')} to ${moment(endDate).format('DD-MM-YYYY')}` : 'Select Date Range'}
                                    </button>
                                }
                            />
                        </div>
                        <Button
                            onClick={fetchExportData}
                            variant="contained"
                            style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none', padding: '0.8% 4%' }}
                        >
                            Export
                        </Button>

                        <Button
                            onClick={() => navigate('/company/dashboard/requestform')}
                            variant="contained"
                            style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none', padding: '0 4%' }}
                        >
                            Create
                        </Button>

                    </div>

                </div>


                {
                    data?.length === 0 ?
                        <>
                            <EmptyPage />
                        </>
                        :
                        <>
                            <ClientRequestTabel
                                type='requests'
                                data={data}
                                tableHeader={tableHeader}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                count={count}
                                totalData={totalData}
                                totalPages={totalPages}
                                loading={loading}
                                setLoading={setLoading}
                                getAllRequests={getAllRequests} />

                            <ClientsRequestTransactionModal
                                open={open}
                                setOpen={setOpen}
                                fromDate={fromDate}
                                toDate={toDate}
                                data={requestsData}
                            />
                        </>}
            </Grid>
        </>
    )
}