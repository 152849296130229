import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, useTheme, useMediaQuery, FormGroup, Slider, Typography, Button, Divider, TextField, MenuItem, Select, OutlinedInput, FormControl, InputLabel, Autocomplete } from '@mui/material';
import { primaryColor } from '../constant';


const Filters = ({ getFilteredData }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const [price, setPrice] = useState(25000);
    const [categoryList, setCategoryList] = useState([]);
    const [modelList, setModelList] = useState([]);

    const [filterPriceRange, setFilterPriceRange] = useState({ from: 0, to: 0 });
    const [fields, setFields] = useState({});


    const handlePriceChange = (field, value) => {
        setFilterPriceRange((prev) => ({
            ...prev,
            [field]: value,
        }));

        setFields({
            ...fields,
            [field]: value
        })

        let body = {
            ...fields,
            [field]: value
        }

        if (body.from === 0 || body.from === '') {
            delete body.from;
            removeField('from');
        }

        if (body.to === 0 || body.to === '') {
            delete body.to;
            removeField('to');
        }

        getFilteredData(body);

    };


    const removeField = (keyToRemove) => {
        const updatedFields = { ...fields };

        delete updatedFields[keyToRemove];

        setFields(updatedFields);
    };

    const styles = {
        filterContainer: {
            padding: '20px 30px',
            border: '1px solid gainsboro',
            borderRadius: 0,
            backgroundColor: 'white',

        },
        sectionTitle: {
            marginBottom: '10px',
        },
        divider: {
            margin: '20px 0',
        },
        priceRangeContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '20px',
        },
        priceRange: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10px',
        },
        priceLabel: {
            fontWeight: 400,
        },
        priceRangeLabel: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
            fontSize: '16px',
            color: primaryColor,
        },
        applyButtonContainer: {
            display: 'flex',
            justifyContent: 'left',
            marginTop: '20px',
        },
        applyButton: {
            backgroundColor: primaryColor,
            color: 'white',
            boxShadow: 'none',
            width: '50%',
            borderRadius: 8
        },
    };

    return (
        <div style={styles.filterContainer}>

            {matches && <div style={{
                width: 'fit-content',
                padding: '6px 20px',
                background: primaryColor,
                color: '#ffff',
                fontSize: '1.2rem',
                fontWeight: 400,
                borderRadius: '10px',
                marginLeft: '27%',
                marginBottom: '20px',
                cursor: 'pointer'
            }}
            >
                Apply
            </div>}
            {/* Categories */}
            <Typography variant="h6" style={styles.sectionTitle}>Categories</Typography>
            <FormGroup>
                {categoryList?.map((item, i) => {
                    return <FormControlLabel key={i} control={<Checkbox sx={{ color: primaryColor, '&.Mui-checked': { color: primaryColor } }} />} label={item.name} value={item?._id} />
                })}
            </FormGroup>

            <FormGroup>
                <FormControlLabel key={'9'} control={<Checkbox sx={{ color: primaryColor, '&.Mui-checked': { color: primaryColor } }} />} label={'Spare Vehicles'} value={'#'} />
            </FormGroup>

            <Divider style={styles.divider} />


            <Typography variant="h6" style={styles.sectionTitle}>Choose a price below</Typography>

            <div style={styles.priceRangeContainer}>
                <div style={styles.priceRangeLabel}>
                    <span>Price:</span>
                    <span>₹{filterPriceRange?.from} - ₹{filterPriceRange?.to}</span>
                </div>
                <div style={styles.priceInputs}>
                    <TextField
                        label="From"
                         
                        variant="outlined"
                        fullWidth
                        value={filterPriceRange?.from}
                        onChange={(e) => handlePriceChange('from', e.target.value)}
                        sx={{ marginRight: '10px' }}
                    />
                    <TextField
                        label="To"
                         
                        variant="outlined"
                        fullWidth
                        value={filterPriceRange?.to}
                        style={{ marginTop: '10px' }}
                        onChange={(e) => handlePriceChange('to', e.target.value)}
                    />
                </div>
            </div>

            <Divider style={styles.divider} />


            {/* Location */}
            <Typography variant="h6" style={styles.sectionTitle}>Locations</Typography>
            <TextField
                label="Enter location"
                variant="outlined"
                fullWidth
                placeholder="Enter location"
                name="location">
            </TextField>

            <Divider style={styles.divider} />

            {/* Mobels */}
            <Typography variant="h6" style={styles.sectionTitle}>Models</Typography>
            <TextField
                label="Enter location"
                variant="outlined"
                fullWidth
                placeholder="Enter location"
                name="location">
            </TextField>

            <Divider style={styles.divider} />

            {/* Price Range */}
            <Typography variant="h6" style={styles.sectionTitle}>Choose a price below</Typography>

            <div style={styles.priceRangeContainer}>
                <div style={styles.priceRangeLabel}>
                    <span>Price:</span>
                    <span>₹{filterPriceRange?.from} - ₹{filterPriceRange?.to}</span>
                </div>
                <div style={styles.priceInputs}>
                    <TextField
                        label="From"
                         
                        variant="outlined"
                        fullWidth
                        value={filterPriceRange?.from}
                        onChange={(e) => handlePriceChange('from', e.target.value)}
                        sx={{ marginRight: '10px' }}
                    />
                    <TextField
                        label="To"
                         
                        variant="outlined"
                        fullWidth
                        value={filterPriceRange?.to}
                        style={{ marginTop: '10px' }}
                        onChange={(e) => handlePriceChange('to', e.target.value)}
                    />
                </div>
            </div>


            <Divider style={styles.divider} />

            {/* Location */}
            <Typography variant="h6" style={styles.sectionTitle}>Locations</Typography>
            <TextField
                label="Enter location"
                variant="outlined"
                fullWidth
                placeholder="Enter location"
                name="location">
            </TextField>

            <Divider style={styles.divider} />


            {/* Mobels */}
            <Typography variant="h6" style={styles.sectionTitle}>Models</Typography>
            <TextField
                label="Enter location"
                variant="outlined"
                fullWidth
                placeholder="Enter location"
                name="location">
            </TextField>

            <Divider style={styles.divider} />


            <Typography variant="h6" style={styles.sectionTitle}>Transmission</Typography>
            <FormGroup style={{ textAlign: 'center' }} >
                {[1, 2, 3, 4, 5]?.map((item, i) => {
                    return <FormControlLabel key={i} control={<Checkbox sx={{ color: primaryColor, '&.Mui-checked': { color: primaryColor } }} />} label={item} value={item} />
                })}
            </FormGroup>


        </div>
    );
}

export default Filters;