import React, { useEffect, useState } from 'react';
import { AppBar, Box, Tab, Tabs, Typography, Grid } from '@mui/material';
import { primaryColor } from '../../../constant';
import ClientDetailComp from '../../../components/ClientDetailComp';
import { get_data } from '../../../api';
import ClientRequestComp from '../../../components/ClientRequestComp';
import ClientReturnComp from '../../../components/ClientReturnComp';
import ClientClaimComp from '../../../components/ClientClaimComp';
import ClientUninstallComp from '../../../components/ClientUninstallComp';
import ClientInventoryComp from '../../../components/ClientInventoryComp';
import ServiceRequestsComp from '../../../components/ServiceRequestsComp';


// Tab Panel to show content based on the active tab
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function ClientDetail() {
    const [tabIndex, setTabIndex] = useState(0);
    const [client, setClient] = useState({});

    const getCilentDetails = async () => {
        let result = await get_data(`client/get-client/${localStorage.getItem('clientId')}`)
        if (result?.status) {
            setClient(result?.data);
        }
    }

    useEffect(() => {
        if (localStorage.getItem('clientId')) {
            getCilentDetails()
        }
    }, [])


    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <AppBar position="static" sx={{ backgroundColor: primaryColor, boxShadow: 'none' }}>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="inherit"
                    variant="fullWidth"
                >
                    <Tab label="Detail" />
                    <Tab label="Request" />
                    <Tab label="Return" />
                    <Tab label="Claim" />
                    <Tab label="Uninstall" />
                    <Tab label="Inventory" />
                    <Tab label="Service Requests" />
                </Tabs>
            </AppBar>

            {/* Tab Panels for each tab */}
            <TabPanel value={tabIndex} index={0}>
                <ClientDetailComp client={client} setClient={setClient} />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <ClientRequestComp client={client} />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
                <ClientReturnComp clientId={client?._id} />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
                <ClientClaimComp clientId={client?._id} />
            </TabPanel>
            <TabPanel value={tabIndex} index={4}>
                <ClientUninstallComp clientId={client?._id} />
            </TabPanel>
            <TabPanel value={tabIndex} index={5}>
                <ClientInventoryComp  clientId={client?._id} />
            </TabPanel>
            <TabPanel value={tabIndex} index={6}>
                <ServiceRequestsComp clientId={client?._id} />
            </TabPanel>
        </Box>
    );
}
