import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { State, City } from 'country-state-city';
import { IoMdAdd } from "react-icons/io";
import { createButtonBg, primaryColor } from '../constant';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import { doesSectionFormatHaveLeadingZeros } from '@mui/x-date-pickers/internals/hooks/useField/useField.utils';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '7px',
    border: '0px solid #fff',
    p: 4,
    maxHeight: '80vh',
    overflow: 'auto',
};

export default function AddSubVendorModal({ title,
    setFormData,
    formData,
    handleVandorSubmit,
    isUpdate,
    vandorTableOpen,
    setVandorTableOpen,
    setVendorUpdateOpen,
    vendorUpdateOpen,
    updateData,
    screen,
    index
}) {
    const [addVendorModalOpen, setAddVendorModalOpen] = useState(false);
    const [vendorName, setVendorName] = useState('');
    const [street, setStreet] = useState('');
    const [countryCode] = useState('IN'); // Assuming only India for simplicity
    const [stateList, setStateList] = useState([]);
    const [state, setState] = useState('');
    const [cityList, setCityList] = useState([]);
    const [city, setCity] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [errors, setErrors] = useState({});


    useEffect(() => {
        if (updateData) {
            setVendorName(updateData?.vendorName || '');
            setStreet(updateData?.street || '');
            setState(updateData?.state || '');
            setCity(updateData?.city || '');
            setZipCode(updateData?.zipCode || '');
        }
    }, [updateData]);

    useEffect(() => {
        if (isUpdate) {
            setCityList(City.getCitiesOfState(countryCode, formData?.vendor?.address?.state));
        }
    }, [])


    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const validation = () => {
        let error = false;
        if (!vendorName) {
            error = true;
            handleError('Please input Vendor Name...', 'vendorName');
        }
        if (!street) {
            error = true;
            handleError('Please input Street...', 'street');
        }
        if (!state) {
            error = true;
            handleError('Please input State...', 'state');
        }
        if (!city) {
            error = true;
            handleError('Please input City...', 'city');
        }
        if (!zipCode) {
            error = true;
            handleError('Please input Zip Code...', 'zipCode');
        }
        return error;
    };

    const handleVendorModal = () => {
        setAddVendorModalOpen(true);
    };


    const handleClose = () => {
        isUpdate ? setVendorUpdateOpen(false) : setAddVendorModalOpen(false);
        setErrors({});
        setVendorName('')
        setStreet('')
        setState('')
        setCity('')
        setZipCode('')
    };

    useEffect(() => {
        setStateList(State.getStatesOfCountry(countryCode));
    }, [countryCode]);


    const handleSubmit = () => {
        if (isUpdate) {
            setVandorTableOpen((prev) => ({ ...prev, [index]: true }))
            handleVandorSubmit(formData?.vendor, isUpdate)
            setVendorName('')
            setStreet('')
            setState('')
            setCity('')
            setZipCode('')
            handleClose();
        } else if (title === 'AdminVendors') {
            setVendorName('')
            setStreet('')
            setState('')
            setCity('')
            setZipCode('')
            handleClose();
        } else {
            setVandorTableOpen((prev) => ({ ...prev, [index]: true }))
            handleVandorSubmit({ ...formData?.vendor }, isUpdate)
        }
    };

    const handleVendorDetailChange = (index, field, value) => {
        setFormData((prevState) => {
            const updatedGps = prevState.gps.map((form, idx) => {

                if (field === 'street' || field === 'zipCode' || field === 'city' || field === 'state' || field === 'landmark') {
                    return {
                        ...form,
                        vendorDetails: {
                            ...form.vendorDetails,
                            address: {
                                ...form.vendorDetails.address,
                                [field]: value
                            }
                        },
                    };
                }

                if (index === idx) {
                    return {
                        ...form,
                        vendorDetails: {
                            ...form.vendorDetails,
                            [field]: value,
                        },
                    };
                }
                return form;
            });
            return { ...prevState, gps: updatedGps };
        });
    };

    const handleStateChange = (event, newValue) => {
        if (newValue) {
            const stateCode = newValue.isoCode;
            const fetchedCities = City.getCitiesOfState(countryCode, newValue.isoCode);

            setCityList(fetchedCities);

            setFormData(prevState => {
                const updatedGps = prevState.gps.map((form, idx) => {
                    if (index === idx) {
                        return {
                            ...form,
                            vendorDetails: {
                                ...form.vendorDetails,
                                address: {
                                    ...form.vendorDetails.address,
                                    state: stateCode, // Update state
                                    city: '' // Reset city when state changes
                                }
                            }
                        };
                    }
                    return form;
                })

                return {
                    ...prevState,
                    gps: updatedGps,
                };
            })
        };
    };

    const handleCityChange = (event, newValue) => {
        if (newValue) {

            setFormData(prevState => {
                const updatedGps = prevState.gps.map((form, idx) => {
                    if (index === idx) {
                        return {
                            ...form,
                            vendorDetails: {
                                ...form.vendorDetails,
                                address: {
                                    ...form.vendorDetails.address,
                                    city: newValue.name
                                }
                            }
                        };
                    }
                    return form;
                })

                return {
                    ...prevState,
                    gps: updatedGps,
                };
            })
        };
    }


    return (
        <div>

            {screen === "requestForm" && <Typography color={primaryColor} onClick={handleVendorModal} style={{ cursor: 'pointer', fontSize: 14, marginLeft: 10 }}>
                Add Your Vendor
            </Typography>}

            <Modal open={isUpdate ? vendorUpdateOpen : addVendorModalOpen} onClose={handleClose}>
                <Box sx={style}>
                    <Typography variant="h6" component="h2">
                        Add Vendor
                    </Typography>
                    <Grid container spacing={2} style={{ marginTop: 10 }}>
                        <Grid item xs={12}>
                            <TextField
                                error={Boolean(errors.vendorName)}
                                helperText={errors.vendorName}
                                onFocus={() => handleError('', 'vendorName')}
                                value={formData?.gps[index]?.vendorDetails?.name}
                                label="Vendor Name"
                                onChange={(e) => handleVendorDetailChange(index, 'name', e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                label="Contact No"
                                 
                                value={formData?.gps[index]?.vendorDetails?.contactNo}
                                onChange={(e) => handleVendorDetailChange(index, 'contactNo', e.target.value)}
                                name="contactNo"
                                error={!!errors.contactNo}
                                helperText={errors.contactNo}
                                onFocus={() => handleError('', 'contactNo')}
                                fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Email"
                                value={formData?.gps[index]?.vendorDetails?.email}
                                onChange={(e) => handleVendorDetailChange(index, 'email', e.target.value)}
                                name="email"
                                error={!!errors.email}
                                helperText={errors.email}
                                onFocus={() => handleError('', 'email')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="h1">Address</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Street"
                                error={Boolean(errors.street)}
                                helperText={errors.street}
                                onFocus={() => handleError('', 'street')}
                                value={formData?.gps[index]?.vendorDetails?.address?.street}
                                onChange={(e) => handleVendorDetailChange(index, 'street', e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Landmark"
                                error={Boolean(errors.landmark)}
                                helperText={errors.landmark}
                                onFocus={() => handleError('', 'landmark')}
                                value={formData?.gps[index]?.vendorDetails?.address?.landmark}
                                onChange={(e) => handleVendorDetailChange(index, 'landmark', e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Autocomplete
                                id="state-select"
                                options={stateList}
                                value={stateList.find(s => s.isoCode === formData?.gps[index]?.vendorDetails?.address?.state) || null}
                                onChange={handleStateChange}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) =>
                                    <TextField
                                        error={!!errors.state}
                                        helperText={errors.state}
                                        onFocus={() => handleError('', 'state')}
                                        {...params}
                                        label="Select State"
                                        fullWidth
                                    />
                                }
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Autocomplete
                                id="city-select"
                                options={cityList}
                                value={cityList.find(c => c.name === formData?.gps[index]?.vendorDetails?.address?.city) || null}
                                onChange={handleCityChange}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        error={!!errors.city}
                                        helperText={errors.city}
                                        onFocus={() => handleError('', 'city')}
                                        label="Select City"
                                        fullWidth
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                label='Zip Code'
                                 
                                name='zipCode'
                                value={formData?.gps[index]?.vendorDetails?.address?.zipCode}
                                error={!!errors.zipCode}
                                helperText={errors.zipCode}
                                onFocus={() => handleError('', "zipCode")}
                                onChange={(e) => handleVendorDetailChange(index, 'zipCode', e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
                            <Button variant="outlined" style={{ textTransform: 'capitalize', color: primaryColor, border: `1px solid ${primaryColor}`, borderRadius: 0 }} fullWidth onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button style={{ textTransform: 'capitalize', color: '#fff', background: primaryColor, borderRadius: 0 }} onClick={handleSubmit} fullWidth>
                                {isUpdate ? "Update" : 'Save'}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}