import { Modal, Box, Typography, Button, Divider } from "@mui/material";
import { post_data, serverURL } from "../api";
import { primaryColor } from "../constant";
import toast from "react-hot-toast";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../redux/slices/user-slice";

const VendorDocsModal = ({ open, setOpen, docs, docVerify, vendorId, setClient, refresh, setRefresh, screen }) => {
    console.log("docs..........",docs)
    const dispatch = useDispatch();
    const [loading, setLoading] = useState({});
    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            boxShadow: 24,
            padding: '24px',
            outline: 'none',
            maxHeight: '80vh',
            overflowY: 'auto',
        },
        heading: {
            fontWeight: 600,
            fontSize: 24,
            marginBottom: '20px',
            textAlign: 'center',
            color: '#333',
        },
        row: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
            margin: '16px 0',
        },
        dataLabel: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer',
        },
        dataValue: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
        },
        docImage: {
            width: 200,
            height: 200,
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            transition: 'transform 0.2s',
            '&:hover': {
                transform: 'scale(1.05)',
            },
        },
        verifyButton: {
            color: '#fff',
            backgroundColor: primaryColor,
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            marginTop: '20px',
            marginBottom: '30px',
            boxShadow: 'none',
        },
        headingTypography: {
            fontWeight: 600,
            fontSize: 22,
            padding: '16px 0',
            color: '#333',
        },
    };

    const handleClose = () => {
        setOpen(false);
    };

    const DataRow = ({ label, value }) => (
    
        <div style={styles.row}>
            {console.log("label.....",label)}
            <span style={styles.dataLabel}>
                <img
                    src={`${serverURL}/uploads/vendor-images/${label}`}
                    alt={label}
                    style={styles.docImage}
                    onClick={() => window.open(`${serverURL}/uploads/vendor-images/${label}`, '_blank')}
                />
            </span>
            <span style={styles.dataValue}>{value}</span>
        </div>
    );

    const handleVerify = async (index) => {
        console.log('vendorId', vendorId, 'index', index);

        setLoading((prev) => ({ [index]: true }));

        const key = `doc${index + 1}_verified`;
        const result = await post_data('vendor/verify-document', {
            vendorId,
            [key]: !docVerify[index],
        })

        if (result?.status === true) {
            setRefresh(!refresh)
            toast.success(result?.message);
        } else {
            toast.error(result?.response?.data?.message || 'Something went wrong');
        }

        setLoading((prev) => ({ [index]: false }));
    };
        console.log("kkkkkkkkkkkkkk: ",docs)
    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={styles.modalBox}>
                <Typography variant="h6" sx={styles.headingTypography}>
                    Vendor Documents
                </Typography>
                <Divider sx={{ marginBottom: '20px' }} />
                <Box sx={{ padding: '0 10%' }}>
                    {docs?.length >=1 ? docs?.map((doc, index) => (
                    doc?.length > 0 ?    <DataRow
                            key={index}
                            label={doc}
                            value={
                             screen === 'admin' ? <></> :   <Button
                                    variant="contained"
                                    sx={styles.verifyButton}
                                    onClick={() => handleVerify(index)}
                                >
                                    {loading[index] ? 'Loading...' : docVerify[index] ? 'Verified' : 'Not Verified'}
                                </Button>
                            }
                        /> : <div></div>
                    )):<div>No Data</div>}
                </Box>
            </Box>
        </Modal>
    );
};

export default VendorDocsModal;