import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Button, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import Rating from '@mui/material/Rating';
import { TextField, InputAdornment } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';

import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { primaryColor } from '../../constant';
import { post_data, serverURL } from '../../api';

export default function ReviewDrawer({ service, vendors, bookingId, getAllBookings }) {

    const [state, setState] = React.useState({ right: false })
    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'));
    const [value, setValue] = useState(0)
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    }

    const handleDrawer = (event) => {
        toggleDrawer('right', true)(event);
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const button = {
        padding: '13px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '100%',
        marginTop: '5px',
        boxShadow: 'none',
    }

    const rating = () => {
        return (
            <Box style={{ margin: '1% 0' }}>
                <Rating
                    name="simple-controlled"
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    emptyIcon={<StarBorderIcon style={{ color: '#BDBDBD', fontSize: 35 }} />}
                    icon={<StarIcon style={{ color: primaryColor, fontSize: 35 }} />}
                />
            </Box>
        );
    };

    const [selectedService, setSelectedService] = useState(service?.[0])
    const [selectedServiceId, setSelectedServiceId] = useState(service?.[0]?._id)
    const [vendorID, setVendorID] = useState(vendors && vendors[0]?.vendor_id?._id);

    const handleServiceClick = (item) => {
        setSelectedService(item)
        setSelectedServiceId(item?._id)
        vendors.map((vendor) => {
            if (item?._id == vendor?.vendor_id?._id) {
                setVendorID(vendor?.vendor_id?._id)
            }
        })

    }

    const { user_data } = useSelector(state => state.user);

    const handleSubmitReview = async () => {
        try {
            let payload = {
                booking_id: bookingId,
                service_id: selectedServiceId,
                customer_id: user_data?._id,
                rating: value,
                review_text: description,
                vendor_id: vendorID
            }
            const data = await post_data(`reviews/create-review`, payload);
            if (data.status) {
                toggleDrawer('right', false)('')
                getAllBookings(1)
                Toast.fire({
                    icon: 'success',
                    title: "Review submited successfully",
                    color: "green"
                })
            }
            else {
                Toast.fire({
                    icon: 'Warning',
                    title: "Something Went Wrong",
                    color: "red"
                })
            }
        } catch (error) {
            console.log("Error While submitting review", error);
        }
    }

    const reviewComponent = () => {
        return (
            <div>
                <Grid container spacing={1} style={{ padding: '3% 5%' }}>
                    <Grid item md={12}>
                        <h3 style={{ margin: 0, fontWeight: 600, fontSize: 25 }}>Write a Review</h3><br />
                        <hr style={{ opacity: '40%' }} /><br />
                        <Grid container spacing={1}>
                            <Grid item md={12}>
                                <div style={{ display: 'flex', gap: 10 }}>
                                    {
                                        service?.map((item) => {
                                            return (
                                                <>
                                                    <img onClick={() => handleServiceClick(item)} src={`${serverURL}/uploads/service-picture/${item?.service_image}`} style={{ width: 100, height: 100, cursor: 'pointer', border: selectedServiceId === item?._id ? `3px solid ${primaryColor}` : '3px solid white', borderRadius: 7 }} />
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </Grid>
                            <Grid item md={12}>
                                <h3 style={{ margin: 0, fontWeight: 500, fontSize: 22 }}>{selectedService?.service_name}</h3>
                                <p style={{ margin: 0, fontWeight: 400, fontSize: 16, opacity: '80%', marginTop: 10 }}>{selectedService?.description}</p>
                            </Grid>
                        </Grid><br />
                        <hr style={{ opacity: '40%' }} /><br />
                        <h3 style={{ margin: 0, fontWeight: 500, fontSize: 22 }}>Product Rating</h3>
                        {rating()}
                        <hr style={{ opacity: '40%' }} /><br />
                        <h3 style={{ margin: 0, fontWeight: 500, fontSize: 22 }}>Write your Review</h3><br />
                        <Grid item md={12} style={{ margin: '1% 0', width: '100%' }}>
                            <div style={{ width: 'auto', height: 130, display: 'flex', background: 'transparent', padding: '2% 4%', borderRadius: 10, border: '1px solid #525252' }}>
                                <TextField fullWidth
                                    onChange={(e) => {
                                        if (e.target.value.length <= 200)
                                            setDescription(e.target.value)
                                        else
                                            setError("Only 200 Characters allowed!")
                                    }}
                                    sx={{ input: { color: 'black', fontSize: 18 } }}
                                    variant="standard"
                                    value={description}
                                    hiddenLabel
                                    placeholder="Write your Review"
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    onFocus={() => {
                                        setError("");
                                    }}
                                />
                                <small>Only 200 characters are allowed</small>
                                {/* <small style={{ color: "#BA0000" }}>{error}</small> */}
                            </div>
                        </Grid><br />
                        <Grid item md={4}>
                            <Button onClick={handleSubmitReview} variant='contained' style={button}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }

    const list = (anchor) => (
        <Box
            style={{ width: matches_md ? '100%' : 600, position: 'relative' }}
            role="presentation"
            onKeyDown={(event) => {
                if (event.key === 'Escape') {
                    toggleDrawer(anchor, false)(event);
                }
            }}
        >
            <CloseIcon onClick={toggleDrawer('right', false)} style={{ position: 'absolute', right: '3%', top: '4%', cursor: 'pointer', opacity: '70%' }} />
            {reviewComponent()}
        </Box>
    );


    return (
        <div>
            <React.Fragment key={'right'}>
                <Button onClick={handleDrawer} variant='outlined' style={{ margin: 0, fontWeight: 500, fontSize: 13, border: '1px solid black', color: 'black', padding: '5px 15px', borderRadius: 5 }}>Write a Review</Button>
                <Drawer
                    anchor={'right'}
                    open={state['right']}
                    onClose={toggleDrawer('right', false)}
                >
                    {list('right')}
                </Drawer>
            </React.Fragment>
        </div>
    );
}