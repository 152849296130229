
import { Box, Button, Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as XLSX from 'xlsx';
import { formatDate, formatPrice, primaryColor } from "../../constant";
import EmptyPage from "../EmptyPage";

const ClaimsTransactionModal = ({ open, setOpen, data, fromDate, toDate, type }) => {
    const contentRef = useRef(null);

    const styles = {
        invoice: {
            padding: "4%",
            height: 470,
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #000",
            paddingBottom: "10px",
            marginBottom: "5%",
        },
        brand: {
            textAlign: "left",
        },
        info: {
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "10px",
            paddingBottom: "10px",
        },
        infoSection: {
            textAlign: "left",
        },
        table: {
            width: "100%",
            borderCollapse: "collapse",
        },
        th: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        td: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        footer: {
            marginTop: "20px",
            borderTop: "2px solid #000",
            marginTop: "5%",
            paddingTop: "10px",
            display: "flex",
            justifyContent: "space-between",
        },
        terms: {
            textAlign: "left",
        },
        payment: {
            textAlign: "left",
        },
        summary: {
            marginTop: "20px",
            textAlign: "right",
        },
        text: {
            fontSize: 14,
        },
    };


    // const handleDownloadPdf = async () => {
    //     if (contentRef.current) {
    //         const pdf = new jsPDF("p", "mm", "a4");
    //         const pageWidth = 210; // A4 page width in mm
    //         const pageHeight = 295; // A4 page height in mm
    //         const margin = 10;
    //         const tableStartY = 20;
    //         const rowHeight = 10; // Estimate row height
    //         const rowsPerPage = Math.floor((pageHeight - tableStartY - margin) / rowHeight);

    //         // Clone the data to paginate through it
    //         const dataClone = [...data];
    //         let positionY = tableStartY;
    //         let pageNumber = 1;

    //         // Render each page
    //         while (dataClone.length > 0) {
    //             const currentPageData = dataClone.splice(0, rowsPerPage);

    //             // Add table headers
    //             pdf.setFontSize(12);
    //             pdf.text("Invoice no.", margin, positionY);
    //             pdf.text("Invoice To", margin + 30, positionY);
    //             pdf.text("name", margin + 70, positionY);
    //             pdf.text("Date", margin + 110, positionY);
    //             pdf.text("Price", margin + 140, positionY);

    //             positionY += rowHeight;

    //             // Add rows for the current page
    //             currentPageData.forEach((item, i) => {
    //                 pdf.setFontSize(10);
    //                 pdf.text(item?.invoiceNumber, margin, positionY);
    //                 pdf.text(item?.userId?.name || "-", margin + 30, positionY);
    //                 pdf.text(item?.listingId?.title + "(Boosted AD)", margin + 70, positionY);
    //                 pdf.text(formatDate(item?.createdAt) || "", margin + 110, positionY);
    //                 pdf.text(formatPrice(item?.amount) || "", margin + 140, positionY);

    //                 positionY += rowHeight;
    //             });

    //             // Add a new page if there are more records to render
    //             if (dataClone.length > 0) {
    //                 pdf.addPage();
    //                 positionY = tableStartY;
    //                 pageNumber++;
    //             }
    //         }

    //         pdf.save("invoice.pdf");
    //     }
    // };

    const handleDownloadExcel = () => {
        let excelData
        if (data && data.length > 0) {
            if (type === 'client') {
                excelData = data.map(item => ({
                    // 'Invoice no.': item?.invoiceNumber || '',
                    // 'Invoice To': item?.userId?.name || '-',
                    'Vehicle No.': item?.details?.map((item) => <div>{item?.vehicleNumber}</div>) || '',
                    'IMEI No.': item?.details?.map((item) => <div>{item?.imei}</div>) || '-',
                    'SIM No.': item?.details?.map((item) => <div>{item?.simNumber}</div>) || '-',
                    'Device UniqueId': item?.details?.map((item) => <div>{item?.deviceUniqueId}</div>) || '-',
                    'Phone': item?.clientId?.contactNo || '-',
                    'Claim Reason': item?.claimReason || '',
                    'Date': formatDate(item?.createdAt) || '',
                    'Status': item?.status || '',

                }));
            } else {
                excelData = data.map(item => ({

                    // 'Invoice no.': item?.invoiceNumber || '',
                    // 'Invoice To': item?.userId?.name || '-',
                    // 'Request Id': item?.requestId || '-',
                    'Vehicle No.': item?.details?.map((item) => <div>{item?.vehicleNumber}</div>) || '',
                    'IMEI No.': item?.details?.map((item) => <div>{item?.imei}</div>) || '-',
                    'SIM No.': item?.details?.map((item) => <div>{item?.simNumber}</div>) || '-',
                    'Device UniqueId': item?.details?.map((item) => <div>{item?.deviceUniqueId}</div>) || '-',
                    'Phone': item?.clientId?.contactNo || '-',
                    'Claim Reason': item?.claimReason || '',
                    'Date': formatDate(item?.createdAt) || '',
                    'Status': item?.status || '',

                }));
            }


            // Create a new workbook and add the data
            const worksheet = XLSX.utils.json_to_sheet(excelData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Invoices");

            // Download the file
            XLSX.writeFile(workbook, "invoices.xlsx");
        }
    };


    const styleModal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 900,
        maxHeight: '90%',
        borderRadius: 2,
        overflowY: 'auto',
        bgcolor: "white",
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        border: "none",
        boxShadow: 24,
        padding: '0 0 5%',
    };

    const button = {
        padding: '5px',
        fontSize: 13,
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '15%',
        marginTop: '20px',
        boxShadow: 'none',
        marginLeft: '2%'
    }
    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
            >
                <Box sx={styleModal}>
                    {
                        data?.length === 0 ? (
                            <EmptyPage />
                        ) : (
                            <>
                                <Button variant="contained" onClick={() => { handleDownloadExcel() }} style={button}>Download</Button>
                                <div ref={contentRef} id="invoice" style={styles.invoice}>
                                    <div style={styles.header}>
                                        <div style={styles.brand}>
                                            <h2 style={{ fontWeight: 600, margin: 0, }}>Claim's Details</h2>
                                            {/* <p style={{ fontSize: 11, opacity: "70%" }}>
                                    Services with a Lifestyle
                                </p> */}
                                        </div>
                                        {/* <h2 style={{ fontWeight: 600, margin: 0 }}>Claim Record</h2> */}
                                    </div>

                                    <table style={{ ...styles.table, ...styles.text, margin: "3% 0" }}>
                                        <thead style={{ textAlign: "left" }}>
                                            {type === 'client' ? <tr>
                                                {/* <th>Invoice no.</th>
                                                <th>Invoice To</th> */}
                                                <th>Claim Id</th>
                                                <th>Vehicle No.</th>
                                                <th>IMEI No.</th>
                                                <th>SIM No.</th>
                                                <th>Device UniqueId</th>
                                                <th>Phone</th>
                                                <th>Claimed Reason</th>
                                                <th>Date</th>

                                            </tr> : <tr>
                                                {/* <th>Invoice no.</th>
                                                <th>Invoice To</th> */}
                                                <th>Claim Id</th>
                                                <th>Vehicle No.</th>
                                                <th>IMEI No.</th>
                                                <th>SIM No.</th>
                                                <th>Device UniqueId</th>
                                                <th>Phone</th>
                                                <th>Claimed Reason</th>
                                                <th>Date</th>

                                            </tr>}

                                        </thead>
                                        <tbody>
                                            {
                                                data?.map((item, i) => {
                                                    return (
                                                        <>{type === 'client' ? <tr style={{ margin: 0, borderBottom: '1px solid gainsboro' }}>
                                                            {/* <td style={{ padding: '1% 0' }}>{item?.invoiceNumber}</td>
                                                        <td style={{ padding: '1% 0' }}>{item?.userId?.name || '-'}</td> */}
                                                            <td style={{ padding: '1% 0' }}>{item?.uniqueClaimId || '-'}</td>
                                                            <td style={{ padding: '1% 0' }}>{item?.details?.map((item) => <div>{item?.vehicleNumber}</div>) || ''}</td>
                                                            <td style={{ padding: '1% 0' }}>{item?.details?.map((item) => <div>{item?.imei}</div>) || '-'}</td>
                                                            <td style={{ padding: '1% 0' }}>{item?.details?.map((item) => <div>{item?.simNumber}</div>) || '-'}</td>
                                                            <td style={{ padding: '1% 0' }}>{item?.details?.map((item) => <div>{item?.deviceUniqueId}</div>) || '-'}</td>
                                                            <td style={{ padding: '1% 0' }}>{item?.clientId?.contactNo || '-'}</td>
                                                            <td style={{ padding: '1% 0' }}>{item?.claimReason || '-'}</td>
                                                            <td style={{ padding: '1% 0' }}>{formatDate(item?.createdAt)}</td>
                                                        </tr> : <tr style={{ margin: 0, borderBottom: '1px solid gainsboro' }}>
                                                            {/* <td style={{ padding: '1% 0' }}>{item?.invoiceNumber}</td>
                                                        <td style={{ padding: '1% 0' }}>{item?.userId?.name || '-'}</td> */}
                                                            <td style={{ padding: '1% 0' }}>{item?.uniqueClaimId || '-'}</td>
                                                            <td style={{ padding: '1% 0' }}>{item?.details?.map((item) => <div>{item?.vehicleNumber}</div>) || ''}</td>
                                                            <td style={{ padding: '1% 0' }}>{item?.details?.map((item) => <div>{item?.imei}</div>) || '-'}</td>
                                                            <td style={{ padding: '1% 0' }}>{item?.details?.map((item) => <div>{item?.simNumber}</div>) || '-'}</td>
                                                            <td style={{ padding: '1% 0' }}>{item?.details?.map((item) => <div>{item?.deviceUniqueId}</div>) || '-'}</td>
                                                            <td style={{ padding: '1% 0' }}>{item?.clientId?.contactNo || '-'}</td>
                                                            <td style={{ padding: '1% 0' }}>{item?.claimReason || '-'}</td>
                                                            <td style={{ padding: '1% 0' }}>{formatDate(item?.createdAt)}</td>
                                                        </tr>}

                                                        </>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <div style={{ ...styles.footer, ...styles.text }}>
                                        <div style={styles.terms}>
                                            <p>All Invoices are from {formatDate(fromDate)} to {formatDate(toDate)} period.</p>
                                            <p>For GPS</p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </Box>
            </Modal>
        </>
    );
};

export default ClaimsTransactionModal;