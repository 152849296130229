import Header from "../../components/Header";
import Footer from "../../components/Footer";
import RequestForm from "../../components/RequestForm";

export default function Request() {

    return (
        <>
            <Header />
            <RequestForm />
            <Footer />
        </>
    )
}