import axios from "axios";

// const serverURL = "http://localhost:8000"
const serverURL = "https://api.oredogps.com"

const axiosRequest = axios.create({
    baseURL: serverURL,
});

axiosRequest.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("authToken");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosRequest;