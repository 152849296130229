import React, { useEffect, useState } from "react";
import { get_data, post_data } from "../api";
import { createButtonBg, formatDate, formatPrice } from "../constant";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { Backdrop, Button, CircularProgress } from "@mui/material";

const AdminDashComp = () => {

  const [uninstalledList, setUninstalledList] = useState([])
  const [requestList, setRequestList] = useState([])
  const [returnList, setReturnList] = useState([])
  const [claimsList, setClaimsList] = useState([]);
  const [ecommerceSale, setEcommerceSale] = useState(0);
  const [rentalSale, setRentalSale] = useState(0);
  const [dateRange, setDateRange] = useState([]);
  const [startDate, endDate] = dateRange;
  const [open, setOpen] = useState(true)
  const [fromDate, setFromDate] = useState([])
  const [toDate, setToDate] = useState([])

  const getUninstalledList = async () => {
    let result = await get_data(`uninstall/get-uninstall-for-dashboard`)
    if (result?.status == true) {
      setUninstalledList(result?.data)
      if (result?.data) {
        setOpen(false)
      }
    }
  }

  const getRequestList = async () => {
    let result = await get_data(`request/get-request-for-dashboard`)
    if (result?.status == true) {
      setRequestList(result?.data)
      if (result?.data) {
        setOpen(false)
      }
    }
  }

  const getReturnList = async () => {
    let result = await get_data(`return/get-return-for-dashboard`)
    if (result?.status == true) {
      setReturnList(result?.data)
      if (result?.data) {
        setOpen(false)
      }
    }
  }

  const getClaimsList = async () => {
    let result = await get_data(`claim/get-claim-for-dashboard`)
    if (result?.status == true) {
      setClaimsList(result?.data)
      if (result?.data) {
        setOpen(false)
      }
    }
  }

  const getEcommerceSale = async () => {
    let result = await get_data(`order/get-total-ecommerce-sales`)
    if (result?.status == true) {
      setEcommerceSale(result?.data)
      if (result?.data) {
        setOpen(false)
      }
    }
  }

  const getRentalSale = async () => {
    let result = await get_data(`invoice/get-total-rental-sales`)
    if (result?.status == true) {
      setRentalSale(result?.data)
      if (result?.data) {
        setOpen(false)
      }
    }
  }

  useEffect(function () {

    getClaimsList()
    getRequestList()
    getReturnList()
    getUninstalledList()
    getEcommerceSale();
    getRentalSale();

  }, [])

  const showList = (data, title) => {
    return data?.map((item) => {
      return <li style={styles.transactionItem}>
        <span>{item?.clientId?.companyName || "-"}</span>
        {title == 'request' ? <span style={styles.completed}>{item?.gps?.length || "-"}</span> : <span style={item?.status == 'pending' ? styles.pending : styles.completed}>{item?.status || "-"}</span>}
      </li>
    })
  }


  ////////////////////////////// DATE PICHER //////////////////////////////
  const rangePickerStyle = {
    display: 'flex',
    cursor: 'pointer',
    fontSize: 14,
    background: 'white',
    padding: '4% 0% 4% 0%',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    color: '#7E8299',
    cursor: 'pointer'
  };

  const handleDateChange = (dates) => {
    if (dates && dates?.length === 2) {
      setDateRange(dates);
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      fetchExportData();
      setOpen(true)
    }
  }, [startDate, endDate]);

  const fetchExportData = async () => {
    try {
      const fromDate = moment(startDate).toISOString();
      const toDate = moment(endDate).toISOString();
      let response = await post_data('order/get-admin-dashboard-statics-by-date', {
        from: fromDate,
        to: toDate
      })


      if (response?.status) {
        setEcommerceSale(response?.data?.totalEcommerceSales)
        setRentalSale(response?.data?.totalRentalSale)
        setClaimsList(response?.data?.claims)
        setRequestList(response?.data?.gpsRequests)
        setReturnList(response?.data?.returns)
        setUninstalledList(response?.data?.uninstall)
        setOpen(false)
        setFromDate(response?.data?.fromDate)
        setToDate(response?.data?.toDate)

      } else {
      }
    } catch (error) {
    }
  };



  return (
    <div style={styles.dashboardContainer}>
      {/* Header Section */}
      <div style={styles.header}>
        <div>
          <h2>Dashboard</h2>
        </div>
        <span style={styles.datePicker}>
          <div style={{ ...rangePickerStyle, border: 'none', }}>
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              dateFormat="dd-MM-yyyy"
              maxDate={new Date()}
              customInput={
                <button style={{ border: 'none', background: '#fff', cursor: 'pointer', }}>
                  {startDate && endDate ? `${moment(startDate).format('DD-MM-YYYY')} to ${moment(endDate).format('DD-MM-YYYY')}` : 'Select Date Range'}
                </button>
              }
            />
          </div>
          <Backdrop
            sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </span>
      </div>

      {/* Cards Section */}
      <div style={styles.cardsContainer}>
        {/* Update Card */}
        <div style={styles.updateCard}>
          <p>Today is {formatDate(new Date())}</p>
          <h3>Check the Statistics of Requests, Returns, Claims & UnInstalls</h3>
          {/* <button style={styles.button}>See Statistics</button> */}
        </div>

        {/* Net Income */}
        <div style={styles.statCard}>
          <h4>E-commerce Sales</h4>
          <h3 style={styles.income}>{formatPrice(ecommerceSale)}</h3>
          {/* <p style={styles.positiveStat}>+35% from last month</p> */}
        </div>

        {/* Total Return */}
        <div style={styles.statCard}>
          <h4>Rental Sales</h4>
          <h3 style={styles.income}>{formatPrice(rentalSale)}</h3>
          {/* <p style={styles.negativeStat}>-24% from last month</p> */}
        </div>
      </div>

      {/* Transaction List */}
      <div style={styles.transactionContainer}>
        <h4>GPS Request</h4>
        <ul style={styles.transactionList}>
          {showList(requestList, 'request')}
        </ul>

      </div>

      {/* Revenue Section */}
      <div style={styles.transactionContainer}>
        <h4>GPS UnInstall</h4>
        <ul style={styles.transactionList}>
          {showList(uninstalledList, 'uninstall')}
        </ul>
      </div>


      <div style={styles.transactionContainer}>
        <h4>GPS Return</h4>
        <ul style={styles.transactionList}>
          {showList(returnList, 'return')}
        </ul>
      </div>

      <div style={styles.transactionContainer}>
        <h4>GPS Claims</h4>
        <ul style={styles.transactionList}>
          {showList(claimsList, 'claim')}
        </ul>
      </div>

    </div>
  );
};

// Inline styles in JSON format
const styles = {
  dashboardContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '20px',
    padding: '0 2%',
    fontFamily: 'Arial, sans-serif',
    minHeight: '100vh',
  },
  header: {
    gridColumn: 'span 3',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  datePicker: {
    fontSize: '14px',
    color: '#666',
    display: 'flex',
    gap: 10
  },
  income: {
    fontSize: 35,
    margin: 0,
    fontWeight: 600
  },
  cardsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '20px',
    gridColumn: 'span 3',
  },
  updateCard: {
    backgroundColor: '#1E6D8A',
    color: '#fff',
    padding: '20px',
    borderRadius: '10px',
  },
  statCard: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
  },
  button: {
    backgroundColor: '#fff',
    color: '#004400',
    padding: '10px',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
  },
  positiveStat: {
    color: '#4caf50',
    fontSize: '12px',
  },
  negativeStat: {
    color: '#f44336',
    fontSize: '12px',
  },
  transactionContainer: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    gridColumn: 'span 1',
  },
  transactionList: {
    listStyleType: 'none',
    padding: 0,
  },
  transactionItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0',
    borderBottom: '1px solid #ddd',
  },
  completed: {
    color: '#4caf50',
  },
  pending: {
    color: '#ff9800',
  },
  revenueContainer: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    gridColumn: 'span 1',
  },
  salesReportContainer: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    gridColumn: 'span 1',
  },
  chart: {
    backgroundColor: '#ddd',
    height: '200px',
    borderRadius: '10px',
  },
};

export default AdminDashComp;
