import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { HiMiniWrenchScrewdriver } from "react-icons/hi2";
import { MdPerson, MdPeople } from "react-icons/md";
import { useSelector } from "react-redux";
import { primaryColor } from "../../../constant";
import { AiOutlineProduct } from "react-icons/ai";
import VendorSidebar from "../../../components/VendorSidebar";
import VendorDashComp from "../../../components/VendorDashComp";
import VendorSettings from "../../../components/VendorSettings"
import VendorClients from "./VendorClients";
import VendorVehicle from "./VendorVehicle";
import { FaCar } from "react-icons/fa";
import CreateVendorVehicles from "../../../components/CreateVendorVehicles";
import VendorVehiclesDetails from "../../../components/VendorVehiclesDeatils";
import VendorAllVehicles from "./VendorAllVehicles";
import CreateAllVendorVehicles from "../../../components/CreateAllVendorVehicles";
import { ListItemButton, Collapse, List } from "@mui/material";
import { TbBrandAirtable } from "react-icons/tb";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Models from "../../admin/pages/Models";
import Brands from "../../admin/pages/Brands";


export default function VendorDashboard() {
    const { user_data } = useSelector(state => state.user);
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0);
    const navigate = useNavigate();
    let current_path = window.location.pathname;

    const handleClick = (index) => {
        setOpen(!open);
        setIndex(index);
    }

    const handleClickSubMenu = (subtitle, url) => {
        const link = `/vendor/dashboard/${url}/${subtitle.toLowerCase().replace(/\s+/g, '')}`;
        navigate(link);
        window.scrollTo(0, 0);
    };

    const listItemButton = (subtitle, url, icon) => {
        const link = `/vendor/dashboard/${url}/${subtitle.toLowerCase().replace(/\s+/g, '')}`;
        return (
            <ListItemButton
                onClick={() => handleClickSubMenu(subtitle, url)}
                style={{
                    padding: '5% 6%',
                    borderRadius: 8,
                    margin: '1% 0',
                    background: window.location.pathname === link ? '#1e6d8a19' : 'transparent'
                }}
            >
                <div style={{
                    display: "flex",
                    gap: '8%',
                    width: '100%',
                    color: window.location.pathname === link ? primaryColor : '#757575',
                }}>
                    <p style={{ color: window.location.pathname === link ? primaryColor : '#757575', fontWeight: window.location.pathname === link ? 500 : 400, fontSize: 14, margin: 0, padding: 0 }}>
                        {subtitle}
                    </p>
                </div>
            </ListItemButton >
        )
    }



    const dashboard_items = [
        {
            title: 'Company',
            link: '/vendor/dashboard/clients',
            icon: <AiOutlineProduct style={{ color: location.pathname === '/vendor/dashboard/clients' ? primaryColor : '#757575' }} />,
        },
        {
            title: 'Vehicle GPS Request',
            link: '/vendor/dashboard/vehiclegpsrequest',
            icon: <FaCar style={{ color: location.pathname === '/vendor/dashboard/vehiclegpsrequest' ? primaryColor : '#757575' }} />,
        },
        {
            title: 'All Vehicles',
            link: '/vendor/dashboard/allVehicles',
            icon: <FaCar style={{ color: location.pathname === '/vendor/dashboard/allVehicles' ? primaryColor : '#757575' }} />,
        },
        {
            title: 'Vehicle Brands',
            link: 1,
            icon: <TbBrandAirtable style={{ color: window.location.pathname === '/vendor/dashboard/vehicle-brand' ? primaryColor : '#757575' }} />,
            subMenu: () => (
                open && index === 1 ? (
                    <KeyboardArrowDownIcon
                        onClick={() => handleClick(1)}
                        style={{ color: 'black' }}
                    />
                ) : (
                    <KeyboardArrowRightIcon
                        onClick={() => handleClick(1)}
                        style={{ color: 'black' }}
                    />
                )
            ),
            collapse: () => <Collapse in={open && index === 1} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
                    {listItemButton("Brands", 'vehicle-brand', <MdPeople style={{ color: window.location.pathname === '/vendor/dashboard/vehicle-brand/brands' ? primaryColor : '#757575' }} />)}
                    {listItemButton("Models", 'vehicle-brand', <MdPerson style={{ color: window.location.pathname === '/vendor/dashboard/vehicle-brand/models' ? primaryColor : '#757575' }} />)}
                </List>
            </Collapse >
        },
        {
            title: 'Settings',
            link: '/vendor/dashboard/settings',
            icon: <HiMiniWrenchScrewdriver style={{ color: location.pathname === '/vendor/dashboard/settings' ? primaryColor : '#757575' }} />,
        },
    ];

    return (
        <div className="layout-container">
            <div className="sidebar-container">
                <VendorSidebar dashboard_items={dashboard_items} setIndex={setIndex} setOpen={setOpen} open={open} />
            </div>
            <div className="content-container" style={{ paddingTop: '1%' }}>
                <Routes>
                    <Route element={<VendorDashComp />} path="/" />
                    <Route element={<VendorSettings />} path="/Settings" />
                    <Route element={<VendorClients />} path="/clients" />
                    {/* <Route element={<Vendors />} path="/vendors" /> */}
                    <Route element={<VendorVehicle />} path="/vehiclegpsrequest" />
                    <Route element={<CreateVendorVehicles />} path="/vehicles/create" />
                    <Route element={<CreateAllVendorVehicles />} path="/all/vehicles/create" />
                    <Route element={<VendorVehiclesDetails />} path="/vehicles/details" />
                    <Route element={<VendorAllVehicles />} path="/allVehicles" />
                    <Route element={<Models />} path="/vehicle-brand/models" />
                    <Route element={<Brands />} path="/vehicle-brand/brands" />
                    {/* <Route element={<SubVendors />} path="/subvendor" /> */}
                </Routes>
            </div>
        </div>
    );
}
