export default function ScreenLoading() {

    return (
        <>
            <div style={{ background: 'transparent', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <div className='loading'></div>
                <div className='loading'></div>
                <div className='loading'></div>
            </div>
        </>
    )
}