import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ClaimForm from "../../components/ClaimForm";


export default function Claim() {

    return (
        <>
            <Header />
            <ClaimForm />
            <Footer />
        </>
    )
}