import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ProductsGrid from "../../components/ProductsGrid";
import ProductsGrid2 from "../../components/ProductsGrid2";
import Features from "../../components/Features";
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";
import HeroSection2 from "../../components/HeroSection2";

export default function Home() {
    const theme = useTheme();
    const matches3 = useMediaQuery(theme.breakpoints.down(500));
    return (
        <>

            <Header />
            {/* <HeroSection /> */}
            <HeroSection2 />
            <div style={{ display: matches3 ? 'flex' : "", justifyContent: matches3 ? 'center' : '' }}>
                <ProductsGrid />
            </div>
            <div style={{ display: matches3 ? 'flex' : "", justifyContent: matches3 ? 'center' : '' }}>
                <ProductsGrid2 />
            </div>
            <Features />
            <Footer />
        </>
    )
}