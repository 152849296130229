import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Avatar,
    Button,
    Typography,
    Menu,
    MenuItem,
    IconButton,
    Pagination,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClaimDetailModel from '../ClaimDetailModel';
import { formatDate } from '../../constant';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
        '& .MuiCheckbox-root': {
            color: '#a3a3a3',
        },
    },
    stageButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        '&.closedOwn': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.pending': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.qualification': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.closedLost': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
    },
    avatarGroup: {
        display: 'flex',
        alignItems: 'center',
    },
    avatarName: {
        marginLeft: '8px',
        fontSize: 14
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});

const ClientsInventoryTable = ({
    data,
    tableHeader, getClaimData,
    setLoading, totalData, loading, currentPage, setCurrentPage, itemsPerPage, totalPages
}) => {

    const classes = useStyles();
    const [detailModelOpen, setDetailModelOpen] = useState(false)
    const [detailModelData, setDetailModelData] = useState({})
    const [rowsData, setRowsData] = useState(data || []);

    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);

    const handleCheckboxToggle = (index) => {
        const updatedRows = [...rowsData];
        updatedRows[index].checked = !updatedRows[index].checked;
        setRowsData(updatedRows);
    };

    const handleMenuOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(index);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentRow(null);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);

    return (
        <TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
            <Table className={classes.root}>
                <TableHead>
                    <TableRow>
                        {
                            tableHeader?.map((item) => {
                                return (
                                    <TableCell style={{ borderRight: '1px solid gainsboro', padding: '16px 10px' }}>{item}</TableCell>
                                )
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rowsData?.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell style={{ borderRight: '1px solid gainsboro', textAlign: 'center', width: '5%' }}>{startEntry + index}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.gpsDetail?.vehicleNumber}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.gpsDetail?.simNumber}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.gpsDetail?.imei}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.gpsDetail?.deviceUniqueId}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro' }}>{formatDate(row?.gpsDetail?.dateOfIntall)}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.gpsDetail?.gpsStatus}</TableCell>
                            {/* <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                <IconButton
                                    className={classes.moreIcon}
                                    onClick={(event) => handleMenuOpen(event, index)}
                                >
                                    <MoreVertIcon />
                                </IconButton>

                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl) && currentRow === index}
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem onClick={() => {
                                        handleMenuClose()
                                        setDetailModelOpen(true)
                                        setDetailModelData(row)
                                    }}>View Details</MenuItem>
                                </Menu>
                            </TableCell> */}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {rowsData?.length >= 0 && (
                <div
                    style={{
                        borderTop: "1px solid gainsboro",
                        padding: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: 500,
                            fontSize: 15,
                            color: "black",
                        }}
                    >{`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}</Typography>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}
        </TableContainer>
    );
};

export default ClientsInventoryTable;
