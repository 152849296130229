import React, { useState } from "react";
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { MdDashboard, MdPeople, MdSettings } from "react-icons/md";
import { TbCreditCardRefund, TbFileInvoice } from "react-icons/tb";
import { MdPerson } from "react-icons/md";
import { RxActivityLog } from "react-icons/rx";
import { HiCalendar } from "react-icons/hi";
import { HiMiniWrenchScrewdriver } from "react-icons/hi2";
import { useSelector } from "react-redux";
import { BiSolidUserPin } from "react-icons/bi";
import { TbBrandAirtable } from "react-icons/tb";
import { MdOutlineInventory2 } from "react-icons/md";
import { TbBoxModel } from "react-icons/tb";
import AdminSidebar from "../../../components/AdminSidebar";
import AdminDashComp from "../../../components/AdminDashComp";
import AdminVendors from "./AdminVendors";
import { primaryColor } from "../../../constant"; // Ensure correct import of primaryColor
import { AiOutlineProduct } from "react-icons/ai";
import { CiGps } from "react-icons/ci";
import Products from "./Products";
import CreateProduct from "../../../components/CreateProduct";
import Activities from "./Activities";
import { MdOutlineSell } from "react-icons/md";
import Settings from './Settings';
import Requests from "./Requests";
import Invoices from "./Invoices";
import Uninstalls from "./Uninstalls";
import Claims from "./Claims";
import Returns from "./Returns";
import RequestsDetailsTable from "../../../components/RequestDetailsTable";
import Client from "./Client";
import CreateClients from "../../../components/CreateClients";
import Brands from "./Brands";
import Models from "./Models";
import Orders from "./Orders";
import AdminSubVendors from "./AdminSubVendors";
import Sales from "./Sales";
import ClientDetail from "./ClientDetail";
import Inventorys from "./Inventorys";
import CreateInventory from "../../../components/CreateInventory";
import DefaultNetwork from "../../../components/tables/DefaultNetwork";
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import Accessories from "./Accessories";
import CreateAccessories from "../../../components/CreateAccessories";
import GpsBrand from "./GpsBrand";
import Buyers from "./Buyers";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Collapse, List, ListItemButton } from "@mui/material";
// import { usePathname, useRouter } from "next/navigation";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import Reason from "./Reason";
import AdminInventory from "./AdminInventory";
import Installer from "../../../components/Installer";
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import Fuel from "./Fuel";
import GpsModal from "./GpsModal";
import PolicyIcon from '@mui/icons-material/Policy';
import { MdOutlinePolicy } from "react-icons/md";
import GavelIcon from '@mui/icons-material/Gavel';
import { PiKeyReturnFill } from "react-icons/pi";
import { VscFeedback } from "react-icons/vsc";
import PrivacyPolicyForm from "../../../components/PrivacyPolicyForm";
import TermsAndConditionsForm from "../../../components/TermsAndConditionsForm";
import ReturnCancellationPolicyForm from "../../../components/Return&CancellationPolicyForm";
import PartsPolicyForm from "../../../components/PartsPolicyForm";
import FeedbackPolicyForm from "../../../components/FeedbackPolicyForm";
import ShippingPolicyForm from "../../../components/ShippingPolicyForm";
import AMCPolicyForm from "../../../components/AMCPolicyForm";
import NetworkPolicyForm from "../../../components/NetworkPolicyForm";
import Record from "../../../components/tables/Record";
import Sites from "../../../components/Sites";
import InsurancePolicy from "../../../components/InsurancePolicy";
import AdminUser from "./AdminUser";
import AdminSite from "./Site";
import { LuFuel } from "react-icons/lu";

export default function AdminDashboard() {

  const { user_data } = useSelector(state => state.user);
  const location = useLocation(); // Hook to get the current route
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [categories, setCategories] = useState([]);
  // const router = useRouter();
  // const pathname = usePathname();
  // const [screenLoading, setScreenLoading] = useState(false)
  // const [currentPath, setCurrentPath] = useState(pathname);
  const navigate = useNavigate();
  let current_path = window.location.pathname;

  const handleClick = (index) => {
    setOpen(!open);
    setIndex(index);
  }

  const handleClickSubMenu = (subtitle, url) => {
    const link = `/admin/dashboard/${url}/${subtitle.toLowerCase().replace(/\s+/g, '')}`;
    navigate(link);
    window.scrollTo(0, 0);
  };

  const listItemButton = (subtitle, url, icon) => {
    const link = `/admin/dashboard/${url}/${subtitle.toLowerCase().replace(/\s+/g, '')}`;
    return (
      <ListItemButton
        onClick={() => handleClickSubMenu(subtitle, url)}
        style={{
          padding: '5% 6%',
          borderRadius: 8,
          margin: '1% 0',
          background: current_path === link ? '#1e6d8a19' : 'transparent'
        }}
      >
        <div style={{
          display: "flex",
          gap: '8%',
          width: '100%',
          color: current_path === link ? primaryColor : '#757575',
        }}>
          <p style={{ color: current_path === link ? primaryColor : '#757575', fontWeight: current_path === link ? 500 : 400, fontSize: 14, margin: 0, padding: 0 }}>
            {subtitle}
          </p>
        </div>
      </ListItemButton >
    )
  }

  const dashboard_items = [
    {
      title: 'Dashboard',
      link: '/admin/dashboard',
      icon: <MdDashboard style={{ color: location.pathname === '/admin/dashboard' ? primaryColor : '#757575' }} />,
    },
    {
      title: 'Sales',
      link: '/admin/dashboard/sales',
      icon: <TbFileInvoice style={{ color: location.pathname === '/admin/dashboard/sales' ? primaryColor : '#757575' }} />,
    },
    {
      title: 'Ecommerce ',
      link: 1,
      icon: <AssistantPhotoIcon style={{ color: location.pathname === '/admin/dashboard/sales' ? primaryColor : '#757575' }} />,
      subMenu: () => (
        open && index === 1 ? (
          <KeyboardArrowDownIcon
            onClick={() => handleClick(1)}
            style={{ color: 'black' }}
          />
        ) : (
          <KeyboardArrowRightIcon
            onClick={() => handleClick(1)}
            style={{ color: 'black' }}
          />
        )
      ),
      collapse: () => <Collapse in={open && index === 1} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
          {listItemButton("Products", 'sales', <AiOutlineProduct style={{ color: location.pathname === '/admin/dashboard/sales/products' ? primaryColor : '#757575' }} />)}
          {listItemButton("Accessories", 'sales', <AiOutlineProduct style={{ color: location.pathname === '/admin/dashboard/sales/accessories' ? primaryColor : '#757575' }} />)}
          {listItemButton("Orders", 'sales', <TbFileInvoice style={{ color: location.pathname === '/admin/dashboard/sales/orders' ? primaryColor : '#757575' }} />)}
          {listItemButton("Users", 'sales', <MdOutlineInventory2 style={{ color: location.pathname === '/admin/dashboard/sales/users' ? primaryColor : '#757575' }} />)}
          {/* {listItemButton("Sales", 'sales', <TbFileInvoice style={{ color: location.pathname === '/admin/dashboard/sales/salse' ? primaryColor : '#757575' }} />)} */}

        </List>
      </Collapse >
    },
    {
      title: 'Rental',
      link: 2,
      icon: <SupervisedUserCircleIcon style={{ color: location.pathname === '/admin/dashboard/sales' ? primaryColor : '#757575' }} />,
      subMenu: () => (
        open && index === 2 ? (
          <KeyboardArrowDownIcon
            onClick={() => handleClick(2)}
            style={{ color: 'black' }}
          />
        ) : (
          <KeyboardArrowRightIcon
            onClick={() => handleClick(2)}
            style={{ color: 'black' }}
          />
        )
      ),
      collapse: () => <Collapse in={open && index === 2}>
        <List component="div" disablePadding sx={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
          {listItemButton("Company", 'rental', <MdPeople style={{ color: location.pathname === '/admin/dashboard/rental/clients' ? primaryColor : '#757575' }} />)}
          {listItemButton("Vendors", 'rental', <MdPerson style={{ color: location.pathname === '/admin/dashboard/rental/vendors' ? primaryColor : '#757575' }} />)}
          {listItemButton("Requests", 'rental', <HiCalendar style={{ color: location.pathname === '/admin/dashboard/rental/requests' ? primaryColor : '#757575' }} />)}
          {listItemButton("Uninstall", 'rental', <HiCalendar style={{ color: location.pathname === '/admin/dashboard/rental/uninstall' ? primaryColor : '#757575' }} />)}
          {listItemButton("Claims", 'rental', <BiSolidUserPin style={{ color: location.pathname === '/admin/dashboard/rental/claims' ? primaryColor : '#757575' }} />)}
          {listItemButton("Returns", 'rental', <TbCreditCardRefund style={{ color: location.pathname === '/admin/dashboard/rental/returns' ? primaryColor : '#757575' }} />)}
          {listItemButton("Inventory", 'rental', <TbCreditCardRefund style={{ color: location.pathname === '/admin/dashboard/rental/admininventory' ? primaryColor : '#757575' }} />)}
          {listItemButton("Sites", 'rental', <TbCreditCardRefund style={{ color: location.pathname === '/admin/dashboard/rental/sites' ? primaryColor : '#757575' }} />)}
          {listItemButton("Reason", 'rental', <MdOutlineInventory2 style={{ color: location.pathname === '/admin/dashboard/rental/reason' ? primaryColor : '#757575' }} />)}
          {listItemButton("Records", 'rental', <MdOutlineInventory2 style={{ color: location.pathname === '/admin/dashboard/rental/records' ? primaryColor : '#757575' }} />)}
          {/* {listItemButton("Users", 'rental', <MdOutlineInventory2 style={{ color: location.pathname === '/admin/dashboard/rental/users' ? primaryColor : '#757575' }} />)} */}
        </List>
      </Collapse >
    },
    {
      title: 'Vehicle Brands',
      link: 3,
      icon: <TbBrandAirtable style={{ color: location.pathname === '/admin/dashboard/vehicle-brand' ? primaryColor : '#757575' }} />,
      subMenu: () => (
        open && index === 3 ? (
          <KeyboardArrowDownIcon
            onClick={() => handleClick(3)}
            style={{ color: 'black' }}
          />
        ) : (
          <KeyboardArrowRightIcon
            onClick={() => handleClick(3)}
            style={{ color: 'black' }}
          />
        )
      ),
      collapse: () => <Collapse in={open && index === 3} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
          {listItemButton("Brands", 'vehicle-brand', <MdPeople style={{ color: location.pathname === '/admin/dashboard/vehicle-brand/brands' ? primaryColor : '#757575' }} />)}
          {listItemButton("Models", 'vehicle-brand', <MdPerson style={{ color: location.pathname === '/admin/dashboard/vehicle-brand/models' ? primaryColor : '#757575' }} />)}
        </List>
      </Collapse >
    },
    {
      title: 'Gps Brands',
      link: 4,
      icon: <CiGps style={{ color: location.pathname === '/admin/dashboard/gps-brand' ? primaryColor : '#757575' }} />,
      subMenu: () => (
        open && index === 4 ? (
          <KeyboardArrowDownIcon
            onClick={() => handleClick(4)}
            style={{ color: 'black' }}
          />
        ) : (
          <KeyboardArrowRightIcon
            onClick={() => handleClick(4)}
            style={{ color: 'black' }}
          />
        )
      ),
      collapse: () => <Collapse in={open && index === 4} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
          {listItemButton("Brands", 'gps-brand', <MdPeople style={{ color: location.pathname === '/admin/dashboard/gps-brand/brands' ? primaryColor : '#757575' }} />)}
          {listItemButton("Models", 'gps-brand', <MdPerson style={{ color: location.pathname === '/admin/dashboard/gps-brand/models' ? primaryColor : '#757575' }} />)}
        </List>
      </Collapse >
    },
    // {
    //   title: 'Records',
    //   link: '/admin/dashboard/records',
    //   icon: <MdPeople style={{ color: location.pathname === '/admin/dashboard/records' ? primaryColor : '#757575' }} />,
    // },
    {
      title: 'Installer',
      link: '/admin/dashboard/installer',
      icon: <MdPeople style={{ color: location.pathname === '/admin/dashboard/installer' ? primaryColor : '#757575' }} />,
    },
    {
      title: 'Fuel',
      link: '/admin/dashboard/fuel',
      icon: <LuFuel style={{ color: location.pathname === '/admin/dashboard/fuel' ? primaryColor : '#757575' }} />,
    },
    {
      title: 'Buyers',
      link: '/admin/dashboard/buyers',
      icon: <MdOutlineSell style={{ color: location.pathname === '/admin/dashboard/buyers' ? primaryColor : '#757575' }} />,
    },
    {
      title: 'Activities',
      link: '/admin/dashboard/activities',
      icon: <RxActivityLog style={{ color: location.pathname === '/admin/dashboard/activities' ? primaryColor : '#757575' }} />,
    },
    {
      title: 'Legal',
      link: 5,
      icon: <PolicyIcon style={{ color: location.pathname === '/admin/dashboard/gps-brand' ? primaryColor : '#757575' }} />,
      subMenu: () => (
        open && index === 5 ? (
          <KeyboardArrowDownIcon
            onClick={() => handleClick(5)}
            style={{ color: 'black' }}
          />
        ) : (
          <KeyboardArrowRightIcon
            onClick={() => handleClick(5)}
            style={{ color: 'black' }}
          />
        )
      ),
      collapse: () => <Collapse in={open && index === 5} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
          {listItemButton("Privacy Policy", 'legal', <MdOutlinePolicy style={{ color: location.pathname === '/admin/dashboard/legal/privacypolicy' ? primaryColor : '#757575' }} />)}
          {listItemButton("Terms and Conditions", 'legal', <GavelIcon style={{ color: location.pathname === '/admin/dashboard/legal/termsandconditions' ? primaryColor : '#757575' }} />)}
          {listItemButton("Return & Cancellation Policy", 'legal', <PiKeyReturnFill style={{ color: location.pathname === '/admin/dashboard/legal/return&cancellationpolicy' ? primaryColor : '#757575' }} />)}
          {listItemButton("Parts Policy", 'legal', <MdPerson style={{ color: location.pathname === '/admin/dashboard/legal/partspolicy' ? primaryColor : '#757575' }} />)}
          {listItemButton("Feedback Policy", 'legal', <VscFeedback style={{ color: location.pathname === '/admin/dashboard/legal/feedbackpolicy' ? primaryColor : '#757575' }} />)}
          {listItemButton("Shipping Policy", 'legal', <VscFeedback style={{ color: location.pathname === '/admin/dashboard/legal/shippingpolicy' ? primaryColor : '#757575' }} />)}
          {listItemButton("AMC Policy", 'legal', <VscFeedback style={{ color: location.pathname === '/admin/dashboard/legal/amcpolicy' ? primaryColor : '#757575' }} />)}
          {listItemButton(" Network Policy", 'legal', <VscFeedback style={{ color: location.pathname === '/admin/dashboard/legal/networkpolicy' ? primaryColor : '#757575' }} />)}
          {listItemButton(" Insurance Policy", 'legal', <MdPerson style={{ color: location.pathname === '/admin/dashboard/legal/insurancepolicy' ? primaryColor : '#757575' }} />)}
        </List>
      </Collapse >
    },
    {
      title: 'Settings',
      link: '/admin/dashboard/settings',
      icon: <HiMiniWrenchScrewdriver style={{ color: location.pathname === '/admin/dashboard/settings' ? primaryColor : '#757575' }} />,
    },
  ];

  return (
    <div className="layout-container">
      <div className="sidebar-container">
        <AdminSidebar dashboard_items={dashboard_items} setIndex={setIndex} setOpen={setOpen} open={open} />
      </div>
      <div className="content-container" style={{ paddingTop: '1%' }}>
        <Routes>
          <Route element={<AdminDashComp />} path="/" />
          <Route element={<AdminVendors />} path="rental/vendors" />
          <Route element={<Sales />} path="/sales" />
          <Route element={<AdminSubVendors />} path="/subvendors" />
          <Route element={<Products />} path="sales/products" />
          <Route element={<Invoices />} path="sales/invoices" />
          <Route element={<Inventorys />} path="/rental/inventorys" />
          <Route element={<CreateInventory />} path="/inventory/create" />
          <Route element={<Requests />} path="rental/requests" />
          <Route element={<RequestsDetailsTable />} path="/requestsdetails" />
          <Route element={<Uninstalls />} path="rental/uninstall" />
          <Route element={<Reason />} path="rental/reason" />
          <Route element={<Claims />} path="rental/claims" />
          <Route element={<Returns />} path="rental/returns" />
          <Route element={<Activities />} path="/activities" />
          <Route element={<CreateProduct />} path="/products/create" />
          <Route element={<Brands />} path="/vehicle-brand/brands" />
          {/* <Route element={<Brands />} path="/gps-brand/brands" /> */}
          <Route element={<Buyers />} path="/buyers" />
          <Route element={<GpsBrand />} path="/gps-brand/brands" />
          <Route element={<Models />} path="/vehicle-brand/models" />
          <Route element={<GpsModal />} path="/gps-brand/models" />
          <Route element={<Orders />} path="sales/orders" />
          <Route element={<Settings />} path="/settings" />
          <Route element={<Client />} path="rental/company" />
          <Route element={<AdminInventory />} path="rental/inventory" />
          <Route element={<AdminSite />} path="rental/sites" />
          <Route element={<CreateClients />} path="/clients/create" />
          <Route element={<ClientDetail />} path="/clients/details" />
          {/* <Route element={<DefaultNetwork />} path="/defaultnetwork" /> */}
          <Route element={<Accessories />} path="sales/accessories" />
          <Route element={<CreateAccessories />} path="/accessories/create" />
          <Route element={<Installer />} path="installer" />
          <Route element={<Fuel />} path="fuel" />
          <Route element={<PrivacyPolicyForm />} path="/legal/privacypolicy" />
          <Route element={<TermsAndConditionsForm />} path="/legal/termsandconditions" />
          <Route element={<ReturnCancellationPolicyForm />} path="/legal/return&cancellationpolicy" />
          <Route element={<PartsPolicyForm />} path="/legal/partspolicy" />
          <Route element={<FeedbackPolicyForm />} path="/legal/feedbackpolicy" />
          <Route element={<ShippingPolicyForm />} path="/legal/shippingpolicy" />
          <Route element={<AMCPolicyForm />} path="/legal/amcpolicy" />
          <Route element={<NetworkPolicyForm />} path="/legal/networkpolicy" />
          <Route element={<InsurancePolicy />} path="/legal/insurancepolicy" />
          <Route element={<Record />} path="rental/records" />
          <Route element={<AdminUser />} path="sales/users" />
        </Routes>
      </div>
    </div>
  );
}
