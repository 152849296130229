import { Grid, useMediaQuery, InputLabel, MenuItem, FormControl, Select, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { primaryColor } from '../constant';
import { CiSearch } from "react-icons/ci";

const HeroSection2 = () => {
    const isDesktop = useMediaQuery('(min-width: 768px)');

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '3% 6%',
            backgroundSize: 'cover',
            backgroundColor: '#EFF5F7',
            backgroundPosition: 'center',
            backgroundAttachment: 'fixed',
            position: 'relative',
            color: '#fff',
            textAlign: 'center',
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
        },
        content: {
            zIndex: 2,
            padding: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
        },
        title: { fontSize: 18, fontWeight: 400 },
        heading: {
            fontSize: isDesktop ? 50 : 32,
            fontWeight: 600,
            marginBottom: 20,
            lineHeight: 1.1,
        },
        createButton: {
            borderRadius: 5,
            // background: '#000',
            color: primaryColor,
            padding: '15px 20px',
            fontWeight: 600,
            textTransform: 'none',
            boxShadow: 'none',
        },
    };

    const headings = ["GPS Installation", "Easy Claim & Return", "Powerful Connections"];
    const [currentHeading, setCurrentHeading] = useState(0);
    const [displayText, setDisplayText] = useState("");
    const [charIndex, setCharIndex] = useState(0);
    const [formState, setFormState] = useState({ product: '', amount: '', brand: '' });

    useEffect(() => {
        if (charIndex < headings[currentHeading].length) {
            const interval = setInterval(() => {
                setDisplayText(prev => prev + headings[currentHeading][charIndex]);
                setCharIndex(prev => prev + 1);
            }, 100);
            return () => clearInterval(interval);
        } else {
            setTimeout(() => {
                setCurrentHeading((prev) => (prev + 1) % headings.length);
                setDisplayText("");
                setCharIndex(0);
            }, 2000);
        }
    }, [charIndex, currentHeading, headings]);

    const handleSelectChange = (event) => {
        setFormState(prevState => ({ ...prevState, [event.target.name]: event.target.value }));
    };

    return (
        <div style={styles.container}>
            <div style={styles.overlay} />
            <div style={styles.content}>
                <h2 style={styles.heading}>
                    <span style={{ color: "#1e6e8a" }}>{displayText}</span> <br />
                    Best GPS Solution for Your Vehicles
                </h2>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '25px', }}>
                    <Grid container spacing={2} width={'80%'} style={{ marginTop: '25px' }}>
                        <Grid item xs={6} md={3}>
                            <FormControl fullWidth>
                                <InputLabel>Product</InputLabel>
                                <Select
                                    style={{ backgroundColor: 'white' }}
                                    name="product"
                                    value={formState.product}
                                    onChange={handleSelectChange}
                                    label="Product"
                                >
                                    <MenuItem value="Product A">Product A</MenuItem>
                                    <MenuItem value="Product B">Product B</MenuItem>
                                    <MenuItem value="Product C">Product C</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <FormControl fullWidth>
                                <InputLabel>Amount</InputLabel>
                                <Select
                                    style={{ backgroundColor: 'white' }}
                                    name="amount"
                                    value={formState.amount}
                                    onChange={handleSelectChange}
                                    label="Amount"
                                >
                                    <MenuItem value="10">₹1,000 - ₹3,000</MenuItem>
                                    <MenuItem value="20">₹4,000 - ₹7,000</MenuItem>
                                    <MenuItem value="30">₹8,000 - ₹10,000</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <FormControl fullWidth>
                                <InputLabel>GPS Brand</InputLabel>
                                <Select
                                    style={{ backgroundColor: 'white' }}
                                    name="brand"
                                    value={formState.brand}
                                    onChange={handleSelectChange}
                                    label="GPS Brand"
                                >
                                    <MenuItem value="Brand X">Concox</MenuItem>
                                    <MenuItem value="Brand Y">Oredo</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Button
                                fullWidth
                                startIcon={<CiSearch />}
                                variant="outlined"
                                style={styles.createButton}
                            >
                                Search New
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
};

export default HeroSection2;
