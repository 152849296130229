import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ReturnForm from "../../components/ReturnForm"

export default function Return() {

    return (
        <>
            <Header />
            <ReturnForm />
            <Footer />
        </>
    )
}