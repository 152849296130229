import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Autocomplete, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { useState, useEffect } from "react"
import { createButtonBg, primaryColor } from '../../constant';
import { get_data, post_data } from '../../api'
import { useDispatch, useSelector } from 'react-redux';
import { City, State } from 'country-state-city';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import toast, { Toaster } from 'react-hot-toast';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
    borderRadius: '7px',
    overflow: 'auto',
};

export default function AdminChangPasswordModals({ isUpdate, formData, passwordChangOpen, setPasswordChangOpen, title }) {
    const [errors, setErrors] = useState({});
    const [password, setPassword] = useState("");
    const [open, setOpen] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const styles = {
        rightSide: {
            backgroundColor: '#fff',
            width: '100%'
        },
        input: {
            marginBottom: '10px',
        },

    };


    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const validation = () => {
        let error = false;
        if (!password) {
            error = true;
            handleError('Please input Confirm Password...', 'password');
        }

        if (!confirmPassword) {
            error = true;
            handleError('Please input Confirm Password...', 'confirmPassword');
        }

        if (password !== confirmPassword) {
            error = true
            handleError('Password does not match...', 'confirmPassword')
        }

        return error;
    };

    const handleClose = () => {
        title === 'vendor' ? setPasswordChangOpen(false) : setOpen(false)
    };

    console.log('eroor', errors);

    const handleSubmit = async () => {
        const error = validation();
        if (!error && password === confirmPassword) {
            setLoading(true)
            let data
            if (title === 'vendor') {
                data = await post_data(`vendor/update-vendor-password-by-admin/${localStorage.getItem('vendorId')}`, { password })
            }
            else if (title === 'subCompany') {
                data = await post_data(`sub-client/update-sub-client-password/${localStorage.getItem('subClientId')}`, { password })
            }
            else {
                data = await post_data(`client/change-client-password/${localStorage.getItem('clientId')}`, { password })
            }

            if (data?.status === true) {
                toast.success(data?.message)
                title === 'vendor' ? setPasswordChangOpen(false) : setOpen(false)
                setConfirmPassword('')
                setLoading(false)
                setPassword('')
            } else {
                toast.error('Somthing Worng !')
                setLoading(false)
            }

        }
    };



    return (
        <div >
            {title === 'vendor' ? <></> : <Typography id="modal-title" variant="h6" onClick={() => setOpen(true)} style={{ fontSize: '14px', color: primaryColor, cursor: 'pointer', marginBottom: '10px' }}>
                Change Password
            </Typography>}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={title === 'vendor' ? passwordChangOpen : open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >

                <Fade in={title === 'vendor' ? passwordChangOpen : open} >
                    <Box sx={style}>
                        <Typography id="modal-title" variant="h6" component="h2" style={{ fontSize: '14px', color: primaryColor, cursor: 'pointer', }}>
                            Change Password
                        </Typography>
                        <Grid container spacing={2} >

                            <Grid item xs={12}>
                                <TextField
                                    label="Password"
                                    variant="outlined"
                                    type={showPassword ? "text" : "password"}
                                    fullWidth
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        setPasswordError("");
                                    }}
                                    error={errors?.password}
                                    helperText={errors?.password}
                                    onFocus={() => handleError('', 'password')}
                                    style={{ ...styles.input, marginTop: '3%' }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Confirm Password"
                                    variant="outlined"
                                    type={showConfirmPassword ? "text" : "password"}
                                    fullWidth
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value);
                                        setConfirmPasswordError("");
                                    }}
                                    error={errors?.confirmPassword}
                                    helperText={errors?.confirmPassword}
                                    onFocus={() => handleError('', 'confirmPassword')}
                                    style={styles.input}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
                                <Button variant="outlined" style={{ textTransform: 'capitalize', color: primaryColor, border: `1px solid ${primaryColor}`, borderRadius: 0 }} fullWidth onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button variant="contained"
                                    disabled={loading} style={{ textTransform: 'capitalize', color: '#fff', background: primaryColor, borderRadius: 0 }} onClick={handleSubmit} fullWidth>
                                    {/* {title==='vendor'?<></>:<></>}   */}
                                    {isUpdate ? <>{loading ? 'Edit...' : 'Edit'}</> : <>{loading ? 'Update Password...' : 'Update Password'}</>}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>

            <Toaster
                position="top-right"
                reverseOrder={false}
            />
        </div>
    );
}

// import * as React from 'react';
// import Backdrop from '@mui/material/Backdrop';
// import Box from '@mui/material/Box';
// import Modal from '@mui/material/Modal';
// import Fade from '@mui/material/Fade';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import { Autocomplete, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
// import { useState, useEffect } from "react"
// import { createButtonBg, primaryColor } from '../../constant';
// import { post_data } from '../../api'
// import { useDispatch, useSelector } from 'react-redux';
// import { City, State } from 'country-state-city';
// import { Visibility, VisibilityOff } from '@mui/icons-material';

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '2px solid #fff',
//     boxShadow: 24,
//     p: 4,
//     borderRadius: '7px',
//     overflow: 'auto',
// };

// export default function AdminClientModal({ setEditeClientOpen, editeClientOpen, editeClientData, isUpdate, createClientOpen, setCreateClientOpen }) {
//     // console.log('Name:',editeBrandData?.name )
//     const { user_data } = useSelector(state => state.user);
//     const dispatch = useDispatch()
//     const [name, setName] = useState(editeClientData?.name || '');
//     const [errors, setErrors] = useState({});
//     const [street, setStreet] = useState('');
//     const [landmark, setLandmark] = useState('');
//     const [countryCode] = useState('IN'); // Assuming only India for simplicity
//     const [stateList, setStateList] = useState([]);
//     const [state, setState] = useState('');
//     const [cityList, setCityList] = useState([]);
//     const [city, setCity] = useState('');
//     const [zipCode, setZipCode] = useState('');

//     const [phone, setPhone] = useState('')
//     const [email, setEmail] = useState('')

//     const [password, setPassword] = useState("");
//     const [confirmPassword, setConfirmPassword] = useState("");
//     const [passwordError, setPasswordError] = useState("");
//     const [confirmPasswordError, setConfirmPasswordError] = useState("");
//     const [showPassword, setShowPassword] = useState(false);
//     const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//     const [resContactNo, setResContactNo] = useState('')

//     const styles = {

//         rightSide: {
//             backgroundColor: '#fff',
//             width: '100%'
//         },
//         input: {
//             marginBottom: '10px',
//         },

//     };

//     useEffect(() => {
//         setStateList(State.getStatesOfCountry(countryCode));
//     }, [countryCode]);

//     useEffect(() => {
//         if (state) {
//             const fetchedCities = City.getCitiesOfState(countryCode, state);
//             setCityList(fetchedCities);
//         }
//     }, [state, countryCode]);

// const handleError = (error, label) => {
//     setErrors((prev) => ({ ...prev, [label]: error }));
// };

//     const validation = () => {
//         let error = false;
//         if (!name) {
//             error = true;
//             handleError('Please input Brand Name...', 'name');
//         }

//         return error;
//     };

//     const handleClose = () => {
//         if (isUpdate === true) {
//             setEditeClientOpen(false)
//         } else {
//             setCreateClientOpen(false)
//         }
//     };

//     const handleSubmit = async () => {
//         const error = validation();
//         if (!error) {
//             let body = {
//                 companyName: name,
//                 contactNo: phone,
//                 email: email,
//                 password: password,

//             }
//             if (isUpdate === true) {
//                 const data = await post_data(`client/update-client/${editeClientData?._id}`, { name })
//             } else {

//                 const data = await post_data(`client/create-client`, { name })
//             }

//         }
//     };

//     const handleStateChange = (event, newValue) => {
//         if (newValue) {
//             setState(newValue.isoCode);
//             const fetchedCities = City.getCitiesOfState(countryCode, newValue.isoCode || state);
//             setCityList(fetchedCities);
//             setCity(''); // Reset city when state changes
//         }
//     };

//     const handleCityChange = (event, newValue) => {
//         if (newValue) {
//             setCity(newValue.name);
//         }
//     };

//     return (
//         <div style={{}}>

//             <Modal
//                 aria-labelledby="transition-modal-title"
//                 aria-describedby="transition-modal-description"
//                 open={isUpdate ? editeClientOpen : createClientOpen}
//                 onClose={handleClose}
//                 closeAfterTransition
//                 slots={{ backdrop: Backdrop }}
//                 slotProps={{
//                     backdrop: {
//                         timeout: 500,
//                     },
//                 }}


//             >
//                 <Fade in={isUpdate ? editeClientOpen : createClientOpen} >
//                     <Box sx={style}>
//                         <Typography id="modal-title" variant="h6" component="h2">
//                             {isUpdate ? 'Edit Client' : 'Create Client'}
//                         </Typography>
//                         <Grid container spacing={2} style={{ height: '70vh' }}>
//                             <Grid item xs={12}>
//                                 <TextField
//                                     error={Boolean(errors.name)}
//                                     helperText={errors.name}
//                                     onFocus={() => handleError('', 'name')}
//                                     value={editeClientData?.name || name}
//                                     label="Company Name"
//                                     onChange={(e) => setName(e.target.value)}
//                                     fullWidth
//                                 />
//                             </Grid>
//                             <Grid item xs={12}>
//                                 <TextField
//                                     error={Boolean(errors.phone)}
//                                     helperText={errors.phone}
//                                     onFocus={() => handleError('', 'phone')}
//                                     value={editeClientData?.contactNo || phone}
//                                     label="Contact No"
//                                     onChange={(e) => setPhone(e.target.value)}
//                                     fullWidth
//                                 />
//                             </Grid>
//                             <Grid item xs={12}>
//                                 <TextField
//                                     error={Boolean(errors.email)}
//                                     helperText={errors.email}
//                                     onFocus={() => handleError('', 'email')}
//                                     value={editeClientData?.email || email}
//                                     label="Email"
//                                     onChange={(e) => setEmail(e.target.value)}
//                                     fullWidth
//                                 />
//                             </Grid>

//                             <Grid item xs={12}>
//                                 <TextField
//                                     error={Boolean(errors.resContactNo)}
//                                     helperText={errors.resContactNo}
//                                     onFocus={() => handleError('', 'email')}
//                                     value={editeClientData?.resContactNo || resContactNo}
//                                     label="resContactNo"
//                                     onChange={(e) => setResContactNo(e.target.value)}
//                                     fullWidth
//                                 />
//                             </Grid>

//                             <Grid item xs={12}>
//                                 <Typography component="h1">Address</Typography>
//                             </Grid>
//                             <Grid item xs={12}>
//                                 <TextField
//                                     label="Street"
//                                     error={Boolean(errors.street)}
//                                     helperText={errors.street}
//                                     onFocus={() => handleError('', 'street')}
//                                     value={street || editeClientData?.street}
//                                     onChange={(e) => setStreet(e.target.value)}
//                                     fullWidth
//                                 />
//                             </Grid>
//                             <Grid item xs={12}>
//                                 <TextField
//                                     label="Landmark"
//                                     // error={Boolean(errors.landmark)}
//                                     // helperText={errors.landmark}
//                                     // onFocus={() => handleError('', 'landmark')}
//                                     value={landmark || editeClientData?.landmark}
//                                     onChange={(e) => setLandmark(e.target.value)}
//                                     fullWidth
//                                 />
//                             </Grid>
//                             <Grid item xs={12} sm={6}>
//                                 <Autocomplete
//                                     id="state-select"
//                                     options={stateList}
//                                     value={stateList.find((s) => s.isoCode === state) || null}
//                                     onChange={handleStateChange}
//                                     getOptionLabel={(option) => option.name}
//                                     renderInput={(params) => <TextField
//                                         error={Boolean(errors.state)}
//                                         helperText={errors.state}
//                                         onFocus={() => handleError('', 'state')}
//                                         {...params} label="Select State" fullWidth />}
//                                 />
//                             </Grid>
//                             <Grid item xs={12} sm={6}>
//                                 <Autocomplete
//                                     id="city-select"
//                                     options={cityList}
//                                     value={cityList.find((c) => c.name === city) || null}
//                                     onChange={handleCityChange}
//                                     getOptionLabel={(option) => option.name}
//                                     renderInput={(params) => <TextField {...params}
//                                         error={Boolean(errors.city)}
//                                         helperText={errors.city}
//                                         onFocus={() => handleError('', 'city')}
//                                         label="Select City" fullWidth />}
//                                 />
//                             </Grid>
//                             <Grid item xs={12}>
//                                 <TextField
//                                     label="Zip Code"
//                                      
//                                     value={zipCode || editeClientData?.zipCode}
//                                     error={Boolean(errors.zipCode)}
//                                     helperText={errors.zipCode}
//                                     onFocus={() => handleError('', 'zipCode')}
//                                     onChange={(e) => setZipCode(e.target.value)}
//                                     fullWidth
//                                 />
//                             </Grid>

//                             <Grid item xs={12}>
//                                 <TextField
//                                     label="Password"
//                                     variant="outlined"
//                                     type={showPassword ? "text" : "password"}
//                                     fullWidth
//                                     value={password}
//                                     onChange={(e) => {
//                                         setPassword(e.target.value);
//                                         setPasswordError("");
//                                     }}
//                                     error={!!passwordError}
//                                     helperText={passwordError}
//                                     style={{ ...styles.input, marginTop: '3%' }}
//                                     InputProps={{
//                                         endAdornment: (
//                                             <InputAdornment position="end">
//                                                 <IconButton
//                                                     onClick={() => setShowPassword(!showPassword)}
//                                                     edge="end"
//                                                 >
//                                                     {showPassword ? <VisibilityOff /> : <Visibility />}
//                                                 </IconButton>
//                                             </InputAdornment>
//                                         ),
//                                     }}
//                                 />
//                             </Grid>
//                             <Grid item xs={12}>
//                                 <TextField
//                                     label="Confirm Password"
//                                     variant="outlined"
//                                     type={showConfirmPassword ? "text" : "password"}
//                                     fullWidth
//                                     value={confirmPassword}
//                                     onChange={(e) => {
//                                         setConfirmPassword(e.target.value);
//                                         setConfirmPasswordError("");
//                                     }}
//                                     error={!!confirmPasswordError}
//                                     helperText={confirmPasswordError}
//                                     style={styles.input}
//                                     InputProps={{
//                                         endAdornment: (
//                                             <InputAdornment position="end">
//                                                 <IconButton
//                                                     onClick={() => setShowConfirmPassword(!showConfirmPassword)}
//                                                     edge="end"
//                                                 >
//                                                     {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
//                                                 </IconButton>
//                                             </InputAdornment>
//                                         ),
//                                     }}
//                                 />
//                             </Grid>

//                             <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
//                                 <Button variant="outlined" style={{ textTransform: 'capitalize', color: primaryColor, border: `1px solid ${primaryColor}`, borderRadius: 0 }} fullWidth onClick={handleClose}>
//                                     Cancel
//                                 </Button>
//                                 <Button style={{ textTransform: 'capitalize', color: '#fff', background: primaryColor, borderRadius: 0}} onClick={handleSubmit} fullWidth>
//                                     {isUpdate ? 'Edit' : 'Save'}
//                                 </Button>
//                             </Grid>
//                         </Grid>
//                     </Box>
//                 </Fade>
//             </Modal>
//         </div>
//     );
// }