import * as React from 'react';
import { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { Toaster, toast } from 'react-hot-toast';
import { Country, City, State } from 'country-state-city';
import { useSelector } from 'react-redux';
import { createButtonBg, primaryColor } from '../../constant';
import { get_data, post_data } from '../../api';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
    maxHeight: '80vh',
    overflow: 'auto'
};

export default function SitesModal({ editSiteData, isUpdate, openForm, setOpenForm, ClientData, getSitesData, title }) {
    const [formData, setFormData] = useState({
        name: '',
        country: '',
        street: '',
        landmark: '',
        state: '',
        city: '',
        zipCode: '',
        company: []
    });
    const [errors, setErrors] = useState({});
    const [countryList, setCountryList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const { user_data } = useSelector(state => state.user);

    useEffect(() => {
        setCountryList(Country.getAllCountries());
    }, []);

    useEffect(() => {
        if (formData.country) {
            setStateList(State.getStatesOfCountry(formData.country));
            setCityList([]);
        }
    }, [formData.country]);

    useEffect(() => {
        if (formData.state) {
            setCityList(City.getCitiesOfState(formData.country, formData.state));
        }
    }, [formData.state]);

    const handleChangeCountry = (newCountry) => {
        setStateList(State.getStatesOfCountry(newCountry?.isoCode || ''));
        setFormData((prev) => ({ ...prev, country: newCountry?.isoCode || '', state: '', city: '' }));
    };
    // console.log("SITEUPDATE", formData, stateList)
    const handleChangeState = (newState) => {
        setFormData((prev) => ({ ...prev, state: newState?.isoCode || '', city: '' }));
        setCityList(City.getCitiesOfState(formData.country, newState?.isoCode || ''));
    };

    useEffect(() => {
        if (isUpdate && ClientData) {
            setFormData({
                name: ClientData?.name || '',
                country: ClientData?.country || '',
                company: ClientData?.clientId?.map((item) => item?._id) || [],
                street: ClientData?.street || '',
                landmark: ClientData?.landmark || '',
                state: ClientData?.state || '',
                city: ClientData?.city || '',
                zipCode: ClientData?.zipCode || ''
            });
        } else if (ClientData) {
            setFormData(ClientData);
        } else {
            setFormData({
                name: '',
                street: '',
                landmark: '',
                state: '',
                city: '',
                zipCode: ''
            });
        }
    }, [editSiteData, isUpdate, ClientData]);



    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const validateForm = () => {
        let tempErrors = {};
        let hasError = false;

        if (!formData.name) {
            tempErrors['name'] = 'Please input Site Name.';
            hasError = true;
        }
        // if (!formData.company || formData.company.length === 0) {
        //     tempErrors['company'] = 'Please select at least one company.';
        //     hasError = true;
        // }
        if (!formData.landmark) {
            tempErrors['landmark'] = 'Please input landmark.';
            hasError = true;
        }
        if (!formData.state) {
            tempErrors['state'] = 'Please input state.';
            hasError = true;
        }
        if (!formData.city) {
            tempErrors['city'] = 'Please input city.';
            hasError = true;
        }
        if (!formData.street) {
            tempErrors['street'] = 'Please input Street.';
            hasError = true;
        }
        if (!formData.zipCode) {
            tempErrors['zipCode'] = 'Please input Zip Code.';
            hasError = true;
        }

        setErrors(tempErrors);
        return hasError;
    };

    const handleClose = () => {
        setOpenForm(false);
        if (!isUpdate) {
            setFormData('')
        }
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            try {
                let payload;
                let data;

                if (isUpdate) {
                    payload = {
                        clientId: ClientData?.companyId,
                        ...formData,
                    };
                    data = await post_data(`sites/update-site/${ClientData?._id}`, payload);
                } else {
                    if (title === 'admin') {
                        payload = { sites: formData, createdBy: user_data?._id };
                    } else if (title === 'company') {
                        payload = { sites: { ...formData, company: [user_data?._id] }, createdBy: user_data?._id };
                    }

                    data = await post_data(`sites/create-sites`, payload);
                }
                // console.log("SITEUPDATE", data, payload)
                if (data?.status === true) {
                    setFormData('')
                    toast.success(data?.message || 'Operation successful!');
                    getSitesData(); // Refresh parent data
                    setOpenForm(false); // Close form
                } else {
                    // toast.error(data?.message || 'Something went wrong!');
                }
            } catch (error) {
                toast.error('An error occurred. Please try again.');
            }
        }
    };

    const fetchClients = async () => {
        const result = await get_data(`client/get-all-clients`)
        if (result.status === true) {
            setCompanyList(result?.data?.clients)
        }
    };


    useEffect(() => {
        fetchClients();
    }, [])

    return (
        <div>
            <Modal
                open={openForm}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 500 } }}
            >
                <Fade in={openForm}>
                    <Box sx={style}>
                        <Typography id="modal-title" variant="h6">
                            {isUpdate ? 'Update Site' : 'Create Site'}
                        </Typography>
                        <Grid container spacing={2} style={{ marginTop: 10 }}>
                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.name)}
                                    helperText={errors.name}
                                    value={formData.name}
                                    onFocus={() => handleError('', 'name')}
                                    label="Site Name"
                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    fullWidth
                                />
                            </Grid>

                            {title === 'admin' ? <Grid item xs={12}>
                                <Autocomplete
                                    multiple
                                    id="company-multi-select"
                                    options={companyList}
                                    value={companyList.filter((c) => formData.company?.includes(c?._id)) || []}
                                    onChange={(e, newValue) => {
                                        setFormData({ ...formData, company: newValue.map((item) => item?._id) });
                                    }}
                                    getOptionLabel={(option) => option?.companyName || ''}
                                    renderInput={(params) => (
                                        <TextField
                                            error={Boolean(errors?.company)}
                                            helperText={errors?.company}
                                            onFocus={() => handleError('', 'company')}
                                            {...params}
                                            label="Select Companies"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid> : <></>}


                            <Grid item xs={12}>
                                <Autocomplete
                                    id="country-select"
                                    options={countryList}
                                    value={countryList.find((c) => c.isoCode === formData.country) || null}
                                    onChange={(e, newValue) => handleChangeCountry(newValue)}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            error={Boolean(errors.country)}
                                            helperText={errors.country}
                                            {...params}
                                            label="Select Country"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Autocomplete
                                    id="state-select"
                                    options={stateList}
                                    value={stateList.find((s) => s.isoCode === formData.state) || null}
                                    onChange={(e, newValue) => handleChangeState(newValue)}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            error={Boolean(errors.state)}
                                            helperText={errors.state}
                                            {...params}
                                            label="Select State"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Autocomplete
                                    id="city-select"
                                    options={cityList}
                                    value={cityList.find((c) => c.name === formData.city) || null}
                                    onChange={(e, newValue) => setFormData({ ...formData, city: newValue?.name || '' })}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            error={Boolean(errors.city)}
                                            helperText={errors.city}
                                            {...params}
                                            label="Select City"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.street)}
                                    helperText={errors.street}
                                    onFocus={() => handleError('', 'street')}
                                    value={formData.street}
                                    label="Street"
                                    onChange={(e) => setFormData({ ...formData, street: e.target.value })}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    value={formData.landmark}
                                    error={Boolean(errors.landmark)}
                                    helperText={errors.landmark}
                                    onFocus={() => handleError('', 'landmark')}
                                    label="Landmark"
                                    onChange={(e) => setFormData({ ...formData, landmark: e.target.value })}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label="Zip Code"
                                    value={formData.zipCode}
                                    error={Boolean(errors.zipCode)}
                                    helperText={errors.zipCode}
                                    onFocus={() => handleError('', 'zipCode')}
                                    onChange={(e) => setFormData({ ...formData, zipCode: e.target.value })}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
                                <Button
                                    variant="outlined"
                                    style={{
                                        textTransform: 'capitalize',
                                        color: primaryColor,
                                        border: `1px solid ${primaryColor}`,
                                        borderRadius: 0,
                                    }}
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    style={{
                                        textTransform: 'capitalize',
                                        color: '#fff',
                                        background: primaryColor,
                                        borderRadius: 0,
                                    }}
                                    onClick={handleSubmit}
                                    fullWidth
                                >
                                    {isUpdate ? 'Update' : 'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
