import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Avatar,
    Button,
    Typography,
    Menu,
    MenuItem,
    IconButton,
    Pagination,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TableLoader from './TableLoader';
import AddVendorModal from '../AddVendorModal';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import { primaryColor } from '../../constant';
import { post_data } from '../../api';
import VendorDetailModal from '../VendorDetailModal';
import AdminChangPasswordModals from "./AdminChangPasswordModals";
import VendorDocsModal from '../VendorDocsModal';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
        '& .MuiCheckbox-root': {
            color: '#a3a3a3',
        },
    },
    avatarGroup: {
        display: 'flex',
        alignItems: 'center',
    },
    avatarName: {
        marginLeft: '8px',
        fontSize: 14
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});

const ClientsVendorsTable = ({ data, tableHeader, setLoading, totalData, loading, currentPage, setCurrentPage, itemsPerPage, totalPages, handleVendorSubmit, refresh, setRefresh, getAllVendors }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const [updateData, setUpdateData] = useState('');
    const [vendorUpdateOpen, setVendorUpdateOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [rowsData, setRowsData] = useState(data || []);
    const [passwordChangOpen, setPasswordChangOpen] = useState(false);
    const [docModalOpen, setDocModalOpen] = useState(false)
    const [vendorData, setVendorData] = useState({})

    const handleMenuOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(index);
    };
    useEffect(() => { setRowsData(data) }, [data])
    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentRow(null);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);

    const handleDelete = async (row) => {
        setAnchorEl(null);
        setCurrentRow(null);

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: primaryColor,
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const result = await post_data(`vendor/delete-vendor/${row?._id}`, {});

                    if (result.status) {
                        toast.success("Vendor Deleted Successfully");
                        getAllVendors();
                        setRefresh(!refresh);
                    } else {
                        toast.error("Something went wrong");
                    }
                } catch (error) {
                    toast.error("An error occurred during deletion");
                }
            }
        });
    };

    const handleEdit = (row) => {
        setUpdateData(row)
        setVendorUpdateOpen(true)
        setAnchorEl(null);
        setCurrentRow(null);
    }

    const handleDetails = (row) => {
        setOpen(true);
        setUpdateData(row);
        setCurrentRow(null);
        setAnchorEl(null);
    }

    return (
        <TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
            <Table className={classes.root}>
                <TableHead>
                    <TableRow>
                        {tableHeader?.map((header, index) => (
                            <TableCell key={index} style={{ borderRight: '1px solid gainsboro', padding: '16px 10px' }}>
                                {header}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                {loading ? (
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={tableHeader.length}>
                                <TableLoader />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                        {rowsData.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ borderRight: '1px solid gainsboro', textAlign: 'center', width: '5%' }}>
                                    {startEntry + index}
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro', width: '8%' }}>
                                    {row?.uniqueVendorId || '-'}
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro', width: '10%' }}>
                                    {row?.name || '-'}
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro', width: '10%' }}>
                                    {row?.totalDetailLength || '-'}
                                </TableCell>
                                <TableCell style={{ textAlign: 'center', borderRight: '1px solid gainsboro', width: '12%' }}>
                                    {row?.phone || '-'}
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro', width: '15%' }}>
                                    {row?.email || '-'}
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro', width: '15%' }}>
                                    {row?.clientId?.map((item) => item?.companyName).join(', ') || '-'}
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro', width: '30%' }}>
                                    {[
                                        row?.address?.street,
                                        row?.address?.city,
                                        row?.address?.state,
                                        row?.address?.zipCode
                                    ]
                                        .filter(Boolean)
                                        .join(' ')}
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                    <IconButton
                                        className={classes.moreIcon}
                                        onClick={(event) => handleMenuOpen(event, index)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>

                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl) && currentRow === index}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem onClick={() => {
                                            localStorage.setItem('vendorId', row?._id);
                                            handleDetails(row);
                                            handleMenuClose()
                                        }}>
                                            View Details
                                        </MenuItem>
                                        <MenuItem onClick={() => {
                                            setDocModalOpen(true)
                                            handleMenuClose()
                                            setVendorData(row)
                                        }}>View Documents</MenuItem>
                                        <MenuItem onClick={() => {
                                            localStorage.setItem('vendorId', row?._id);
                                            handleEdit(row);
                                            handleMenuClose()
                                        }}>
                                            Edit
                                        </MenuItem>
                                        <MenuItem onClick={() => handleDelete(row)}>
                                            Delete
                                        </MenuItem>
                                    </Menu>
                                </TableCell>

                            </TableRow>

                        ))}
                    </TableBody>
                )}

                <AddVendorModal title={'ClientVendors'} vendorUpdateOpen={vendorUpdateOpen} passwordChangOpen={passwordChangOpen} setPasswordChangOpen={setPasswordChangOpen} setVendorUpdateOpen={setVendorUpdateOpen} updateData={updateData} handleVendorSubmit={handleVendorSubmit} isUpdate={true} />
                <VendorDetailModal open={open} setOpen={setOpen} data={updateData} />
                <AdminChangPasswordModals passwordChangOpen={passwordChangOpen} isUpdate={true} setPasswordChangOpen={setPasswordChangOpen} title={'vendor'} />

                <VendorDocsModal open={docModalOpen} setOpen={setDocModalOpen} docs={[vendorData?.doc1, vendorData?.doc2, vendorData?.doc3, vendorData?.doc4, vendorData?.doc5]} docVerify={[vendorData?.doc1_verified, vendorData?.doc2_verified, vendorData?.doc3_verified, vendorData?.doc4_verified, vendorData?.doc5_verified]} vendorId={vendorData?._id} refresh={refresh} setRefresh={setRefresh} screen={'company'} />
            </Table>
            {rowsData?.length > 0 && (
                <div
                    style={{
                        borderTop: "1px solid gainsboro",
                        padding: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography style={{ fontWeight: 500, fontSize: 15, color: "black" }}>
                        {`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}
                    </Typography>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}
        </TableContainer>
    );
};

export default ClientsVendorsTable;
