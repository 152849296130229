import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Fade, Autocomplete, Grid, Button, TextField } from '@mui/material';
import { formatDate, primaryColor } from '../../constant';
import Backdrop from '@mui/material/Backdrop';
import { get_data, post_data } from '../../api';
import toast from 'react-hot-toast';
// import { serverURL } from '../api';

const AssignGpsToCompany = ({ open, setOpen, data, getInvenrotyData }) => {
    const [clientId, setClientId] = useState('');
    const [companyList, setCompanyList] = useState([]);
    const [vehicleNumber, setVehicleNumber] = useState('');
    const handleClose = () => {
        setOpen(false);
        setClientId('');
        setVehicleNumber('');
    };

    const fetchCompany = async () => {
        const data = await get_data(`client/get-all-clients`)
        setCompanyList(data?.data?.clients)
    }

    const handleCompanyChangeChange = (event, newValue) => {
        setClientId(newValue?._id);
    }


    useEffect(() => {
        fetchCompany()
    }, [data])

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 555,
            border: 'none',
            bgcolor: 'white',
            borderRadius: '10px',
            boxShadow: 12,
            textAlign: 'center',
            maxHeight: '80vh',
            overflow: 'auto',
        },
        heading: {
            fontWeight: 600,
            fontSize: 27,
            marginBottom: '10px',
        },
        row: {
            display: 'flex',
            width: '100%',
            gap: '10px',
            margin: '4% 0',
            fontWeight: 400,
            fontSize: 16,
        },
        dataLabel: {
            fontWeight: 400,
            fontSize: 16,
            width: '50%',
            textAlign: 'left'
        },
        dataValue: {
            fontWeight: 400,
            fontSize: 16,
            marginLeft: 'auto',
            width: '50%',
            textAlign: 'right',
            wordBreak: 'break-all',
            wordWrap: 'wrap',
        },
        icon: {
            width: 22,
        },
        headingTypography: {
            fontWeight: 500,
            fontSize: 22,
            marginBottom: '10px',
            textAlign: 'left',
            p: 3,
            borderBottom: '1px solid gainsboro'
        }
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #fff',
        boxShadow: 24,
        p: 4,
    };

    const handleAssign = async () => {
        const res = await post_data(`admin-inventory/assign-gps-to-company`, { ...data, clientId, vehicleNumber, gpsStatus: "active" })

        if (res?.status === true) {
            toast.success(res?.message)
            getInvenrotyData();
            handleClose();
        } else {
            toast.error(res?.response?.data?.message)
        }
    }
    console.log("vvvvvv", data)
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Assign Gps
                    </Typography>
                    <Grid container spacing={2} style={{ marginTop: 10 }}>
                        <Grid item xs={12}>
                            {data?.clientId?._id ? <Autocomplete
                                fullWidth
                                options={companyList}
                                value={companyList.find((s) => s?._id === data?.clientId?._id) || null}
                                // onChange={(event, newValue) => handleCompanyChangeChange(event, newValue)}
                                autoHighlight
                                disabled
                                getOptionLabel={(option) => option.companyName || ''}
                                renderInput={(params) => <TextField {...params} label="Select Company" fullWidth />}
                            />
                                : <Autocomplete
                                    fullWidth
                                    options={companyList}
                                    value={companyList.find((s) => s?._id === clientId) || null}
                                    onChange={(event, newValue) => handleCompanyChangeChange(event, newValue)}
                                    autoHighlight
                                    getOptionLabel={(option) => option.companyName || ''}
                                    renderInput={(params) => <TextField {...params} label="Select Company" fullWidth />}
                                />}
                        </Grid>
                        <Grid item xs={12}>
                            {data?.vehicleNumber ? <TextField
                                type='text'
                                label="Vehicle Number"
                                value={data?.vehicleNumber || ''}
                                fullWidth
                                disabled
                                onChange={(e) => setVehicleNumber(e.target.value)}
                            /> : <TextField
                                type='text'
                                label="Vehicle Number"
                                value={vehicleNumber || ''}
                                fullWidth
                                onChange={(e) => setVehicleNumber(e.target.value)}
                            />}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type='text'
                                disabled
                                label="IMEI Number"
                                value={data?.imeiNumber || ''}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type='text'
                                disabled
                                label="SIM Number"
                                value={data?.simNumber || ''}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type='text'
                                disabled
                                label="Sim network"
                                value={data?.network || ''}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                type='text'
                                disabled
                                label="Device Unique Id"
                                value={data?.deviceUniqueId || ''}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
                            <Button
                                variant="outlined"
                                style={{
                                    textTransform: 'capitalize',
                                    color: primaryColor,
                                    border: `1px solid ${primaryColor}`,
                                    borderRadius: 0
                                }}
                                fullWidth
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            {data?.clientId?._id && data?.vehicleNumber ? <Button
                                style={{
                                    textTransform: 'capitalize',
                                    color: '#fff',
                                    background: primaryColor,
                                    borderRadius: 0
                                }}
                                disabled
                                onClick={handleAssign}
                                fullWidth
                            >
                                Assign
                            </Button> : <Button
                                style={{
                                    textTransform: 'capitalize',
                                    color: '#fff',
                                    background: primaryColor,
                                    borderRadius: 0
                                }}

                                onClick={handleAssign}
                                fullWidth
                            >
                                Assign
                            </Button>}
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal >
    );
};

export default AssignGpsToCompany;
