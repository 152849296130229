import { Grid, Pagination, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import ProductCard from "../components/ProductCard"
import EmptyPage from "./EmptyPage";

const FilterResults = ({ user_data, data, totalData, loading, currentPage, setCurrentPage, itemsPerPage, totalPages }) => {

    const [refresh, setRefresh] = useState(false);

    const styles = {
        container: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: '15px',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
        },
        card: {
            flex: '1 1 calc(33.33% - 20px)',
            boxSizing: 'border-box',
        }
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);


    return (
        <div style={styles.container}>
            <Grid container spacing={2}>
                {
                    data?.length > 0 ?
                        data?.map((item, i) => {
                            return (
                                <Grid item md={3} sm={6} xs={12} key={i}>
                                    <ProductCard item={item} refresh={refresh} setRefresh={setRefresh} />
                                </Grid>
                            )
                        })
                        :
                        <EmptyPage />
                }
                <Grid item xs={12}>
                    {data?.length > 0 && (
                        <div
                            style={{
                                borderTop: "1px solid gainsboro",
                                padding: "2%",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                style={{
                                    fontWeight: 500,
                                    fontSize: 15,
                                    color: "black",
                                }}
                            >{`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}</Typography>
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                            />
                        </div>
                    )}
                </Grid>
            </Grid>
        </div>
    );
}

export default FilterResults;