import { Button, Grid } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import { createButtonBg } from "../../../constant";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../../api";
import EmptyPage from "../../../components/EmptyPage";
import OrdersTable from "../../../components/tables/OrdersTable";
import SearchComponent from "../../../components/SearchComponent";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import OrdersTransactionModal from "../../../components/models/OrdersTransactionModal";

const dummyData = [
    { ordersId: "ORD12346", name: "Smartphone", amounts: "$800", itemId: "ITM9877" },
    { ordersId: "ORD12347", name: "Headphones", amounts: "$150", itemId: "ITM9878" },
    { ordersId: "ORD12348", name: "Tablet", amounts: "$500", itemId: "ITM9879" },
    { ordersId: "ORD12349", name: "Monitor", amounts: "$300", itemId: "ITM9880" }
]


const rangePickerStyle = {
    display: 'flex',
    cursor: 'pointer',
    fontSize: 14,
    background: 'white',
    padding: '0% 0% 0% 0%',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    color: '#7E8299',
    cursor: 'pointer'
};


export default function Orders() {
    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [value, setValue] = useState('')
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [open, setOpen] = useState(false)
    const [fromDate, setFromDate] = useState([])
    const [toDate, setToDate] = useState([])
    const [orderData, setOrderData] = useState([])


    const getAllOrders = async () => {
        try {
            const data = await get_data(`order/get-all-orders?pageNumber=${currentPage}`);
            if (data.status) {
                setLoading(false);
                setData(data?.data?.orders || dummyData);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalOrders);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllOrders(currentPage);
        }
    }, [currentPage])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }, []);


    const itemsPerPage = 15;
    const count = data?.length;


    const tableHeader = [
        "S No.", 'Name', 'Email', "Product Name", "Transaction No","Amount", 'Shipping Address', 'Status'
    ]

    const handleNavigate = () => {
        // navigate('/admin/dashboard/orders/create')
        // window.scrollTo(0, 0)
    }

    const handleSearch = async () => {
        setLoading(true);
        if (value.length > 0) {
            const data = await get_data(`order/search-orders/${value}?pageNumber=${currentPage}`)
            if (data.status) {
                setData(data?.data?.orders);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalOrders);
            }
        } else {
            getAllOrders(currentPage);
        }
        setLoading(false);
    }

    const handleDateChange = (dates) => {
        if (dates && dates?.length === 2) {
            setDateRange(dates);
        }
    };

    const fetchExportData = async (search) => {
        try {
            const fromDate = moment(startDate).toISOString();
            const toDate = moment(endDate).toISOString();

            let response = await post_data('order/get-all-sales-by-date', {
                from: fromDate,
                to: toDate
            })
            // console.log("CCCCCC", response)
            if (response?.status === true) {
                setOrderData(response?.data?.salesRecords)
                setData(response?.data?.salesRecords)
                // search ? setOpen(false) : setOpen(true)
                setOpen(true)
                setFromDate(response?.data?.fromDate)
                setToDate(response?.data?.toDate)

            } else {

            }
        } catch (error) {
        }
    };

    // useEffect(() => {
    //     if (startDate && endDate) {
    //         let search = true
    //         fetchExportData(search);
    //     }
    // }, [startDate, endDate]);


    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <div style={{ display: 'flex', marginBottom: '1%', width: '100%' }}>
                    <div>
                        <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                    </div>
                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        <div style={{ ...rangePickerStyle, border: 'none', }}>
                            <DatePicker
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                dateFormat="dd-MM-yyyy"
                                maxDate={new Date()}
                                customInput={
                                    <button style={{ border: 'none', background: '#fff', cursor: 'pointer', }}>
                                        {startDate && endDate ? `${moment(startDate).format('DD-MM-YYYY')} to ${moment(endDate).format('DD-MM-YYYY')}` : 'Select Date Range'}
                                    </button>
                                }
                            />
                        </div>
                        <Button
                            onClick={fetchExportData}
                            variant="contained"
                            style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                        >
                            Export
                        </Button>

                        {/* <Button
                            onClick={handleNavigate}
                            startIcon={<IoMdAdd style={{ color: 'white' }} />}
                            variant="contained"
                            style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                        >
                            Create
                        </Button> */}
                    </div>

                </div>
                {
                    data?.length === 0 ?
                        <>
                            <EmptyPage />
                        </>
                        :
                        <>
                            <OrdersTable
                                data={data}
                                tableHeader={tableHeader}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                count={count}
                                totalData={totalData}
                                totalPages={totalPages}
                                loading={loading}
                                setLoading={setLoading}
                                getAllOrders={getAllOrders}
                            />
                            <OrdersTransactionModal
                                open={open}
                                setOpen={setOpen}
                                fromDate={fromDate}
                                toDate={toDate}
                                data={orderData}
                            />
                        </>
                }
            </Grid>
        </>
    )
}