import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Menu,
    MenuItem,
    IconButton,
    Avatar,
    Typography,
    Pagination,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Swal from 'sweetalert2';
import { post_data, serverURL } from '../../api'; // Ensure serverURL is correctly imported
import { useNavigate } from 'react-router-dom';
import TableLoader from './TableLoader';
import EmptyPage from '../EmptyPage';
import { primaryColor } from '../../constant';
import { Toaster, toast } from 'react-hot-toast';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
    },
    stageButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        '&.approved': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.pending': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.qualification': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.closedLost': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
        '&.true': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});

const VendorVehicleTable = ({ data, tableHeader, getAllVehicles, setLoading, totalData, loading, currentPage, setCurrentPage, itemsPerPage, totalPages }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [rowsData, setRowsData] = useState(data || []);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);

    const handleCheckboxToggle = (index) => {
        const updatedRows = [...rowsData];
        updatedRows[index].checked = !updatedRows[index].checked;
        setRowsData(updatedRows);
    };

    useEffect(() => { setRowsData(data) }, [data])

    // Open menu on click of more options icon
    const handleMenuOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(index);
    };

    // Close the options menu
    const handleMenuClose = (row) => {
        setAnchorEl(null);
        setCurrentRow(null);
    };

    const handleViewDetails = (row) => {
        navigate('/vendor/dashboard/vehicles/details', { state: { row, title: 'update' } });
        window.scrollTo(0, 0);
        setAnchorEl(null);
        setCurrentRow(null);
    };

    const handleEdit = (row)=>{
        navigate('/vendor/dashboard/vehicles/create', { state: { row, title: 'update' } });
        window.scrollTo(0, 0);
        setAnchorEl(null);
        setCurrentRow(null);
    }
    // Delete a product after confirmation
    const handleDelete = async (row) => {
        setAnchorEl(null);
        setCurrentRow(null);

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: primaryColor,
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = await post_data(`vehicles/delete-vehicle/${row?._id}`, {})
                    if (data.status) {
                        toast.success("Vehicle Deleted Successfully");
                        getAllVehicles();
                    }
                    else {
                        toast.error("Something Went Wrong");
                    }
                } catch (error) {
                }
            }
        });

    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);
    return (
        <>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
                <Table className={classes.root}>
                    <TableHead>
                        <TableRow>
                            {tableHeader?.map((item, idx) => (
                                <TableCell key={idx} style={{ borderRight: '1px solid gainsboro', padding: '16px 10px' }}>
                                    {item}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {loading ?
                        <div>
                            <TableLoader />
                        </div>
                        :
                        <TableBody>
                            {
                                // rowsData?.length > 0 ? ()
                                rowsData?.map((row, index) => {
                                    return (
                                        <>
                                            <TableRow key={index}>

                                                <TableCell style={{ borderRight: '1px solid gainsboro', textAlign: 'center', width: '5%' }}>{startEntry + index}</TableCell>
                                                {/* <TableCell style={{ borderRight: '1px solid gainsboro', width: '5%' }}>
                                                    <Avatar
                                                        alt={row?.images[0]}
                                                        src={`${serverURL}/uploads/products-images/${row?.images[0]}`}
                                                        style={{ borderRadius: 5 }}
                                                    />
                                                </TableCell> */}
                                                {/* <TableCell style={{ borderRight: '1px solid gainsboro', width: '20%' }}>{row?.name}</TableCell> */}
                                                <TableCell style={{ borderRight: '1px solid gainsboro', width: '15%' }}>{row?.detail?.map((item) => { return (<div >{item?.vehicleDetails?.regnOwnerName}</div>) })}</TableCell>
                                                <TableCell style={{ borderRight: '1px solid gainsboro', width: '15%' }}>{row?.detail?.map((item) => { return (<div >{item?.vehicleDetails.regnOwnerContactNo}</div>) })}</TableCell>
                                                <TableCell style={{ borderRight: '1px solid gainsboro', width: '15%' }}>{row?.detail?.map((item) => { return (<div >{item?.vehicleDetails.regnNumber}</div>) })}</TableCell>
                                                <TableCell style={{ borderRight: '1px solid gainsboro', width: '15%' }}>{row?.detail?.map((item) => { return (<div >{item?.vehicleDetails.typeOfVehicle}</div>) })}</TableCell>
                                                <TableCell style={{ borderRight: '1px solid gainsboro', width: '15%' }}>{row?.detail?.map((item) => { return (<div >{item?.vehicleDetails.plate}</div>) })}</TableCell>

                                                {/* <TableCell style={{ borderRight: '1px solid gainsboro', width: '10%' }}>{row?.color}</TableCell> */}
                                                <TableCell style={{ borderRight: '1px solid gainsboro', width: '10%' }}>
                                                    <Button style={{ fontSize: 11, fontWeight: 500, borderRadius: 30 }}
                                                        className={`${classes.stageButton} ${row?.status?.toLowerCase()?.replace(' ', '')}`}
                                                    >
                                                        {row?.status}
                                                    </Button>
                                                </TableCell>

                                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                                    <IconButton
                                                        className={classes.moreIcon}
                                                        onClick={(event) => handleMenuOpen(event, index)}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>

                                                    {/* Options menu */}
                                                    <Menu
                                                        anchorEl={anchorEl}
                                                        open={Boolean(anchorEl) && currentRow === index}
                                                        onClose={handleMenuClose}
                                                    >   
                                                        <MenuItem onClick={() => handleViewDetails(row)}>View Details</MenuItem>
                                                        <MenuItem onClick={() => handleEdit(row)}>Edit</MenuItem>
                                                        <MenuItem onClick={() => handleDelete(row)}>Delete</MenuItem>
                                                    </Menu>
                                                </TableCell>
                                            </TableRow >
                                        </>
                                    )
                                })
                            }
                        </TableBody>
                    }
                </Table>

                {rowsData?.length > 0 && (
                    <div
                        style={{
                            borderTop: "1px solid gainsboro",
                            padding: "2%",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            style={{
                                fontWeight: 500,
                                fontSize: 15,
                                color: "black",
                            }}
                        >{`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}</Typography>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </div>
                )}

            </TableContainer >
        </>
    );
};

export default VendorVehicleTable;
