import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, Box, CircularProgress } from '@mui/material';
import { get_data } from '../api';

const SubCompanyUninstallComponent = ({ clientId }) => {
    const [uninstallData, setUninstallData] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchUninstallData = async () => {
        try {
            const data = await get_data("uninstall/get-all-uninstalls-by-sub-client/" + clientId);
            if (data.status) {
                setUninstallData(data?.data?.uninstalls || []);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUninstallData();
    }, [clientId]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (!uninstallData || uninstallData.length === 0) {
        return <Typography>No Uninstall details found for this client.</Typography>;
    }

    return (
        <Grid container spacing={3}>
            {uninstallData?.map((returnItem) => (
                <Grid item xs={12} key={returnItem._id}>
                    <Card>
                        <CardContent>
                            <Box mb={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="h6" color="primary">Uninstall ID: {returnItem.uniqueUninstallId}</Typography>
                                <Typography>{new Date(returnItem.createdAt).toLocaleDateString()}</Typography>
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Typography>Request ID: {returnItem.requestId}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography>Claim Reason: {returnItem.uninstallReason || 'N/A'}</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default SubCompanyUninstallComponent;
