
import { Button, Grid } from "@mui/material";
import { createButtonBg } from "../../../constant";
import { IoMdAdd } from "react-icons/io";
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../../api";
import EmptyPage from "../../../components/EmptyPage";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import SearchComponent from "../../../components/SearchComponent";
import { useDispatch, useSelector } from "react-redux";
import ServiceRequestModals from "../../../components/models/ServiceRequestModals";
import ServiceRequestTable from "../../../components/tables/ServiceRequestTable";

const rangePickerStyle = {
    display: 'flex',
    cursor: 'pointer',
    fontSize: 14,
    background: 'white',
    padding: '0% 0% 0% 0%',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    color: '#7E8299',
    cursor: 'pointer'
};
const dummy = [
    {

        vehicleNo: "AB123CD",
        driverName: "John Doe",
        driverAddress: "123 Main St, Springfield",
        driverPhone: "+1234567890",
        imeiNo: "357289063547895"
    },
    {
        "vehicleNo": "EF456GH",
        "driverName": "Jane Smith",
        "driverAddress": "456 Elm St, Shelbyville",
        "driverPhone": "+0987654321",
        "imeiNo": "358374092845637"
    },
    {
        "vehicleNo": "IJ789KL",
        "driverName": "Alice Johnson",
        "driverAddress": "789 Oak St, Ogdenville",
        "driverPhone": "+1122334455",
        "imeiNo": "356789013467852"
    },
    {
        "vehicleNo": "MN012OP",
        "driverName": "Bob Williams",
        "driverAddress": "101 Pine St, Capital City",
        "driverPhone": "+2233445566",
        "imeiNo": "359087451236589"
    },
    {
        "vehicleNo": "QR345ST",
        "driverName": "Eve Brown",
        "driverAddress": "202 Maple St, North Haverbrook",
        "driverPhone": "+3344556677",
        "imeiNo": "357298156739084"
    }
]

export default function ServiceRequest() {

    const page = { width: '100%', marginBottom: '2%' };
    const { user_data } = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [value, setValue] = useState('')
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [open, setOpen] = useState(false)
    const [fromDate, setFromDate] = useState([])
    const [toDate, setToDate] = useState([])
    const [serviceRequestData, setServiceRequestData] = useState(dummy || [])

    const itemsPerPage = 15;
    const count = data?.length;


    const tableHeader = [
        "S No.", "Service Id", "Vehicle no.", "IMEI no.", "Driver phone no.", "Driver name", "Driver address",
    ]

    const getServiceRequestData = async () => {
        try {
            let data

            data = await get_data(`service-request/get-all-service-requests-for-client?pageNumber=${currentPage}`)
            console.log("XXXXXXXXXXX", data)

            if (data?.status === true) {
                setLoading(false);
                setData(data?.data?.serviceRequests);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalServiceRequests);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getServiceRequestData(currentPage);
        }
    }, [currentPage])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }, []);

    const handleSearch = async () => {
        setLoading(true);
        if (value.length > 0) {
            let data = await get_data(`service-request/search-service-requests/${value}/${user_data?._id}?pageNumber=${currentPage}`)
            // console.log("cccccccccc", data)
            if (data.status === true) {
                setData(data?.data?.serviceRequests)
                setTotalData(data?.data?.totalServiceRequests)
                setTotalPages(data?.data?.totalPages)
            }
        } else {
            getServiceRequestData(currentPage);
        }
        setLoading(false);
    }


    const handleDateChange = (dates) => {
        if (dates && dates?.length === 2) {
            setDateRange(dates);
        }
    };

    const fetchExportData = async () => {
        try {

            const fromDate = moment(startDate).toISOString();
            const toDate = moment(endDate).toISOString();
            let response
            user_data?.isSubClient ?
                response = await post_data('claim/get-claims-by-date-for-sub-client', {
                    from: fromDate,
                    to: toDate
                }) :
                response = await post_data('claim/get-claims-by-date-for-client', {
                    from: fromDate,
                    to: toDate
                })

            if (response?.status) {
                setServiceRequestData(response?.data?.serviceRequest)
                setOpen(true)
                setFromDate(response?.data?.fromDate)
                setToDate(response?.data?.toDate)
            } else {

            }
        } catch (error) {
        }
    };

    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>

                <div style={{ display: 'flex', marginBottom: '1%', width: '100%' }}>
                    <div>
                        <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                    </div>
                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>

                        <Button
                            onClick={() => setOpen(true)}
                            variant="contained"
                            startIcon={<IoMdAdd />}
                            style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                        >
                            Create
                        </Button>
                        <ServiceRequestModals open={open} setOpen={setOpen} getServiceRequestData={getServiceRequestData} isUpdate={false} />
                    </div>

                </div>

                {
                    data?.length === 0 ?
                        <>
                            <EmptyPage />
                        </>
                        :
                        <>
                            <ServiceRequestTable
                                data={data}
                                tableHeader={tableHeader}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                count={count}
                                totalData={totalData}
                                totalPages={totalPages}
                                loading={loading}
                                setLoading={setLoading}
                                getServiceRequestData={getServiceRequestData}
                            />

                        </>
                }
            </Grid>
        </>
    )
}