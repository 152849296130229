import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { useState, useEffect } from "react";
import { createButtonBg, primaryColor } from '../../constant';
import { get_data, post_data } from '../../api';
import toast from 'react-hot-toast';
import { City, Country, State } from 'country-state-city';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
};

export default function AddInstallerModal({ setEditModelOpen, editModelOpen, editModelData, isUpdate, createModelOpen, setCreateModelOpen, refresh, setRefresh }) {
    const [name, setName] = useState('');
    const [errors, setErrors] = useState({});
    const [brandList, setBrandList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [brandId, setBrandId] = useState('');
    //-------------------------------------------------------------------------------------------------------------------
    // const [countryCode] = useState('IN'); // Assuming only India for simplicity
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [countryList, setCountryList] = useState([])
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        address: {
            street: '',
            city: '',
            state: '',
            zipCode: '',
            country: ''
        }
    })

    useEffect(() => {
        setCountryList(Country.getAllCountries());
    }, []);

    useEffect(() => {
        if (formData?.address?.country && formData?.address?.state) {
            const fetchedState = State.getStatesOfCountry(formData?.address?.country)
            const fetchedCities = City.getCitiesOfState(formData?.address?.country, formData?.address?.state);
            setStateList(fetchedState)
            setCityList(fetchedCities);
        }
    }, [formData?.address?.state, formData?.address?.country]);

    const handleCountryChange = (event, newValue) => {
        if (newValue) {
            setFormData({ ...formData, address: { ...formData?.address, country: newValue?.isoCode } })
            const fetchedStates = State.getStatesOfCountry(newValue.isoCode || formData?.address?.country);
            setStateList(fetchedStates);
        }
    }

    const handleStateChange = (event, newValue) => {
        if (newValue) {
            setFormData({ ...formData, address: { ...formData?.address, state: newValue?.isoCode } })
            const fetchedCities = City.getCitiesOfState(formData?.address?.country, newValue.isoCode || formData?.address?.state);
            setCityList(fetchedCities);
            // setFormData({...formData,address:{...formData?.address,city:''}}); // Reset city when state changes
        }
    };

    const handleCityChange = (event, newValue) => {
        if (newValue) {
            setFormData({ ...formData, address: { ...formData?.address, city: newValue?.name } })
        }
    };

    //-------------------------------------------------------------------------------------------------------------------
    useEffect(() => {

        // Initialize form state based on whether it is an update or a new entry
        if (isUpdate && editModelData) {
            // console.log("{...formData,name:editModelData?.name}",editModelData)
            setFormData({
                ...formData,
                name: editModelData?.name,
                phone: editModelData?.phone,
                address: {
                    country: editModelData?.address?.country,
                    street: editModelData?.address?.street,
                    state: editModelData?.address?.state,
                    city: editModelData?.address?.city,
                    zipCode: editModelData?.address?.zipCode
                }
            })

        } else {
            setFormData({ ...formData })
        }
    }, [editModelData, isUpdate]);

    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const validation = () => {
        let error = false;
        // if (!name) {
        //     error = true;
        //     handleError('Please input Model Name...', 'name');
        // }
        return error;
    };

    const handleClose = () => {
        if (isUpdate) {
            setEditModelOpen(false);
        } else {
            setCreateModelOpen(false);
        }
    };

    const handleSubmit = async () => {
        const error = validation();
        if (!error) {
            try {
                let data;
                if (isUpdate) {
                    data = await post_data(`installer/update-installer/${editModelData?._id}`, { ...formData });
                    setEditModelOpen(false);
                } else {
                    data = await post_data(`installer/create-installer`, { ...formData });
                    setCreateModelOpen(false);
                }
                setRefresh(!refresh);
                if (data?.status === true) {
                    toast.success(data?.message || 'Operation successful!');
                    setFormData('')
                } else {
                    toast.error(data?.message || 'Operation failed!');
                }
            } catch (error) {
                toast.error('An error occurred. Please try again.');
            }
        }
    };
    // console.log("formData: ", formData)
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={isUpdate ? editModelOpen : createModelOpen}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={isUpdate ? editModelOpen : createModelOpen}>
                    <Box sx={style}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            {isUpdate ? 'Edit Installer' : 'Create Installer'}
                        </Typography>
                        <Grid container spacing={2} style={{ marginTop: 10 }}>

                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.name)}
                                    helperText={errors.name}
                                    onFocus={() => handleError('', 'name')}
                                    value={formData?.name}
                                    label="Installer Name"
                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    fullWidth
                                    type='text'
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.phone)}
                                    helperText={errors.phone}
                                    onFocus={() => handleError('', 'phone')}
                                    value={formData?.phone}
                                    label="Phone Number"
                                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                                    fullWidth

                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography component="h1">Address</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Street"
                                    error={Boolean(errors.street)}
                                    helperText={errors.street}
                                    onFocus={() => handleError('', 'street')}
                                    value={formData?.address?.street}
                                    onChange={(e) => setFormData({ ...formData, address: { ...formData?.address, street: e.target.value } })}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    id="country-select"
                                    options={countryList}
                                    value={countryList.find(s => s.isoCode === formData?.address?.country || '') || null}
                                    onChange={(event, newValue) => handleCountryChange(event, newValue)}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            error={!!errors?.country} helperText={errors?.country} onFocus={() => handleError('', 'country')}
                                            {...params}
                                            label="Select Country"
                                            fullWidth
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    id="state-select"
                                    options={stateList}
                                    value={stateList.find((s) => s.isoCode === formData?.address?.state) || null}
                                    onChange={handleStateChange}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField
                                        error={Boolean(errors.state)}
                                        helperText={errors.state}
                                        onFocus={() => handleError('', 'state')}
                                        {...params} label="Select State" fullWidth />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    id="city-select"
                                    options={cityList}
                                    value={cityList.find((c) => c.name === formData?.address?.city) || null}
                                    onChange={handleCityChange}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params}
                                        error={Boolean(errors.city)}
                                        helperText={errors.city}
                                        onFocus={() => handleError('', 'city')}
                                        label="Select City" fullWidth />}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Zip Code"

                                    value={formData?.address?.zipCode}
                                    error={Boolean(errors.zipCode)}
                                    helperText={errors.zipCode}
                                    onFocus={() => handleError('', 'zipCode')}
                                    onChange={(e) => setFormData({ ...formData, address: { ...formData?.address, zipCode: e.target.value } })}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
                                <Button
                                    variant="outlined"
                                    style={{
                                        textTransform: 'capitalize',
                                        color: primaryColor,
                                        border: `1px solid ${primaryColor}`,
                                        borderRadius: 0,
                                    }}
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    style={{
                                        textTransform: 'capitalize',
                                        color: '#fff',
                                        background: primaryColor,
                                        borderRadius: 0,
                                    }}
                                    onClick={handleSubmit}
                                    fullWidth
                                >
                                    {isUpdate ? 'Edit' : 'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
