import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, Box, CircularProgress } from '@mui/material';
import { get_data } from '../api';

const SubCompanyClaimComponent = ({ clientId }) => {
    const [claimData, setClaimData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchClaimData = async () => {
        try {
            const data = await get_data("claim/get-all-claim-by-sub-client/" + clientId);
            
            if (data.status) {
                setClaimData(data?.data?.claims || []);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchClaimData();
    }, [clientId]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (!claimData || claimData.length === 0) {
        return <Typography>No Claim details found for this client.</Typography>;
    }

    return (
        <Grid container spacing={3}>
            {claimData?.map((returnItem) => (
                <Grid item xs={12} key={returnItem._id}>
                    <Card>
                        <CardContent>
                            <Box mb={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="h6" color="primary">{returnItem.uniqueClaimId}</Typography>
                                <Typography>{new Date(returnItem.createdAt).toLocaleDateString()}</Typography>
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Typography>Request ID: {returnItem.requestId}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography>Claim Reason: {returnItem.claimReason || 'N/A'}</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default SubCompanyClaimComponent;
