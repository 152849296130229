import { useMediaQuery, useTheme } from '@mui/material';
import { BsShop } from 'react-icons/bs';
import { FaMoneyBillWave, FaRegImage } from 'react-icons/fa';
import { PiCursorClick } from 'react-icons/pi';

const Features = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const matches1 = useMediaQuery(theme.breakpoints.down(1100))
    const matches2 = useMediaQuery(theme.breakpoints.down(800));

    const styles = {
        boxStyle: {
            // boxShadow: '4px 4px 44px rgba(0,5,46,.08)',
            padding: matches1 ? '13% 3%' : '3%',
            width: matches1 ? '95%' : '30%',
            textAlign: 'center',
            background: 'white',
            borderRadius: '8px',
            marginBottom: isMobile ? '4%' : ''
        },

        iconStyle: {
            fontSize: '2rem',
            marginBottom: '10px',
        },
    }

    return (
        <div style={{ textAlign: 'center', padding: matches1 ? '3% 5%' : '5% 10%', background: '#EFF5F7' }}>
            <h3 style={{ fontWeight: 500, fontSize: matches1 ? 25 : 30, marginTop: 0 }}>Why choose us for GPS</h3>

            <div style={{ display: 'flex', gap: 30, marginTop: '50px', flexDirection: isMobile ? 'column' : '' }}>

                <div style={styles.boxStyle}>
                    <BsShop style={styles.iconStyle} />
                    <h2 style={{ fontWeight: 500, marginBottom: '2%' }}>Powerfull Connections</h2>
                    <p style={{ opacity: '70%' }}>Once onboarded, list you Shop for free.</p>
                </div>


                <div style={styles.boxStyle}>
                    <FaRegImage style={styles.iconStyle} />
                    <h2 style={{ fontWeight: 500, marginBottom: '2%' }}>Easy to Install</h2>
                    <p style={{ opacity: '70%' }}>Create a Banner Ad by giving few details.</p>
                </div>


                <div style={styles.boxStyle}>
                    <PiCursorClick style={styles.iconStyle} />
                    <h2 style={{ fontWeight: 500, marginBottom: '2%' }}>Easy Claims and Returns</h2>
                    <p style={{ opacity: '70%' }}>Once your Banner Ad is designed by our Team, it will be Live.</p>
                </div>
            </div>
        </div>
    );
};


export default Features;