"use client";

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useState } from 'react';

export default function GoogleMapCard({
    data
}) {
    const [center, setCenter] = useState({ lat: 17.313110, lng: 78.482560 });

    const containerStyle = {
        width: '100%',
        height: '400px'
    };

    return (
        <>
            <div style={{ marginTop: '6%' }}>
                <LoadScript googleMapsApiKey="AIzaSyCY_PgQIZnMhHPDnz-778AOE6_Swa0afc4" libraries={['places']}>
                    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
                        <Marker position={center} />
                    </GoogleMap>
                </LoadScript>
            </div>
        </>
    )
}