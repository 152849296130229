import { useState, useEffect } from "react";
import { get_data } from "../api";
import { Box, Typography, Card, CardContent, Divider, Collapse, Button, Grid, CircularProgress, Pagination } from '@mui/material';
import { primaryColor } from "../constant";
import SearchComponent from "./SearchComponent";

export default function ClientRequestComp({ client }) {
    const [requests, setRequests] = useState([]);
    const [expanded, setExpanded] = useState({});
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);

    const itemsPerPage = 15;
    const count = requests?.length;

    const toggleExpanded = (index) => {
        setExpanded((prevState) => ({ ...prevState, [index]: !prevState[index] }));
    };


    const handleSearch = async () => {
        if (value.length > 0) {
            try {
                const data = await get_data(`request/search-request-by-admin/${value}/${client?._id}?pageNumber=${currentPage}`)
                if (data.status) {
                    setRequests(data?.data?.requests)
                    setTotalPages(data?.data?.totalPages)
                    setTotalData(data?.data?.totalRequests)
                }
            }
            catch (error) {
                console.log('Requests serarch error : ', error)
            }

        } else {
            fetchAllRequestByClient(currentPage)
        }
    }



    const fetchAllRequestByClient = async () => {
        const data = await get_data(`request/get-all-request-by-client/${client?._id}?pageNumber=${currentPage}`);

        if (data.status) {
            setRequests(data?.data?.requests);
            setTotalPages(data?.data?.totalPages)
            setTotalData(data?.data?.totalRequests)
        }
        setLoading(false);
    }

    useEffect(() => {
        if (client?._id || currentPage) {
            fetchAllRequestByClient(currentPage);
        }
    }, [client, currentPage]);


    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (!requests || requests.length === 0) {
        return <Typography style={{ textAlign: 'center' }}>No Request details found for this client.</Typography>;
    }


    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);


    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <Box >
            <div style={{marginBottom:10}}>
                <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch}  />
            </div>
            {/* <Typography variant="h4">Requests for Client</Typography> */}
            {requests?.length > 0 &&
                requests?.map((request, index) => (
                    <Card key={request?._id} sx={{ marginBottom: 2 }}>
                        <CardContent>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="h6" sx={{ color: primaryColor }}>Request ID: {request?.uniqueRequestId}</Typography>
                                <Typography variant="body2">{new Date(request?.createdAt).toLocaleDateString()}</Typography>
                            </Box>
                            <Divider sx={{ marginY: 1 }} />

                            {/* Basic Details */}
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="body1">Tracking ID:</Typography>
                                    <Typography variant="body2">{request?.trackingId || "N/A"}</Typography>
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <Typography variant="body1">Total Amount:</Typography>
                                    <Typography variant="body2">&#8377;{request?.totalAmount || "N/A"}</Typography>
                                </Grid> */}
                            </Grid>

                            <Box mt={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">Company Name:</Typography>
                                        <Typography variant="body2">{request?.customerDetails?.companyName || "N/A"}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">Contact No:</Typography>
                                        <Typography variant="body2">{request?.customerDetails?.contactNo || "N/A"}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">City:</Typography>
                                        <Typography variant="body2">{request?.customerDetails?.address?.city || "N/A"}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* GPS Info */}
                            <Button onClick={() => toggleExpanded(index)} sx={{ marginTop: 2 }}>
                                {expanded[index] ? 'Hide' : 'Show'} GPS Info
                            </Button>
                            <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                                {request?.gps.map((gpsItem, idx) => (
                                    <Box key={idx} mt={2}>
                                        {/* Device Details */}
                                        <Typography variant="h6">Device Details</Typography>
                                        <Divider sx={{ marginY: 1 }} />

                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">Device Model:</Typography>
                                                <Typography variant="body2">{gpsItem.deviceDetails?.deviceModel}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">IMEI No:</Typography>
                                                <Typography variant="body2">{gpsItem.deviceDetails?.imeiNo}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">Sim No:</Typography>
                                                <Typography variant="body2">{gpsItem.deviceDetails?.simNo}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">Network Provider:</Typography>
                                                <Typography variant="body2">{gpsItem.deviceDetails?.networkProvider}</Typography>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Typography variant="body1">Network:</Typography>
                                                <Typography variant="body2">{gpsItem.deviceDetails?.network?.airtel ? "Airtel" :
                                                    gpsItem.deviceDetails?.network?.vi ? "vi" :
                                                        gpsItem.deviceDetails?.network?.bsnl ? "BSNL" :
                                                            "Jio"
                                                }</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">Plan Type:</Typography>
                                                <Typography variant="body2">{gpsItem.deviceDetails?.planType}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">Relay:</Typography>
                                                <Typography variant="body2">{gpsItem.deviceDetails?.relay}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">Date of Install:</Typography>
                                                <Typography variant="body2">{new Date(gpsItem.deviceDetails?.dateOfInstall).toLocaleDateString()}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">Amount:</Typography>
                                                <Typography variant="body2">{gpsItem.deviceDetails?.amount}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">Panic Button:</Typography>
                                                <Typography variant="body2">{gpsItem.deviceDetails?.panicButton ? 'Yes' : 'No'}</Typography>
                                            </Grid>
                                        </Grid>

                                        {/* Driver Details */}
                                        <Box mt={2}>
                                            <Typography variant="h6">Driver Details</Typography>
                                            <Divider sx={{ marginY: 1 }} />

                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1">Driver Name:</Typography>
                                                    <Typography variant="body2">{gpsItem.driverDetails?.fullName || "N/A"}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1">Contact No:</Typography>
                                                    <Typography variant="body2">{gpsItem.driverDetails?.contactNo || "N/A"}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        {/* Vehicle Details */}
                                        <Box mt={2}>
                                            <Typography variant="h6">Vehicle Details</Typography>
                                            <Divider sx={{ marginY: 1 }} />      <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1">Registration No:</Typography>
                                                    <Typography variant="body2">{gpsItem.vehicleDetails?.regnNumber || "N/A"}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1">Plate:</Typography>
                                                    <Typography variant="body2">{gpsItem.vehicleDetails?.plate || "N/A"}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1">Plate:</Typography>
                                                    <Typography variant="body2">{gpsItem.vehicleDetails?.regnOwnerName || "N/A"}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1">Registration Owner Contact:</Typography>
                                                    <Typography variant="body2">{gpsItem.vehicleDetails?.regnOwnerContactNo || "N/A"}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1">Vehicle Type:</Typography>
                                                    <Typography variant="body2">{gpsItem.vehicleDetails?.typeOfVehicle || "N/A"}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1">Chassis Number:</Typography>
                                                    <Typography variant="body2">{gpsItem.vehicleDetails?.chassisNumber || "N/A"}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box mt={2}>
                                            <Typography variant="h6" >Installer Details</Typography>
                                            <Divider sx={{ marginY: 1 }} />
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1">Installer Name:</Typography>
                                                    <Typography variant="body2">{gpsItem.installerDetails?.fullName || "N/A"}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1">Contact No:</Typography>
                                                    <Typography variant="body2">{gpsItem.installerDetails?.contactNo || "N/A"}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                ))}
                            </Collapse>
                        </CardContent>
                    </Card>
                ))}

            {requests?.length > 0 && (
                // <Card>
                    <div
                        style={{
                            borderTop: "1px solid gainsboro",
                            padding: "2%",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            style={{
                                fontWeight: 500,
                                fontSize: 15,
                                color: "black",
                            }}
                        >{`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}</Typography>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </div>
                // </Card>
            )}
        </Box>
    )
}