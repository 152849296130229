import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

function CardSkeleton() {
    return (
        <Box>
            <Skeleton variant="rectangular" width={'100%'} height={200} />
            <Box sx={{ pt: 0.5, widt: '100%' }}>
                <Skeleton height={30} />
                <Skeleton width="60%" height={30} />
            </Box>
        </Box>
    );
}

export default function ProductCardSkeleton() {
    return (
        <Grid container spacing={3}>
            <Grid item xs>
                <CardSkeleton />
            </Grid>
            <Grid item xs>
                <CardSkeleton />
            </Grid>
            <Grid item xs>
                <CardSkeleton />
            </Grid>
            <Grid item xs>
                <CardSkeleton />
            </Grid>
        </Grid>
    );
}
