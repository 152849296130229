import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Button, Divider, Pagination, Card, CardContent, Grid2, Tabs, Tab } from '@mui/material';
import { get_data, post_data, serverURL } from '../api';
import InventoryUpdate from './InventoryUpdate';
import { createButtonBg, primaryColor } from '../constant';
import { IoMdAdd } from "react-icons/io";
import AllInventory from './AllInventory';
import StockInventory from './StockInventory';
import NoDataInventory from './NoDataInventory';
import InActiveInventory from './InActiveInventory';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InventoryExportModal from './models/InventroyExportModal';
import ActiveInventory from './ActiveInventory';
// A reusable component for displaying label and value pairs
const DetailItem = ({ label, value }) => (
    <Grid item xs={12} sm={6} md={4}>
        <Typography variant="subtitle2" color="textSecondary">{label}</Typography>
        <Typography variant="body1">{value || 'N/A'}</Typography>
    </Grid>
);


export default function ClientInventoryComp({ clientId }) {
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [value, setValue] = useState('')
    const [open, setOpen] = useState(false);
    const [tab, setTab] = useState(0);
    const itemsPerPage = 15;
    const count = data?.length;
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [fromDate, setFromDate] = useState([])
    const [toDate, setToDate] = useState([])
    const [inventoryData, setInventoryData] = useState([]);

    const rangePickerStyle = {
        display: 'flex',
        cursor: 'pointer',
        fontSize: 14,
        background: 'white',
        padding: '0% 0% 0% 0%',
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        width: 250,
        color: '#7E8299',
        cursor: 'pointer'
    };

    const fetchExportData = async (search) => {
        try {
            const fromDate = moment(startDate).toISOString();
            const toDate = moment(endDate).toISOString();

            let response = await post_data(`inventory/get-inventory-by-date/${tab}/${clientId}`, {
                from: fromDate,
                to: toDate
            })

            if (response?.status) {
                setInventoryData(response?.data?.inventory)
                setOpen(true)
                setFromDate(response?.data?.fromDate)
                setToDate(response?.data?.toDate)
            } else {

            }
        } catch (error) {
        }
    };

    const handleDateChange = (dates) => {
        if (dates && dates?.length === 2) {
            setDateRange(dates);
        }
    };

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };


    return (
        <Grid container spacing={3}>

            <InventoryExportModal open={open} setOpen={setOpen} fromDate={fromDate} toDate={toDate} data={inventoryData} />

            <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div>
                    <Typography variant="h6">Total Inventories</Typography>
                    <Typography variant="h4">{totalData}</Typography>
                </div>


                <div style={{ display: 'flex', gap: 10, height: 40 }}>
                    <div style={{ ...rangePickerStyle, border: 'none', }}>
                        <DatePicker
                            selected={startDate}
                            onChange={handleDateChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            dateFormat="dd-MM-yyyy"
                            maxDate={new Date()}
                            customInput={
                                <button style={{ border: 'none', background: '#fff', cursor: 'pointer', }}>
                                    {startDate && endDate ? `${moment(startDate).format('DD-MM-YYYY')} to ${moment(endDate).format('DD-MM-YYYY')}` : 'Select Date Range'}
                                </button>
                            }
                        />
                    </div>
                    <Button
                        onClick={fetchExportData}
                        variant="contained"
                        style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                        disabled={dateRange?.length === 0}
                    >
                        Export
                    </Button>
                </div>
            </div>

            <Grid item xs={12}>
                <Tabs value={tab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                    <Tab label="All" />
                    <Tab label="Active" />
                    <Tab label="In Active" />
                    <Tab label="No Data" />
                    <Tab label="Stock" />
                </Tabs>

                <Divider />
                <div style={{ marginTop: 10 }}>
                    {tab === 0 && <AllInventory totalData={totalData} setTotalData={setTotalData} clientId={clientId} />}
                    {tab === 1 && <ActiveInventory totalData={totalData} setTotalData={setTotalData} clientId={clientId} />}
                    {tab === 2 && <InActiveInventory totalData={totalData} setTotalData={setTotalData} clientId={clientId} />}
                    {tab === 3 && <NoDataInventory totalData={totalData} setTotalData={setTotalData} clientId={clientId} />}
                    {tab === 4 && <StockInventory totalData={totalData} setTotalData={setTotalData} clientId={clientId} />}
                </div>
            </Grid>

        </Grid >
    );
};
