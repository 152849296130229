import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Menu,
    MenuItem,
    IconButton,
    Avatar,
    Typography,
    Pagination,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Swal from 'sweetalert2';
import { post_data, serverURL } from '../../api'; // Ensure serverURL is correctly imported
import { useNavigate } from 'react-router-dom';
import TableLoader from './TableLoader';
import { Toaster, toast } from 'react-hot-toast';
import { formatPrice, primaryColor } from '../../constant';
import ServiceRequestModals from '../models/ServiceRequestModals';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
    },
    stageButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        '&.delivered': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.processing': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});


const dummy = [
    {

        vehicleNo: "AB123CD",
        driverName: "John Doe",
        driverAddress: "123 Main St, Springfield",
        driverPhone: "+1234567890",
        imeiNo: "357289063547895"
    },
    {
        "vehicleNo": "EF456GH",
        "driverName": "Jane Smith",
        "driverAddress": "456 Elm St, Shelbyville",
        "driverPhone": "+0987654321",
        "imeiNo": "358374092845637"
    },
    {
        "vehicleNo": "IJ789KL",
        "driverName": "Alice Johnson",
        "driverAddress": "789 Oak St, Ogdenville",
        "driverPhone": "+1122334455",
        "imeiNo": "356789013467852"
    },
    {
        "vehicleNo": "MN012OP",
        "driverName": "Bob Williams",
        "driverAddress": "101 Pine St, Capital City",
        "driverPhone": "+2233445566",
        "imeiNo": "359087451236589"
    },
    {
        "vehicleNo": "QR345ST",
        "driverName": "Eve Brown",
        "driverAddress": "202 Maple St, North Haverbrook",
        "driverPhone": "+3344556677",
        "imeiNo": "357298156739084"
    }
]

const ServiceRequestTable = ({
    data,
    tableHeader,
    getServiceRequestData,
    setLoading,
    totalData,
    loading,
    currentPage,
    setCurrentPage,
    itemsPerPage,
    totalPages,
}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [rowData, setRowData] = useState(data)
    const [currentRow, setCurrentRow] = useState(null);
    const [open, setOpen] = useState(false)
    const [editData, setEditData] = useState('')

    useEffect(() => {
        setRowData(data)
    }, [data])

    // Handle menu open
    const handleMenuOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(index);
    };

    // Handle menu close
    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentRow(null);
    };

    // Edit product
    const handleEdit = (row) => {
        setEditData(row)
        setOpen(true)
        setAnchorEl(null);
        setCurrentRow(null);
    };

    // Delete product with confirmation
    const handleDelete = async (row) => {
        setAnchorEl(null);
        setCurrentRow(null);

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: primaryColor,
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await post_data(`service-request/delete-service-request/${row?._id}`, {});
                    if (response.status === true) {
                        toast.success("Sales Deleted Successfully");
                        getServiceRequestData();
                    } else {
                        toast.error("Something Went Wrong");
                    }
                } catch (error) {
                    // toast.error("An error occurred while deleting the sales.");
                }
            }
        });
    };

    // Handle page change for pagination
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);

    console.log("data", rowData)

    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            <TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
                <Table className={classes.root}>
                    <TableHead>
                        <TableRow>
                            {tableHeader?.map((item, idx) => (
                                <TableCell key={idx} style={{ borderRight: '1px solid gainsboro', textAlign: 'center', padding: '16px 10px' }}>
                                    {item}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {loading ? (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={tableHeader.length} align="center">
                                    <TableLoader />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ) : (
                        <TableBody>
                            {rowData?.length > 0 ? (
                                rowData?.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ textAlign: 'center', borderRight: '1px solid gainsboro', width: '5%' }} >{startEntry + index}</TableCell>
                                        <TableCell style={{ textAlign: 'center', borderRight: '1px solid gainsboro', width: '10%' }}>{row?.uniqueServiceRequestId || '-'}</TableCell>
                                        <TableCell style={{ textAlign: 'center', borderRight: '1px solid gainsboro', width: '10%' }}>{row?.vehicleNo || '-'}</TableCell>
                                        <TableCell style={{ textAlign: 'center', borderRight: '1px solid gainsboro', width: '10%' }}>{row?.imeiNo || '-'}</TableCell>
                                        <TableCell style={{ textAlign: 'center', borderRight: '1px solid gainsboro', width: '15%' }}>{row?.driverPhoneNo || "-"}</TableCell>
                                        <TableCell style={{ textAlign: 'center', borderRight: '1px solid gainsboro', width: '15%' }}> {row?.driverName || '-'} </TableCell>
                                        <TableCell style={{ textAlign: 'center', borderRight: '1px solid gainsboro', width: '40%' }}>
                                            {[
                                                row?.driverAddress?.street,
                                                row?.driverAddress?.city,
                                                row?.driverAddress?.state,
                                                row?.driverAddress?.pincode
                                            ]
                                                .filter(Boolean)
                                                .join(' ')}
                                        </TableCell>


                                        {/* <TableCell style={{ textAlign: 'center', borderRight: '1px solid gainsboro' }}>
                                            <Button
                                                className={`${classes.stageButton} ${row?.orderStatus === 'delivered' ? 'delivered' : 'processing'}`}
                                            >
                                                {row?.orderStatus}
                                            </Button>
                                        </TableCell> */}
                                        <TableCell style={{ textAlign: 'center', borderRight: '1px solid gainsboro' }}>
                                            <IconButton className={classes.moreIcon} onClick={(event) => handleMenuOpen(event, index)}>
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl) && currentRow === index}
                                                onClose={handleMenuClose}
                                            >
                                                <MenuItem onClick={() => handleEdit(row)}>Edit</MenuItem>
                                                <MenuItem onClick={() => handleDelete(row)}>Delete</MenuItem>
                                            </Menu>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={tableHeader.length} align="center">
                                        <Typography>No products found.</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    )}
                </Table>
                <ServiceRequestModals editData={editData} getServiceRequestData={getServiceRequestData} isUpdate={true} open={open} setOpen={setOpen} />
                {rowData?.length > 0 && (
                    <div style={{ borderTop: "1px solid gainsboro", padding: "2%", display: "flex", justifyContent: "space-between" }}>
                        <Typography style={{ fontWeight: 500, fontSize: 15, color: "black" }}>
                            {`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}
                        </Typography>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </div>
                )}
            </TableContainer>
        </>
    );
};

export default ServiceRequestTable;
