
import { Button, Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import { useEffect, useState } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { post_data, get_data } from " ../../api";
// import { SearchComponent } from "./SearchComponent";
// import { EmptyPage } from "./EmptyPage";
import ClientsInventoryTable from "./tables/ClientsInventoryTable";
import { get_data, post_data } from "../api";
import SearchComponent from "./SearchComponent";
import { createButtonBg } from "../constant";
import AllInventory from "./AllInventory";
import StockInventory from "./StockInventory";
import NoDataInventory from "./NoDataInventory";
import InActiveInventory from "./InActiveInventory";
import ActiveInventory from "./ActiveInventory";

const rangePickerStyle = {
    display: 'flex',
    cursor: 'pointer',
    fontSize: 14,
    background: 'white',
    padding: '0% 0% 0% 0%',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    color: '#7E8299',
    cursor: 'pointer'
};

export default function ShowInventory() {
    const navigate = useNavigate()
    const page = { width: '100%', marginBottom: '2%' };
    const { user_data } = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [value, setValue] = useState('')
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [open, setOpen] = useState(false)
    const [fromDate, setFromDate] = useState([])
    const [toDate, setToDate] = useState([])
    const [claimsData, setClaimsData] = useState([])
    const [tab, setTab] = useState(0);

    const itemsPerPage = 15;
    const count = data?.length;


    // const tableHeader = [
    //     "S No.", "Vehicle Number", "Sim Number", "Imei Number", "Device Unique Id", "Gps Install Date", "Gps Status"
    // ]

    // const getInvenrotyData = async () => {
    //     try {
    //         const data = await get_data(`inventory/get-inventory-by-client/${user_data?._id}?pageNumber=${currentPage}`);

    //         if (data.status === true) {
    //             setData(data?.data?.inventory || []);
    //             setTotalPages(data?.data?.totalPages);
    //             setTotalData(data?.data?.totalInventory);
    //         }
    //     } catch (error) {
    //         setLoading(false);
    //     }
    // }

    // useEffect(() => {
    //     if (currentPage) {
    //         getInvenrotyData(currentPage);
    //     }
    // }, [currentPage])

    // useEffect(() => {
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 5000);
    // }, []);

    // const handleSearch = async () => {
    //     setLoading(true);
    //     if (value.length > 0) {
    //         let data
    //         data = await get_data(`inventory/search-inventory-by-admin/${value}/${user_data?._id}?pageNumber=${currentPage}`)
    //         console.log("xxxxxxxxxx", data)
    //         if (data.status) {
    //             setData(data?.data?.inventory || []);
    //             setTotalPages(data?.data?.totalPages);
    //             setTotalData(data?.data?.totalInventory);
    //         }
    //     } else {
    //         getInvenrotyData(currentPage);
    //     }
    //     setLoading(false);
    // }


    // const handleDateChange = (dates) => {
    //     if (dates && dates?.length === 2) {
    //         setDateRange(dates);
    //     }
    // };

    // const fetchExportData = async () => {
    //     try {

    //         const fromDate = moment(startDate).toISOString();
    //         const toDate = moment(endDate).toISOString();
    //         let response
    //         user_data?.isSubClient ?
    //             response = await post_data('claim/get-claims-by-date-for-sub-client', {
    //                 from: fromDate,
    //                 to: toDate
    //             }) :
    //             response = await post_data('claim/get-claims-by-date-for-client', {
    //                 from: fromDate,
    //                 to: toDate
    //             })

    //         if (response?.status) {
    //             setClaimsData(response?.data?.claim)
    //             setData(response?.data?.claim)
    //             setOpen(true)
    //             setFromDate(response?.data?.fromDate)
    //             setToDate(response?.data?.toDate)
    //         } else {

    //         }
    //     } catch (error) {
    //     }
    // };

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <>
            <Grid item xs={12}>
                <div>
                    <Typography variant="h6">Total Inventories</Typography>
                    <Typography variant="h4">{totalData}</Typography>
                </div>

                <Tabs value={tab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                    <Tab label="All" />
                    <Tab label="Active" />
                    <Tab label="In Active" />
                    <Tab label="No Data" />
                    <Tab label="Stock" />
                </Tabs>

                <Divider />
                <div style={{ marginTop: 10 }}>
                    {tab === 0 && <AllInventory totalData={totalData} setTotalData={setTotalData} clientId={user_data?._id} />}
                    {tab === 1 && <ActiveInventory totalData={totalData} setTotalData={setTotalData} clientId={user_data?._id} />}
                    {tab === 2 && <InActiveInventory totalData={totalData} setTotalData={setTotalData} clientId={user_data?._id} />}
                    {tab === 3 && <NoDataInventory totalData={totalData} setTotalData={setTotalData} clientId={user_data?._id} />}
                    {tab === 4 && <StockInventory totalData={totalData} setTotalData={setTotalData} clientId={user_data?._id} />}
                </div>
            </Grid>
        </>
    )
}



