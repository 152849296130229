import Header from "../../components/Header";
import Footer from "../../components/Footer";
import RecordForm from "../../components/RecordForm";

export default function Record() {

    return (
        <>
            <Header />
            <RecordForm />
            <Footer />
        </>
    )
}