import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { State, City } from 'country-state-city';
import { IoMdAdd } from "react-icons/io";
import { createButtonBg, primaryColor } from '../../constant';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import { post_data } from '../../api';
import toast, { Toaster } from 'react-hot-toast';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '7px',
    border: '0px solid #fff',
    p: 4,
};

export default function AddAdminSubVendorModals({ title, updateData, editOpen, setEditOpen, isUpdate }) {
    // console.log('updateData', updateData)
    const [addVendorModalOpen, setAddVendorModalOpen] = useState(false);
    const [name, setName] = useState('');
    const [street, setStreet] = useState('');
    const [landmark, setLandmark] = useState('');
    const [countryCode] = useState('IN'); // Assuming only India for simplicity
    const [stateList, setStateList] = useState([]);
    const [state, setState] = useState('');
    const [cityList, setCityList] = useState([]);
    const [city, setCity] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [errors, setErrors] = useState({});
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')

    useEffect(() => {
        if (updateData) {
            setName(updateData?.name || '');
            setPhone(updateData?.phone || '');
            setEmail(updateData?.email || '');
            setStreet(updateData?.address?.street || '');
            setLandmark(updateData?.address?.landmark || '');
            setState(updateData?.address?.state || '');
            setCity(updateData?.address?.city || '');
            setZipCode(updateData?.address?.zipCode || '');
        }
    }, [updateData]);


    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const validation = () => {
        let error = false;
        if (!name) {
            error = true;
            handleError('Please input  Name...', 'name');
        }
        if (!street) {
            error = true;
            handleError('Please input Street...', 'street');
        }
        if (!state) {
            error = true;
            handleError('Please input State...', 'state');
        }
        if (!city) {
            error = true;
            handleError('Please input City...', 'city');
        }
        if (!zipCode) {
            error = true;
            handleError('Please input Zip Code...', 'zipCode');
        }
        if (!phone) {
            error = true;
            handleError('Please input Phone number...', 'phone');
        }
        if (!email) {
            error = true;
            handleError('Please input Email Id...', 'email');
        }
        return error;
    };

    const handleVendorModal = () => {
        setAddVendorModalOpen(true);
    };


    const handleClose = () => {
        isUpdate ? setEditOpen(false) : setAddVendorModalOpen(false);
        setErrors({}); // Clear errors on close
        setName('')
        setStreet('')
        setState('')
        setCity('')
        setZipCode('')
    };

    useEffect(() => {
        setStateList(State.getStatesOfCountry(countryCode));
    }, [countryCode]);

    useEffect(() => {
        if (state) {
            const fetchedCities = City.getCitiesOfState(countryCode, state);
            setCityList(fetchedCities);
        }
    }, [state, countryCode]);

    const handleSubmit = async () => {
        const error = validation();
        if (!error) {
            const formData = { name: name, email: email, phone: phone, address: { street: street, landmark: landmark, state: state, city: city, zipCode: zipCode } }
            if (isUpdate) {
                let response = await post_data(`sub-vendor/update-sub-vendor/${updateData?._id}`, formData)
                // console.log('response', response.status)
                if (response.status) {

                    toast.success('Sub Vendor Update Successfully')
                    setEditOpen(false)
                } else {
                    toast.error('Somthing Worng !')
                }
            } else {
                let response = await post_data(`sub-vendor/create-sub-vendor`, formData)
                // console.log('response', response)
                if (response.status) {
                    toast.success(response.message)
                    setAddVendorModalOpen(false);
                } else {
                    toast.error('Somthing Worng !')
                }
            }

        }


    };

    const handleStateChange = (event, newValue) => {
        if (newValue) {
            setState(newValue.isoCode);
            const fetchedCities = City.getCitiesOfState(countryCode, newValue.isoCode || state);
            setCityList(fetchedCities);
            setCity(''); // Reset city when state changes
        }
    };

    const handleCityChange = (event, newValue) => {
        if (newValue) {
            setCity(newValue.name);
        }
    };

    return (
        <div>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            {isUpdate ? <></> :
                <Button
                    startIcon={<IoMdAdd style={{ color: 'white' }} />}
                    variant="contained"
                    onClick={handleVendorModal}
                    style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                >
                    Create
                </Button>}

            <Modal open={isUpdate ? editOpen : addVendorModalOpen} onClose={handleClose}>
                <Box sx={style}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Add Sub Vendor
                    </Typography>
                    <Grid container spacing={2} style={{ marginTop: 10 }}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                error={Boolean(errors.name)}
                                helperText={errors.name}
                                onFocus={() => handleError('', 'name')}
                                value={name}
                                label="Sub Vendor Name"
                                onChange={(e) => setName(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                error={Boolean(errors.email)}
                                helperText={errors.email}
                                onFocus={() => handleError('', 'email')}
                                value={email}
                                type='email'
                                label="Email Id"
                                onChange={(e) => setEmail(e.target.value)}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                error={Boolean(errors.phone)}
                                helperText={errors.phone}
                                onFocus={() => handleError('', 'phone')}
                                value={phone}
                                 
                                label="Phone Number"
                                onChange={(e) => setPhone(e.target.value)}
                                fullWidth
                            />
                        </Grid>



                        <Grid item xs={12}>
                            <Typography component="h1">Address</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Street"
                                error={Boolean(errors.street)}
                                helperText={errors.street}
                                onFocus={() => handleError('', 'street')}
                                value={street || updateData?.street}
                                onChange={(e) => setStreet(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Landmark"
                                // error={Boolean(errors.landmark)}
                                // helperText={errors.landmark}
                                // onFocus={() => handleError('', 'landmark')}
                                value={landmark || updateData?.landmark}
                                onChange={(e) => setLandmark(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                id="state-select"
                                options={stateList}
                                value={stateList.find((s) => s.isoCode === state) || null}
                                onChange={handleStateChange}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField
                                    error={Boolean(errors.state)}
                                    helperText={errors.state}
                                    onFocus={() => handleError('', 'state')}
                                    {...params} label="Select State" fullWidth />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                id="city-select"
                                options={cityList}
                                value={cityList.find((c) => c.name === city) || null}
                                onChange={handleCityChange}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params}
                                    error={Boolean(errors.city)}
                                    helperText={errors.city}
                                    onFocus={() => handleError('', 'city')}
                                    label="Select City" fullWidth />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Zip Code"
                                 
                                value={zipCode || updateData?.zipCode}
                                error={Boolean(errors.zipCode)}
                                helperText={errors.zipCode}
                                onFocus={() => handleError('', 'zipCode')}
                                onChange={(e) => setZipCode(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
                            <Button variant="outlined" style={{ textTransform: 'capitalize', color: primaryColor, border: `1px solid ${primaryColor}`, borderRadius: 0 }} fullWidth onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button style={{ textTransform: 'capitalize', color: '#fff', background: primaryColor, borderRadius: 0 }} onClick={handleSubmit} fullWidth>
                                {isUpdate ? "Update" : 'Add'}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
