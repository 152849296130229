import React, { useEffect } from 'react';
import { Box, Grid, Typography, Paper, Divider } from '@mui/material';

// A reusable component for displaying label and value pairs
const DetailItem = ({ label, value }) => (
    <Grid item xs={12} sm={6} md={4}>
        <Typography variant="subtitle2" color="textSecondary">{label}</Typography>
        <Typography variant="body1">{value || 'N/A'}</Typography>
    </Grid>
);

export default function SubCompanyDetailComponent({ client }) {


    return (
        <Box>
            <Paper sx={{ p: 2 }}>
                <Typography variant="h5" gutterBottom>
                    Sub Company Details
                </Typography>
                <Divider sx={{ mb: 2 }} />

                <Grid container spacing={2}>
                    {/* Client Basic Information */}
                    <DetailItem label="Sub Company Name" value={client.companyName} />
                    <DetailItem label="Unique Sub Company ID" value={client.uniqueClientId} />
                    <DetailItem label="Contact No" value={client.contactNo} />
                    <DetailItem label="Email" value={client.email} />
                    <DetailItem label="Residential Contact No" value={client.resContactNo} />
                    
                    {/* Address Information */}
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            Address
                        </Typography>
                        <Divider sx={{ mb: 1 }} />
                    </Grid>
                    <DetailItem label="Street" value={client.address?.street} />
                    <DetailItem label="Landmark" value={client.address?.landmark} />
                    <DetailItem label="City" value={client.address?.city} />
                    <DetailItem label="Zip Code" value={client.address?.zipCode} />
                    <DetailItem label="State" value={client.address?.state} />
                    
                    {/* Sub Vendors */}
                    {client.subVendors && client.subVendors.length > 0 && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    Sub Vendors
                                </Typography>
                                <Divider sx={{ mb: 1 }} />
                            </Grid>
                            {client.subVendors.map((vendor, index) => (
                                <Grid key={index} container spacing={2}>
                                    <DetailItem label="Vendor Name" value={vendor.name} />
                                    <DetailItem label="Vendor Phone" value={vendor.phone} />
                                    <DetailItem label="Vendor Street" value={vendor.address?.street} />
                                    <DetailItem label="Vendor City" value={vendor.address?.city} />
                                </Grid>
                            ))}
                        </>
                    )}

                    {/* Sites Information */}
                    {client.sites && client.sites.length > 0 && (
                        <>
                            <Grid item xs={12} >
                                <Typography variant="h6" gutterBottom>
                                    Site Addresses
                                </Typography>
                                <Divider sx={{ mb: 1 }} />
                            </Grid>
                            {client.sites.map((site, index) => (
                                <Grid key={index} item xs={12} style={{display:'flex',flexWrap:'wrap'}} >
                                    <DetailItem label="Site Street" value={site.address?.street} />
                                    <DetailItem label="Site City" value={site.address?.city} />
                                    <DetailItem label="Site Zip Code" value={site.address?.zipCode} />
                                </Grid>
                            ))}
                        </>
                    )}

                    {/* Other Information */}
                    <DetailItem label="Client Status" value={client.isClient ? 'Active' : 'Inactive'} />
                    <DetailItem label="Created At" value={new Date(client.createdAt).toLocaleDateString()} />
                </Grid>
            </Paper>
        </Box>
    );
}
