import Header from "../../components/Header";
import Footer from "../../components/Footer";
import UninstallForm from "../../components/UninstallForm";


export default function Uninstall() {

    return (
        <>
            <Header />
            <UninstallForm />
            <Footer />
        </>
    )
}