// import * as React from 'react';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
// import { Country, State, City } from 'country-state-city';
// import { IoMdAdd } from "react-icons/io";
// import { createButtonBg, primaryColor } from '../constant';
// import { Autocomplete, Button, Grid, TextField } from '@mui/material';
// import { useState, useEffect } from 'react';

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     borderRadius: '7px',
//     p: 4,
// };

// export default function AddRequestsModal({ title,row ,handleMenuClose}) {
//     const [addVendorModalOpen, setAddVendorModalOpen] = useState(false);
//     const [vendorName, setVendorName] = useState('');
//     const [street, setStreet] = useState('');
//     const [countryCode] = useState('IN'); // Assuming only India for simplicity
//     const [stateList, setStateList] = useState([]);
//     const [state, setState] = useState('');
//     const [cityList, setCityList] = useState([]);
//     const [city, setCity] = useState('');
//     const [zipCode, setZipCode] = useState('');
//     const [errors, setErrors] = useState({});

//     const handleError = (error, label) => {
//         setErrors((prev) => ({ ...prev, [label]: error }));
//     };

//     const validation = () => {
//         let error = false;
//         if (!vendorName) {
//             error = true;
//             handleError('Please input Vendor Name...', 'vendorName');
//         }
//         if (!street) {
//             error = true;
//             handleError('Please input Street...', 'street');
//         }
//         if (!state) {
//             error = true;
//             handleError('Please input State...', 'state');
//         }
//         if (!city) {
//             error = true;
//             handleError('Please input City...', 'city');
//         }
//         if (!zipCode) {
//             error = true;
//             handleError('Please input Zip Code...', 'zipCode');
//         }
//         return error;
//     };

//     const handleVendorModal = () => {
//         setAddVendorModalOpen(true);
//         handleMenuClose()
       
//     };

//     const handleClose = () => setAddVendorModalOpen(false);

//     useEffect(() => {
//         setStateList(State.getStatesOfCountry(countryCode));
//     }, [countryCode]);

//     const handleSubmit = () => {
//         const error = validation();
//         if (!error) {
//             const body = { vendorName, street, state, city, zipCode };
//             handleClose(); // Close the modal after submission
//         }
//     };

//     const handleStateChange = (event, newValue) => {
//         if (newValue) {
//             setState(newValue.isoCode);
//             const fetchedCities = City.getCitiesOfState(countryCode, newValue.isoCode);
//             setCityList(fetchedCities);
//             setCity(''); // Reset city when state changes
//         }
//     };

//     const handleCityChange = (event, newValue) => {
//         if (newValue) {
//             setCity(newValue.name);
//         }
//     };

//     console.log('row',row)
//     return (
//         <div>
//           <div onClick={handleVendorModal}>View Details</div>

//             <Modal open={addVendorModalOpen} onClose={handleClose}>
//                 <Box sx={style}>
//                     <Typography id="modal-title" variant="h6" component="h2">
//                         View Details
//                     </Typography>
//                     <Grid container spacing={2} style={{ marginTop: 10 }}>
//                         <Grid item xs={12}>
//                             <TextField
//                                 error={!!errors.vendorName}
//                                 helperText={errors.vendorName}
//                                 onFocus={() => handleError('', "vendorName")}
//                                 value={vendorName}
//                                 label="Vendor Name"
//                                 onChange={(e) => setVendorName(e.target.value)}
//                                 fullWidth
//                             />
//                         </Grid>
//                         {/* <Grid item xs={12}>
//                             <Typography component="h1">Address</Typography>
//                         </Grid> */}
//                         {/* <Grid item xs={12}>
//                             <TextField
//                                 label='Street'
//                                 error={!!errors.street}
//                                 helperText={errors.street}
//                                 onFocus={() => handleError('', "street")}
//                                 value={street}
//                                 onChange={(e) => setStreet(e.target.value)}
//                                 fullWidth
//                             />
//                         </Grid> */}
//                         {/* <Grid item xs={12}>
//                             <Autocomplete
//                                 id="state-select"
//                                 options={stateList}
//                                 value={stateList.find(s => s.isoCode === state) || null}
//                                 onChange={handleStateChange}
//                                 getOptionLabel={(option) => option.name}
//                                 renderInput={(params) => <TextField {...params} label="Select State" fullWidth />}
//                             />
//                         </Grid> */}
//                         {/* <Grid item xs={12}>
//                             <Autocomplete
//                                 id="city-select"
//                                 options={cityList}
//                                 value={cityList.find(c => c.name === city) || null}
//                                 onChange={handleCityChange}
//                                 getOptionLabel={(option) => option.name}
//                                 renderInput={(params) => <TextField {...params} label="Select City" fullWidth />}
//                             />
//                         </Grid> */}
//                         {/* <Grid item xs={12}>
//                             <TextField
//                                 label='Zip Code'
//                                  
//                                 error={!!errors.zipCode}
//                                 helperText={errors.zipCode}
//                                 onFocus={() => handleError('', "zipCode")}
//                                 onChange={(e) => setZipCode(e.target.value)}
//                                 fullWidth
//                             />
//                         </Grid> */}
//                         <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
//                             <Button variant="outlined" style={{ textTransform: 'capitalize', color: primaryColor, border: `1px solid ${primaryColor}`, borderRadius: 0 }} fullWidth onClick={handleClose}>Cancel</Button>
//                             <Button style={{ textTransform: 'capitalize', color: "#fff", background: primaryColor, borderRadius: 0 }} onClick={handleSubmit} fullWidth>Add</Button>
//                         </Grid>
//                     </Grid>
//                 </Box>
//             </Modal>
//         </div>
//     );
// }






import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IoMdAdd } from "react-icons/io";
import { createButtonBg, primaryColor } from '../constant';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import { State, City } from 'country-state-city'; // Assuming only India for simplicity

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '7px',
    p: 4,
};

export default function AddRequestsModal({ title, row, handleModalClose, handleModalOpen, addRequestsModalOpen }) {
    const [vendorName, setVendorName] = useState('');
    const [street, setStreet] = useState('');
    const [stateList, setStateList] = useState([]);
    const [state, setState] = useState('');
    const [cityList, setCityList] = useState([]);
    const [city, setCity] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [errors, setErrors] = useState({});

    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };
    const validation = () => {
        let error = false;
        if (!vendorName) {
            error = true;
            handleError('Please input Vendor Name...', 'vendorName');
        }
        if (!street) {
            error = true;
            handleError('Please input Street...', 'street');
        }
        if (!state) {
            error = true;
            handleError('Please input State...', 'state');
        }
        if (!city) {
            error = true;
            handleError('Please input City...', 'city');
        }
        if (!zipCode) {
            error = true;
            handleError('Please input Zip Code...', 'zipCode');
        }
        return error;
    };

    const handleSubmit = () => {
        const hasError = validation();
        if (!hasError) {
            const body = { vendorName, street, state, city, zipCode };
            resetForm();
            handleModalClose(); // Close the modal after submission
        }
    };

    const resetForm = () => {
        setVendorName('');
        setStreet('');
        setState('');
        setCity('');
        setZipCode('');
        setErrors({});
    };

    useEffect(() => {
        setStateList(State.getStatesOfCountry('IN')); // India as the default country
    }, []);

    const handleStateChange = (event, newValue) => {
        if (newValue) {
            setState(newValue.isoCode);
            const fetchedCities = City.getCitiesOfState('IN', newValue.isoCode);
            setCityList(fetchedCities);
            setCity(''); // Reset city when state changes
        }
    };

    const handleCityChange = (event, newValue) => {
        if (newValue) {
            setCity(newValue.name);
        }
    };

    return (
        <div>
            <Modal open={addRequestsModalOpen} onClose={handleModalClose}>
                <Box sx={style}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        {title}
                    </Typography>
                    <Grid container spacing={2} style={{ marginTop: 10 }}>
                        <Grid item xs={12}>
                            <TextField
                                error={!!errors.vendorName}
                                helperText={errors.vendorName}
                                onFocus={() => handleError('', "vendorName")}
                                value={vendorName}
                                label="Vendor Name"
                                onChange={(e) => setVendorName(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label='Street'
                                error={!!errors.street}
                                helperText={errors.street}
                                onFocus={() => handleError('', "street")}
                                value={street}
                                onChange={(e) => setStreet(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="state-select"
                                options={stateList}
                                value={stateList.find(s => s.isoCode === state) || null}
                                onChange={handleStateChange}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} label="Select State" fullWidth />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="city-select"
                                options={cityList}
                                value={cityList.find(c => c.name === city) || null}
                                onChange={handleCityChange}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} label="Select City" fullWidth />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label='Zip Code'
                                 
                                error={!!errors.zipCode}
                                helperText={errors.zipCode}
                                onFocus={() => handleError('', "zipCode")}
                                onChange={(e) => setZipCode(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
                            <Button variant="outlined" style={{ textTransform: 'capitalize', color: primaryColor, border: `1px solid ${primaryColor}`, borderRadius: 0 }} fullWidth onClick={handleModalClose}>Cancel</Button>
                            <Button style={{ textTransform: 'capitalize', color: "#fff", background: primaryColor, borderRadius: 0 }} onClick={handleSubmit} fullWidth>Add</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
