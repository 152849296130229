import { TextField, Grid, Switch, Checkbox, FormControlLabel, Button, FormGroup, Typography, Autocomplete, FormHelperText, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { formatDate, formatPrice, primaryColor, primaryColorHover } from '../constant';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Country, State, City } from 'country-state-city';
import VendorsTableName from './VanderTableName';
import { get_data, post_data, serverURL } from '../api';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Swal from 'sweetalert2';
import AddSubVendorModal from './AddSubVendorModel';
import { useSelector } from 'react-redux';

import * as XLSX from 'xlsx';
import ClientSitesForm from './models/ClientSitesForm';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
});

export default function CreateAllVendorVehicles() {

    const { isLoggedIn, user_data } = useSelector(state => state.user);
    const location = useLocation()
    const vehicleData = location?.state?.row
    const title = location?.state?.title
    const [driverCityList, setDriverCityList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [deviceModelList, setDeviceModelList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [data, setData] = useState([]);
    const [vandorTableOpen, setVandorTableOpen] = useState({});
    const [stateList, setStateList] = useState([]);
    const [brandList, setBrandList] = useState([[]]);
    const [modelList, setModelList] = useState([]);
    const [excelData, setExcelData] = useState([]);
    const [fileName, setFileName] = useState('');
    const [openSite, setOpenSite] = useState(false);
    const [formData, setFormData] = useState({
        vendorId: user_data?._id,
        status: 'pending',
        vehicleDetails: { brand: '', model: '', regnNumber: '', plate: '', regnOwnerName: '', regnOwnerContactNo: '', typeOfVehicle: '', chassisNumber: '', noOfSeats: '' },
    });
    const [errors, setErrors] = useState({});
    const [countryCode] = useState('IN');
    const [vendorList, setVendorList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [index, setIndex] = useState(0);
    const [defaultnetwork, setDefaultnetwork] = useState('');
    const [siteList, setSiteList] = useState([]);
    const [vehicleList, setVehicleList] = useState(['Car', 'Bus', 'Cab'])
    const [noOfSeats, setNoOfSeats] = useState(['5 Seater', '7 Seater', '22 Seater'])

    const navigate = useNavigate()
    useEffect(() => {
        if (title === 'update' && vehicleData) {
            setFormData({
                vehicleDetails: {
                    brand: vehicleData?.vehicleDetails?.brand || '',
                    model: vehicleData?.vehicleDetails?.model || '',
                    regnNumber: vehicleData?.vehicleDetails?.regnNumber || '',
                    plate: vehicleData?.vehicleDetails?.plate || '',
                    regnOwnerName: vehicleData?.vehicleDetails?.regnOwnerName || '',
                    regnOwnerContactNo: vehicleData?.vehicleDetails?.regnOwnerContactNo || '',
                    typeOfVehicle: vehicleData?.vehicleDetails?.typeOfVehicle || '',
                    vehicleClass: vehicleData?.vehicleDetails?.vehicleClass || '',
                    chassisNumber: vehicleData?.vehicleDetails?.chassisNumber || '',
                    noOfSeats: vehicleData?.vehicleDetails?.noOfSeats || ''
                }
            })

        }
    }, [title, vehicleData]);

    const handleVehicleInputChange = (field, value) => {
        setFormData({ ...formData, vehicleDetails: { ...formData.vehicleDetails, [field]: value, } });

        if (field == 'brand') {
            getAllModels(value)
        }
    };


    // const fetchCompanyList = async () => {
    //     const result = await get_data(`vendor/get-client-of-vendor`);
    //     if (result?.status) {
    //         setCompanyList(result?.data || [{ name: 'aasib', _id: 12345678 }, { name: 'san', _id: 87654321 }]);
    //     }
    // }
    console.log("formData", formData)
    const fetchBrands = async () => {
        const result = await get_data(`brand/get-all-brands-for-options`);
        if (result?.status) {
            setBrandList(result?.data || []);

        }
    }

    const getAllModels = async (value) => {
        let result = await get_data(`model/get-all-model-by-brand/${value}`)
        if (result?.status == true) {
            setModelList(result?.data)
        } else {
            toast.error(result?.message)
        }
    }
    useEffect(() => {
        // fetchdeviceModelList();
        // fetchCompanyList();
        fetchBrands();
    }, []);

    // const handleError2 = (error, label) => {
    //     setErrors((prev) => ({ ...prev, [label]: error }));
    // };

    const handleError = (error, label) => {
        setErrors((prev) => {
            const updatedErrors = { ...prev };
            if (!updatedErrors) {
                updatedErrors = {};
            }
            updatedErrors[label] = error;
            return updatedErrors;
        });
    };

    const validation = () => {
        let hasError = false;
        if (!formData?.vehicleDetails?.brand) {
            handleError('Please Select Brand', 'brand');
            hasError = true;
        }
        if (!formData?.vehicleDetails?.model) {
            handleError('Please select Modal', 'model');
            hasError = true;
        }
        if (!formData?.vehicleDetails?.noOfSeats) {
            handleError('Please select Number of seats', 'noOfSeats');
            hasError = true;
        }
        if (!formData?.vehicleDetails?.plate) {
            handleError('Please select Plate', 'plate');
            hasError = true;
        }
        if (!formData?.vehicleDetails?.regnNumber) {
            handleError('Please Enter regnNumber', 'regnNumber');
            hasError = true;
        }
        if (!formData?.vehicleDetails?.regnOwnerContactNo) {
            handleError('Please Enter regnOwnerContactNo', 'regnOwnerContactNo');
            hasError = true;
        }
        if (!formData?.vehicleDetails?.regnOwnerName) {
            handleError('Please Enter regnOwnerName', 'regnOwnerName');
            hasError = true;
        }
        if (!formData?.vehicleDetails?.chassisNumber) {
            handleError('Please Enter chassisNumber', 'chassisNumber');
            hasError = true;
        }
        if (!formData?.vehicleDetails?.typeOfVehicle) {
            handleError('Please Select typeOfVehicle', 'typeOfVehicle');
            hasError = true;
        }

        return hasError;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validation()) {
            console.log("formdata", formData)
            let respons
            {
                title === 'update' ? respons = await post_data(`vendor-vehicle/update-vendor-vehicle/${vehicleData._id}`, formData)
                    :
                    respons = await post_data(`vendor-vehicle/create-vendor-vehicle`, formData)
            }
            console.log("xxxxxxxxxxx", respons)
            if (respons.status === true) {
                toast.success("Vehicle Register Successfully")
                navigate('/vendor/dashboard/allVehicles')
            }
            else {
                toast.error("Something Went Wrong")
            }
        }

    }


    const buttonStyles = {
        borderColor: primaryColor,
        color: 'white',
        background: primaryColor,
        textTransform: 'capitalize',
        borderRadius: 0,
        fontWeight: 400,
        padding: '1% 3.5%',
        boxShadow: 'none',
        '&:hover': {
            borderColor: primaryColor,
            boxShadow: 'none',
        },
        '&:focus': {
            outline: 'none',
            boxShadow: 'none',
            borderColor: primaryColor,
        }
    };

    useEffect(() => {
        if (formData?.vehicleDetails.brand) {
            getAllModels(formData?.vehicleDetails.brand);
        }
    }, [formData?.vehicleDetails.brand]);


    return (
        <Grid container spacing={3} style={{ padding: '3% 7%' }}>
            <Grid item md={12} style={{ background: '#fff', padding: '20px' }}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}><Typography variant="h6" gutterBottom>Vehicle Details</Typography></Grid>

                        {/* Brand Selection */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth error={Boolean(errors?.brand)}>
                                <Autocomplete
                                    disablePortal
                                    options={brandList}
                                    getOptionLabel={(option) => option?.name || ''}
                                    value={brandList?.find((brand) => brand?._id === formData?.vehicleDetails?.brand ) || null}
                                    onChange={(event, newValue) => handleVehicleInputChange('brand', newValue?._id)}
                                    required
                                    renderInput={(params) => <TextField {...params} label="Select Brand" />}
                                    onFocus={() => handleError('', 'brand')}
                                />
                                {errors?.brand && <FormHelperText sx={{ color: '#D32f2f' }}>{errors?.brand}</FormHelperText>}
                            </FormControl>
                        </Grid>

                        {/* Model Selection */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth error={Boolean(errors?.model)}>
                                <Autocomplete
                                    disablePortal
                                    options={modelList || []}
                                    getOptionLabel={(option) => option?.name || ''}
                                    value={modelList?.find((model) => model?._id === formData?.vehicleDetails?.model ) || null}
                                    onChange={(event, newValue) => handleVehicleInputChange('model', newValue?._id)}
                                    required
                                    renderInput={(params) => <TextField {...params} label="Select Model" />}
                                    onFocus={() => handleError('', 'model')}
                                />
                                {errors?.model && <FormHelperText sx={{ color: '#D32f2f' }}>{errors?.model}</FormHelperText>}
                            </FormControl>
                        </Grid>

                        {/* Registration Number */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Registration Number"
                                name="regnNumber"
                                value={formData?.vehicleDetails?.regnNumber  || ''}
                                onChange={(e) => handleVehicleInputChange('regnNumber', e.target.value)}
                                fullWidth
                                onFocus={() => handleError('', 'regnNumber')}
                            />
                            {errors?.regnNumber && <FormHelperText sx={{ color: '#D32f2f' }}>{errors?.regnNumber}</FormHelperText>}
                        </Grid>

                        {/* Plate Selection */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id={`plate-select-label`}>Plate</InputLabel>
                                <Select
                                    label="Plate"
                                    labelId={`plate-select-label`}
                                    value={formData?.vehicleDetails?.plate  || ''}
                                    onChange={(e) => handleVehicleInputChange('plate', e.target.value)}
                                    onFocus={() => handleError('', 'plate')}
                                >
                                    <MenuItem value="Yellow">Yellow</MenuItem>
                                    <MenuItem value="Green">Green</MenuItem>
                                    <MenuItem value="Black">Black</MenuItem>
                                </Select>
                                {errors?.plate && <FormHelperText sx={{ color: '#D32f2f' }}>{errors?.plate}</FormHelperText>}
                            </FormControl>
                        </Grid>

                        {/* Owner Name */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Owner Name"
                                name="regnOwnerName"
                                value={formData?.vehicleDetails?.regnOwnerName  || ''}
                                onChange={(e) => handleVehicleInputChange('regnOwnerName', e.target.value)}
                                fullWidth
                                onFocus={() => handleError('', 'regnOwnerName')}
                            />
                            {errors?.regnOwnerName && <FormHelperText sx={{ color: '#D32f2f' }}>{errors?.regnOwnerName}</FormHelperText>}
                        </Grid>

                        {/* Owner Contact Number */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Owner Contact Number"
                                name="regnOwnerContactNo"
                                value={formData?.vehicleDetails?.regnOwnerContactNo  || ''}
                                onChange={(e) => handleVehicleInputChange('regnOwnerContactNo', e.target.value)}
                                fullWidth
                                onFocus={() => handleError('', 'regnOwnerContactNo')}
                            />
                            {errors?.regnOwnerContactNo && <FormHelperText sx={{ color: '#D32f2f' }}>{errors?.regnOwnerContactNo}</FormHelperText>}
                        </Grid>


                        {/* <Grid item xs={12} sm={4}>
                            <TextField
                                label="Type of Vehicle"
                                name="typeOfVehicle"
                                value={formData?.vehicleDetails?.typeOfVehicle || vehicleData?.vehicleDetails?.typeOfVehicle || ''}
                                onChange={(e) => handleVehicleInputChange('typeOfVehicle', e.target.value)}
                                fullWidth
                            />
                        </Grid>

                       
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Vehicle Class"
                                name="vehicleClass"
                                value={formData?.vehicleDetails?.vehicleClass || vehicleData?.vehicleDetails?.vehicleClass || ''}
                                onChange={(e) => handleVehicleInputChange('vehicleClass', e.target.value)}
                                fullWidth
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <InputLabel id={`type-select-label`}>Type Of Vehicle</InputLabel>
                                <Select
                                    label="Type Of Vehicle"
                                    labelId={`type-of-vehicle-select-label`}
                                    value={formData?.vehicleDetails?.typeOfVehicle  || ''}
                                    onChange={(e) => handleVehicleInputChange('typeOfVehicle', e.target.value)}
                                    onFocus={() => handleError('', 'typeOfVehicle')}
                                >
                                    {vehicleList?.map((item) => <MenuItem value={item}>{item}</MenuItem>)}

                                </Select>
                                {errors?.typeOfVehicle && <FormHelperText sx={{ color: '#D32f2f' }}>{errors?.typeOfVehicle}</FormHelperText>}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <InputLabel id={`noOfSeats-select-label`}>Number Of Seats</InputLabel>
                                <Select
                                    label="Number Of Seats"
                                    labelId={`noOfSeats-select-label`}
                                    value={formData?.vehicleDetails?.noOfSeats  || ''}
                                    onChange={(e) => handleVehicleInputChange('noOfSeats', e.target.value)}
                                    onFocus={() => handleError('', 'noOfSeats')}
                                >
                                    {noOfSeats?.map((item) => <MenuItem value={item}>{item}</MenuItem>)}

                                </Select>
                                {errors?.noOfSeats && <FormHelperText sx={{ color: '#D32f2f' }}>{errors?.noOfSeats}</FormHelperText>}
                            </FormControl>
                        </Grid>

                        {/* Chassis Number */}
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Chassis Number"
                                name="chassisNumber"
                                value={formData?.vehicleDetails?.chassisNumber  || ''}
                                onChange={(e) => handleVehicleInputChange('chassisNumber', e.target.value)}
                                fullWidth
                                onFocus={() => handleError('', 'chassisNumber')}
                            />
                            {errors?.chassisNumber && <FormHelperText sx={{ color: '#D32f2f' }}>{errors?.chassisNumber}</FormHelperText>}
                        </Grid>

                        {/* Submit Button */}
                        <Grid item xs={12}>
                            <Button variant="contained" type="submit" style={buttonStyles} disabled={loader}>
                                {loader ? 'Loading...' : (title === 'update' ? 'Update' : 'Submit')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>

    );
}


