import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch } from "react-redux";
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Country, State, City } from 'country-state-city';
import { primaryColor } from "../constant";
import { post_data } from "../api";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { login } from "../redux/slices/user-slice";

export default function SubCompanyLogin() {

    const theme = useTheme();
    const matches1 = useMediaQuery(theme.breakpoints.down('md'));
    const matches3 = useMediaQuery(theme.breakpoints.down(500));

    const navigate = useNavigate()

    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [countryList, setCountryList] = useState([]);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [nameError, setNameError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [countryError, setCountryError] = useState("");
    const [stateError, setStateError] = useState("");
    const [cityError, setCityError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [loader, setLoader] = useState(false);
    const [otpForm, setOtpForm] = useState(false);
    const [emailForm, setEmailForm] = useState(true);

    let dispatch = useDispatch()
    const validate = () => {
        let valid = true;
        setEmailError("");
        setNameError("");
        setCountryError("")
        setPasswordError("");
        setConfirmPasswordError("");

        if (!email) {
            setEmailError("Email is required");
            valid = false;
        }
      
        if (!password) {
            setPasswordError("Password is required");
            valid = false;
        }

        return valid;
    };

    const handleSubmit = async () => {
      
        if (validate()) {
            try {
                setLoader(true);
                
                const data = await post_data("sub-client/sub-client-login", { email, password});
            
                if (data?.status === true) {
                    setEmail("");
                    setPassword("");
                    toast.success('Login Successfully')
                    setOtpForm(true);
                    setEmailForm(false);
                    dispatch(login(data?.data?.user));
                    localStorage.setItem("authToken", data?.data?.token);
                    navigate('/client/dashboard')
                }
                else {
                        toast.error(data?.response?.data?.message) 
                }
                setLoader(false);
            } catch (error) {
                setEmail("");
                setPassword("");
                toast.error("Something went wrong")
                setLoader(false);
            }
        }
    };

    useEffect(() => {
        setCountryList(Country.getAllCountries());
    }, []);


    const handleCountryChange = (event, newValue) => {
        if (newValue) {
            setCountry(newValue.isoCode);
            setCountryError(false)
            const countryCode = newValue.isoCode;
            const fetchedStates = State.getStatesOfCountry(countryCode);
            setStateList(fetchedStates);
            setCityList([]);
            // setFormData(prev => ({ ...prev, country: countryCode, state: '', city: '' }));
        }
    };

    const handleStateChange = (event, newValue) => {
        if (newValue) {
            setState(newValue.isoCode)
            setStateError(false)
            const stateCode = newValue.isoCode;
            const fetchedCities = City.getCitiesOfState(country, stateCode);
            setCityList(fetchedCities);
        }
    };

    const handleCityChange = (event, newValue) => {
        if (newValue) {
            setCityError(false)
            setCity(newValue?.name)
        }
    };


    return (
        <div style={styles.container}>
             <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <div style={{ ...styles.rightSide, padding: matches3 ? "20px" : '10px', width: matches3 ? '100%' : '45%' }}>
                <div style={{ width: matches3 ? '100%' : matches1 ? "95%" : '60%', margin: 'auto' }}>
                    <h2 style={styles.loginHeading}>Login</h2>
                    <p style={styles.loginText}>
                        Fill in the details below to login your account.
                    </p>

                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailError("");
                        }}
                        error={!!emailError}
                        helperText={emailError}
                        style={{ ...styles.input, marginTop: '3%', width: '100%' }}
                    />
                    <TextField
                        label="Password"
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        fullWidth
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                            setPasswordError("");
                        }}
                        error={!!passwordError}
                        helperText={passwordError}
                        style={{ ...styles.input, marginTop: '3%' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={loader}
                        style={styles.signInButton}
                    >
                        {loader ? 'Login In...' : ' Login In'}
                    </Button>
                </div>
            </div>
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: 'white',
    },
    rightSide: {
        backgroundColor: '#fff',
        width: '100%'
    },
    loginHeading: {
        fontSize: '1.5rem',
        color: primaryColor,
        marginBottom: '20px',
        fontWeight: 500,
        textAlign: 'center'
    },
    loginText: {
        textAlign: 'center',
        fontSize: '1rem',
        marginTop: 0,
        color: '#2a2a2a',
        marginBottom: '20px',
    },
    input: {
        marginBottom: '10px',
    },
    signInButton: {
        padding: '9px 20px',
        backgroundColor: primaryColor,
        color: '#fff',
        borderRadius: 0,
        width: '100%',
        marginTop: '4%',
        boxShadow: 'none',
        fontSize: '1rem',
        fontWeight: 400,
        cursor: 'pointer',
    },
};