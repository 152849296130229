import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Paper, Divider, Pagination } from '@mui/material';
import { get_data, serverURL } from '../api';
import SearchComponent from './SearchComponent';

// A reusable component for displaying label and value pairs
const DetailItem = ({ label, value }) => (
    <Grid item xs={12} sm={6} md={4}>
        <Typography variant="subtitle2" color="textSecondary">{label}</Typography>
        <Typography variant="body1">{value || 'N/A'}</Typography>
    </Grid>
);


export default function ServiceRequestsComp({ clientId }) {
    // console.log('clientid', clientId)
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [value, setValue] = useState('')


    const itemsPerPage = 15;
    const count = data?.length;


    const handleSearch = async () => {
        if (value.length > 0) {
            try {
                const data = await get_data(`service-request/search-service-requests/${value}/${clientId}?pageNumber=${currentPage}`)
                console.log('Requests serarch error : ', data)
                if (data.status === true) {
                    setData(data?.data?.serviceRequests || []);
                    setTotalPages(data?.data?.totalPages)
                    setTotalData(data?.data?.totalServiceRequests)
                }
            }
            catch (error) {
                console.log('Requests serarch error : ', error)
            }

        } else {
            getClientData(currentPage)
        }
    }


    const getClientData = async () => {
        try {
            const data = await get_data(`service-request/get-all-service-requests-for-admin/${clientId}?pageNumber=${currentPage}`);
            // console.log("data", data.data.serviceRequests)
            if (data.status === true) {
                setData(data?.data?.serviceRequests || []);
                setTotalPages(data?.data?.totalPages)
                setTotalData(data?.data?.totalServiceRequests)
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (clientId || currentPage) {

            getClientData(currentPage);
        }
    }, [clientId || currentPage])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }, []);


    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);


    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    if (!data || data.length === 0) {
        return <Typography style={{ textAlign: 'center' }}>No Service Requests found for this client.</Typography>;
    }

    return (
        <Box>
            {data?.length > 0 && (
                <div style={{ marginBottom: 10 }}>
                    <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                </div>
            )}

            {data?.map((item, inx) => {
                return (
                    <Paper sx={{ p: 2, marginTop: inx === 0 ? '' : 5 }}>
                        <Typography variant="h5" gutterBottom>
                            Service Request Details
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Grid container spacing={2}>
                            {/* Client Basic Information */}
                            <DetailItem label="Driver Name" value={item?.driverName} />
                            <DetailItem label="Unique Service Request Id" value={item?.uniqueServiceRequestId} />
                            <DetailItem label="Driver Phone No" value={item?.driverPhoneNo} />
                            <DetailItem label="Vehicle No" value={item?.vehicleNo} />
                            <DetailItem label="IMEI No" value={item?.imeiNo} />

                            {/* Address Information */}
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    Address
                                </Typography>
                                <Divider sx={{ mb: 1 }} />
                            </Grid>
                            <DetailItem label="Street" value={item?.driverAddress?.street} />
                            {/* <DetailItem label="Landmark" value={item?.driverAddress?.landmark} /> */}
                            <DetailItem label="State" value={item?.driverAddress?.state} />
                            <DetailItem label="City" value={item?.driverAddress?.city} />
                            <DetailItem label="Zip Code" value={item?.driverAddress?.pincode} />

                            {/* <Divider sx={{ mb: 2 }} /> */}

                            {/* Sites Information */}
                            {/* {item?.sites && item?.sites?.length > 0 && (
                                <>
                                    <Grid item xs={12} >
                                        <Typography variant="h6" gutterBottom>
                                            Site Addresses
                                        </Typography>
                                        <Divider sx={{ mb: 1 }} />
                                    </Grid>
                                    {item.sites.map((site, index) => (
                                        <Grid key={index} item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }} >
                                            <DetailItem label="Site Street" value={site?.street} />
                                            <DetailItem label="Landmark" value={site?.landmark} />
                                            <DetailItem label="Site State" value={site?.state} />
                                            <DetailItem label="Site City" value={site?.city} />
                                            <DetailItem label="Site Zip Code" value={site?.zipCode} />
                                        </Grid>
                                    ))}
                                </>
                            )} */}

                            {/* Other Information */}
                            {/* <DetailItem label="Client Status" value={item?.isClient ? 'Active' : 'Inactive'} />
                            <DetailItem label="Created At" value={new Date(item?.createdAt).toLocaleDateString()} /> */}


                            {/* sub client */}

                            {/* {item?.subClientId && item?.subClientId?.length > 0 && (
                                <>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" gutterBottom>
                                            Sub Clients
                                        </Typography>
                                        <Divider sx={{ mb: 1 }} />
                                    </Grid>
                                    {item?.subClientId?.map((subClient, index) => (
                                        <Grid key={index} item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }} >
                                            <DetailItem label="Sub Client Name" value={subClient?.companyName} />
                                            <DetailItem label="Sub Client Phone" value={subClient?.contactNo} />
                                            <DetailItem label="Sub Client Email" value={subClient?.email} />
                                            <DetailItem label="Email" value={item?.email} />
                                            <DetailItem label="Residential Contact No" value={item?.resContactNo} />

                                          
                                            <Grid item xs={12}>
                                                <Typography variant="h6" gutterBottom>
                                                    Address
                                                </Typography>
                                                <Divider sx={{ mb: 1 }} />
                                            </Grid>
                                            <DetailItem label="Street" value={item?.address?.street} />
                                            <DetailItem label="Landmark" value={item?.address?.landmark} />
                                            <DetailItem label="City" value={item?.address?.city} />
                                            <DetailItem label="Zip Code" value={item?.address?.zipCode} />
                                            <DetailItem label="State" value={item?.address?.state} />

                                        </Grid>
                                    ))}
                                </>
                            )} */}


                            {/* {
                                item?.images && item?.images.length > 0 && (
                                    <Grid item xs={12}>
                                        <Typography variant="h6" gutterBottom>
                                            Documents
                                        </Typography>
                                        <Divider sx={{ mb: 1 }} />

                                        {
                                            item?.images?.map((image, index) => (
                                                <img key={index} src={`${serverURL}/uploads/client-images/${image}`} alt="image" style={{ width: '100px', height: '100px', margin: '5px' }} />
                                            ))
                                        }
                                    </Grid>
                                )
                            } */}
                        </Grid>
                    </Paper>)
            })}


            {data?.length > 0 && (
                <Grid item xs={12}>
                    {/* <Card> */}
                    <div
                        style={{
                            borderTop: "1px solid gainsboro",
                            padding: "2%",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            style={{
                                fontWeight: 500,
                                fontSize: 15,
                                color: "black",
                            }}
                        >
                            {`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}
                        </Typography>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </div>
                    {/* </Card> */}
                </Grid>
            )}

        </Box >
    );
}
