
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { createButtonBg } from "../constant";
import { IoMdAdd } from "react-icons/io";
import { useEffect, useState } from "react";
import { get_data, post_data } from "../api";
import EmptyPage from "./EmptyPage";
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import SearchComponent from "./SearchComponent";
import ClaimsTransactionModal from "./models/ClaimsTransactionModal";
import { useDispatch, useSelector } from "react-redux";
import ClientSitesTable from "./tables/ClientSitesTable";
import SitesModal from "./models/SitesModal";
import AdminSitesTable from "./tables/AdminSitesTable";

export default function Sites({ title }) {

    const page = { width: '100%', marginBottom: '2%' };
    const { user_data } = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [value, setValue] = useState('')
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [open, setOpen] = useState(false)
    const [fromDate, setFromDate] = useState([])
    const [toDate, setToDate] = useState([])
    const [claimsData, setClaimsData] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [openForm, setOpenForm] = useState(false)
    const itemsPerPage = 15;
    const count = data?.length;

    const tableHeader = [
        "S No.", "Site Name", "Street", "Landmark", "City", "State", "country", "Zipcode"
    ]

    const getSitesData = async () => {
        try {
            setLoading(true);
            let data
            if (title === 'admin') {
                data = await get_data(`sites/get-all-sites?pageNumber=${currentPage}`);
            } else if (title === 'company') {
                data = await get_data(`sites/get-all-sites-by-company/${user_data?._id}?pageNumber=${currentPage}`);

            }

            if (data.status === true && data?.data?.sites?.length > 0) {
                setLoading(false);
                setData(data?.data?.sites);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalSites);
            }
            setLoading(false);
        } catch (error) {
            console.log('error while search sites', error);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getSitesData(currentPage);
        }
    }, [currentPage])

    const handleSearch = async () => {
        try {
            setLoading(true);
            if (value?.length > 0) {
                let data
                if (title === 'admin') {
                    data = await get_data(`sites/search-sites/${value}?pageNumber=${currentPage}`)
                } else if (title === 'company') {
                    data = await get_data(`sites/search-sites-by-company/${value}/${user_data?._id}?pageNumber=${currentPage}`);
                }
                // console.log("data", data)

                if (data.status === true) {
                    setData(data?.data?.sites);
                    setTotalData(data?.data?.totalSites);
                    setTotalPages(data?.data?.totalPages);
                    setLoading(false);
                }
            } else {
                getSitesData(currentPage);
                setLoading(false)
            }
            setLoading(false);
        } catch (error) {

        }
    }


    const handleDateChange = (dates) => {
        if (dates && dates?.length === 2) {
            setDateRange(dates);
        }
    };

    const fetchExportData = async () => {
        try {

            const fromDate = moment(startDate).toISOString();
            const toDate = moment(endDate).toISOString();

            let response = await post_data('claim/get-claims-by-date', {
                from: fromDate,
                to: toDate
            })

            if (response?.status) {
                setClaimsData(response?.data?.claim)
                setOpen(true)
                setFromDate(response?.data?.fromDate)
                setToDate(response?.data?.toDate)
            } else {

            }
        } catch (error) {
        }
    };

    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>

                <div style={{ display: 'flex', marginBottom: '1%', width: '100%' }}>
                    <div>
                        <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                    </div>
                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        {/*  <div style={{ ...rangePickerStyle, border: 'none', }}>
                            <DatePicker
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                dateFormat="dd-MM-yyyy"
                                maxDate={new Date()}
                                customInput={
                                    <button style={{ border: 'none', background: '#fff', cursor: 'pointer', }}>
                                        {startDate && endDate ? `${moment(startDate).format('DD-MM-YYYY')} to ${moment(endDate).format('DD-MM-YYYY')}` : 'Select Date Range'}
                                    </button>
                                }
                            />
                        </div>
                        <Button
                            onClick={fetchExportData}
                            variant="contained"
                            style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                        >
                            Export
                        </Button>*/}
                        {/* <AddRequestsModal title={'Requests'} /> */}

                        <Button
                            startIcon={<IoMdAdd />}
                            onClick={() => setOpenForm(true)}
                            variant="contained"
                            style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                        >
                            Create
                        </Button>
                        <SitesModal title={title} openForm={openForm} setOpenForm={setOpenForm} isUpdate={false} getSitesData={getSitesData} />
                    </div>
                </div>

               {  data?.length === 0 ?
                            <>
                                <EmptyPage />
                            </>
                            :
                            <>
                                <AdminSitesTable
                                    data={data}
                                    tableHeader={tableHeader}
                                    itemsPerPage={itemsPerPage}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    count={count}
                                    totalData={totalData}
                                    totalPages={totalPages}
                                    loading={loading}
                                    setLoading={setLoading}
                                    refresh={refresh}
                                    setRefresh={setRefresh}
                                    getSitesData={getSitesData}
                                    user_data={user_data}
                                    title={title}
                                />

                                <ClaimsTransactionModal
                                    open={open}
                                    setOpen={setOpen}
                                    fromDate={fromDate}
                                    toDate={toDate}
                                    data={claimsData}
                                />
                            </>
                    }
            </Grid>
        </>
    )
}