import { Button, Grid } from "@mui/material";
import AdminVendorsTable from "../../../components/tables/AdminVendorsTable";
import { IoMdAdd } from "react-icons/io";
import AddVendorModal from "../../../components/AddVendorModal";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { baseURL, get_data, post_data } from "../../../api";
import EmptyPage from "../../../components/EmptyPage";
import toast, { Toaster } from "react-hot-toast";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import SearchComponent from "../../../components/SearchComponent";
import { createButtonBg, primaryColor, primaryColorHover } from "../../../constant";
import AdminVendorsTransactionModal from "../../../components/models/AdminVendorsTransactionModal";
import ClientsVendorsTable from "../../../components/tables/ClientsVendorsTable";
import { useSelector } from "react-redux";
import * as XLSX from 'xlsx';

const rangePickerStyle = {
    display: 'flex',
    cursor: 'pointer',
    fontSize: 14,
    background: 'white',
    padding: '0% 0% 0% 0%',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    color: '#7E8299',
    cursor: 'pointer'
};


export default function ClientsVendor() {

    const page = {
        width: '100%',
        marginBottom: '2%'
    };
    const { user_data } = useSelector(state => state.user);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [value, setValue] = useState('');
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [open, setOpen] = useState(false)
    const [fromDate, setFromDate] = useState([])
    const [toDate, setToDate] = useState([])
    const [vendorsData, setVendorsData] = useState([])
    const [fileName, setFileName] = useState('');
    const [excelData, setExcelData] = useState([]);


    const getAllVendors = async (pageNumber) => {
        setLoading(true);
        let response
        try {
            if (user_data?.isSubClient) {

                response = await get_data(`vendor/get-all-vendors-for-sub-client/${user_data?._id}?pageNumber=${pageNumber}`);
            } else {
                response = await get_data(`vendor/get-all-vendors-for-client/${user_data?._id}?pageNumber=${pageNumber}`);
            }

            if (response.status) {
                setData(response?.data?.vendors);
                setTotalPages(response?.data?.totalPages);
                setTotalData(response?.data?.totalVendors);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllVendors(currentPage);
    }, [currentPage]);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }, []);

    const itemsPerPage = 15;
    const count = data?.length;

    const tableHeader = [
        "S No.", "Vendor Id", "Vendor Name", 'Total Vehicels', "Mobile Number", "Email", "Company Name", "Address"
    ];

    const handleVendorSubmit = async (body, isUpdate) => {
        let result;
        const vendorId = localStorage.getItem('vendorId');

        if (isUpdate) {
            result = await post_data(`vendor/update-vendor/${vendorId}`, body);
        } else {
            result = await post_data(`vendor/create-vendor`, body);
        }

        if (result?.status) {
            setRefresh(!refresh); // Trigger re-fetch of data
            toast.success(result?.message);
            getAllVendors(currentPage);
        } else {
            toast.error(result?.message);
        }
    };

    const handleSearch = async () => {
        if (value.length > 0) {
            const data = await get_data(`vendor/search-vendors-for-client/${value}?pageNumber=${currentPage}`);
            // console.log("jjjjjjjjjj", data)
            if (data.status === true) {
                setData(data?.data?.vendors);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalVendors);
            }
        } else {
            getAllVendors(currentPage);
        }
    };

    const handleDateChange = (dates) => {
        if (dates && dates?.length === 2) {
            setDateRange(dates);
        }
    };

    const fetchExportData = async () => {
        try {

            const fromDate = moment(startDate).toISOString();
            const toDate = moment(endDate).toISOString();

            let response = await post_data('vendor/get-all-vendors-by-date-for-client', {
                from: fromDate,
                to: toDate
            })

            if (response?.status) {
                setVendorsData(response?.data?.vendors)
                setData(response?.data?.vendors)
                setOpen(true)
                setFromDate(response?.data?.fromDate)
                setToDate(response?.data?.toDate)
            } else {

            }
        } catch (error) {
        }
    };


    ////////////////////////////// UPLOAD AND DOWNLOAD /////////////////////////////////////

    const downloadCSV = () => {
        const userAgent = window.navigator.userAgent;
        let fileType = '';
        let fileName = '';

        if (userAgent.includes('Mac')) {
            fileType = 'numbers';
            fileName = 'vendor_form_client.numbers';
        } else if (userAgent.includes('Windows')) {
            fileType = 'xlsx';
            fileName = 'vendor_form_client.xlsx';
        } else {
            fileType = 'xlsx';
            fileName = 'vendor_form_client.xlsx';
        }

        const link = document.createElement('a');
        link.href = `${baseURL}/images/${fileName}`;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setFileName(file.name);
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);

            setExcelData(jsonData);
        };

        reader.readAsArrayBuffer(file);
    };


    const handleSubmitExcel = async () => {
        try {
            const result = await post_data("vendor/create-vendor-excel", excelData);
            if (result.status === true) {
                toast.success("Vendor created Successfully");
                getAllVendors(currentPage);
                setExcelData([]);
                setFileName();
                // handleClose();
            } else {
                toast.error(result?.response?.data?.message || "Something went wrong");
                // handleClose();
            }
        } catch (error) {
        }
    };

    ////////////////////////////////////////////////////////////////



    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <div style={{ display: 'flex', marginBottom: '1%', width: '100%' }}>
                    <div>
                        <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                    </div>
                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        <div style={{ ...rangePickerStyle, border: 'none', }}>
                            <DatePicker
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                dateFormat="dd-MM-yyyy"
                                maxDate={new Date()}
                                customInput={
                                    <button style={{ border: 'none', background: '#fff', cursor: 'pointer', }}>
                                        {startDate && endDate ? `${moment(startDate).format('DD-MM-YYYY')} to ${moment(endDate).format('DD-MM-YYYY')}` : 'Select Date Range'}
                                    </button>
                                }
                            />
                        </div>
                        <Button
                            onClick={fetchExportData}
                            variant="contained"
                            style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                        >
                            Export
                        </Button>
                        <AddVendorModal title={'ClientVendors'} currentPage={currentPage} getAllVendors={getAllVendors} handleVendorSubmit={handleVendorSubmit} isUpdate={false} />
                    </div>
                </div>

                <div style={{ display: 'flex', marginBottom: '1%', width: '100%', }}>
                    <Grid item sx={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: "10px", marginLeft: 'auto' }}>
                        {
                            excelData.length === 0 &&
                            <>
                                <Button
                                    onClick={downloadCSV}
                                    variant='outlined'
                                    sx={{
                                        borderColor: primaryColor,
                                        color: primaryColor,
                                        textTransform: 'capitalize',
                                        borderRadius: 0,
                                        fontWeight: 400,
                                        boxShadow: 'none',
                                        '&:hover': {
                                            borderColor: primaryColor,
                                            boxShadow: 'none',
                                        },
                                        '&:focus': {
                                            outline: 'none',
                                            boxShadow: 'none',
                                            borderColor: primaryColor,
                                        }
                                    }}
                                >
                                    Download Excel
                                </Button>
                                <Button
                                    variant="contained"
                                    component="label"
                                    sx={{
                                        background: primaryColor,
                                        color: 'white',
                                        borderRadius: 0,
                                        fontWeight: 400,
                                        textTransform: 'capitalize',
                                        boxShadow: 'none',
                                        '&:hover': {
                                            backgroundColor: primaryColorHover,
                                            boxShadow: 'none',
                                        },
                                        '&:focus': {
                                            outline: 'none',
                                            boxShadow: 'none',
                                            backgroundColor: primaryColor,
                                        },
                                    }}
                                >
                                    Upload Excel
                                    <input
                                        type="file"
                                        accept=".xlsx, .xls"
                                        hidden
                                        onChange={handleFileUpload}
                                    />
                                </Button>
                            </>
                        }
                        {/* Show file name after upload */}
                        {fileName && <p>Uploaded File: {fileName}</p>}

                        {/* Show Submit button only when there is data */}
                        {excelData.length > 0 && (
                            <Button
                                variant="contained"
                                sx={{
                                    background: primaryColor,
                                    color: 'white',
                                    borderRadius: 0,
                                    fontWeight: 400,
                                    textTransform: 'capitalize',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        backgroundColor: primaryColorHover,
                                        boxShadow: 'none',
                                    },
                                    '&:focus': {
                                        outline: 'none',
                                        boxShadow: 'none',
                                        backgroundColor: primaryColor,
                                    }
                                }}
                                onClick={handleSubmitExcel}
                            >
                                Submit
                            </Button>
                        )}
                    </Grid>
                </div>


                {data?.length === 0 ? (
                    <EmptyPage />
                ) : <>
                    <ClientsVendorsTable
                        data={data}
                        tableHeader={tableHeader}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        count={count}
                        totalData={totalData}
                        totalPages={totalPages}
                        loading={loading}
                        setLoading={setLoading}
                        getAllVendors={getAllVendors}
                        handleVendorSubmit={handleVendorSubmit}
                        refresh={refresh}
                        setRefresh={setRefresh}
                    />
                    <AdminVendorsTransactionModal
                        open={open}
                        setOpen={setOpen}
                        fromDate={fromDate}
                        toDate={toDate}
                        data={vendorsData}
                    />
                </>}
            </Grid>
        </>
    );
}
