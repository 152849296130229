import { Button, Grid } from "@mui/material";

export default function VendorDashComp() {

    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
    };

    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <div style={page_container}>
                </div>
            </Grid>
        </>
    )
}