import React, { useEffect, useState } from "react";
import { get_data, post_data } from "../api";
import { createButtonBg, formatDate, formatPrice } from "../constant";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { Avatar, Backdrop, Box, Button, CircularProgress, Paper, Typography } from "@mui/material";
import EmptyPage from "./EmptyPage";
import { useSelector } from "react-redux";
import BusinessIcon from "@mui/icons-material/Business";
import { useNavigate } from "react-router-dom";

const CompanyDashComp = () => {
    const { user_data } = useSelector(state => state.user)
    const navigate = useNavigate()
    const [uninstalledList, setUninstalledList] = useState([])
    const [requestList, setRequestList] = useState([])
    const [returnList, setReturnList] = useState([])
    const [claimsList, setClaimsList] = useState([]);
    const [inventorysList, setInventorysList] = useState([]);
    //   const [ecommerceSale, setEcommerceSale] = useState(0);
    //   const [rentalSale, setRentalSale] = useState(0);
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [open, setOpen] = useState(true)
    const [fromDate, setFromDate] = useState([])
    const [toDate, setToDate] = useState([])
    const [isEmpty, setIsEmpty] = useState(false);

    const getUninstalledList = async () => {
        let result = await get_data(`uninstall/get-uninstall-for-client-dashboard`)

        if (result?.status == true) {
            setUninstalledList(result?.data)
            if (result?.data) {
                setOpen(false)
            }
        }
    }

    const getRequestList = async () => {
        let result = await get_data(`request/get-request-for-client-dashboard`)
        // console.log('resultresultresult', result.data[0])
        if (result?.status == true) {
            setRequestList(result?.data)
            if (result?.data) {
                setOpen(false)
            }
        }
    }

    const getReturnList = async () => {
        let result = await get_data(`return/get-return-for-client-dashboard`)
        if (result?.status == true) {
            setReturnList(result?.data)
            if (result?.data) {
                setOpen(false)
            }
        }
    }

    const getClaimsList = async () => {
        let result = await get_data(`claim/get-claim-for-client-dashboard`)
        if (result?.status == true) {
            setClaimsList(result?.data)
            if (result?.data) {
                setOpen(false)
            }
        }
    }

    const getInventoryList = async () => {
        let result = await get_data(`inventory/get-inventory-by-client-for-dashboard/${user_data?._id}`)
        if (result?.status == true) {
            setInventorysList(result?.data)
            if (result?.data) {
                setOpen(false)
            }
        }
    }


    useEffect(function () {
        getClaimsList()
        getRequestList()
        getReturnList()
        getUninstalledList()
        getInventoryList()
    }, []);

    const showList = (data, title) => {
        return data?.map((item) => {
            return <li style={styles.transactionItem}>
                {title == 'request' ? <span>{formatDate(item?.createdAt) === 'NaN-NaN-NaN' ? '-' : formatDate(item?.createdAt)}</span> : <>{title == 'inventory' ? <span>{item?.gpsDetail?.vehicleNumber || "-"}</span> : <span>{item?.vehicleNumbers[0] || "-"}</span>}</>}
                {title == 'request' ? <span style={styles.completed}>{"GPS :-  " + item?.gps?.length || "-"}</span> : <span style={item?.status == 'pending' ? styles.pending : styles.completed}>{item?.status || "-"}</span>}
            </li>
        })
    }


    ////////////////////////////// DATE PICKER //////////////////////////////
    const rangePickerStyle = {
        display: 'flex',
        cursor: 'pointer',
        fontSize: 14,
        background: 'white',
        padding: '4% 0% 4% 0%',
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        width: 250,
        color: '#7E8299',
        cursor: 'pointer'
    };

    const handleDateChange = (dates) => {
        if (dates && dates?.length === 2) {
            setDateRange(dates);
        }
    };

    useEffect(() => {
        if (startDate && endDate) {
            fetchExportData();
            setOpen(true)
        }
    }, [startDate, endDate]);

    const fetchExportData = async () => {
        try {
            const fromDate = moment(startDate).toISOString();
            const toDate = moment(endDate).toISOString();
            let response = await post_data('order/get-client-dashboard-statics-by-date', {
                from: fromDate,
                to: toDate
            })

            console.log('response', response)
            if (response?.status === true) {
                // setEcommerceSale(response?.data?.totalEcommerceSales)
                // setRentalSale(response?.data?.totalRentalSale)
                setClaimsList(response?.data?.claims)
                setRequestList(response?.data?.gpsRequests)
                setReturnList(response?.data?.returns)
                setUninstalledList(response?.data?.uninstall)
                setInventorysList(response?.data?.inventory)
                setOpen(false)
                setFromDate(response?.data?.fromDate)
                setToDate(response?.data?.toDate)

            } else {
            }
        } catch (error) {
        }
    };

    if (claimsList?.length == 0 && requestList?.length == 0 && returnList?.length == 0 && uninstalledList?.length == 0) {
        return (
            <EmptyPage />
        )
    }

    return (
        <div style={styles.dashboardContainer}>
            {/* Header Section */}
            <div style={styles.header}>
                <div>
                    <Typography variant="h5">{user_data?.companyName}</Typography>
                    <Typography>{user_data?.uniqueClientId}</Typography>
                </div>
                <span style={styles.datePicker}>
                    <div style={{ ...rangePickerStyle, border: 'none', }}>
                        <DatePicker
                            selected={startDate}
                            onChange={handleDateChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            dateFormat="dd-MM-yyyy"
                            maxDate={new Date()}
                            customInput={
                                <button style={{ border: 'none', background: '#fff', cursor: 'pointer', }}>
                                    {startDate && endDate ? `${moment(startDate).format('DD-MM-YYYY')} to ${moment(endDate).format('DD-MM-YYYY')}` : 'Select Date Range'}
                                </button>
                            }
                        />
                    </div>
                    <Backdrop
                        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                        open={open}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </span>
            </div>

            {/* Transaction List */}
            <div style={styles.transactionContainer}>
                <h4>GPS Request</h4>
                <ul style={styles.transactionList}>
                    {showList(requestList, 'request')}
                </ul>

            </div>

            {/* Revenue Section */}
            <div style={styles.transactionContainer}>
                <h4>GPS UnInstall</h4>
                <ul style={styles.transactionList}>
                    {showList(uninstalledList, 'uninstall')}
                </ul>
            </div>


            <div style={styles.transactionContainer}>
                <h4>GPS Return</h4>
                <ul style={styles.transactionList}>
                    {showList(returnList, 'return')}
                </ul>
            </div>

            <div style={styles.transactionContainer}>
                <h4>GPS Claims</h4>
                <ul style={styles.transactionList}>
                    {showList(claimsList, 'claim')}
                </ul>
            </div>

            <div style={styles.transactionContainer}>
                <h4>Inventory</h4>
                <ul style={styles.transactionList}>
                    {showList(inventorysList, 'inventory')}
                </ul>
                <ul style={{ display: 'flex', marginLeft: 'auto' }}>
                    <Button style={{ marginLeft: 'auto', fontSize: 12 }} onClick={() => navigate('/company/dashboard/inventory')}>
                        View all
                    </Button>
                </ul>

            </div>

        </div>
    );
};

// Inline styles in JSON format
const styles = {
    dashboardContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: '20px',
        padding: '0 2%',
        fontFamily: 'Arial, sans-serif',
        minHeight: '100vh',
    },
    header: {
        gridColumn: 'span 3',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    datePicker: {
        fontSize: '14px',
        color: '#666',
        display: 'flex',
        gap: 10
    },
    income: {
        fontSize: 35,
        margin: 0,
        fontWeight: 600
    },
    cardsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: '20px',
        gridColumn: 'span 3',
    },
    updateCard: {
        backgroundColor: '#1E6D8A',
        color: '#fff',
        padding: '20px',
        borderRadius: '10px',
    },
    statCard: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '10px',
    },
    button: {
        backgroundColor: '#fff',
        color: '#004400',
        padding: '10px',
        borderRadius: '5px',
        border: 'none',
        cursor: 'pointer',
    },
    positiveStat: {
        color: '#4caf50',
        fontSize: '12px',
    },
    negativeStat: {
        color: '#f44336',
        fontSize: '12px',
    },
    transactionContainer: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '10px',
        gridColumn: 'span 1',
    },
    transactionList: {
        listStyleType: 'none',
        padding: 0,
    },
    transactionItem: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 0',
        borderBottom: '1px solid #ddd',
    },
    completed: {
        color: '#4caf50',
    },
    pending: {
        color: '#ff9800',
    },
    revenueContainer: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '10px',
        gridColumn: 'span 1',
    },
    salesReportContainer: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '10px',
        gridColumn: 'span 1',
    },
    chart: {
        backgroundColor: '#ddd',
        height: '200px',
        borderRadius: '10px',
    },
};

export default CompanyDashComp;
