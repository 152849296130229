import React, { useEffect, useState } from 'react';
import { Box, Grid, Avatar, Button, TextField, Typography, Autocomplete, Card, CardContent, FormControl, InputLabel, Select, MenuItem, useTheme, useMediaQuery, IconButton, InputAdornment, OutlinedInput, } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { get_data } from '../../../api';

const Billing = () => {
    const navigate = useNavigate()
    const { user_data } = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [bankDetails, setBankDetails] = useState({})

    const fetchDetail = async () => {
        let result = await get_data(`super-admin/get-admin-bank-details`)
        if (result?.status == true) {
            setBankDetails(result?.data)
        }
    }

    useEffect(() => {
        fetchDetail()
    }, [])

    const list = [{ title: 'Account Holder Name ', value: bankDetails?.accountHolderName || '-' },
    { title: 'Bank Name ', value: bankDetails?.bankName || '-' },
    { title: 'Account Number', value: bankDetails?.accountNumber || '-' },
    { title: 'IFSC Code', value: bankDetails?.ifsc || '-' },
    ]

    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro', padding: 20 }} >
                        <Typography variant="h6" fontWeight="500" gutterBottom >
                            Billing Details
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro', padding: 20 }}>
                        <Typography variant="h6" fontWeight="500" gutterBottom>
                            Bank Account Details
                        </Typography>
                        <Button style={{ background: '#e1ffd1', color: 'green', fontSize: 13, margin: '10px 0px 20px 0px', textTransform: "capitalize", borderRadius: 50 }}>
                            Active
                        </Button>
                        <div style={{ width: '100%', paddingBottom: user_data?.isSuperAdmin ? '3%' : '' }}>
                            {list?.map((item) => {
                                return (<div style={{ display: 'flex', marginTop: 5 }}>
                                    <div style={{ width: '35%' }} >
                                        <span style={{ color: "#808080", fontSize: '14px' }}>
                                            {item?.title}
                                        </span>
                                    </div>
                                    <div style={{ width: '65%', }}>
                                        <span style={{ fontSize: '14px', }}>
                                            {item?.value}
                                        </span>
                                    </div>
                                </div>)
                            })}
                        </div>

                    </Box>
                </Grid>
            </Grid >
        </Box>
    );
};

export default Billing;