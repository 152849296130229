import React, { useEffect, useState } from 'react';
import { AppBar, Box, Tab, Tabs, Typography, Grid } from '@mui/material';
import { primaryColor } from '../constant';
import ClientDetailComp from './ClientDetailComp';
import { get_data } from '../api';
import ClientRequestComp from './ClientRequestComp';
import ClientReturnComp from './ClientReturnComp';
import ClientClaimComp from './ClientClaimComp';
import ClientUninstallComp from './ClientUninstallComp';
import SubCompanyDetailComponent from './SubCompanyDetailComponent';
import SubCompanyRequestComponent from './SubCompanyRequestComponent';
import SubCompanyReturnComponent from './SubCompanyReturnComponent';
import SubCompanyClaimComponent from './SubCompanyClaimComponent';
import SubCompanyUninstallComponent from './SubCompanyUninstallComponent';

// Tab Panel to show content based on the active tab
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
} 

export default function SubCompanyDetails() {
    const [tabIndex, setTabIndex] = useState(0);
    const [client, setClient] = useState({});

    const getCilentDetails = async () => {
        let result = await get_data(`sub-client/get-sub-client/${localStorage.getItem('subClientId')}`)
        if (result?.status) {
            setClient(result?.data);
        }
    }

    useEffect(() => {
        if (localStorage.getItem('subClientId')) {
            getCilentDetails()
        }
    }, [])


    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <AppBar position="static" sx={{ backgroundColor: primaryColor, boxShadow: 'none' }}>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="inherit"
                    variant="fullWidth"
                >
                    <Tab label="Detail" />
                    <Tab label="Request" />
                    <Tab label="Return" />
                    <Tab label="Claim" />
                    <Tab label="Uninstall" />
                </Tabs>
            </AppBar>

            {/* Tab Panels for each tab */}
            <TabPanel value={tabIndex} index={0}>
                <SubCompanyDetailComponent client={client} />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <SubCompanyRequestComponent client={client} />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
                <SubCompanyReturnComponent clientId={client?._id} />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
                <SubCompanyClaimComponent clientId={client?._id} />
            </TabPanel>
            <TabPanel value={tabIndex} index={4}>
                <SubCompanyUninstallComponent clientId={client?._id} />
            </TabPanel>
        </Box>
    );
}
