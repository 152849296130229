import { Autocomplete, Box, Button, Dialog, DialogContent, DialogContentText, Divider, FormControlLabel, FormGroup, Grid, List, ListItem, Modal, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useLocation } from "react-router-dom";
import { get_data, post_data } from "../api";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { City, State } from "country-state-city";
import { createButtonBg, formatDate, formatPrice, primaryColor } from "../constant";
import Swal from "sweetalert2";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { jsPDF } from "jspdf";
import "jspdf-autotable";



export default function RequestsDetailsTable({ requestData }) {
    const contentRef = useRef(null);
    const [modalOpen, setModalOpen] = useState(false)
    const { isLoggedIn, user_data } = useSelector(state => state.user);
    const location = useLocation();
    const requests = location?.state?.row || requestData;
    const [toggleGpsStatus, setToggleGpsStatus] = useState([])
    const [reason, setReason] = useState('');
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [countryCode] = useState('IN');
    const [errors, setErrors] = useState({});
    const [deviceModelList, setDeviceModelList] = useState([]);
    const [driverCityList, setDriverCityList] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [index, setIndex] = useState(0);
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [openInstall, setOpenInstall] = useState(false);
    const [reasonList, setReasonList] = useState([]);
    const [sno, setSno] = useState(0);
    const [installDate, setInstallDate] = useState(dayjs());
    const [installerList, setInstallerList] = useState([])
    const [deviceUniqueIdList,setDeviceUniqueIdList] = useState([])

    const [formData, setFormData] = useState({
        gps: requests?.gps?.map((gpsData) => ({
            deviceDetails: {
                network: {
                    airtel: gpsData?.deviceDetails?.network?.airtel || false,
                    vi: gpsData?.deviceDetails?.network?.vi || false,
                    bsnl: gpsData?.deviceDetails?.network?.bsnl || false,
                    jio: gpsData?.deviceDetails?.network?.jio || false,
                },
                otherDetails: {
                    new: gpsData?.deviceDetails?.otherDetails?.new || false,
                    rental: gpsData?.deviceDetails?.otherDetails?.rental || false,
                    renew: gpsData?.deviceDetails?.otherDetails?.renew || false,
                    repair: gpsData?.deviceDetails?.otherDetails?.repair || false,
                },
                imeiNo: gpsData?.deviceDetails?.imeiNo || '',
                simNo: gpsData?.deviceDetails?.simNo || '',
                uniqueId: gpsData?.deviceDetails?.uniqueId || '',
                amount: gpsData?.deviceDetails?.amount || 0,
                panicButton: gpsData?.deviceDetails?.panicButton || false,
                relay: gpsData?.deviceDetails?.relay || false,
                dateOfInstall: formatDates(gpsData?.deviceDetails?.dateOfInstall) || '',
                networkProvider: gpsData?.deviceDetails?.networkProvider || '',
                planType: gpsData?.deviceDetails?.planType || '',
            },
            driverDetails: {
                fullName: gpsData?.driverDetails?.fullName || '',
                pan: gpsData?.driverDetails?.pan || '',
                aadhar: gpsData?.driverDetails?.aadhar || '',
                badgeNo: gpsData?.driverDetails?.badgeNo || '',
                shift: gpsData?.driverDetails?.shift || '',
                contactNo: gpsData?.driverDetails?.contactNo || '',
                resContactNo: gpsData?.driverDetails?.resContactNo || '',
                address: {
                    street: gpsData?.driverDetails?.address?.street || '',
                    state: gpsData?.driverDetails?.address?.state || '',
                    city: gpsData?.driverDetails?.address?.city || '',
                    landmark: gpsData?.driverDetails?.address?.landmark || '',
                    zipCode: gpsData?.driverDetails?.address?.zipCode || '',
                },
                fatherName: gpsData?.driverDetails?.fatherName || '',
            },
            vehicleDetails: {
                // brand: gpsData?.vehicleDetails?.brand || '',
                // model: gpsData?.vehicleDetails?.model || '',
                regnNumber: gpsData?.vehicleDetails?.regnNumber || '',
                plate: gpsData?.vehicleDetails?.plate || '',
                regnOwnerName: gpsData?.vehicleDetails?.regnOwnerName || '',
                typeOfVehicle: gpsData?.vehicleDetails?.typeOfVehicle || '',
                vehicleClass: gpsData?.vehicleDetails?.vehicleClass || '',
                chassisNumber: gpsData?.vehicleDetails?.chassisNumber || '',
            },
            vendor: gpsData?.vendor || '',
        })) || [{
            deviceDetails: {
                network: { airtel: false, vi: false, bsnl: false, jio: false },
                otherDetails: { new: false, rental: false, renew: false, repair: false }
            },
            driverDetails: { contactNo: '', resContactNo: '', address: { street: '', zipCode: '' }, fatherName: '' },
            vehicleDetails: { brand: '', model: '', regnNumber: '', plate: '', regnOwnerName: '', typeOfVehicle: '', vehicleClass: '' },
        }],
        installerDetails: requests?.installerDetails || {},
    });

    function formatDates(dateString) {
        const date = new Date(dateString);
        if (isNaN(date)) return ''; // Handle invalid dates
        return date.toLocaleDateString('en-US'); // You can customize the locale and format as needed
    }

    const handleClose = () => {
        setReason('');
        setOpenInstall(false);
        setOpen(false);
    }

    const fetchAllDeviceUniqueIds = async () => {
        let result = await get_data(`admin-inventory/get-all-unique-devices`)
        if (result?.status) {
            console.log("DeviceUniqueIds: ", result)
            setDeviceUniqueIdList(result?.data)
        }
    } 

    const fetchAllInstallers = async () => {
        let result = await get_data(`installer/get-all-installers?pageNumber=1`)
        if (result?.status) {
            console.log("++++++++++++++++", result, requests)
            setInstallerList(result?.data?.installers)
        }
    }

    useEffect(function () {
        fetchAllInstallers()
        fetchAllDeviceUniqueIds()
    }, [])

    useEffect(function () {
        setToggleGpsStatus((prev) => {
            let updatedGpsStatus = requests?.gps?.map((item, idx) => {
                // if (!item?.reason) {
                //     return [...prev, false]
                // }
                return [...prev, item?.gpsStatus]
            })
            return updatedGpsStatus;
        })

    }, [requests])

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });


    const getTrueNetworkProvider = (network) => {
        const activeProviders = Object.keys(network).filter((provider) => network[provider]);
        return activeProviders.length > 0 ? activeProviders.join(', ') : 'None'; // Display comma-separated if multiple
    };

    const getTrueOtherDetails = (details) => {
        const activeDetails = Object.keys(details).filter((key) => details[key]);
        return activeDetails.length > 0 ? activeDetails.join(', ') : 'None'; // Display comma-separated if multiple
    };

    const handleChange = async (value, index) => {
        if (value === "Not Installed") {
            setOpen(true);
            setSno(index);
            return;
        }
        if (value === "Installed") {
            setOpenInstall(true);
            setSno(index);
            return
        }

        let result = await post_data(`request/change-gps-status/${requests?._id}`,
            {
                index: index,
                gpsStatus: value === "Installeded" ? "Installed" : "Not Installed",
                reason: reason,
                DateOfInstall: value === "Installeded" ? new Date(installDate) : null
            });


        if (result?.status == true) {
            toast.success(result?.message)
            setToggleGpsStatus((prev) => {
                let updatedGpsStatus = prev?.map((item, idx) => {
                    if (index == idx) {
                        return [
                            value === "Installeded" ? "Installed" : "Not Installed"
                        ]
                    }
                    return item
                })
                return updatedGpsStatus
            })
            handleClose()
        } else {
            toast.error(result?.response?.data?.message || 'Something went wrong')
        }
    }

    useEffect(() => {
        setStateList(State.getStatesOfCountry(countryCode));
    }, [countryCode]);

    const handleStateChange = (event, newValue) => {
        if (newValue) {
            const stateCode = newValue.isoCode;
            const fetchedCities = City.getCitiesOfState(countryCode, newValue.isoCode);

            setFormData(prevState => ({
                ...prevState,
                customerDetails: {
                    ...prevState.customerDetails,
                    address: {
                        ...prevState.customerDetails.address,
                        state: stateCode, // Update state
                        city: '' // Reset city when state changes
                    }
                }
            }));

            setCityList(fetchedCities); // Populate city list based on selected state
        }
    };

    const handleCityChange = (event, newValue) => {
        if (newValue) {
            setFormData(prevState => ({
                ...prevState,
                customerDetails: {
                    ...prevState.customerDetails,
                    address: {
                        ...prevState.customerDetails.address,
                        city: newValue.name // Update city
                    }
                }
            }));
        }
    };

    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const handleDeviceInputChange = (index, field, value) => {
        setFormData((prevState) => {
            const updatedGps = prevState.gps.map((form, idx) => {
                if (index === idx) {
                    const newDeviceInfo = { ...form.deviceDetails, [field]: value };

                    // Automatically set IMEI No if deviceModel is changed
                    if (field === 'deviceModel') {
                        const imeiNo = fetchIMEINo(value);
                        newDeviceInfo.imeiNo = imeiNo ? imeiNo : ''; // Auto-fill IMEI if available
                    }

                    return { ...form, deviceDetails: newDeviceInfo };
                }
                return form;
            });

            return { ...prevState, gps: updatedGps };
        });
    };

    // Fetch device model list
    const fetchdeviceModelList = async () => {
        try {
            const data = await get_data(`product/get-all-products-for-options`);
            setDeviceModelList(data?.data || []);
        } catch (error) {
        }
    };

    // Fetch IMEI based on selected device model
    const fetchIMEINo = (deviceModel) => {
        const device = deviceModelList?.find((d) => d._id === deviceModel);
        return device ? device.imeiNo : '';
    };

    useEffect(() => {
        fetchdeviceModelList();
    }, []);

    const handleDeviceSwitchChange = (index, provider) => {
        setFormData((prevState) => {
            const updatedGps = prevState.gps.map((form, idx) => {
                if (index === idx) {
                    // Set the network as the selected provider's name as a string
                    return { ...form, deviceDetails: { ...form.deviceDetails, network: { [provider.toLowerCase()]: true } } };
                }
                return form;
            });

            return { ...prevState, gps: updatedGps };
        });
    };
    const handleDeviceSwitchChange2 = (index, provider) => {
        setFormData(prevState => {
            const updatedGps = prevState.gps.map((form, idx) => {
                if (index === idx) {
                    let updatedOtherDetails = form.deviceDetails.otherDetails === provider
                        ? ''  // Deselect if the same provider is clicked again
                        : provider; // Set the selected provider

                    updatedOtherDetails = updatedOtherDetails.toLowerCase();

                    return { ...form, deviceDetails: { ...form.deviceDetails, otherDetails: { [updatedOtherDetails]: true } } };
                }
                return form;
            });

            return { ...prevState, gps: updatedGps, };
        });
    };

    const handleDriverInputChange = (index, field, value) => {
        setFormData((prevState) => {
            const updatedGps = prevState.gps.map((form, idx) => {
                if (index === idx) {
                    if (['street', 'landmark', 'city', 'state', 'zipCode'].includes(field)) {
                        // Update address inside driverDetails
                        return {
                            ...form, driverDetails: { ...form.driverDetails, address: { ...form.driverDetails.address, [field]: value, } },
                        };
                    } else {

                        return {
                            ...form, driverDetails: { ...form.driverDetails, [field]: value }
                        };
                    }
                }
                return form; // Return unchanged form for other indices
            });
            return { ...prevState, gps: updatedGps, };
        });
    };

    // console.log('requests', requests);
    // console.log('formdata,', formData);
    useEffect(() => {
        const countryCode = 'IN';
        requests?.gps?.map((gpsData) => {
            if (gpsData?.driverDetails?.address?.state) {
                setDriverCityList(City.getCitiesOfState(countryCode, gpsData?.driverDetails?.address?.state));
            }
        })

    }, [formData?.driverDetails?.address?.state, countryCode]);
    const handleDriverStateChange = (index, event, newValue) => {
        if (newValue) {
            const stateCode = newValue.isoCode;
            const countryCode = 'IN'; // Replace with actual country code

            // Fetch cities based on selected state
            const fetchedCities = City.getCitiesOfState(countryCode, stateCode);
            setDriverCityList(fetchedCities); // Update city list based on state

            setFormData(prevState => {
                const updatedGps = prevState.gps.map((form, idx) => {
                    if (index === idx) {
                        return {
                            ...form,
                            driverDetails: { ...form.driverDetails, address: { ...form.driverDetails.address, state: stateCode, city: '' } },
                        };
                    }
                    return form; // Return unchanged form for other indices
                });

                return {
                    ...prevState,
                    gps: updatedGps,
                };
            });
        }
    };

    // Handle city change
    const handleDriverCityChange = (index, event, newValue) => {
        if (newValue) {
            setFormData(prevState => {
                const updatedGps = prevState.gps.map((form, idx) => {
                    if (index === idx) {
                        return {
                            ...form,
                            driverDetails: {
                                ...form.driverDetails, address: { ...form.driverDetails.address, city: newValue.name, }
                            },
                        };
                    }
                    return form; // Return unchanged form for other indices
                });

                return {
                    ...prevState,
                    gps: updatedGps,
                };
            });
        }
    };

    const handleVehicleInputChange = (index, field, value) => {
        setFormData((prevState) => {
            const updatedGps = prevState.gps.map((form, idx) => {
                if (index === idx) {
                    // Update the vehicleDetails field dynamically
                    return {
                        ...form,
                        vehicleDetails: {
                            ...form.vehicleDetails,
                            [field]: value, // Dynamically update the vehicle field
                        },
                    };
                }
                return form; // Return unchanged form for other indices
            });

            return {
                ...prevState,
                gps: updatedGps, // Update the gps array in formData
            };
        });

        if (field == 'brand') {
            getAllModels(index, value)
        }
    };

    const getAllModels = async (index, value) => {
        let result = await get_data(`model/get-all-model-by-brand/${value}`)
        if (result?.status == true) {

            setModelList((prev) => {
                let updatedModelList = formData?.gps?.map((item, idx) => {
                    if (index == idx) {
                        return result?.data
                    }
                    return modelList?.[idx]
                })
                return updatedModelList
            })
        } else {
            toast.error(result?.message)
        }
    }

    const handleInstallerInputChange = (value) => {
        // setFormData((prevState) => {
        //     return { ...prevState, installerDetails: { ...prevState.installerDetails, 'name': value?.name, 'contactNo': value?.contactNo } };
        // });
        setFormData((prevState) => {
            return { ...prevState, installerDetails: value };
        });
    }

    const buttonStyles = {
        borderColor: primaryColor,
        color: 'white',
        background: primaryColor,
        textTransform: 'capitalize',
        borderRadius: 0,
        fontWeight: 400,
        padding: '1% 3.5%',
        boxShadow: 'none',
        '&:hover': {
            borderColor: primaryColor,
            boxShadow: 'none',
        },
        '&:focus': {

            boxShadow: 'none',
            borderColor: primaryColor,
        }
    };



    const handleEdit = async (event) => {
        event.preventDefault();
        setLoader(true)
        const respons = await post_data(`request/update-request/${requests?._id}`, { ...formData, installerDetails: formData?.installerDetails?._id })
        if (respons.status) {
            toast.success('Request Details Updated Successfully')
        }
        else {
            toast.success('Something Went Wrong')
        }
        setLoader(false)
    }


    const fetchAllReason = async () => {
        const data = await get_data(`reason/get-all-reasons-for-options`)
        console.log("xxxxxxxxxxx", data)
        if (data.status === true) {
            setReasonList(data?.data || [])
        }
    }

    useEffect(() => {
        fetchAllReason()
    }, [requests])




    const handleDownloadPdf = async () => {
        const customPageWidth = 210; // Width in mm for an A4 landscape format
        const margin = 10;
        const rowHeight = 8;
        const sectionTitleHeight = 12;
        const headerHeight = 15;
        const pageHeight = 297; // A4 paper height in mm

        const pdf = new jsPDF({
            orientation: "landscape",
            unit: "mm",
            format: "a4",
        });

        let positionY = margin;

        const addSectionTitle = (title) => {
            pdf.setFontSize(14);
            pdf.text(title, margin, positionY);
            positionY += sectionTitleHeight;
            checkPageBreak();
        };

        const checkPageBreak = () => {
            if (positionY + rowHeight > pageHeight - margin) {
                pdf.addPage();
                positionY = margin; // Reset position to top of new page
            }
        };

        // Customer Details Section
        addSectionTitle("Customer Details");
        const customerDetails = [
            ["Request ID", requests?.uniqueRequestId],
            ["Company Name", requests?.customerDetails?.companyName],
            ["State", requests?.customerDetails?.address?.state],
            ["City", requests?.customerDetails?.address?.city],
            ["Street", requests?.customerDetails?.address?.street],
            ["Landmark", requests?.customerDetails?.address?.landmark],
            ["Zip Code", requests?.customerDetails?.address?.zipCode],
            ["Contact No", requests?.customerDetails?.address?.contactNo],
            ["Residential Contact No", requests?.customerDetails?.address?.resContactNo],
        ];

        pdf.autoTable({
            startY: positionY,
            head: [["Field", "Value"]],
            body: customerDetails.map(([label, value]) => [label, value || "-"]),
            theme: "grid",
            margin: { top: 10 },
            styles: { fontSize: 10 },

        });

        positionY = pdf.lastAutoTable.finalY + rowHeight; // Update Y position after the table

        // GPS Details Section (dynamically handled from formData)
        addSectionTitle("GPS Details");
        requests.gps.forEach((gpsData, index) => {
            addSectionTitle(`Device Details ${index + 1}`);
            const deviceDetails = [
                ["Gps Install Date", gpsData.DateOfInstall || "-"],
                ["IMEI No", gpsData.deviceDetails?.imeiNo],
                ["SIM No", gpsData.deviceDetails?.simNo],
                ["Device Linkage ID", gpsData.deviceDetails?.uniqueId],
                ["Network", Object.keys(gpsData.deviceDetails?.network).filter(key => gpsData.deviceDetails?.network[key]).join(", ") || "None"],
                ["Network Provider", Object.keys(gpsData.deviceDetails?.network).filter(key => gpsData.deviceDetails?.network[key]).map(network => network.charAt(0).toUpperCase() + network.slice(1)).join(", ") || "-"],
                ["Main Type", gpsData.deviceDetails?.mainType || "-"],
                ["Relay", gpsData.deviceDetails?.relay ? "Enabled" : "Disabled"],
                ["Panic Button", gpsData.deviceDetails?.panicButton ? "Enabled" : "Disabled"],
                ["Other Details", Object.keys(gpsData.deviceDetails?.otherDetails).filter(key => gpsData.deviceDetails?.otherDetails[key]).join(", ") || "None"],
                ["Amount", gpsData.deviceDetails?.amount || 0],
            ];

            pdf.autoTable({
                startY: positionY,
                head: [["Field", "Value"]],
                body: deviceDetails.map(([label, value]) => [label, value]),
                theme: "grid",
                margin: { top: 10 },
                styles: { fontSize: 10 },
            });

            positionY = pdf.lastAutoTable.finalY + rowHeight; // Update Y position after the table

            // Driver Details Section
            addSectionTitle(`Driver Details ${index + 1}`);
            const driverDetails = [
                ["Full Name", gpsData.driverDetails?.fullName],
                ["Father Name", gpsData.driverDetails?.fatherName],
                ["Contact No", gpsData.driverDetails?.contactNo],
                ["Residential Contact No", gpsData.driverDetails?.resContactNo],
                ["Aadhar", gpsData.driverDetails?.aadhar || "-"],
                ["PAN", gpsData.driverDetails?.pan || "-"],
                ["Badge No", gpsData.driverDetails?.badgeNo || "-"],
                ["Shift", gpsData.driverDetails?.shift || "-"],
            ];

            pdf.autoTable({
                startY: positionY,
                head: [["Field", "Value"]],
                body: driverDetails.map(([label, value]) => [label, value]),
                theme: "grid",
                margin: { top: 10 },
                styles: { fontSize: 10 },
            });

            positionY = pdf.lastAutoTable.finalY + rowHeight; // Update Y position after the table

            // Vehicle Details Section
            addSectionTitle(`Vehicle Details ${index + 1}`);
            const vehicleDetails = [
                ["Owner Name", gpsData.vehicleDetails?.regnOwnerName],
                ["Registration Number", gpsData.vehicleDetails?.regnNumber],
                ["Plate", gpsData.vehicleDetails?.plate],
                ["Type of Vehicle", gpsData.vehicleDetails?.typeOfVehicle],
                ["Vehicle Class", gpsData.vehicleDetails?.vehicleClass],
            ];

            pdf.autoTable({
                startY: positionY,
                head: [["Field", "Value"]],
                body: vehicleDetails.map(([label, value]) => [label, value]),
                theme: "grid",
                margin: { top: 10 },
                styles: { fontSize: 10 },
            });

            positionY = pdf.lastAutoTable.finalY + rowHeight; // Update Y position after the table
        });

        // Installer Details Section
        addSectionTitle("Installer Details");
        const installerDetails = [
            ["Name", requests.installerDetails?.name || "-"],
            ["Contact No", requests.installerDetails?.contactNo || "-"],
        ];

        pdf.autoTable({
            startY: positionY,
            head: [["Field", "Value"]],
            body: installerDetails.map(([label, value]) => [label, value]),
            theme: "grid",
            margin: { top: 10 },
            styles: { fontSize: 10 },
        });

        pdf.save("request_details.pdf");
    };



    console.log("deviceUniqueIdList.find(s => s?.deviceUniqueId === formData?.gps[index]?.deviceDetails?.uniqueId", deviceUniqueIdList.find(s=>s?.deviceUniqueId == formData?.gps[0]?.deviceDetails?.uniqueId),deviceUniqueIdList,formData,formData?.gps[index]?.deviceDetails?.uniqueId)

    return (
        <div>

            <div style={{ background: 'white', padding: 20, borderRadius: '5px' }} >
                <Toaster position="top-right" reverseOrder={false} />
                <Typography variant='h6' style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <div>Company Detail</div>
                    <div>
                        <Button style={{
                            backgroundColor: primaryColor,
                            color: '#ffff',
                            cursor: 'pointer',
                            boxShadow: 'none',
                            marginRight: 10

                        }}
                            variant="contained"
                            onClick={handleDownloadPdf}
                        >
                            Download PDF
                        </Button>

                        <Button style={{
                            backgroundColor: primaryColor,
                            color: '#ffff',
                            cursor: 'pointer',
                            boxShadow: 'none'
                        }}
                            variant="contained"
                            onClick={handleEdit}
                        >
                            {loader ? 'Updating...' : 'Update'}
                        </Button>
                    </div>
                </Typography>
                {/* Customer Details */}
                <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                    <List sx={{ border: '1px solid gainsboro' }}>
                        <React.Fragment >

                            <ListItem>
                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Request ID  </div>
                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                    {requests?.uniqueRequestId || 'NA'}
                                </div>
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Company Name  </div>
                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                    {requests?.customerDetails?.companyName}
                                </div>
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  State  </div>
                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                    {requests?.customerDetails?.address?.state}
                                </div>
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  City  </div>
                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                    {requests?.customerDetails?.address?.city}
                                </div>
                            </ListItem>
                            <Divider />


                            <ListItem>
                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Street  </div>
                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                    {requests?.customerDetails?.address?.street}
                                </div>
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Landmark  </div>
                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                    {requests?.customerDetails?.address?.landmark}
                                </div>
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Zip Code  </div>
                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                    {requests?.customerDetails?.address?.zipCode}
                                </div>
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Contact No  </div>
                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                    {requests?.customerDetails?.contactNo}
                                </div>
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Residential Contact No  </div>
                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                    {requests?.customerDetails?.resContactNo}
                                </div>
                            </ListItem>
                        </React.Fragment>
                    </List>
                </Typography>

                {requests?.subVendorDetails ? <>
                    <Typography variant='h6' style={{ margin: '10px 0px' }}>Sub Vendor Details</Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                        <List sx={{ border: '1px solid gainsboro' }}>
                            <React.Fragment >
                                <ListItem>
                                    <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Sub Vendor Name  </div>
                                    <div style={{ width: '60%', marginLeft: '10px' }}> {requests?.subVendor?.name || '-'}</div>
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Address  </div>
                                    <div style={{ width: '60%', marginLeft: '10px' }}>
                                        {requests?.subVendor?.address?.street + ' ' + requests?.subVendor?.address?.state + " " + requests?.subVendor?.address?.landmark + ' ' + requests?.subVendor?.address?.city + ' ' + requests?.subVendor?.address?.zipCode}
                                    </div>
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Contact No  </div>
                                    <div style={{ width: '60%', marginLeft: '10px' }}> {requests?.subVendor?.contactNo}</div>
                                </ListItem>
                            </React.Fragment>
                        </List>
                    </Typography>
                </> : <>
                </>}


                <Typography variant='h6' style={{ margin: '10px 0px' }}>Gps Details</Typography>
                {requests?.gps?.map((item, index) => {
                    return (<div style={{ marginTop: 10, gap: '10px', width: '100%', display: 'flex' }}>
                        <Accordion style={{ width: '80%' }}>
                            <AccordionSummary
                                expandIcon={<ArrowDownwardIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography>Gps {index + 1}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>

                                <Typography variant='h6' style={{ margin: '10px 0px' }}>Device Details</Typography>
                                <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                                    <List sx={{ border: '1px solid gainsboro' }}>
                                        <React.Fragment >
                                            {/* <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Device Model   </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                 <Autocomplete
                                                    id="country-deviceModel"
                                                    name="deviceModel"
                                                    options={deviceModelList} // List of available device models
                                                    value={deviceModelList.find(c => c._id === (formData.gps[index]?.deviceDetails?.deviceModel || item?.deviceDetails?.deviceModel)) || null}
                                                    onChange={(event, newValue) => handleDeviceInputChange(index, 'deviceModel', newValue ? newValue._id : '')}
                                                    autoHighlight
                                                    getOptionLabel={(option) => option.model}

                                                    renderOption={(props, option) => (
                                                        <Box
                                                            component="li"
                                                            {...props}
                                                            key={option._id}
                                                        >
                                                            {option.name}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            name="model"
                                                            error={!!errors.deviceModel}
                                                            helperText={errors.deviceModel}
                                                            onFocus={() => handleError('', 'deviceModel')}
                                                            {...params}
                                                            label="Select Device Model"
                                                            fullWidth
                                                            variant="standard"
                                                        />
                                                    )}
                                                /> 
                                            </div>
                                        </ListItem>
                                        <Divider /> */}
                                            {/* {console.log('formdata', formData.gps[index])} */}

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Gps Install Date  </div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    {/* {formatDate(requests?.gps[index]?.deviceDetails?.dateOfInstall) === '-NaN' ? '-' : formatDate(requests?.gps[index]?.deviceDetails?.dateOfInstall)} */}
                                                    {formData?.gps[index]?.deviceDetails?.dateOfInstall}
                                                </div>
                                            </ListItem>
                                            <Divider />

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}> Device Unique ID  </div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <Autocomplete
                                                    id="device-unique-id-select"
                                                    options={deviceUniqueIdList}
                                                    value={deviceUniqueIdList.find(s => s?.deviceUniqueId == formData?.gps[index]?.deviceDetails?.uniqueId || '') || null}
                                                    onChange={(event, newValue) => {
                                                        handleDeviceInputChange(index, 'uniqueId', newValue?.deviceUniqueId)
                                                        handleDeviceInputChange(index, 'imeiNo', newValue?.imeiNumber)
                                                        handleDeviceInputChange(index, 'simNo', newValue?.simNumber)
                                                    } }
                                                    getOptionLabel={(option) => option?.deviceUniqueId}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            // error={!!errors?.country} helperText={errors?.country} onFocus={() => handleError('', 'country')}
                                                            {...params}
                                                            label="Select Device Unique Id"
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                                </div>
                                            </ListItem>
                                            <Divider />

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>IMEI NO  </div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    <input type='text'
                                                        value={formData.gps[index]?.deviceDetails?.imeiNo}
                                                        style={{


                                                            width: '100%',
                                                            color: "#666666"
                                                        }}
                                                        disabled
                                                        onChange={(e) => handleDeviceInputChange(index, 'imeiNo', e.target.value)}
                                                    />
                                                </div>
                                            </ListItem>
                                            <Divider />

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  SIM No  </div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    <input type='text' value={formData.gps[index]?.deviceDetails?.simNo}
                                                        style={{


                                                            width: '100%',
                                                            color: "#666666"
                                                        }}
                                                        disabled
                                                        onChange={(e) => handleDeviceInputChange(index, 'simNo', e.target.value)}
                                                    />
                                                </div>
                                            </ListItem>
                                            <Divider />

                                            {/* <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}> Device Unique ID </div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    <input
                                                        type='text'
                                                        value={formData.gps[index]?.deviceDetails?.uniqueId}
                                                        style={{

                                                            width: '100%',

                                                            color: "#666666"
                                                        }}
                                                        onChange={(e) => handleDeviceInputChange(index, 'uniqueId', e.target.value)}
                                                    />
                                                </div>
                                            </ListItem>
                                            <Divider /> */}

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Network </div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>

                                                    <FormGroup style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        {['Airtel', 'Vi', 'BSNL', 'Jio'].map((provider) => (
                                                            <FormControlLabel

                                                                key={provider}
                                                                control={
                                                                    <Switch
                                                                        error={!!errors.network}
                                                                        onFocus={() => handleError('', 'network')}
                                                                        checked={formData.gps[index]?.deviceDetails?.network[provider.toLowerCase()] === true}
                                                                        onChange={() => handleDeviceSwitchChange(index, provider)} // Pass index and provider
                                                                    />
                                                                }
                                                                label={provider}
                                                            />
                                                        ))}
                                                    </FormGroup>
                                                </div>
                                            </ListItem>
                                            <Divider />

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Network Provider</div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    <input type='text' value={formData.gps[index]?.deviceDetails?.networkProvider}
                                                        style={{


                                                            width: '100%',
                                                            color: "#666666"
                                                        }}

                                                        onChange={(e) => handleDeviceInputChange(index, 'networkProvider', e.target.value)}

                                                    />
                                                </div>
                                            </ListItem>
                                            <Divider />

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Plan Type</div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    <input type='text' value={formData.gps[index]?.deviceDetails?.planType}
                                                        style={{


                                                            width: '100%',
                                                            color: "#666666"
                                                        }}

                                                        onChange={(e) => handleDeviceInputChange(index, 'planType', e.target.value)}

                                                    />
                                                </div>
                                            </ListItem>
                                            <Divider />

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Relay </div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    <FormControlLabel
                                                        key={'relay'}
                                                        control={
                                                            <Switch
                                                                error={!!errors.relay}
                                                                onFocus={() => handleError('', 'relay')}
                                                                checked={formData.gps[index]?.deviceDetails?.relay === true}
                                                                onChange={() => setFormData((prevState) => {
                                                                    const updatedGps = prevState.gps.map((form, idx) => {
                                                                        if (index === idx) {
                                                                            let dataForUpdate = (formData.gps[index]?.deviceDetails?.relay === true) ? false : true
                                                                            return { ...form, deviceDetails: { ...form.deviceDetails, relay: dataForUpdate } };
                                                                        }
                                                                        return form;
                                                                    });
                                                                    return { ...prevState, gps: updatedGps };
                                                                })}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </ListItem>
                                            <Divider />

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Panic Button</div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    <FormControlLabel

                                                        key={'panicButton'}
                                                        control={
                                                            <Switch
                                                                error={!!errors.panicButton}
                                                                onFocus={() => handleError('', 'panicButton')}
                                                                checked={formData.gps[index]?.deviceDetails?.panicButton === true}
                                                                onChange={() => setFormData((prevState) => {
                                                                    const updatedGps = prevState.gps.map((form, idx) => {

                                                                        if (index === idx) {
                                                                            let dataForUpdate = (formData.gps[index]?.deviceDetails?.panicButton === true) ? false : true
                                                                            return { ...form, deviceDetails: { ...form.deviceDetails, panicButton: dataForUpdate } };
                                                                        }
                                                                        return form;
                                                                    });
                                                                    return { ...prevState, gps: updatedGps };
                                                                })}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </ListItem>
                                            <Divider />

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Date Of Install </div>

                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    <input type='date' value={formData?.gps[index]?.deviceDetails?.dateOfInstall}
                                                        style={{


                                                            width: '100%',
                                                            color: "#666666"
                                                        }}
                                                        onChange={(e) => handleDeviceInputChange(index, 'dateOfInstall', e.target.value)}
                                                    />
                                                </div>
                                            </ListItem>
                                            <Divider />

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Other Details </div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    <FormGroup style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        {['New', 'Repair', 'Renew', 'Rental'].map((provider) => (
                                                            <FormControlLabel
                                                                key={provider}
                                                                control={
                                                                    <Switch
                                                                        error={!!errors.OtherDetails}
                                                                        onFocus={() => handleError('', 'OtherDetails')}
                                                                        checked={formData.gps[index]?.deviceDetails?.otherDetails[provider?.toLowerCase()] === true}
                                                                        onChange={() => handleDeviceSwitchChange2(index, provider)}
                                                                    />
                                                                }
                                                                label={provider}
                                                            />
                                                        ))}
                                                    </FormGroup>
                                                </div>
                                            </ListItem>
                                            <Divider />

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>Amount </div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    <input type='text' value={formData.gps[index]?.deviceDetails?.amount}
                                                        style={{


                                                            width: '100%',
                                                            color: "#666666"
                                                        }}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value.replace(/\D/g, '').slice(0, 10)
                                                            handleDeviceInputChange(index, 'amount', newValue)
                                                        }}
                                                    />
                                                </div>
                                            </ListItem>
                                        </React.Fragment>
                                    </List>
                                </Typography>

                                <Typography variant='h6' style={{ margin: '10px 0px' }}>Driver Details</Typography>
                                <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                                    <List sx={{ border: '1px solid gainsboro' }}>
                                        <React.Fragment >
                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Full Name   </div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    <input
                                                        type='text'
                                                        value={formData.gps[index]?.driverDetails?.fullName}
                                                        name="fullName"
                                                        style={{

                                                            width: '100%',

                                                            color: "#666666"
                                                        }}
                                                        onChange={(e) => handleDriverInputChange(index, 'fullName', e.target.value)}
                                                    />
                                                </div>
                                            </ListItem>
                                            <Divider />

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>Father Name </div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    <input
                                                        type='text'
                                                        value={formData.gps[index]?.driverDetails?.fatherName}
                                                        name="fatherName"
                                                        style={{


                                                            width: '100%',
                                                            color: "#666666"
                                                        }}
                                                        onChange={(e) => handleDriverInputChange(index, 'fatherName', e.target.value)}
                                                    />
                                                </div>
                                            </ListItem>
                                            <Divider />

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Contact No </div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    <input
                                                        value={formData.gps[index]?.driverDetails?.contactNo}
                                                        name="contactNo"
                                                        style={{


                                                            width: '100%',
                                                            color: "#666666"
                                                        }}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value.replace(/\D/g, '').slice(0, 10);
                                                            handleDriverInputChange(index, 'contactNo', newValue)
                                                        }}
                                                    />
                                                </div>
                                            </ListItem>
                                            <Divider />

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Residential Contact No  </div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>

                                                    <input

                                                        value={formData.gps[index]?.driverDetails?.resContactNo}
                                                        name="resContactNo"
                                                        style={{


                                                            width: '100%',
                                                            color: "#666666"
                                                        }}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value.replace(/\D/g, '').slice(0, 10)
                                                            handleDriverInputChange(index, 'resContactNo', newValue)
                                                        }}
                                                    />
                                                </div>
                                            </ListItem>
                                            <Divider />

                                            {/* <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}> address </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}> {item?.driverDetails?.address?.street + ' ' + item?.driverDetails?.address?.landmark + ' ' + item?.driverDetails?.address?.state + ' ' + item?.driverDetails?.address?.city + ' ' + item?.driverDetails?.address?.zipCode || '-'}</div>
                                        </ListItem>
                                        <Divider /> */}

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Street  </div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    <input type='text' fullWidth value={formData.gps[index]?.driverDetails?.address?.street}
                                                        style={{


                                                            color: "#666666",
                                                            width: '100%',

                                                        }}
                                                        onChange={(e) => handleDriverInputChange(index, 'street', e.target.value)}
                                                    />
                                                </div>
                                            </ListItem>
                                            <Divider />

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Landmark  </div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    <input type='text'
                                                        value={formData.gps[index]?.driverDetails?.address?.landmark}
                                                        style={{


                                                            color: "#666666",
                                                            width: '100%',
                                                        }}
                                                        onChange={(e) => handleDriverInputChange(index, 'landmark', e.target.value)}
                                                    />
                                                </div>
                                            </ListItem>
                                            <Divider />

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Zip Code  </div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    <input type='text' value={formData.gps[index]?.driverDetails?.address?.zipCode}
                                                        style={{


                                                            width: '100%',
                                                            color: "#666666"
                                                        }}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value.replace(/\D/g, '').slice(0, 10)
                                                            handleDriverInputChange(index, 'zipCode', newValue)
                                                        }}
                                                    />
                                                </div>
                                            </ListItem>
                                            <Divider />

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  State  </div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    <Autocomplete
                                                        id="state-select"
                                                        options={stateList}
                                                        value={stateList?.find(s => s.isoCode === (formData?.gps?.[index]?.driverDetails?.address?.state)) || null}
                                                        onChange={(event, newValue) => handleDriverStateChange(index, event, newValue)}
                                                        getOptionLabel={(option) => option.name}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                error={!!errors.driverState}
                                                                helperText={errors.driverState}
                                                                onFocus={() => handleError('', 'state')}
                                                                {...params}
                                                                label="Select State"
                                                                fullWidth
                                                                variant="standard"
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </ListItem>
                                            <Divider />

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  City  </div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    <Autocomplete
                                                        id="city-select"
                                                        options={driverCityList}
                                                        value={driverCityList?.find(c => c.name === (formData?.gps?.[index]?.driverDetails?.address?.city || item?.driverDetails?.address?.city)) || null}
                                                        onChange={(event, newValue) => handleDriverCityChange(index, event, newValue)}
                                                        getOptionLabel={(option) => option.name}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                error={!!errors.driverCity}
                                                                helperText={errors.driverCity}
                                                                onFocus={() => handleError('', 'driverCity')}
                                                                label="Select City"
                                                                fullWidth
                                                                variant="standard"
                                                            />
                                                        )}
                                                    />

                                                </div>
                                            </ListItem>


                                        </React.Fragment>
                                    </List>
                                </Typography>


                                <Typography variant='h6' style={{ margin: '10px 0px' }}>Vehicle Details</Typography>
                                <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                                    <List sx={{ border: '1px solid gainsboro' }}>
                                        <React.Fragment >
                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Owner Name </div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    <input type='text' value={formData.gps[index]?.vehicleDetails?.regnOwnerName || item?.vehicleDetails?.regnOwnerName} placeholder='regnOwnerName'
                                                        name="regnOwnerName"
                                                        style={{


                                                            width: '100%',
                                                            color: "#666666"
                                                        }}

                                                        onChange={(e) => handleVehicleInputChange(index, 'regnOwnerName', e.target.value)}

                                                    />
                                                </div>
                                            </ListItem>
                                            <Divider />

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Registration Number   </div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    <input type='text' value={formData.gps[index]?.vehicleDetails?.regnNumber || item?.vehicleDetails?.regnNumber} placeholder='regnNumber'
                                                        name="regnNumber"
                                                        style={{


                                                            width: '100%',
                                                            color: "#666666"
                                                        }}

                                                        onChange={(e) => handleVehicleInputChange(index, 'regnNumber', e.target.value)}

                                                    />
                                                </div>
                                            </ListItem>
                                            <Divider />

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>Plate </div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    <input type='text' value={formData.gps[index]?.vehicleDetails?.plate || item?.vehicleDetails?.plate} placeholder='plate'
                                                        name="plate"
                                                        style={{


                                                            width: '100%',
                                                            color: "#666666"
                                                        }}

                                                        onChange={(e) => handleVehicleInputChange(index, 'plate', e.target.value)}

                                                    />
                                                </div>
                                            </ListItem>
                                            <Divider />

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Type Of Vehicle</div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    <input type='text' value={formData.gps[index]?.vehicleDetails?.typeOfVehicle || item?.vehicleDetails?.typeOfVehicle} placeholder='typeOfVehicle'
                                                        name="typeOfVehicle"
                                                        style={{


                                                            width: '100%',
                                                            color: "#666666"
                                                        }}

                                                        onChange={(e) => handleVehicleInputChange(index, 'typeOfVehicle', e.target.value)}

                                                    />
                                                </div>
                                            </ListItem>
                                            <Divider />

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}> Vehicle Class </div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    <input type='text' value={formData.gps[index]?.vehicleDetails?.vehicleClass || item?.vehicleDetails?.vehicleClass} placeholder='vehicleClass'
                                                        name="vehicleClass"
                                                        style={{


                                                            width: '100%',
                                                            color: "#666666"
                                                        }}

                                                        onChange={(e) => handleVehicleInputChange(index, 'vehicleClass', e.target.value)}

                                                    />
                                                </div>
                                            </ListItem>
                                            <Divider />

                                            <ListItem>
                                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}> Chassis Number </div>
                                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                                    <input
                                                        type='text'
                                                        label="Chassis Number"
                                                        name="chassisNumber"
                                                        style={{


                                                            width: '100%',
                                                            color: "#666666"
                                                        }}
                                                        value={formData.gps[index]?.vehicleDetails?.chassisNumber}
                                                        onChange={(e) => handleVehicleInputChange(index, 'chassisNumber', e.target.value)}
                                                        fullWidth
                                                    />
                                                </div>
                                            </ListItem>
                                        </React.Fragment>
                                    </List>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>



                        <ToggleButtonGroup
                            color="primary"
                            value={toggleGpsStatus?.[index]}
                            exclusive
                            onChange={(e) => { handleChange(e.target.value, index) }}
                            aria-label="Platform"
                            style={{ marginLeft: 'auto', height: '50px' }}
                        >
                            <ToggleButton value="Installed" style={{ fontSize: '.7rem' }}>Installed</ToggleButton>
                            <ToggleButton value="Not Installed" style={{ fontSize: '.7rem' }}>Not Installed</ToggleButton>
                        </ToggleButtonGroup>

                        <Modal
                            open={open}
                            onClose={handleClose}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: 400,
                                    bgcolor: 'background.paper',
                                    border: '1px solid #fff',
                                    boxShadow: 24,
                                    p: 4,
                                    borderRadius: '10px'
                                }}
                            >

                                <Autocomplete
                                    options={reasonList}
                                    value={reasonList?.find(s => s.name === reason) || null}
                                    onChange={(e, newValue) => {
                                        if (newValue) {
                                            setReason(newValue.name);
                                        }
                                    }}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            {...props}
                                            value={option.name}
                                            key={option._id}
                                        >
                                            {option.name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Reason"
                                            fullWidth
                                        />
                                    )}
                                />

                                <Button variant="contained"
                                    fullWidth
                                    style={{ marginTop: '10px', borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                                    onClick={() => handleChange("Not Installeded", sno)}>Submit</Button>
                            </Box>
                        </Modal>

                        <Modal
                            open={openInstall}
                            onClose={handleClose}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: 400,
                                    bgcolor: 'background.paper',
                                    border: '1px solid #fff',
                                    boxShadow: 24,
                                    p: 4,
                                    borderRadius: '10px'
                                }}
                            >
                                <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        fullWidth
                                        label="Date of Install"
                                        value={installDate}
                                        onChange={(newValue) => setInstallDate(newValue)}
                                    />
                                </LocalizationProvider>

                                <Button variant="contained"
                                    fullWidth
                                    style={{ marginTop: '10px', borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                                    onClick={() => handleChange("Installeded", sno)}>Submit</Button>
                            </Box>
                        </Modal>
                    </div >
                    )
                })}

                <Typography variant='h6' style={{ margin: '10px 0px' }}>Installer Details</Typography>
                <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                    <List sx={{ border: '1px solid gainsboro' }}>
                        {/* <React.Fragment >
                            <ListItem>
                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Name </div>
                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                    <input type='text' value={formData?.installerDetails?.name}
                                        name="name"
                                        style={{
                                            
                                            
                                            width: '100%',
                                            color: "#666666"
                                        }}

                                        onChange={(e) => handleInstallerInputChange('name', e.target.value)}

                                    />
                                </div>
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Contact No   </div>
                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                    <input type='text'
                                        value={formData?.installerDetails?.contactNo}
                                        name="contactNo"
                                        style={{
                                            
                                            
                                            width: '100%',
                                            color: "#666666"
                                        }}

                                        onChange={(e) => handleInstallerInputChange('contactNo', e.target.value)}

                                    />
                                </div>
                            </ListItem>
                        </React.Fragment> */}
                        <Autocomplete
                            id="installer"
                            options={installerList}
                            value={installerList?.find((s) => s._id === requests?.installerDetails?._id) || null}
                            onChange={(e, n) => handleInstallerInputChange(n)}
                            getOptionLabel={(option) => `${option.name}  ( ${option?.phone} )`}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Installer"
                                    fullWidth
                                    error={Boolean(errors.brand)}
                                    helperText={errors.brand}
                                />
                            )}
                        />
                    </List>
                </Typography>


                {/* <Grid item xs={12} sm={6}>
                                {index > 0 && <Button onClick={() => removeGpsForm(index)}
                                    variant="outlined"
                                    style={{
                                        borderColor: primaryColor,
                                        color: primaryColor,
                                        border: `2px solid ${primaryColor}`,
                                        textTransform: 'capitalize',
                                        padding: '1% 3.5%',
                                        textAlign: 'center',
                                        fontWeight: '500',
                                        cursor: 'pointer',
                                        marginRight: "10px"
                                    }}
                                >
                                    Remove
                                </Button>}
                                <Button onClick={addForm} variant="contained" style={buttonStyles}>
                                    Add
                                </Button>
                            </Grid> */}

            </div >

        </div >
    );
}
