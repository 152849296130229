import { Autocomplete, Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { get_data } from "../api";
import { Country, State, City } from 'country-state-city';
// import { GoogleMap, LoadScript, Marker, Autocomplete } from '@react-google-maps/api';

const BillingDetail = ({ user_data, billingDetail, setBillingDetail, billingError, setBillingError }) => {
    // const [formValues, setFormValues] = useState({
    //     fullName: '',
    //     phoneNumber: '',
    //     shippingAddress: { street: '', city: '',state: '', zipCode: '' },
    //     userId: '',
    //     productId: ''
    // });
    const [countryCode, setCountryCode] = useState('IN');
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [state, setState] = useState("");
    const [city, setCity] = useState("");

    const isDesktop = useMediaQuery('(min-width: 768px)');

    const getCity = async () => {
        const data = await get_data("service-area/get-all-service-area");

        const city = data?.data?.map((item) => item.city);
        setCity(city);

        const state = data?.data?.map((item) => item.state);
        const uniqueStates = [...new Set(state)];
        setState(uniqueStates);
    };

    useEffect(() => {
        setBillingDetail({
            fullName: user_data?.name || user_data?.companyName || '',
            phoneNumber: user_data?.phone || user_data?.contactNo || '',
            email: user_data?.email || '',
            shippingAddress: {
                street: user_data?.address?.[0]?.street || user_data?.address?.street || '',
                city: user_data?.address?.[0]?.city || user_data?.address?.city || '',
                state: user_data?.address?.[0]?.state || user_data?.address?.state || '',
                zipCode: user_data?.address?.[0]?.zip_code || user_data?.address?.zipCode || '',
            },
            userId: user_data?._id || ''
        });

        getCity();


        if (user_data?.address?.[0]?.state || user_data?.address?.state) {
            // Trigger city update based on existing state
            const stateCode = user_data?.address?.[0]?.state || user_data?.address?.state;
            const fetchedCities = City.getCitiesOfState(countryCode, stateCode);
            setCityList(fetchedCities);
        }

    }, [user_data]);
    const [formErrors, setFormErrors] = useState({});


    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'phoneNumber') {

            const phoneValue = value.replace(/\D/g, '');
            setBillingDetail((prev) => ({ ...prev, [name]: phoneValue }));
        } else if (name === 'street' || name === 'state' || name === 'city' || name === 'zipCode') {

            setBillingDetail((prev) => ({
                ...prev,
                shippingAddress: { ...prev.shippingAddress, [name]: value }
            }));
        } else {

            setBillingDetail((prev) => ({ ...prev, [name]: value }));
        }

        // Reset errors
        setBillingError((prev) => ({ ...prev, [name]: '' }));
    };



    useEffect(() => {
        setStateList(State.getStatesOfCountry(countryCode));
    }, [countryCode]);

    const handleStateChange = (event, newValue) => {
        if (newValue) {
            const stateCode = newValue.isoCode;
            const fetchedCities = City.getCitiesOfState(countryCode, stateCode);
            setCityList(fetchedCities);
            // setFormValues(prev => ({ ...prev, state: stateCode, city: '' }));
            setBillingDetail((prev) => ({
                ...prev,
                shippingAddress: { ...prev.shippingAddress, state: stateCode, city: '' }
            }));
            // setBillingDetail(prev => ({ ...prev, state: stateCode, city: '' }));
        }
    };

    const handleCityChange = (event, newValue) => {
        if (newValue) {
            // setFormValues(prev => ({ ...prev, city: newValue.name }));
            setBillingDetail((prev) => ({
                ...prev,
                shippingAddress: { ...prev.shippingAddress, city: newValue.name }
            }));
            // setBillingDetail(prev => ({ ...prev, city: newValue.name }));
        }
    };

    return (
        <div>
            <Typography variant="h6" gutterBottom>Shipping Address Details</Typography>

            <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px', marginTop: '5%' }}>
                <TextField
                    fullWidth
                    label="Full Name"
                    name="fullName"
                    variant="outlined"
                    value={billingDetail?.fullName || ""}
                    onChange={handleChange}
                    error={!!billingError?.fullName}
                    helperText={billingError?.fullName}
                />
                <TextField
                    fullWidth
                    label="Phone Number"
                    name="phoneNumber"
                    type="tel"
                    variant="outlined"
                    value={billingDetail?.phoneNumber}
                    onChange={handleChange}
                    inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                    error={!!billingError?.phoneNumber}
                    helperText={billingError?.phoneNumber}
                />
            </Box>

            <Box>
                <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                    variant="outlined"
                    value={billingDetail?.email}
                    onChange={handleChange}
                    error={!!billingError?.email}
                    helperText={billingError?.email}
                />
            </Box>

            <Typography variant="h6" gutterBottom style={{ fontSize: 20, marginBottom: '3%', marginTop: '3%' }}>
                Address
            </Typography>
            {/* <p style={{ marginTop: 0, fontSize: 14, opacity: '70%' }}>Confirm your address manually</p> */}
            <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                <TextField
                    fullWidth
                    multiline
                    rows={3}
                    label="Street"
                    name="street"
                    variant="outlined"
                    value={billingDetail?.shippingAddress?.street}
                    onChange={handleChange}
                    error={!!billingError?.street}
                    helperText={billingError?.street}
                />
            </Box>

            <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                <Autocomplete
                    fullWidth
                    // style={{ marginBottom: 10 }}
                    id="state-select"
                    options={stateList}
                    value={stateList.find(s => s.isoCode === billingDetail?.shippingAddress?.state) || null}
                    onChange={handleStateChange}
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                        <Box
                            component="li"
                            {...props}
                            value={option.isoCode}
                            key={option.isoCode}
                        >
                            {option.name}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            // error={!!stateError}
                            // helperText={stateError}
                            // onFocus={() => handleError('', 'state')}
                            label="Select State"
                            fullWidth
                        />
                    )}
                />

                <Autocomplete
                    id="city-select"
                    options={cityList}
                    fullWidth
                    value={cityList.find(c => c.name === billingDetail?.shippingAddress?.city) || null}
                    onChange={handleCityChange}
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                        <Box
                            component="li"
                            {...props}
                            value={option.name}
                            key={option.name}
                        >
                            {option.name}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select City"
                            fullWidth
                        />
                    )}
                />


                {
                    isDesktop && (
                        <TextField
                            fullWidth
                            label="Zip Code"
                            name="zipCode"
                             
                            variant="outlined"
                            value={billingDetail?.shippingAddress?.zipCode}
                            onChange={handleChange}
                            error={!!billingError?.zipCode}
                            helperText={billingError?.zipCode}
                        />
                    )
                }
            </Box>

            {
                !isDesktop && (
                    <Box>
                        <TextField
                            fullWidth
                            label="Zip Code"
                            name="zipCode"
                             
                            variant="outlined"
                            value={billingDetail?.shippingAddress?.zipCode}
                            onChange={handleChange}
                            error={!!billingError?.zipCode}
                            helperText={billingError?.zipCode}
                        />
                    </Box>
                )
            }


            {/* <Typography variant="h4" gutterBottom style={{ fontWeight: 500, fontSize: 20, marginBottom: '1%', marginTop: '3%' }}>
                Locate on Google Maps
            </Typography>
            <LoadScript googleMapsApiKey="AIzaSyADcEUt4NJHZ4R4ELZWxPtRSNjnXooFHAc" libraries={['places']}>
                <Autocomplete onLoad={(ref) => (autocompleteRef.current = ref)} onPlaceChanged={onPlaceChanged}>
                    <TextField
                        fullWidth
                        label="Search Address"
                        name="searchAddress"
                        variant="outlined"
                        style={{ marginBottom: '20px' }}
                    />
                </Autocomplete>
                <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
                    <Marker position={center} />
                </GoogleMap>
            </LoadScript>

            <Typography variant="body1" gutterBottom style={{ marginTop: '5%' }}>
                Selected Address: {selectedAddress}
            </Typography> */}
            {/* <Typography variant="body1" gutterBottom>
                Selected Coordinates: {`Latitude: ${selectedCoordinates.lat}, Longitude: ${selectedCoordinates.lng}`}
            </Typography> */}
        </div>
    );
}

export default BillingDetail;
