import { Autocomplete, Box, Button, Dialog, DialogContent, DialogContentText, Divider, FormControlLabel, FormGroup, Grid, List, ListItem, Modal, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useLocation } from "react-router-dom";
import { get_data, post_data } from "../api";

import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { City, State } from "country-state-city";
import { createButtonBg, primaryColor } from "../constant";
import Swal from "sweetalert2";


export default function RequestDetails({ requestData }) {
    const { isLoggedIn, user_data } = useSelector(state => state.user);
    const location = useLocation();
    const requests = location?.state?.row || requestData;
    const [toggleGpsStatus, setToggleGpsStatus] = useState([])
    const [reason, setReason] = useState('');
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [countryCode] = useState('IN');
    const [errors, setErrors] = useState({});
    const [deviceModelList, setDeviceModelList] = useState([]);
    const [driverCityList, setDriverCityList] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [index, setIndex] = useState(0);
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [sno, setSno] = useState(0);

    const [formData, setFormData] = useState({
        clientId: user_data?._id || requests?.clientId || '',
        customerDetails: {
            companyName: requests?.customerDetails?.companyName || '',
            contactNo: requests?.customerDetails?.contactNo || '',
            resContactNo: requests?.customerDetails?.resContactNo || '',
            address: {
                street: requests?.customerDetails?.address?.street || '',
                landmark: requests?.customerDetails?.address?.landmark || '',
                city: requests?.customerDetails?.address?.city || '',
                state: requests?.customerDetails?.address?.state || '',
                zipCode: requests?.customerDetails?.address?.zipCode || '',
            },
        },
        gps: requests?.gps?.map((gpsData) => ({
            deviceDetails: {
                network: {
                    airtel: gpsData?.deviceDetails?.network?.airtel || false,
                    vi: gpsData?.deviceDetails?.network?.vi || false,
                    bsnl: gpsData?.deviceDetails?.network?.bsnl || false,
                    jio: gpsData?.deviceDetails?.network?.jio || false,
                },
                otherDetails: {
                    new: gpsData?.deviceDetails?.otherDetails?.new || false,
                    rental: gpsData?.deviceDetails?.otherDetails?.rental || false,
                    renew: gpsData?.deviceDetails?.otherDetails?.renew || false,
                    repair: gpsData?.deviceDetails?.otherDetails?.repair || false,
                },
                deviceModel: gpsData?.deviceDetails?.deviceModel || '',
                imeiNo: gpsData?.deviceDetails?.imeiNo || '',
                simNo: gpsData?.deviceDetails?.simNo || '',
                uniqueId: gpsData?.deviceDetails?.uniqueId || '',
                amount: gpsData?.deviceDetails?.amount || 0,
                panicButton: gpsData?.deviceDetails?.panicButton || false,
                relay: gpsData?.deviceDetails?.relay || false,
            },
            driverDetails: {
                fullName: gpsData?.driverDetails?.fullName || '',
                pan: gpsData?.driverDetails?.pan || '',
                aadhar: gpsData?.driverDetails?.aadhar || '',
                badgeNo: gpsData?.driverDetails?.badgeNo || '',
                shift: gpsData?.driverDetails?.shift || '',
                contactNo: gpsData?.driverDetails?.contactNo || '',
                resContactNo: gpsData?.driverDetails?.resContactNo || '',
                address: {
                    street: gpsData?.driverDetails?.address?.street || '',
                    zipCode: gpsData?.driverDetails?.address?.zipCode || '',
                },
                fatherName: gpsData?.driverDetails?.fatherName || '',
            },
            vehicleDetails: {
                regnNumber: gpsData?.vehicleDetails?.regnNumber || '',
                plate: gpsData?.vehicleDetails?.plate || '',
                regnOwnerName: gpsData?.vehicleDetails?.regnOwnerName || '',
                typeOfVehicle: gpsData?.vehicleDetails?.typeOfVehicle || '',
                vehicleClass: gpsData?.vehicleDetails?.vehicleClass || '',
            },
            installerDetails: {
                name: gpsData?.installerDetails?.name || '',
                contactNo: gpsData?.installerDetails?.contactNo || '',
            },
        })) || [{
            deviceDetails: {
                network: { airtel: false, vi: false, bsnl: false, jio: false },
                otherDetails: { new: false, rental: false, renew: false, repair: false }
            },
            driverDetails: { contactNo: '', resContactNo: '', address: { street: '', zipCode: '' }, fatherName: '' },
            vehicleDetails: { brand: '', model: '', regnNumber: '', plate: '', regnOwnerName: '', typeOfVehicle: '', vehicleClass: '' },
            installerDetails: { name: '', contactNo: '' }
        }],
    });

    const handleClose = () => {
        setReason('');
        setOpen(false);
    }

    useEffect(function () {
        setToggleGpsStatus((prev) => {
            let updatedGpsStatus = requests?.gps?.map((item, idx) => {
                if (!item?.reason) {
                    return [...prev, false]
                }
                return [...prev, item?.gpsStatus]
            })
            return updatedGpsStatus
        })

    }, [requests])

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });



    const getTrueNetworkProvider = (network) => {
        const activeProviders = Object.keys(network).filter((provider) => network[provider]);
        return activeProviders.length > 0 ? activeProviders.join(', ') : 'None'; // Display comma-separated if multiple
    };

    const getTrueOtherDetails = (details) => {
        const activeDetails = Object.keys(details).filter((key) => details[key]);
        return activeDetails.length > 0 ? activeDetails.join(', ') : 'None'; // Display comma-separated if multiple
    };

    // const handleChange = async (value, index) => {
    //     if (value === "Not Installed") {
    //         setOpen(true);
    //         setSno(index);
    //         return;
    //     }

    //     let result = await post_data(`request/change-gps-status/${requests?._id}`,
    //         {
    //             index: index,
    //             gpsStatus: value === "Installed" ? "Installed" : "Not Installed",
    //             reason: reason
    //         });

    //     if (result?.status == true) {
    //         toast.success(result?.message)

    //         setToggleGpsStatus((prev) => {
    //             let updatedGpsStatus = prev?.map((item, idx) => {
    //                 if (index == idx) {
    //                     return [
    //                         value === "Installed" ? "Installed" : "Not Installed"
    //                     ]
    //                 }
    //                 return item
    //             })
    //             return updatedGpsStatus
    //         })

    //         handleClose()

    //     } else {
    //         toast.error(result?.message)
    //     }
    // }

    useEffect(() => {
        setStateList(State.getStatesOfCountry(countryCode));
    }, [countryCode]);

    const handleStateChange = (event, newValue) => {
        if (newValue) {
            const stateCode = newValue.isoCode;
            const fetchedCities = City.getCitiesOfState(countryCode, newValue.isoCode);

            setFormData(prevState => ({
                ...prevState,
                customerDetails: {
                    ...prevState.customerDetails,
                    address: {
                        ...prevState.customerDetails.address,
                        state: stateCode, // Update state
                        city: '' // Reset city when state changes
                    }
                }
            }));

            setCityList(fetchedCities); // Populate city list based on selected state
        }
    };

    const handleCityChange = (event, newValue) => {
        if (newValue) {
            setFormData(prevState => ({
                ...prevState,
                customerDetails: {
                    ...prevState.customerDetails,
                    address: {
                        ...prevState.customerDetails.address,
                        city: newValue.name // Update city
                    }
                }
            }));
        }
    };

    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const handleDeviceInputChange = (index, field, value) => {
        setFormData((prevState) => {
            const updatedGps = prevState.gps.map((form, idx) => {
                if (index === idx) {
                    const newDeviceInfo = { ...form.deviceDetails, [field]: value };

                    // Automatically set IMEI No if deviceModel is changed
                    if (field === 'deviceModel') {
                        const imeiNo = fetchIMEINo(value);
                        newDeviceInfo.imeiNo = imeiNo ? imeiNo : ''; // Auto-fill IMEI if available
                    }

                    return { ...form, deviceDetails: newDeviceInfo };
                }
                return form;
            });

            return { ...prevState, gps: updatedGps };
        });
    };

    // Fetch device model list
    const fetchdeviceModelList = async () => {
        try {
            const data = await get_data(`product/get-all-products-for-options`);
            setDeviceModelList(data?.data || []);
        } catch (error) {
            console.log('Error fetching device model list:', error);
        }
    };

    // Fetch IMEI based on selected device model
    const fetchIMEINo = (deviceModel) => {
        const device = deviceModelList.find((d) => d._id === deviceModel);
        return device ? device.imeiNo : '';
    };

    useEffect(() => {
        fetchdeviceModelList();
    }, []);

    const handleDeviceSwitchChange = (index, provider) => {
        setFormData((prevState) => {
            const updatedGps = prevState.gps.map((form, idx) => {
                if (index === idx) {
                    // Set the network as the selected provider's name as a string
                    return { ...form, deviceDetails: { ...form.deviceDetails, network: { [provider.toLowerCase()]: true } } };
                }
                return form;
            });

            return { ...prevState, gps: updatedGps };
        });
    };
    const handleDeviceSwitchChange2 = (index, provider) => {
        setFormData(prevState => {
            const updatedGps = prevState.gps.map((form, idx) => {
                if (index === idx) {
                    let updatedOtherDetails = form.deviceDetails.otherDetails === provider
                        ? ''  // Deselect if the same provider is clicked again
                        : provider; // Set the selected provider

                    updatedOtherDetails = updatedOtherDetails.toLowerCase();

                    return { ...form, deviceDetails: { ...form.deviceDetails, otherDetails: { [updatedOtherDetails]: true } } };
                }
                return form;
            });

            return { ...prevState, gps: updatedGps, };
        });
    };

    const handleDriverInputChange = (index, field, value) => {
        setFormData((prevState) => {
            const updatedGps = prevState.gps.map((form, idx) => {
                if (index === idx) {
                    if (['street', 'landmark', 'city', 'state', 'zipCode'].includes(field)) {
                        // Update address inside driverDetails
                        return {
                            ...form, driverDetails: { ...form.driverDetails, address: { ...form.driverDetails.address, [field]: value, } },
                        };
                    } else {

                        return {
                            ...form, driverDetails: { ...form.driverDetails, [field]: value }
                        };
                    }
                }
                return form; // Return unchanged form for other indices
            });
            return { ...prevState, gps: updatedGps, };
        });
    };

    const handleDriverStateChange = (index, event, newValue) => {
        if (newValue) {
            const stateCode = newValue.isoCode;
            const countryCode = 'IN'; // Replace with actual country code

            // Fetch cities based on selected state
            const fetchedCities = City.getCitiesOfState(countryCode, stateCode);
            setDriverCityList(fetchedCities); // Update city list based on state

            setFormData(prevState => {
                const updatedGps = prevState.gps.map((form, idx) => {
                    if (index === idx) {
                        return {
                            ...form,
                            driverDetails: {
                                ...form.driverDetails,
                                driverState: stateCode, // Update the state
                                driverCity: '', // Reset city when state changes
                            },
                        };
                    }
                    return form; // Return unchanged form for other indices
                });

                return {
                    ...prevState,
                    gps: updatedGps,
                };
            });
        }
    };

    // Handle city change
    const handleDriverCityChange = (index, event, newValue) => {
        if (newValue) {
            setFormData(prevState => {
                const updatedGps = prevState.gps.map((form, idx) => {
                    if (index === idx) {
                        return {
                            ...form,
                            driverDetails: {
                                ...form.driverDetails,
                                driverCity: newValue.name, // Update the driverCity correctly
                            },
                        };
                    }
                    return form; // Return unchanged form for other indices
                });

                return {
                    ...prevState,
                    gps: updatedGps,
                };
            });
        }
    };

    const handleVehicleInputChange = (index, field, value) => {
        setFormData((prevState) => {
            const updatedGps = prevState.gps.map((form, idx) => {
                if (index === idx) {
                    // Update the vehicleDetails field dynamically
                    return {
                        ...form,
                        vehicleDetails: {
                            ...form.vehicleDetails,
                            [field]: value, // Dynamically update the vehicle field
                        },
                    };
                }
                return form; // Return unchanged form for other indices
            });

            return {
                ...prevState,
                gps: updatedGps, // Update the gps array in formData
            };
        });

        if (field == 'brand') {
            getAllModels(index, value)
        }
    };

    const getAllModels = async (index, value) => {
        let result = await get_data(`model/get-all-model-by-brand/${value}`)
        if (result?.status == true) {

            setModelList((prev) => {
                let updatedModelList = formData?.gps?.map((item, idx) => {
                    if (index == idx) {
                        return result?.data
                    }
                    return modelList?.[idx]
                })
                return updatedModelList
            })
        } else {
            toast.error(result?.message)
        }
    }

    const handleInstallerInputChange = (index, field, value) => {
        setFormData((prevState) => {
            const updatedGps = prevState.gps.map((form, idx) => {
                if (index === idx) {
                    return {
                        ...form,
                        installerDetails: {
                            ...form.installerDetails,
                            [field]: value,
                        },
                    };
                }
                return form;
            });
            return { ...prevState, gps: updatedGps };
        });
    }


    return (
        <div style={{ background: 'white', padding: 20, borderRadius: '5px' }}>
            <Toaster position="top-right" reverseOrder={false} />
            {/* Customer Details */}
            <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                <List sx={{ border: '1px solid gainsboro' }}>
                    <React.Fragment >

                        <ListItem>
                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Request ID  </div>
                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                <input type='text' value={formData.customerDetails.uniqueRequestId || requests?.uniqueRequestId} placeholder='uniqueRequestId' style={{
                                    border: 'none',
                                    outline: 'none',
                                    color: "#666666"
                                }}

                                    onChange={(e) => setFormData(prevState => ({
                                        ...prevState,
                                        customerDetails: {
                                            ...prevState.customerDetails,
                                            companyName: e.target.value  // Directly update companyName
                                        }
                                    }))}

                                />
                            </div>
                        </ListItem>
                        <Divider />

                        <ListItem>
                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Company Name  </div>
                            <div style={{ width: '60%', marginLeft: '10px' }}>

                                <input type='text' value={formData.customerDetails.companyName || requests?.customerDetails?.companyName} placeholder='Company Name' style={{
                                    border: 'none',
                                    outline: 'none',
                                    color: "#666666"
                                }}

                                    // onChange={(e) => setFormData(prevState => ({
                                    //     ...prevState,
                                    //     customerDetails: {
                                    //         ...prevState.customerDetails,
                                    //         companyName: e.target.value  // Directly update companyName
                                    //     }
                                    // }))}

                                />

                            </div>
                        </ListItem>
                        <Divider />

                        <ListItem>
                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  State  </div>
                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                {/* <Autocomplete
                                    id="state-select"
                                    options={stateList}
                                    value={stateList.find(s => s.isoCode === (formData?.customerDetails?.address?.state || requests?.customerDetails?.address?.state)) || null}
                                    onChange={handleStateChange}
                                    getOptionLabel={(option) => option.name}

                                    renderInput={(params) =>
                                        <TextField
                                            error={!!errors.state}
                                            helperText={errors.state}
                                            onFocus={() => handleError('', 'state')}
                                            {...params}
                                            label="Select State"
                                            fullWidth
                                            variant="standard"

                                        />
                                    }
                                /> */}
                                <div style={{ width: '60%', }}>

                                    <input type='text' value={formData?.customerDetails?.address?.state || requests?.customerDetails?.address?.state} placeholder='State' style={{
                                        border: 'none',
                                        outline: 'none',
                                        color: "#666666"
                                    }}

                                    // onChange={(e) => setFormData(prevState => ({
                                    //     ...prevState,
                                    //     customerDetails: {
                                    //         ...prevState.customerDetails,
                                    //         companyName: e.target.value  // Directly update companyName
                                    //     }
                                    // }))}

                                    />

                                </div>
                            </div>
                        </ListItem>
                        <Divider />

                        <ListItem>
                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  City  </div>
                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                {/* <Autocomplete
                                    id="city-select"
                                    options={cityList}
                                    value={cityList.find(c => c.name == (formData?.customerDetails?.address?.city || requests?.customerDetails?.address?.city)) || null}
                                    onChange={handleCityChange}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            error={!!errors.city}
                                            helperText={errors.city}
                                            onFocus={() => handleError('', 'city')}
                                            label="Select City"
                                            fullWidth
                                            variant="standard"
                                        />
                                    }
                                /> */}
                                <div style={{ width: '60%', }}>

                                    <input type='text' value={formData?.customerDetails?.address?.city || requests?.customerDetails?.address?.city} placeholder='city' style={{
                                        border: 'none',
                                        outline: 'none',
                                        color: "#666666"
                                    }}

                                    // onChange={(e) => setFormData(prevState => ({
                                    //     ...prevState,
                                    //     customerDetails: {
                                    //         ...prevState.customerDetails,
                                    //         companyName: e.target.value  // Directly update companyName
                                    //     }
                                    // }))}

                                    />

                                </div>
                            </div>
                        </ListItem>
                        <Divider />


                        <ListItem>
                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Street  </div>
                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                <input type='text' value={formData?.customerDetails?.address?.street || requests?.customerDetails?.address?.street} placeholder='street' style={{
                                    border: 'none',
                                    outline: 'none',
                                    color: "#666666"
                                }}

                                    // onChange={(e) => setFormData(prevState => ({
                                    //     ...prevState,
                                    //     customerDetails: {
                                    //         ...prevState.customerDetails,
                                    //         address: {
                                    //             ...prevState.customerDetails.address,
                                    //             street: e.target.value // Assuming "Area" refers to the street field in the address
                                    //         }
                                    //     }
                                    // }))}

                                />
                            </div>
                        </ListItem>
                        <Divider />

                        <ListItem>
                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Landmark  </div>
                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                <input type='text' value={formData?.customerDetails?.address?.landmark || requests?.customerDetails?.address?.landmark} placeholder='landmark' style={{
                                    border: 'none',
                                    outline: 'none',
                                    color: "#666666"
                                }}

                                    // onChange={(e) => setFormData(prevState => ({
                                    //     ...prevState,
                                    //     customerDetails: {
                                    //         ...prevState.customerDetails,
                                    //         address: {
                                    //             ...prevState.customerDetails.address,
                                    //             landmark: e.target.value // Assuming "Area" refers to the street field in the address
                                    //         }
                                    //     }
                                    // }))}

                                />
                            </div>
                        </ListItem>
                        <Divider />

                        <ListItem>
                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Zip Code  </div>
                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                <input type='text' value={formData?.customerDetails?.address?.zipCode || requests?.customerDetails?.address?.zipCode} placeholder='zipCode' style={{
                                    border: 'none',
                                    outline: 'none',
                                    color: "#666666"
                                }}

                                    // onChange={(e) => setFormData(prevState => ({
                                    //     ...prevState,
                                    //     customerDetails: {
                                    //         ...prevState.customerDetails,
                                    //         address: {
                                    //             ...prevState.customerDetails.address,
                                    //             zipCode: e.target.value // Assuming "Area" refers to the street field in the address
                                    //         }
                                    //     }
                                    // }))}

                                />
                            </div>
                        </ListItem>
                        <Divider />



                        <ListItem>
                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Contact No  </div>
                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                <input   value={formData.customerDetails.contactNo || requests?.customerDetails?.contactNo} placeholder='Contact No'
                                    name="contactNo"
                                    style={{
                                        border: 'none',
                                        outline: 'none',
                                        color: "#666666"
                                    }}

                                />
                            </div>
                        </ListItem>
                        <Divider />

                        <ListItem>
                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Residential Contact No  </div>
                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                <input   value={formData.customerDetails.resContactNo || requests?.customerDetails?.resContactNo} placeholder='Contact No'
                                    name="resContactNo"
                                    style={{
                                        border: 'none',
                                        outline: 'none',
                                        color: "#666666"
                                    }}

                                />
                            </div>
                        </ListItem>
                    </React.Fragment>
                </List>
            </Typography>

            {requests?.subVendorDetails ? <>
                <Typography variant='h6' style={{ margin: '10px 0px' }}>Sub Vendor Details</Typography>
                <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                    <List sx={{ border: '1px solid gainsboro' }}>
                        <React.Fragment >
                            <ListItem>
                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Sub Vendor Name  </div>
                                <div style={{ width: '60%', marginLeft: '10px' }}> {requests?.subVendor?.name || '-'}</div>
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Address  </div>
                                <div style={{ width: '60%', marginLeft: '10px' }}>
                                    {requests?.subVendor?.address?.street + ' ' + requests?.subVendor?.address?.state + " " + requests?.subVendor?.address?.landmark + ' ' + requests?.subVendor?.address?.city + ' ' + requests?.subVendor?.address?.zipCode}
                                </div>
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Contact No  </div>
                                <div style={{ width: '60%', marginLeft: '10px' }}> {requests?.subVendor?.contactNo}</div>
                            </ListItem>
                        </React.Fragment>
                    </List>
                </Typography>
            </> : <>
            </>}


            <Typography variant='h6' style={{ margin: '10px 0px' }}>Gps Details</Typography>
            {requests?.gps?.map((item, index) => {
                return (<div style={{ marginTop: 10, gap: '10px', width: '100%', display: 'flex' }}>
                    <Accordion style={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography>Gps {index + 1}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='h6' style={{ margin: '10px 0px' }}>Device Details</Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                                <List sx={{ border: '1px solid gainsboro' }}>
                                    <React.Fragment >
                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Device Model   </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>

                                                {/* <Autocomplete
                                                    id="country-deviceModel"
                                                    name="deviceModel"
                                                    options={deviceModelList} // List of available device models
                                                    value={deviceModelList.find(c => c._id === (formData.gps[index]?.deviceDetails?.deviceModel || item?.deviceDetails?.deviceModel)) || null}
                                                    onChange={(event, newValue) => handleDeviceInputChange(index, 'deviceModel', newValue ? newValue._id : '')}
                                                    autoHighlight
                                                    getOptionLabel={(option) => option.model}

                                                    renderOption={(props, option) => (
                                                        <Box
                                                            component="li"
                                                            {...props}
                                                            key={option._id}
                                                        >
                                                            {option.name}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            name="model"
                                                            error={!!errors.deviceModel}
                                                            helperText={errors.deviceModel}
                                                            onFocus={() => handleError('', 'deviceModel')}
                                                            {...params}
                                                            label="Select Device Model"
                                                            fullWidth
                                                            variant="standard"
                                                        />
                                                    )}
                                                /> */}
                                                <input type='text' value={formData.gps[index]?.deviceDetails?.deviceModel || item?.deviceDetails?.deviceModel} placeholder='Model' style={{
                                                    border: 'none',
                                                    outline: 'none',
                                                    color: "#666666"
                                                }}

                                                // onChange={(e) => handleDeviceInputChange(index, 'imeiNo', e.target.value)}

                                                />

                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>IMEI NO  </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.deviceDetails?.imeiNo || item?.deviceDetails?.imeiNo} placeholder='imwiNo' style={{
                                                    border: 'none',
                                                    outline: 'none',
                                                    color: "#666666"
                                                }}

                                                // onChange={(e) => handleDeviceInputChange(index, 'imeiNo', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  SIM No  </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.deviceDetails?.simNo || item?.deviceDetails?.simNo} placeholder='simNo' style={{
                                                    border: 'none',
                                                    outline: 'none',
                                                    color: "#666666"
                                                }}

                                                // onChange={(e) => handleDeviceInputChange(index, 'simNo', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}> Device Unique ID </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.deviceDetails?.uniqueId || item?.deviceDetails?.uniqueId} placeholder='uniqueId' style={{
                                                    border: 'none',
                                                    outline: 'none',
                                                    color: "#666666"
                                                }}

                                                // onChange={(e) => handleDeviceInputChange(index, 'uniqueId', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            {/* {console.log('item?.deviceDetails?.network', item?.deviceDetails?.network)} */}
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Network </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>

                                                <FormGroup style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    {['Airtel', 'Vi', 'BSNL', 'Jio'].map((provider) => (
                                                        <FormControlLabel

                                                            key={provider}
                                                            control={
                                                                <Switch
                                                                    error={!!errors.network}
                                                                    onFocus={() => handleError('', 'network')}
                                                                    checked={formData.gps[index]?.deviceDetails?.network[provider.toLowerCase()] === true || item?.deviceDetails?.network?.[provider?.toLocaleLowerCase()] === true}
                                                                // onChange={() => handleDeviceSwitchChange(index, provider)} // Pass index and provider
                                                                />
                                                            }
                                                            label={provider}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Network Provider</div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.deviceDetails?.networkProvider || item?.deviceDetails?.networkProvider} placeholder='networkProvider' style={{
                                                    border: 'none',
                                                    outline: 'none',
                                                    color: "#666666"
                                                }}

                                                // onChange={(e) => handleDeviceInputChange(index, 'networkProvider', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Plan Type</div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.deviceDetails?.planType || item?.deviceDetails?.planType} placeholder='planType' style={{
                                                    border: 'none',
                                                    outline: 'none',
                                                    color: "#666666"
                                                }}

                                                    // onChange={(e) => handleDeviceInputChange(index, 'planType', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Relay </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <FormControlLabel

                                                    key={'relay'}
                                                    control={
                                                        <Switch
                                                            error={!!errors.relay}
                                                            onFocus={() => handleError('', 'relay')}
                                                            checked={formData.gps[index]?.deviceDetails?.relay === true || item?.deviceDetails?.relay === true}
                                                            // onChange={() => setFormData((prevState) => {
                                                            //     const updatedGps = prevState.gps.map((form, idx) => {

                                                            //         if (index === idx) {
                                                            //             let dataForUpdate = (formData.gps[index]?.deviceDetails?.relay === true || item?.deviceDetails?.relay === true) ? false : true
                                                            //             // console.log('dataForUpdate',dataForUpdate)
                                                            //             // Set the network as the selected provider's name as a string
                                                            //             return { ...form, deviceDetails: { ...form.deviceDetails, relay: dataForUpdate } };
                                                            //         }
                                                            //         return form;
                                                            //     });
                                                            //     return { ...prevState, gps: updatedGps };
                                                            // })} // Pass index and provider
                                                        />
                                                    }
                                                //    label={'relay'}
                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Panic Button</div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <FormControlLabel

                                                    key={'panicButton'}
                                                    control={
                                                        <Switch
                                                            error={!!errors.panicButton}
                                                            onFocus={() => handleError('', 'panicButton')}
                                                            checked={formData.gps[index]?.deviceDetails?.panicButton === true || item?.deviceDetails?.panicButton === true}
                                                            // onChange={() => setFormData((prevState) => {
                                                            //     const updatedGps = prevState.gps.map((form, idx) => {

                                                            //         if (index === idx) {
                                                            //             let dataForUpdate = (formData.gps[index]?.deviceDetails?.panicButton === true || item?.deviceDetails?.panicButton === true) ? false : true
                                                            //             // console.log('dataForUpdate', dataForUpdate)
                                                            //             // Set the network as the selected provider's name as a string
                                                            //             return { ...form, deviceDetails: { ...form.deviceDetails, panicButton: dataForUpdate } };
                                                            //         }
                                                            //         return form;
                                                            //     });
                                                            //     return { ...prevState, gps: updatedGps };
                                                            // })} // Pass index and provider
                                                        />
                                                    }
                                                //    label={'relay'}
                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Date Of Install </div>

                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='date' value={formData.gps[index]?.deviceDetails?.dateOfInstall || item?.deviceDetails?.dateOfInstall} placeholder='dateOfInstall' style={{
                                                    border: 'none',
                                                    outline: 'none',
                                                    color: "#666666"
                                                }}

                                                    // onChange={(e) => handleDeviceInputChange(index, 'dateOfInstall', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Other Details </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <FormGroup style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    {['New', 'Repair', 'Renew', 'Rental'].map((provider) => (
                                                        <FormControlLabel
                                                            key={provider}
                                                            control={
                                                                <Switch
                                                                    error={!!errors.OtherDetails}
                                                                    onFocus={() => handleError('', 'OtherDetails')}
                                                                    checked={formData.gps[index]?.deviceDetails?.otherDetails[provider?.toLowerCase()] === true || item?.deviceDetails?.otherDetails?.[provider?.toLowerCase()] === true} // Compare as string
                                                                    // checked={
                                                                    //     provider === 'New'
                                                                    //         ? formData.gps[index]?.deviceDetails?.otherDetails?.new
                                                                    //         : provider === 'Repair'
                                                                    //             ? formData.gps[index]?.deviceDetails?.otherDetails?.repair
                                                                    //             : provider === 'Renew'
                                                                    //                 ? formData.gps[index]?.deviceDetails?.otherDetails?.renew
                                                                    //                 : provider === 'Rental'
                                                                    //                     ? formData.gps[index]?.deviceDetails?.otherDetails?.rental
                                                                    //                     : false
                                                                    // }
                                                                    // onChange={() => handleDeviceSwitchChange2(index, provider)} // Pass index and provider
                                                                />
                                                            }
                                                            label={provider}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>Amount </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.deviceDetails?.amount || item?.deviceDetails?.amount || ''} placeholder='amount' style={{
                                                    border: 'none',
                                                    outline: 'none',
                                                    color: "#666666"
                                                }}

                                                    // onChange={(e) => handleDeviceInputChange(index, 'amount', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                    </React.Fragment>
                                </List>
                            </Typography>

                            <Typography variant='h6' style={{ margin: '10px 0px' }}>Driver Details</Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                                <List sx={{ border: '1px solid gainsboro' }}>
                                    <React.Fragment >
                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Full Name   </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.driverDetails?.fullName || ''} placeholder='fullName'
                                                    name="fullName"
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        color: "#666666"
                                                    }}

                                                    // onChange={(e) => handleDriverInputChange(index, 'fullName', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>Father Name </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.driverDetails?.fatherName || item?.driverDetails?.fatherName} placeholder='fatherName'
                                                    name="fatherName"
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        color: "#666666"
                                                    }}

                                                    // onChange={(e) => handleDriverInputChange(index, 'fatherName', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Contact No </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input   value={formData.gps[index]?.driverDetails?.contactNo || item?.driverDetails?.contactNo} placeholder='contactNo'
                                                    name="contactNo"
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        color: "#666666"
                                                    }}

                                                    // onChange={(e) => handleDriverInputChange(index, 'contactNo', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Residential Contact No  </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>

                                                <input   value={formData.gps[index]?.driverDetails?.resContactNo || item?.driverDetails?.resContactNo} placeholder='resContactNo'
                                                    name="resContactNo"
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        color: "#666666"
                                                    }}

                                                    // onChange={(e) => handleDriverInputChange(index, 'resContactNo', e.target.value)}

                                                /> </div>
                                        </ListItem>
                                        <Divider />

                                        {/* <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}> address </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}> {item?.driverDetails?.address?.street + ' ' + item?.driverDetails?.address?.landmark + ' ' + item?.driverDetails?.address?.state + ' ' + item?.driverDetails?.address?.city + ' ' + item?.driverDetails?.address?.zipCode || '-'}</div>
                                        </ListItem>
                                        <Divider /> */}

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Street  </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.driverDetails?.address?.street || item?.driverDetails?.address?.street} placeholder='street' style={{
                                                    border: 'none',
                                                    outline: 'none',
                                                    color: "#666666"
                                                }}

                                                    // onChange={(e) => handleDriverInputChange(index, 'street', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Landmark  </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.driverDetails?.address?.landmark || item?.driverDetails?.address?.landmark} placeholder='landmark' style={{
                                                    border: 'none',
                                                    outline: 'none',
                                                    color: "#666666"
                                                }}

                                                    // onChange={(e) => handleDriverInputChange(index, 'landmark', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Zip Code  </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.driverDetails?.address?.zipCode || item?.driverDetails?.address?.zipCode} placeholder='zipCode' style={{
                                                    border: 'none',
                                                    outline: 'none',
                                                    color: "#666666"
                                                }}

                                                    // onChange={(e) => handleDriverInputChange(index, 'zipCode', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  State  </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                {/* <Autocomplete
                                                    id="state-select"
                                                    options={stateList}
                                                    value={stateList.find(s => s.isoCode === (formData?.gps?.[index]?.driverDetails?.driverState || item?.driverDetails?.address?.state)) || null}
                                                    onChange={(event, newValue) => handleDriverStateChange(index, event, newValue)}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            error={!!errors.driverState}
                                                            helperText={errors.driverState}
                                                            onFocus={() => handleError('', 'state')}
                                                            {...params}
                                                            label="Select State"
                                                            fullWidth
                                                            variant="standard"
                                                        />
                                                    )}
                                                /> */}
                                                <input type='text' value={formData?.gps?.[index]?.driverDetails?.driverState || item?.driverDetails?.address?.state} placeholder='zipCode' style={{
                                                    border: 'none',
                                                    outline: 'none',
                                                    color: "#666666"
                                                }}

                                                // onChange={(e) => handleDriverInputChange(index, 'zipCode', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  City  </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                {/* <Autocomplete
                                                    id="city-select"
                                                    options={driverCityList}
                                                    value={driverCityList.find(c => c.name === (formData?.gps?.[index]?.driverDetails?.driverCity || item?.driverDetails?.address?.city)) || null}
                                                    onChange={(event, newValue) => handleDriverCityChange(index, event, newValue)}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            error={!!errors.driverCity}
                                                            helperText={errors.driverCity}
                                                            onFocus={() => handleError('', 'driverCity')}
                                                            label="Select City"
                                                            fullWidth
                                                            variant="standard"
                                                        />
                                                    )}
                                                /> */}

                                                <input type='text' value={formData?.gps?.[index]?.driverDetails?.driverCity || item?.driverDetails?.address?.city} placeholder='zipCode' style={{
                                                    border: 'none',
                                                    outline: 'none',
                                                    color: "#666666"
                                                }}

                                                // onChange={(e) => handleDriverInputChange(index, 'zipCode', e.target.value)}

                                                />

                                            </div>
                                        </ListItem>


                                    </React.Fragment>
                                </List>
                            </Typography>


                            <Typography variant='h6' style={{ margin: '10px 0px' }}>Vehicle Details</Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                                <List sx={{ border: '1px solid gainsboro' }}>
                                    <React.Fragment >
                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Owner Name </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.vehicleDetails?.regnOwnerName || item?.vehicleDetails?.regnOwnerName} placeholder='regnOwnerName'
                                                    name="regnOwnerName"
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        color: "#666666"
                                                    }}

                                                // onChange={(e) => handleVehicleInputChange(index, 'regnOwnerName', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Registration Number   </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.vehicleDetails?.regnNumber || item?.vehicleDetails?.regnNumber} placeholder='regnNumber'
                                                    name="regnNumber"
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        color: "#666666"
                                                    }}

                                                // onChange={(e) => handleVehicleInputChange(index, 'regnNumber', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>Plate </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.vehicleDetails?.plate || item?.vehicleDetails?.plate} placeholder='plate'
                                                    name="plate"
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        color: "#666666"
                                                    }}

                                                // onChange={(e) => handleVehicleInputChange(index, 'plate', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Type Of Vehicle</div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.vehicleDetails?.typeOfVehicle || item?.vehicleDetails?.typeOfVehicle} placeholder='typeOfVehicle'
                                                    name="typeOfVehicle"
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        color: "#666666"
                                                    }}

                                                // onChange={(e) => handleVehicleInputChange(index, 'typeOfVehicle', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}> Vehicle Class </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.gps[index]?.vehicleDetails?.vehicleClass || item?.vehicleDetails?.vehicleClass} placeholder='vehicleClass'
                                                    name="vehicleClass"
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        color: "#666666"
                                                    }}

                                                // onChange={(e) => handleVehicleInputChange(index, 'vehicleClass', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}> Chassis Number </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <FormControlLabel

                                                    key={'chassisNumber'}
                                                    control={
                                                        <Switch
                                                            error={!!errors.relay}
                                                            onFocus={() => handleError('', 'chassisNumber')}
                                                            checked={formData.gps[index]?.vehicleDetails?.chassisNumber === true || item?.vehicleDetails?.chassisNumber}
                                                            // onChange={() => setFormData((prevState) => {
                                                            //     const updatedGps = prevState.gps.map((form, idx) => {

                                                            //         if (index === idx) {
                                                            //             let dataForUpdate = (formData.gps[index]?.vehicleDetails?.chassisNumber === true || item?.vehicleDetails?.chassisNumber) ? false : true
                                                            //             // console.log('dataForUpdate',dataForUpdate)
                                                            //             // Set the network as the selected provider's name as a string
                                                            //             return { ...form, vehicleDetails: { ...form.vehicleDetails, chassisNumber: dataForUpdate } };
                                                            //         }
                                                            //         return form;
                                                            //     });
                                                            //     return { ...prevState, gps: updatedGps };
                                                            // })} // Pass index and provider
                                                        />
                                                    }
                                                //    label={'relay'}
                                                />

                                            </div>
                                        </ListItem>
                                    </React.Fragment>
                                </List>
                            </Typography>


                        </AccordionDetails>
                    </Accordion>



                    {/* <ToggleButtonGroup
                        color="primary"
                        value={toggleGpsStatus?.[index]}
                        exclusive
                        // onChange={(e) => { handleChange(e.target.value, index) }}
                        aria-label="Platform"
                        style={{ marginLeft: 'auto', height: '50px' }}
                    >
                        <ToggleButton value="Installed" style={{ fontSize: '.7rem', background: item.gpsStatus === 'Installed' ? primaryColor : '' }}>Installed</ToggleButton>
                        <ToggleButton value="Not Installed" style={{ fontSize: '.7rem',background: item.gpsStatus === 'Not Installed' ? primaryColor : '' }}>Not Installed</ToggleButton>
                    </ToggleButtonGroup> */}

                    <Modal
                        open={open}
                        onClose={handleClose}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                border: '2px solid #000',
                                boxShadow: 24,
                                p: 4,
                                borderRadius: '10px'
                            }}
                        >
                            <TextField
                                id="outlined-multiline-flexible"
                                label="Reason"
                                multiline
                                maxRows={4}
                                value={reason}
                                // onChange={(e) => setReason(e.target.value)}
                                fullWidth
                            />
                            {/* <Button variant="contained"
                                fullWidth
                                style={{ marginTop: '10px', borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                                onClick={() => handleChange("Not Installeded", sno)}>Submit</Button> */}
                        </Box>
                    </Modal>
                </div>
                )
            })}

            <Typography variant='h6' style={{ margin: '10px 0px' }}>Installer Details</Typography>
            <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                <List sx={{ border: '1px solid gainsboro' }}>
                    <React.Fragment >
                        <ListItem>
                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Name </div>
                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                <input type='text' value={formData.gps[index]?.installerDetails?.name || requests?.installerDetails?.name} placeholder='name'
                                    name="name"
                                    style={{
                                        border: 'none',
                                        outline: 'none',
                                        color: "#666666"
                                    }}

                                // onChange={(e) => handleInstallerInputChange(index, 'name', e.target.value)}

                                />
                            </div>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Contact No   </div>
                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                <input type='text' value={formData.gps[index]?.installerDetails?.contactNo || requests?.installerDetails?.contactNo} placeholder='contactNo'
                                    name="contactNo"
                                    style={{
                                        border: 'none',
                                        outline: 'none',
                                        color: "#666666"
                                    }}

                                // onChange={(e) => handleInstallerInputChange(index, 'contactNo', e.target.value)}

                                />
                            </div>
                        </ListItem>
                    </React.Fragment>
                </List>
            </Typography>


            {/* <Grid item xs={12} sm={6}>
                                {index > 0 && <Button onClick={() => removeGpsForm(index)}
                                    variant="outlined"
                                    style={{
                                        borderColor: primaryColor,
                                        color: primaryColor,
                                        border: `2px solid ${primaryColor}`,
                                        textTransform: 'capitalize',
                                        padding: '1% 3.5%',
                                        textAlign: 'center',
                                        fontWeight: '500',
                                        cursor: 'pointer',
                                        marginRight: "10px"
                                    }}
                                >
                                    Remove
                                </Button>}
                                <Button onClick={addForm} variant="contained" style={buttonStyles}>
                                    Add
                                </Button>
                            </Grid> */}

        </div>
    );
}
