import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ProductsGrid from "../../components/ProductsGrid";
import ProductComp from "../../components/ProductComp";
import { useEffect, useState } from "react";
import { get_data } from "../../api";
import { useParams } from "react-router-dom";
import ScreenLoading from "../../components/ScreenLoading"

export default function ProductPage() {

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})

    const { id } = useParams();

    const getProduct = async () => {
        try {
            const data = await get_data(`product/get-product/${id}`);
            if (data.status) {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                setData(data?.data);
            }
        } catch (error) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    useEffect(() => {
        getProduct();
    }, [id])

    return (
        <div>
            {
                loading ?
                    <>
                        <ScreenLoading />
                    </>
                    :
                    <>
                        <Header />
                        <ProductComp product={data} />
                        <ProductsGrid />
                        <Footer />
                    </>
            }
        </div>
    )
}