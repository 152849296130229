import React, { useState } from 'react';
// import '../../faq/FAQ.css';
import { Grid, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { IoCallOutline, IoChatbubblesOutline, IoLocationOutline } from 'react-icons/io5';
// import { primaryColor } from '@/app/constants';
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaYoutube } from 'react-icons/fa';
import { primaryColor } from '../constant';

const FAQSection = () => {

    const theme = useTheme();
    const matches1 = useMediaQuery(theme.breakpoints.down('md'));
    const matches2 = useMediaQuery(theme.breakpoints.down(900));
    const matches3 = useMediaQuery(theme.breakpoints.down(500));

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedQuestion, setSelectedQuestion] = useState(null);


    const faqData = [
        {
            question: 'Who should use Popupsmart?',
            answer: 'Popupsmart can be used by anyone who wants to create popups easily without coding skills.',
        },
        {
            question: 'What is required to use Popupsmart?',
            answer:
                'You are not required anything to use Popupsmart. It is a no-code tool. All you need to do is to create a free account, design a popup or select one of our predesigned popup templates, and embed the popup code to your website.',
        },
        {
            question: 'Do I need to have coding skills to use Popupsmart?',
            answer: 'No coding skills are needed. Popupsmart is a no-code tool.',
        },
        {
            question: 'Do I need to have design skills to design popups with Popupsmart?',
            answer: 'No design skills are required. Popupsmart offers predesigned templates to choose from.',
        },
        {
            question: 'Why should I choose Popupsmart over similar popup apps?',
            answer: 'Popupsmart offers better integration, ease of use, and no requirement for coding or design skills.',
        },
    ];

    const handleQuestionClick = (index) => {
        if (selectedQuestion === index) {
            setSelectedQuestion(null);
        } else {
            setSelectedQuestion(index);
        }
    };

    const filteredFAQs = faqData.filter((faq) =>
        faq.question.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const leftGridItem = (icon, heading, content, detail) => (
        <div style={{ display: 'flex', marginBottom: '20px' }}>
            <IconButton style={{ ...iconStyle, alignSelf: 'flex-start' }}>
                {icon}
            </IconButton>
            <div style={{ marginLeft: '15px' }}>
                <h3 style={{ margin: 0, fontWeight: 500 }}>{heading}</h3>
                <p style={{ margin: '5px 0' }}>{content}</p>
                <p style={{ fontWeight: 600, margin: 0, fontSize: 15 }}>{detail}</p>
            </div>
        </div>
    );

    const iconStyle = {
        border: '1px solid gainsboro',
        borderRadius: '50%',
        padding: '5px',
        color: primaryColor,
    };

    return (
        <div style={{ display: 'flex', flexDirection: matches3 || matches1 ? 'column' : 'row', padding: '7% 15%' }}>
            {/* Left Side */}
            <div style={{ textAlign: 'left' }}>
                <h3 style={{ fontWeight: 500, fontSize: 25 }}>Frequently Asked Questions</h3>
                <Grid container spacing={0}>
                    {/* Left Section */}
                    <Grid item md={12} style={{ marginBottom: matches2 ? '20px' : '', marginTop: '2%' }}>
                        {leftGridItem(<IoChatbubblesOutline size={15} />, 'Chat to us', 'Our friendly team is here to help.', 'support@oredomotor.com')}
                        {leftGridItem(<IoLocationOutline size={15} />, 'Visit us', 'Come say hello at our office HQ.', '6-67, Yerrakunta, Chandrayangutta, Hyderabad, Telangana 500005')}
                        {leftGridItem(<IoCallOutline size={15} />, 'Call us', 'Directly call us now.', '+91 8790200520')}
                        <div style={{ display: 'flex', marginTop: '10%', gap: '15px' }}>
                            <FaFacebookF style={iconStyle} />
                            <FaTwitter style={iconStyle} />
                            <FaYoutube style={iconStyle} />
                        </div>
                    </Grid>
                </Grid>
            </div>

            {/* Right Side */}
            <div className="faq-right" style={{ paddingLeft: '20px' }}>

                {filteredFAQs.length > 0 ? (
                    filteredFAQs.map((faq, index) => (
                        <div key={index} className="faq-item">
                            <div
                                onClick={() => handleQuestionClick(index)}
                                className="faq-question"
                                style={{
                                    cursor: 'pointer',
                                    fontWeight: 'bold',
                                    marginBottom: '5px',
                                    padding: '5px 0',
                                    borderBottom: '1px solid #ddd',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}

                            >
                                <h3 style={{ fontWeight: 500, fontSize: 15 }}>{faq?.question}</h3>
                                <span>{selectedQuestion === index ? '-' : '+'}</span>
                            </div>
                            {selectedQuestion === index && (
                                <div style={{ fontSize: 15 }}>{faq?.answer}</div>
                            )}
                        </div>
                    ))
                ) : (
                    <p>No results found.</p>
                )}
            </div>
        </div >
    );
};

export default FAQSection;
