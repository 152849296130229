import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { useState, useEffect } from "react";
import { createButtonBg, primaryColor } from '../../constant';
import { get_data, post_data } from '../../api';
import toast from 'react-hot-toast';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
};

export default function AddGpsModals({ setEditModelOpen, editModelOpen, editModelData, isUpdate, createModelOpen, setCreateModelOpen, refresh, setRefresh }) {
    const [name, setName] = useState('');
    const [errors, setErrors] = useState({});
    const [brandList, setBrandList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [brandId, setBrandId] = useState('');
    const [fuelList,setFuelList]=useState([])
    const [selectedFuel,setSelectedFuel] = useState([])
    useEffect(() => {
        // Initialize form state based on whether it is an update or a new entry
        if (isUpdate && editModelData) {
            console.log("ooooo",editModelData)
            setName(editModelData?.name || '');
            setBrandId(editModelData?.brandId?._id || '');
            
            // setSelectedFuel(editModelData?.fuelId)
        } else {
            setName('');
            setBrandId('');
        }
    }, [editModelData, isUpdate]);

    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const getAllFuels = async()=>{
        try{
        let result = await get_data(`fuel/get-all-fuels?pageNumber=${currentPage}`)
        if(result?.status) {
            setFuelList(result?.data?.fuel)
        }else{
            toast.error('Failed to fetch Fuel list');
        }
    }catch (error) {
        toast.error('An error occurred while fetching brands');
    }
    }

    const getAllBrands = async () => {
        try {
            const data = await get_data(`gpsbrand/get-all-brands?pageNumber=${currentPage}`);
            if (data?.status) {
                setBrandList(data?.data?.brands || []);
            } else {
                toast.error('Failed to fetch brand list');
            }
        } catch (error) {
            toast.error('An error occurred while fetching brands');
        }
    };

    useEffect(() => {
        getAllBrands();
        getAllFuels();
    }, [currentPage, refresh]);

    const validation = () => {
        let error = false;
        if (!name) {
            error = true;
            handleError('Please input Model Name...', 'name');
        }
        if (!brandId) {
            error = true;
            handleError('Please select a Brand...', 'brand');
        }
        // if(selectedFuel?.length == 0){
        //     error = true;
        //     handleError('Please select a Fuel...', 'fuel');
        // }
        return error;
    };

    const handleClose = () => {
        if (isUpdate) {
            setEditModelOpen(false);
        } else {
            setCreateModelOpen(false);
        }
    };

    const handleSubmit = async () => {
        const error = validation();
        if (!error) {
            try {
                let data;
                let fuelIds = selectedFuel?.map((item)=>item?._id)
                if (isUpdate) {
                    data = await post_data(`gps-model/update-gps-model/${editModelData?._id}`, { name, brandId});
                    setEditModelOpen(false);
                } else {
                    data = await post_data(`gps-model/create-gps-model`, { name, brandId});
                    setCreateModelOpen(false);
                }
                setRefresh(!refresh);
                if (data?.status) {
                    toast.success(data?.message || 'Operation successful!');
                } else {
                    toast.error(data?.message || 'Operation failed!');
                }
                setBrandId('')
                setName('')
            } catch (error) {
                toast.error('An error occurred. Please try again.');
            }
        }
    };
// console.log("fuelList?.find((item)=>fuelId?.includes(item?._id) )",fuelList?.find((item)=>fuelId?.includes(item?._id) ))
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={isUpdate ? editModelOpen : createModelOpen}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={isUpdate ? editModelOpen : createModelOpen}>
                    <Box sx={style}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            {isUpdate ? 'Edit Gps Model' : 'Create Gps Model'}
                        </Typography>
                        <Grid container spacing={2} style={{ marginTop: 10 }}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="state-Brands"
                                    options={brandList}
                                    value={brandList.find((s) => s._id === brandId) || null}
                                    onChange={(e, n) => setBrandId(n?._id || '')}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Brands"
                                            fullWidth
                                            error={Boolean(errors.brand)}
                                            helperText={errors.brand}
                                        />
                                    )}
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Autocomplete
                                    id="state-Fuel"
                                    disablePortal
                                    multiple
                                    options={fuelList}
                                    value={selectedFuel || null}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => {
                                        console.log("option, value",option, value)
                                        if (!option || !value) return false;
                                        return option?._id === value?._id;
                                    }}
                                    onChange={(e, n) => {
                                        setSelectedFuel(n)
                                    }}
                                    defaultChecked={selectedFuel}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select fuel"
                                            fullWidth
                                            error={Boolean(errors.fuel)}
                                            helperText={errors.fuel}
                                        />
                                    )}
                                />
                            </Grid> */}
                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.name)}
                                    helperText={errors.name}
                                    onFocus={() => handleError('', 'name')}
                                    value={name}
                                    label="Model Name"
                                    onChange={(e) => setName(e.target.value)}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
                                <Button
                                    variant="outlined"
                                    style={{
                                        textTransform: 'capitalize',
                                        color: primaryColor,
                                        border: `1px solid ${primaryColor}`,
                                        borderRadius: 0,
                                    }}
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    style={{
                                        textTransform: 'capitalize',
                                        color: '#fff',
                                        background: primaryColor,
                                        borderRadius: 0,
                                    }}
                                    onClick={handleSubmit}
                                    fullWidth
                                >
                                    {isUpdate ? 'Edit' : 'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
