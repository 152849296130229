import React, { useEffect, useState } from 'react';
import { Box, Grid, Avatar, Button, TextField, Typography, Autocomplete, Card, CardContent, FormControl, InputLabel, Select, MenuItem, useTheme, useMediaQuery, IconButton, InputAdornment, OutlinedInput, } from '@mui/material';
import { Country, State, City } from 'country-state-city';
import { AiOutlineLogout } from "react-icons/ai";
import Swal from "sweetalert2";
import { primaryColor } from '../constant';
import { useDispatch, useSelector } from "react-redux";
import { post_data } from '../api';
import { Toaster, toast } from 'react-hot-toast';
import { login } from "../../src/redux/slices/user-slice";
import { logout } from "../../src/redux/slices/user-slice"
import { Visibility, VisibilityOff } from '@mui/icons-material';
import EmailModel from './models/EmailModel';
import { useNavigate } from 'react-router-dom';


const VendorSettingComp = () => {

    const navigate = useNavigate()
    const { user_data } = useSelector(state => state.user);
    const dispatch = useDispatch();
    console.log("DATA", user_data)
    const [name, setName] = useState(user_data?.name || '')
    const [gst, setGst] = useState(user_data?.gst || '')
    const [phone, setPhone] = useState(user_data?.phone || '')
    const [email, setEmail] = useState(user_data?.email || '')
    const [countryCode, setCountryCode] = useState('IN');
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [landmark, setLandmark] = useState(user_data?.address?.landmark || '')
    const [street, setStreet] = useState(user_data?.address?.street || '')
    const [zipCode, setZipCode] = useState(user_data?.address?.zipCode || '')
    const [state, setState] = useState(user_data?.address?.state || '')
    const [city, setCity] = useState(user_data?.address?.city || '')
    const [emailModel, setEmailModel] = useState(false)
    const [branchName, setBranchName] = useState(user_data?.bankDetails?.branchName || '')
    const [bankName, setBankName] = useState(user_data?.bankDetails?.bankName || '')
    const [accountNumber, setAccountNumber] = useState(user_data?.bankDetails?.accountNumber || '')
    const [ifscCode, setIfscCode] = useState(user_data?.bankDetails?.ifscCode || '')
    const [accountHolderName, setAccountHolderName] = useState(user_data?.bankDetails?.accountHolderName || '')
    const [errors, setErrors] = useState({})

    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }))

    }

    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [oldPassword, setOldPassword] = useState('')

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfrimNewPassword, setShowConfrimNewPassword] = useState(false);
    const theme = useTheme();
    const matches3 = useMediaQuery(theme.breakpoints.down(500));
    const matches1 = useMediaQuery(theme.breakpoints.down('md'));
    const isDesktop = useMediaQuery('(min-width: 768px)');

    const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const handleClickShowConfrimNewPassword = () => setShowConfrimNewPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const validatAddress = () => {
        var errors = false
        if (!landmark.trim()) {
            errors = true
            handleError('pls Input Landmark...', 'landmark')
        } if (!street.trim()) {
            errors = true
            handleError('pls Input Street...', 'street')
        } if (!state.trim()) {
            errors = true
            handleError('pls Input State...', 'state')
        } if (!city.trim()) {
            errors = true
            handleError('pls Input City...', 'city')
        } if (!zipCode.trim()) {
            errors = true
            handleError('pls Input zipCode...', 'zipCode')
        }

        return errors
    }



    const validation = () => {
        var error = false
        if (!name.trim()) {
            error = true
            handleError('pls Input Name...', 'name')
        }

        if (!phone.trim()) {
            error = true
            handleError('pls Input Phone...', 'phone')
        }
        return error
    }

    const handleSubmit = async () => {
        var error = validation();
        if (error === false) {
            var body = { name: name, gst, phone }
            let response
            if (user_data?.isSubClient) {
                // response = await post_data(`sub-client/update-sub-client/${user_data?._id}`, body);
            } else {
                response = await post_data(`vendor/update-vendor/${user_data?._id}`, body);
            }
            console.log('update-vandor-response', response)
            if (response?.status) {
                toast.success(response?.message);
                dispatch(login(response?.data));
                setEmailModel(false)
            }
            else {
                toast.error("Something went wrong");
            }
        }
    }

    const handleBankSubmit = async () => {
        var body = { bankDetails: { accountHolderName, branchName, bankName, ifscCode, accountNumber } }
        let response = await post_data(`vendor/update-vendor/${user_data?._id}`, body);
        console.log('update-vandor-response', response)
        if (response?.status) {
            toast.success(response?.message);
            dispatch(login(response?.data));
            setEmailModel(false)
        }
        else {
            toast.error("Something went wrong");
        }
    }

    const handleAddressSubmit = async () => {
        var errors = validatAddress()
        if (errors === false) {
            var body = { address: { street, landmark, state, city, zipCode } }
            let response = await post_data(`vendor/update-vendor/${user_data?._id}`, body);
            console.log('update-vandor-response', response)
            if (response?.status) {
                toast.success(response?.message);
                dispatch(login(response?.data));
                setEmailModel(false)
            }
            else {
                toast.error("Something went wrong");
            }
        }
    }


    useEffect(() => {
        setStateList(State.getStatesOfCountry(countryCode));
    }, [countryCode]);

    useEffect(() => {
        if (user_data?.address?.state) {
            setCityList(City.getCitiesOfState(countryCode, user_data?.address?.state));
        }
    }, [user_data?.address?.state, countryCode])

    const handleStateChange = (event, newValue) => {
        if (newValue) {
            const stateCode = newValue.isoCode;
            const countryCodes = countryCode;
            const fetchedCities = City.getCitiesOfState(countryCodes, stateCode);
            setCityList(fetchedCities);
            setState(stateCode);
            setCity('')
        }
    };

    const handleCityChange = (event, newValue) => {
        if (newValue) {
            setCity(newValue.name);
        }
    };



    // const handlePicture = (event) => {
    //     setProfilePicture({ bytes: event.target.files[0], filename: URL.createObjectURL(event.target.files[0]) })
    // }


    const handleLogout = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: ` #d33`,
            cancelButtonColor: `${primaryColor}`,
            confirmButtonText: 'Yes, Logout !'

        }).then(async (result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('authToken')
                dispatch(logout());
                navigate('/vendor/login')
            }
        })

    }

    const validate = () => {

        if (oldPassword == "" && user_data?.password) {
            toast.error("Please Enter Old Password!");
            return false
        }

        if (newPassword === confirmNewPassword) {
            return true
        } else {
            toast.error("Password doesn't match!");
            return false
        }
    }

    const handleSaveNewPassword = async () => {
        if (validate()) {
            let result
            if (user_data?.password) {
                if (user_data?.isSubClient) {
                } else {
                    result = await post_data(`vendor/change-vendor-password/${user_data._id}`, { currentPassword: oldPassword, newPassword: newPassword })
                }

                if (result.status) {
                    dispatch(login(result?.data));
                    toast.success(result.message);
                } else {
                    if (result?.response?.data?.message === "Wrong Password") {
                        toast.error("Old Password doesn't match!")
                    } else {
                        toast.error("Something Went Wrong!")
                    }
                }
            }
            else {
                let result

                if (user_data?.isSubClient) {
                } else {
                    result = await post_data(`vendor/change-vendor-password/${user_data._id}`, { newPassword: newPassword })
                }
                if (result.status) {
                    dispatch(login(result?.data));
                    toast.success(result.message);
                } else {
                    toast.error("Something Went Wrong!")
                }
            }
        }
    }


    const browseButton = {
        padding: '10px 12px',
        borderRadius: 0,
        backgroundColor: primaryColor,
        color: 'white',
        fontSize: 15,
        width: 'fit-content',
        textTransform: 'capitalize',
        cursor: 'pointer',
        border: 'none',
    }

    console.log('user_datauser_data', user_data)

    return (
        <Box>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            {/* Tabs Section */}
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2rem', }}>
                    <Typography sx={{ fontWeight: '500', paddingBottom: '0.5rem', borderBottom: `3px solid ${primaryColor}` }}>
                        Profile settings
                    </Typography>
                </Box>
                <Button variant="outlined" onClick={handleLogout} color='#ff4d4d' style={{ color: '#ff4d4d', borderRadius: '0px', boxShadow: 'none', gap: 10 }}  >
                    <AiOutlineLogout />
                    LogOut
                </Button>
            </Box>


            {/* Main Grid Layout */}
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <Box style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro', padding: 20 }} >
                                <Typography variant="h6" fontWeight="500" gutterBottom >
                                    Profile Details
                                </Typography>
                                <Grid container spacing={2} style={{ marginTop: '1%' }} >
                                    <Grid item xs={12} md={6} >
                                        <TextField
                                            label="Username"
                                            fullWidth
                                            value={name}
                                            // variant="outlined"
                                            error={errors.name}
                                            helperText={errors.name}
                                            onFocus={() => handleError('', 'name')}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <TextField
                                            label="GST"
                                            fullWidth
                                            value={gst}
                                            // variant="outlined"
                                            // error={errors.gst}
                                            // helperText={errors.gst}
                                            // onFocus={() => handleError('', 'gst')}
                                            onChange={(e) => setGst(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={errors.email}
                                            helperText={errors.email}
                                            // onFocus={() => handleError('', 'email')}
                                            // onChange={(event) => setEmail(event.target.value)}
                                            label="Email"
                                            fullWidth
                                            value={email}
                                            disabled
                                            variant="outlined"
                                        />
                                        <p style={{ fontSize: '14px', color: primaryColor, cursor: 'pointer', }} onClick={() => navigate('/admin/change-email', { state: "isVendor" })} >Change Email</p>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Phone"
                                            fullWidth
                                            error={errors.phone}
                                            helperText={errors.phone}
                                            onFocus={() => handleError('', 'phone')}
                                            onChange={(event) => setPhone(event.target.value)}
                                            value={phone}
                                            variant="outlined"
                                        />
                                        {/* <p style={{ fontSize: '14px', color: primaryColor, margin: '0px,0px', cursor: 'pointer' }} onClick={() => navigate('/admin/change-phone', { state: "isVendor" })}>Change Phone Number</p> */}
                                    </Grid>


                                    <Grid item xs={12} md={12}>
                                        <Button onClick={(e) => handleSubmit()} variant="contained" style={{ background: `${primaryColor}`, color: '#fff', padding: '8px 44px 8px 44px', marginTop: '10px', borderRadius: '0px', boxShadow: 'none' }}  >
                                            Save
                                        </Button>
                                    </Grid>

                                </Grid>
                            </Box>
                        </Grid>


                        <Grid item xs={12} >
                            <Box style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro', padding: 20 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="h6" fontWeight="500" gutterBottom>
                                            Bank Details
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <TextField
                                            value={branchName}
                                            // error={errors.branchName}
                                            // helperText={errors.branchName}
                                            // onFocus={() => handleError('', 'branchName')}
                                            onChange={(e) => setBranchName(e.target.value)}
                                            label='Branch Name' fullWidth />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            value={bankName}
                                            // error={errors.bankName}
                                            // helperText={errors.bankName}
                                            // onFocus={() => handleError('', 'bankName')}
                                            onChange={(e) => setBankName(e.target.value)}
                                            label='Bank Name' fullWidth />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            value={accountHolderName}
                                            // error={errors.accountHolderName}
                                            // helperText={errors.accountHolderName}
                                            // onFocus={() => handleError('', 'accountHolderName')}
                                            onChange={(e) => setAccountHolderName(e.target.value)}
                                            label='Account Holder Name' fullWidth />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            value={accountNumber}
                                            // error={errors.accountNumber}
                                            // helperText={errors.accountNumber}
                                            // onFocus={() => handleError('', 'accountNumber')}
                                            onChange={(e) => setAccountNumber(e.target.value)}
                                              label='Account Number' fullWidth />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            value={ifscCode}
                                            // error={errors.ifscCode}
                                            // helperText={errors.ifscCode}
                                            // onFocus={() => handleError('', 'ifscCode')}
                                            onChange={(e) => setIfscCode(e.target.value)}
                                            label='IFSC Code' fullWidth />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <Button onClick={handleBankSubmit} variant="contained" style={{ background: `${primaryColor}`, color: '#fff', padding: '8px 44px 8px 44px', marginTop: '10px', borderRadius: '0px', boxShadow: 'none' }}  >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>


                        <Grid item xs={12} >
                            <Box style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro', padding: 20 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="h6" fontWeight="500" gutterBottom>
                                            Address
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <TextField
                                            value={landmark}
                                            error={errors.landmark}
                                            helperText={errors.landmark}
                                            onFocus={() => handleError('', 'landmark')}
                                            onChange={(e) => setLandmark(e.target.value)}
                                            label='Landmark' fullWidth />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            value={street}
                                            error={errors.street}
                                            helperText={errors.street}
                                            onFocus={() => handleError('', 'street')}
                                            onChange={(e) => setStreet(e.target.value)}
                                            label='Street' fullWidth />
                                    </Grid>

                                    <Grid item md={6} sm={6} xs={12}>
                                        <Autocomplete
                                            id="state-select"
                                            options={stateList}
                                            value={stateList.find(s => s.isoCode === state) || null}
                                            onChange={handleStateChange}
                                            autoHighlight
                                            getOptionLabel={(option) => option.name}
                                            renderOption={(props, option) => (
                                                <Box
                                                    component="li"
                                                    {...props}
                                                    value={option.isoCode}
                                                    key={option.isoCode}
                                                >
                                                    {option.name}
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={errors.state}
                                                    helperText={errors.state}
                                                    onFocus={() => handleError('', 'state')}
                                                    label="Select State"
                                                    fullWidth
                                                />
                                            )}
                                        />


                                    </Grid>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <Autocomplete
                                            id="city-select"
                                            options={cityList}
                                            value={cityList.find(c => c.name === city) || null}
                                            onChange={handleCityChange}
                                            autoHighlight
                                            getOptionLabel={(option) => option.name}
                                            renderOption={(props, option) => (
                                                <Box
                                                    component="li"
                                                    {...props}
                                                    value={option.name}
                                                    key={option.name}
                                                >
                                                    {option.name}
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    error={errors.city}
                                                    helperText={errors.city}
                                                    onFocus={() => handleError('', 'city')}
                                                    {...params}
                                                    label="Select City"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            value={zipCode}
                                            error={errors.zipCode}
                                            helperText={errors.zipCode}
                                            onFocus={() => handleError('', 'zipCode')}
                                            onChange={(e) => setZipCode(e.target.value)}
                                              label='Zipcode' fullWidth />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <Button onClick={handleAddressSubmit} variant="contained" style={{ background: `${primaryColor}`, color: '#fff', padding: '8px 44px 8px 44px', marginTop: '10px', borderRadius: '0px', boxShadow: 'none' }}  >
                                            Save
                                        </Button>
                                    </Grid>

                                </Grid>
                            </Box>
                        </Grid>

                    </Grid>

                </Grid>




                <Grid item xs={12} >
                    <Grid container spacing={1} style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro', padding: 20, margin: '0px', width: '100%' }}>
                        {user_data?.password &&
                            <Grid item xs={12} sm={12} >
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Old Password</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        label="Old Password"
                                        variant="outlined"
                                        placeholder="Enter Your Name"
                                        type={showOldPassword ? 'text' : 'password'}
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowOldPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={12} sm={6} style={{ display: 'flex', gap: 15 }}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel>New Password</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    label="New Password"
                                    placeholder="Enter Your New Password"
                                    type={showNewPassword ? 'text' : 'password'}
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowNewPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ display: 'flex', gap: 15 }}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel>Confirm New Password</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    label="Confirm New Password"
                                    variant="outlined"
                                    placeholder="Confrim Your New Password"
                                    type={showConfrimNewPassword ? 'text' : 'password'}
                                    value={confirmNewPassword}
                                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfrimNewPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showConfrimNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={6} sm={6} >
                            <Box sx={{ mt: 3 }}>
                                <Button style={browseButton} variant='outlined' onClick={handleSaveNewPassword}>
                                    Save Password
                                </Button>
                            </Box>
                        </Grid>

                    </Grid>
                </Grid>


                {/* Sidebar Section */}
                <Grid item xs={12} md={4} >
                    {/* <div style={{ background: 'white', borderRadius: 5, padding: '6%', border: '1px solid gainsboro' }}>
                        <Typography variant="h6" fontWeight="500" gutterBottom>
                            Change Password
                        </Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            You can permanently delete or temporarily freeze your account.
                        </Typography>
                        <Button variant="contained" style={{ marginTop: '1rem', width: '100%', borderRadius: '0px', boxShadow: 'none', background: primaryColor, padding: '3% 0' }}>
                            Change Password
                        </Button>
                    </div> */}


                    {/* Close Account */}
                    {/* <div style={{ background: 'white', borderRadius: 5, padding: '6%', border: '1px solid gainsboro', marginTop: '5%' }}>
                        <Typography variant="h6" fontWeight="500" gutterBottom>
                            Close Account
                        </Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            You can permanently delete or temporarily freeze your account.
                        </Typography>
                        <Button variant="outlined" color="error" sx={{ marginTop: '1rem', width: '100%' }}>
                            Close Account
                        </Button>
                        <Button onClick={handleLogout} variant="outlined" color="error" sx={{ marginTop: '1rem', width: '100%',gap:2 }}>
                           <LogoutIcon sx={{width:20}} /> Longuot
                        </Button>
                    </div> */}

                </Grid>

            </Grid>
        </Box>
    );
};

export default VendorSettingComp;