
import { Button, Grid, Tabs, Tab, CircularProgress, Typography, TextField, Autocomplete } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import { useEffect, useState } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminInventoryTable from "../../../components/tables/AdminInventoryTable";
import { baseURL, get_data, post_data } from "../../../api";
import SearchComponent from "../../../components/SearchComponent";
import { createButtonBg, primaryColor, primaryColorHover } from "../../../constant";
import EmptyPage from "../../../components/EmptyPage"
import AdminInventorysModal from "../../../components/models/AdminInventoryModal";
import AdminActiveInventoryTable from "../../../components/tables/AdminActiveInventoryTable";
import * as XLSX from 'xlsx';
import toast from "react-hot-toast";
import AdminInventoryExportModal from "../../../components/models/AdminInventoryExportModal";

const rangePickerStyle = {
    display: 'flex',
    cursor: 'pointer',
    fontSize: 14,
    background: 'white',
    padding: '0% 0% 0% 0%',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    color: '#7E8299',
    cursor: 'pointer'
};

export default function AdminInventory() {
    const navigate = useNavigate()
    const page = { width: '100%', marginBottom: '2%' };
    const { user_data } = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [value, setValue] = useState('')
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [open, setOpen] = useState(false);
    const [exportOpen, setExportOpen] = useState(false);
    const [fromDate, setFromDate] = useState([])
    const [toDate, setToDate] = useState([])
    const [inventoryData, setInventoryData] = useState([])
    const [tab, setTab] = useState(0)
    const [fileName, setFileName] = useState('');
    const [excelData, setExcelData] = useState([]);
    const [excelLoading, setExcelLoading] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const [companyId, setCompanyId] = useState(null);

    const itemsPerPage = 15;
    const count = data?.length;

    const fetchExportData = async (search) => {
        try {
            const fromDate = moment(startDate).toISOString();
            const toDate = moment(endDate).toISOString();

            let response = await post_data(`admin-inventory/get-admin-inventory-by-date/${tab}`, {
                from: fromDate,
                to: toDate
            })

            if (response?.status) {
                setInventoryData(response?.data?.adminInventory)
                setExportOpen(true)
                setFromDate(response?.data?.fromDate)
                setToDate(response?.data?.toDate)
            } else {

            }
        } catch (error) {
        }
    };

    const handleDateChange = (dates) => {
        if (dates && dates?.length === 2) {
            setDateRange(dates);
        }
    };

    const fetchAllCompany = async () => {
        const result = await get_data(`client/get-all-clients-for-options`);
        setCompanyList(result?.data || []);
    };

    const getInvenrotyData = async (page) => {
        try {
            setLoading(true);
            if (tab === 0) {
                const data = await get_data(`admin-inventory/get-all-admin-inventory?pageNumber=${page || currentPage}`);
                if (data.status) {
                    setData(data?.data?.adminInventory || []);
                    setTotalPages(data?.data?.totalPages);
                    setTotalData(data?.data?.totalAdminInventory);
                    if (page) {
                        setCurrentPage(page)
                    }
                }
            } else if (tab === 2) {
                const data = await get_data(`admin-inventory/get-all-non-active-admin-inventory?pageNumber=${page || currentPage}`);
                if (data.status) {
                    setData(data?.data?.adminInventory || []);
                    setTotalPages(data?.data?.totalPages);
                    setTotalData(data?.data?.totalAdminInventory);
                    if (page) {
                        setCurrentPage(page)
                    }
                }
            } else if (tab === 3) {
                const data = await get_data(`admin-inventory/get-all-stock-admin-inventory?pageNumber=${page || currentPage}`);
                if (data.status) {
                    setData(data?.data?.adminInventory || []);
                    setTotalPages(data?.data?.totalPages);
                    setTotalData(data?.data?.totalAdminInventory);
                    if (page) {
                        setCurrentPage(page)
                    }
                }
            } else if (tab === 4) {
                const data = await get_data(`admin-inventory/get-all-no-data-admin-inventory?pageNumber=${page || currentPage}`);
                if (data.status) {
                    setData(data?.data?.adminInventory || []);
                    setTotalPages(data?.data?.totalPages);
                    setTotalData(data?.data?.totalAdminInventory);
                    if (page) {
                        setCurrentPage(page)
                    }
                }
            } else {
                const data = await get_data(`admin-inventory/get-all-active-admin-inventory?pageNumber=${page || currentPage}`);
                if (data.status) {
                    setData(data?.data?.adminInventory || []);
                    setTotalPages(data?.data?.totalPages);
                    setTotalData(data?.data?.totalAdminInventory);
                    if (page) {
                        setCurrentPage(page)
                    }
                }
            }
            setLoading(false);

        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentPage) {
            if (value.length > 0) {
                handleSearch(currentPage);
            } else if (companyId) {
                handleCompanyChange(companyId, currentPage)
            } else {
                getInvenrotyData(currentPage);
                fetchAllCompany();
            }
        }
    }, [currentPage, tab, companyId])


    const handleSearch = async (page) => {
        setLoading(true);
        if (value.length > 0) {
            let data;
            data = await get_data(`admin-inventory/search-admin-inventory/${value}/${tab === 1 ? 'active' : tab === 2 || tab === 3 || tab === 4 && 'non-active'}?pageNumber=${page || 1}&tab=${tab}`);
            if (data.status) {
                setData(data?.data?.adminInventory || []);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalAdminInventory);
                setCurrentPage(page || 1);
            }
        } else {
            getInvenrotyData(1);
        }
        setLoading(false);
    };


    const handleTabChange = (event, newValue) => {
        setTab(newValue);
        setCurrentPage(1);
        setDateRange([]);
    };

    const downloadCSV = () => {
        const userAgent = window.navigator.userAgent;
        let fileType = '';
        let fileName = '';

        if (userAgent.includes('Mac')) {
            fileType = 'numbers';
            fileName = 'Admin_Inventory.numbers';
        } else if (userAgent.includes('Windows')) {
            fileType = 'xlsx';
            fileName = 'Admin_Inventory.xlsx';
        } else {
            fileType = 'xlsx';
            fileName = 'Admin_Inventory.xlsx';
        }

        const link = document.createElement('a');
        link.href = `${baseURL}/images/${fileName}`;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setFileName(file.name);
        const reader = new FileReader();

        // When file is loaded
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);

            setExcelData(jsonData);
        };

        reader.readAsArrayBuffer(file);
    };

    const handleSubmitExcel = async () => {
        try {
            setExcelLoading(true);
            const result = await post_data("admin-inventory/create-admin-inventory-by-excel", excelData);
            if (result.status === true) {
                toast.success("Inventory created Successfully");
                setExcelData([]);
                setFileName('');
                getInvenrotyData(currentPage);
            } else {
                toast.error(result?.response?.data?.message || "Something went wrong");
                getInvenrotyData(currentPage);
            }
            setExcelLoading(false);
        } catch (error) {
        }
    };


    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        )
    }

    const handleCompanyChange = async (newValue, page) => {
        if (!newValue) {
            setCompanyId(null);
            return;
        }
        setCompanyId(newValue)
        const data = await get_data(`admin-inventory/filter-admin-inventory-by-company/${newValue}/${tab}?pageNumber=${page || 1}`);

        if (data.status === true) {
            setData(data?.data?.adminInventory || []);
            setTotalPages(data?.data?.totalPages);
            setTotalData(data?.data?.totalAdminInventory);
        }
    }

    return (
        <>
            <AdminInventoryExportModal
                open={exportOpen}
                setOpen={setExportOpen}
                fromDate={fromDate}
                toDate={toDate}
                data={inventoryData}
            />
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>

                <div style={{ display: 'flex', marginBottom: '1%', width: '100%', gap: '1%' }}>
                    <div>
                        <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                    </div>
                    <div>
                        <Autocomplete
                            fullWidth
                            options={companyList}
                            value={companyList?.find((s) => s?._id === companyId) || null}
                            onChange={(event, newValue) => handleCompanyChange(newValue?._id || null)}
                            autoHighlight
                            getOptionLabel={(option) => option.companyName || ''}
                            renderInput={(params) => <TextField {...params} size="small" sx={{ width: '300px' }} label="Select Company" fullWidth />}
                        />
                    </div>

                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        {
                            excelData.length === 0 &&
                            <>
                                <Button
                                    onClick={downloadCSV}
                                    variant='outlined'
                                    sx={{
                                        borderColor: primaryColor,
                                        color: primaryColor,
                                        textTransform: 'capitalize',
                                        borderRadius: 0,
                                        fontWeight: 400,
                                        boxShadow: 'none',
                                        '&:hover': {
                                            borderColor: primaryColor,
                                            boxShadow: 'none',
                                        },
                                        '&:focus': {
                                            outline: 'none',
                                            boxShadow: 'none',
                                            borderColor: primaryColor,
                                        }
                                    }}
                                >
                                    Download Excel
                                </Button>
                                <Button
                                    variant="contained"
                                    component="label"
                                    sx={{
                                        background: primaryColor,
                                        color: 'white',
                                        borderRadius: 0,
                                        fontWeight: 400,
                                        textTransform: 'capitalize',
                                        boxShadow: 'none',
                                        '&:hover': {
                                            backgroundColor: primaryColorHover,
                                            boxShadow: 'none',
                                        },
                                        '&:focus': {
                                            outline: 'none',
                                            boxShadow: 'none',
                                            backgroundColor: primaryColor,
                                        },
                                    }}
                                >
                                    Upload Excel
                                    <input
                                        type="file"
                                        accept=".xlsx, .xls"
                                        hidden
                                        onChange={handleFileUpload}
                                    />
                                </Button>
                            </>
                        }
                        {fileName && <p>Uploaded File: {fileName}</p>}

                        {excelData.length > 0 && (
                            <Button
                                variant="contained"
                                sx={{
                                    background: primaryColor,
                                    color: 'white',
                                    borderRadius: 0,
                                    fontWeight: 400,
                                    textTransform: 'capitalize',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        backgroundColor: primaryColorHover,
                                        boxShadow: 'none',
                                    },
                                    '&:focus': {
                                        outline: 'none',
                                        boxShadow: 'none',
                                        backgroundColor: primaryColor,
                                    }
                                }}
                                disabled={excelLoading}
                                onClick={handleSubmitExcel}
                            >
                                {excelLoading ? 'Uploading...' : "Submit"}
                            </Button>
                        )}
                    </div>

                    <div style={{ marginLeft: 'auto' }}>
                        <Button
                            startIcon={<IoMdAdd style={{ color: 'white' }} />}
                            onClick={() => setOpen(true)}
                            variant="contained"
                            style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                        >
                            Create
                        </Button>
                        <AdminInventorysModal open={open} setOpen={setOpen} isUpdate={false} getInvenrotyData={getInvenrotyData} />
                    </div>

                </div>

                <div>
                    <Typography variant="h6">Total Inventories</Typography>
                    <Typography variant="h4">{totalData}</Typography>
                </div>


                <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                    <div style={{ ...rangePickerStyle, border: 'none', }}>
                        <DatePicker
                            selected={startDate}
                            onChange={handleDateChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            dateFormat="dd-MM-yyyy"
                            maxDate={new Date()}
                            customInput={
                                <button style={{ border: 'none', background: '#fff', cursor: 'pointer', }}>
                                    {startDate && endDate ? `${moment(startDate).format('DD-MM-YYYY')} to ${moment(endDate).format('DD-MM-YYYY')}` : 'Select Date Range'}
                                </button>
                            }
                        />
                    </div>
                    <Button
                        onClick={fetchExportData}
                        variant="contained"
                        style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                        disabled={dateRange?.length === 0}
                    >
                        Export
                    </Button>
                </div>

                <div style={{ width: '100%', marginBottom: '1%', display: 'flex', justifyContent: 'center' }}>
                    <Tabs value={tab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                        <Tab label="All" />
                        <Tab label="Active" />
                        <Tab label="In-Active" />
                        <Tab label="Stock" />
                        <Tab label="No Data" />
                    </Tabs>
                </div>

                {
                    data?.length === 0 ?
                        <>
                            <EmptyPage />
                        </>
                        :
                        tab === 0 || tab === 2 || tab === 3 || tab === 4 ?
                            <>
                                <AdminInventoryTable
                                    data={data}
                                    itemsPerPage={itemsPerPage}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    count={count}
                                    totalData={totalData}
                                    totalPages={totalPages}
                                    loading={loading}
                                    setLoading={setLoading}
                                    getInvenrotyData={getInvenrotyData}
                                />
                            </>
                            :
                            <>
                                <AdminActiveInventoryTable
                                    data={data}
                                    itemsPerPage={itemsPerPage}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    count={count}
                                    totalData={totalData}
                                    totalPages={totalPages}
                                    loading={loading}
                                    setLoading={setLoading}
                                    getInvenrotyData={getInvenrotyData}
                                />
                            </>
                }
            </Grid>
        </>
    )
}