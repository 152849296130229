import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Paper, Divider } from '@mui/material';
import { serverURL } from '../api';
import DocModal from './DocModal';

// A reusable component for displaying label and value pairs
const DetailItem = ({ label, value }) => (
    <Grid item xs={12} sm={6} md={4}>
        <Typography variant="subtitle2" color="textSecondary">{label}</Typography>
        <Typography variant="body1">{value || 'N/A'}</Typography>
    </Grid>
);

const style = {
    docStyle: {
        height: 100,
        width: 100,
        border: '1px solid #ccc',
        borderRadius: 10,
        cursor: 'pointer',
        '&:hover': {
            width: 110,
        },
    }
}


export default function ClientDetailComp({ client, setClient }) {

    const [open, setOpen] = useState(false);

    // console.log("xxxxxxxxxxxxxxxxxx", client)
    return (
        <Box>
            <Paper sx={{ p: 2 }}>
                <Typography variant="h5" gutterBottom>
                    Company Details
                </Typography>
                <Divider sx={{ mb: 2 }} />

                <Grid container spacing={2}>
                    {/* Client Basic Information */}
                    <DetailItem label="Company Name" value={client?.companyName} />
                    <DetailItem label="Unique Client ID" value={client?.uniqueClientId} />
                    <DetailItem label="Contact No" value={client?.contactNo} />
                    <DetailItem label="Email" value={client?.email} />
                    <DetailItem label="Residential Contact No" value={client?.resContactNo} />

                    {/* Address Information */}
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            Address
                        </Typography>
                        <Divider sx={{ mb: 1 }} />
                    </Grid>
                    <DetailItem label="Street" value={client?.address?.street} />
                    <DetailItem label="Landmark" value={client?.address?.landmark} />
                    <DetailItem label="City" value={client?.address?.city} />
                    <DetailItem label="Zip Code" value={client?.address?.zipCode} />
                    <DetailItem label="State" value={client?.address?.state} />


                    {/* Sites Information */}
                    {client?.sites && client?.sites?.length > 0 && (
                        <>
                            <Grid item xs={12} >
                                <Typography variant="h6" gutterBottom>
                                    Site Addresses
                                </Typography>
                                <Divider sx={{ mb: 1 }} />
                            </Grid>
                            {client?.sites?.map((site, index) => (
                                <Grid key={index} item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }} >
                                    <DetailItem label="Site Street" value={site?.street} />
                                    <DetailItem label="Site Landmark" value={site?.landmark} />
                                    <DetailItem label="Site City" value={site?.city} />
                                    <DetailItem label="Site State" value={site?.state} />
                                    <DetailItem label="Site Zip Code" value={site?.zipCode} />
                                </Grid>
                            ))}
                        </>
                    )}

                    {/* Other Information */}
                    <DetailItem label="Client Status" value={client?.isClient ? 'Active' : 'Inactive'} />
                    <DetailItem label="Created At" value={new Date(client?.createdAt).toLocaleDateString()} />


                    {/* sub client */}

                    {client?.subClientId && client?.subClientId?.length > 0 && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    Sub Clients
                                </Typography>
                                <Divider sx={{ mb: 1 }} />
                            </Grid>
                            {client?.subClientId?.map((subClient, index) => (
                                <Grid key={index} item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }} >
                                    <DetailItem label="Sub Client Name" value={subClient?.companyName} />
                                    <DetailItem label="Sub Client Phone" value={subClient?.contactNo} />
                                    <DetailItem label="Sub Client Email" value={subClient?.email} />
                                    <DetailItem label="Email" value={client?.email} />
                                    <DetailItem label="Residential Contact No" value={client?.resContactNo} />

                                    {/* Address Information */}
                                    <Grid item xs={12}>
                                        <Typography variant="h6" gutterBottom>
                                            Address
                                        </Typography>
                                        <Divider sx={{ mb: 1 }} />
                                    </Grid>
                                    <DetailItem label="Street" value={client?.address?.street} />
                                    <DetailItem label="Landmark" value={client?.address?.landmark} />
                                    <DetailItem label="City" value={client?.address?.city} />
                                    <DetailItem label="Zip Code" value={client?.address?.zipCode} />
                                    <DetailItem label="State" value={client?.address?.state} />

                                </Grid>
                            ))}
                        </>
                    )}


                    {
                        client?.doc1?.length > 0 && (
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    Documents
                                </Typography>
                                <Divider sx={{ mb: 1 }} />
                                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                    {client?.doc1 && <img src={`${serverURL}/uploads/client-images/${client?.doc1}`} alt="image" style={style.docStyle} onClick={() => setOpen(true)} />}
                                    {client?.doc2 && <img src={`${serverURL}/uploads/client-images/${client?.doc2}`} alt="image" style={style.docStyle} onClick={() => setOpen(true)} />}
                                    {client?.doc3 && <img src={`${serverURL}/uploads/client-images/${client?.doc3}`} alt="image" style={style.docStyle} onClick={() => setOpen(true)} />}
                                    {client?.doc4 && <img src={`${serverURL}/uploads/client-images/${client?.doc4}`} alt="image" style={style.docStyle} onClick={() => setOpen(true)} />}
                                    {client?.doc5 && <img src={`${serverURL}/uploads/client-images/${client?.doc5}`} alt="image" style={style.docStyle} onClick={() => setOpen(true)} />}
                                </div>
                            </Grid>
                        )
                    }

                    <DocModal open={open} setOpen={setOpen} docs={[client?.doc1, client?.doc2, client?.doc3, client?.doc4, client?.doc5]} docVerify={[client?.doc1_verified, client?.doc2_verified, client?.doc3_verified, client?.doc4_verified, client?.doc5_verified]} clientId={client?._id} setClient={setClient} />

                </Grid>
            </Paper>
        </Box>
    );
}
