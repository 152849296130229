// import React, { useEffect, useState } from 'react';
// import {
//     Table,
//     TableBody,
//     TableCell,
//     TableContainer,
//     TableHead,
//     TableRow,
//     Checkbox,
//     Avatar,
//     Button,
//     Typography,
//     Menu,
//     MenuItem,
//     IconButton,
//     Pagination,
// } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import { get_data } from '../api';
// import { formatDate } from '../constant';
// import SearchComponent from './SearchComponent';

// const useStyles = makeStyles({
//     root: {
//         '& .MuiTableCell-root': {
//             borderBottom: 'none',
//             padding: '7px 10px',
//         },
//         '& .MuiTableRow-root': {
//             borderBottom: '1px solid gainsboro',
//         },
//         '& .MuiCheckbox-root': {
//             color: '#a3a3a3',
//         },
//     },
//     stageButton: {
//         padding: '4px 12px',
//         borderRadius: '20px',
//         fontSize: '12px',
//         fontWeight: 'bold',
//         '&.closedOwn': {
//             backgroundColor: '#e8f5e9',
//             color: '#43a047',
//         },
//         '&.pending': {
//             backgroundColor: '#fff3e0',
//             color: '#fb8c00',
//         },
//         '&.qualification': {
//             backgroundColor: '#e3f2fd',
//             color: '#1e88e5',
//         },
//         '&.closedLost': {
//             backgroundColor: '#ffebee',
//             color: '#e53935',
//         },
//     },
//     avatarGroup: {
//         display: 'flex',
//         alignItems: 'center',
//     },
//     avatarName: {
//         marginLeft: '8px',
//         fontSize: 14
//     },
//     moreIcon: {
//         color: '#a3a3a3',
//     },
// });


// const StockInventory = ({
//     clientId
// }) => {

//     const tableHeader = [
//         "SNo.", "Vehicle Number", "Sim Number", "Imei Number", "Device Unique Id", "Gps Install Date", 'Gps uninstall Date', "Gps Status"
//     ]
//     const classes = useStyles();
//     const [detailModelOpen, setDetailModelOpen] = useState(false)
//     const [detailModelData, setDetailModelData] = useState({})
//     const [rowsData, setRowsData] = useState([]);
//     const [totalData, setTotalData] = useState(0);
//     const [totalPages, setTotalPages] = useState(0);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [anchorEl, setAnchorEl] = useState(null);
//     const [currentRow, setCurrentRow] = useState(null);
//     const [loading, setLoading] = useState(false);
//     const [itemsPerPage, setItemsPerPage] = useState(15);
//     const [value, setValue] = useState('')


//     const getInvenrotyData = async () => {
//         try {
//             const data = await get_data(`inventory/get-stock-inventory/${clientId}?pageNumber=${currentPage}`);
//             console.log("clientId", data)

//             if (data.status === true) {
//                 setRowsData(data?.data?.inventory || []);
//                 setTotalPages(data?.data?.totalPages);
//                 setTotalData(data?.data?.totalInventory);
//             }
//         } catch (error) {
//             setLoading(false);
//         }
//     }

//     useEffect(() => {
//         getInvenrotyData();
//     }, [])

//     const handleMenuOpen = (event, index) => {
//         setAnchorEl(event.currentTarget);
//         setCurrentRow(index);
//     };

//     const handleMenuClose = () => {
//         setAnchorEl(null);
//         setCurrentRow(null);
//     };

//     const handlePageChange = (event, value) => {
//         setCurrentPage(value);
//     };

//     const startEntry = (currentPage - 1) * itemsPerPage + 1;
//     const endEntry = Math.min(currentPage * itemsPerPage, totalData);

//     const handleSearch = async () => {
//         setLoading(true);
//         if (value.length > 0) {
//             const data = await get_data(`inventory/search-inventory-by-admin/${value}/${clientId}?pageNumber=${currentPage}`)
//             if (data.status) {
//                 setRowsData(data?.data?.inventories)
//                 setTotalData(data?.data?.totalInventory)
//                 setTotalPages(data?.data?.totalPages)
//             }
//         } else {
//             getInvenrotyData(currentPage);
//         }
//         setLoading(false);
//     }


//     return (
//         <>
//             <div>
//                 <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
//             </div>
//             <TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>

//                 <Table className={classes.root}>
//                     <TableHead>
//                         <TableRow>
//                             {
//                                 tableHeader?.map((item) => {
//                                     return (
//                                         <TableCell style={{ borderRight: '1px solid gainsboro', padding: '16px 10px' }}>{item}</TableCell>
//                                     )
//                                 })
//                             }
//                         </TableRow>
//                     </TableHead>
//                     <TableBody>
//                         {rowsData?.map((row, index) => (
//                             <TableRow key={index}>
//                                 <TableCell style={{ borderRight: '1px solid gainsboro', textAlign: 'center', width: '5%' }}>{startEntry + index}</TableCell>
//                                 <TableCell style={{ borderRight: '1px solid gainsboro', width: '15%' }}>{row?.gpsDetail?.vehicleNumber}</TableCell>
//                                 <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.gpsDetail?.simNumber}</TableCell>
//                                 <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.gpsDetail?.imei}</TableCell>
//                                 <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.gpsDetail?.deviceUniqueId}</TableCell>
//                                 <TableCell style={{ borderRight: '1px solid gainsboro' }}>{formatDate(row?.gpsDetail?.dateOfIntall === 'NaN-NaN-NaN' ? row?.gpsDetail?.dateOfIntall : '')}</TableCell>
//                                 <TableCell style={{ borderRight: '1px solid gainsboro' }}>{formatDate(row?.gpsDetail?.dateOfUnintall === 'NaN-NaN-NaN' ? row?.gpsDetail?.dateOfUnintall : '')}</TableCell>
//                                 <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.gpsDetail?.gpsStatus}</TableCell>
//                                 {/* <TableCell style={{ borderRight: '1px solid gainsboro' }}>
//                                     <IconButton
//                                         className={classes.moreIcon}
//                                         onClick={(event) => handleMenuOpen(event, index)}
//                                     >
//                                         <MoreVertIcon />
//                                     </IconButton>

//                                     <Menu
//                                         anchorEl={anchorEl}
//                                         open={Boolean(anchorEl) && currentRow === index}
//                                         onClose={handleMenuClose}
//                                     >
//                                         <MenuItem onClick={() => {
//                                             handleMenuClose()
//                                             setDetailModelOpen(true)
//                                             setDetailModelData(row)
//                                         }}>View Details</MenuItem>
//                                     </Menu>
//                                 </TableCell> */}
//                             </TableRow>
//                         ))}
//                     </TableBody>
//                 </Table>
//                 {rowsData?.length >= 0 && (
//                     <div
//                         style={{
//                             borderTop: "1px solid gainsboro",
//                             padding: "2%",
//                             display: "flex",
//                             justifyContent: "space-between",
//                         }}
//                     >
//                         <Typography
//                             style={{
//                                 fontWeight: 500,
//                                 fontSize: 15,
//                                 color: "black",
//                             }}
//                         >{`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}</Typography>
//                         <Pagination
//                             count={totalPages}
//                             page={currentPage}
//                             onChange={handlePageChange}
//                             color="primary"
//                         />
//                     </div>
//                 )}
//             </TableContainer>
//         </>
//     );
// };

// export default StockInventory;



import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Pagination,
    Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { get_data } from '../api';
import { formatDate, primaryColor } from '../constant';
import SearchComponent from './SearchComponent';
import VendorDetailModal from './VendorDetailModal';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
    },
    stageButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        '&.installed': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.uninstalled': {
            backgroundColor: '#ffebee',
            color: '#1e88e5',
        },
        '&.stock': {
            backgroundColor: '#ffebee',
            color: '#1e88e5',
        },
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});

const StockInventory = ({
    clientId,
    totalData,
    setTotalData
}) => {
    const tableHeader = [
        "SNo.", "Vehicle Number", "Imei Number", "Sim Number", "Device Unique Id", "Network", "Vendor", "Gps Install Date", 'Gps Uninstall Date', "Gps Status"
    ];

    const classes = useStyles();
    const [rowsData, setRowsData] = useState([]);
    // const [totalData, setTotalData] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(15);
    const [value, setValue] = useState('');
    const [vendorOpen, setVendorOpen] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);

    const getInventoryData = async () => {
        try {
            const data = await get_data(`inventory/get-stock-inventory/${clientId}?pageNumber=${currentPage}`);
            if (data.status === true) {
                setRowsData(data?.data?.inventory || []);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalInventory);
            } else {
                setRowsData([]);
                setTotalData(0);
                setTotalPages(0);
            }
        } catch (error) {
            setLoading(false);
            setRowsData([]);
            setTotalData(0);
            setTotalPages(0);
        }
    };

    useEffect(() => {
        getInventoryData();
    }, [clientId, currentPage]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);

    const handleSearch = async () => {
        setLoading(true);
        if (value.length > 0) {
            const data = await get_data(`inventory/search-inventory-by-admin/${value}/${clientId}/stock?pageNumber=${currentPage}`);
            if (data.status) {
                setRowsData(data?.data?.inventories || []);
                setTotalData(data?.data?.totalInventory);
                setTotalPages(data?.data?.totalPages);
            } else {
                setRowsData([]);
                setTotalData(0);
                setTotalPages(0);
            }
        } else {
            getInventoryData();
        }
        setLoading(false);
    };

    return (
        <>
            <VendorDetailModal open={vendorOpen} setOpen={setVendorOpen} data={currentRow} />
            <div>
                <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
            </div>
            <TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
                <Table className={classes.root}>
                    <TableHead>
                        <TableRow>
                            {tableHeader?.map((item, index) => (
                                <TableCell key={index} style={{ borderRight: '1px solid gainsboro', padding: '16px 10px' }}>
                                    {item}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowsData?.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={8} style={{ textAlign: 'center', padding: '20px' }}>
                                    <Typography variant="body1" color="textSecondary">
                                        No records found.
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            rowsData?.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ borderRight: '1px solid gainsboro', textAlign: 'center', width: '5%' }}>
                                        {startEntry + index}
                                    </TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro', width: '15%' }}>{row?.gpsDetail?.vehicleNumber || "-"}</TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.gpsDetail?.imei || "-"}</TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.gpsDetail?.simNumber || "-"}</TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.gpsDetail?.deviceUniqueId || "-"}</TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.gpsDetail?.network || "-"}</TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro', cursor: 'pointer' }}
                                        onClick={() => {
                                            setVendorOpen(true)
                                            setCurrentRow(row?.vendorId)
                                        }}>
                                        <span style={{ color: primaryColor, fontSize: 11, fontWeight: 500, textDecoration: 'underline' }}>View Detail</span>
                                    </TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                        {formatDate(row?.gpsDetail?.dateOfIntall) === 'NaN-NaN-NaN' ? '-' : formatDate(row?.gpsDetail?.dateOfIntall)}
                                    </TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                        {row?.gpsDetail?.dateOfUninstall && formatDate(row?.gpsDetail?.dateOfUninstall) || '-'}
                                    </TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                        <Button
                                            style={{ fontSize: 11, fontWeight: 500, borderRadius: 30 }}
                                            className={`${classes.stageButton} ${row?.gpsDetail?.gpsStatus?.toLowerCase()?.replace(' ', '')}`}
                                        >
                                            stock
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
                {rowsData?.length > 0 && (
                    <div style={{ borderTop: "1px solid gainsboro", padding: "2%", display: "flex", justifyContent: "space-between" }}>
                        <Typography style={{ fontWeight: 500, fontSize: 15, color: "black" }}>
                            {`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}
                        </Typography>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </div>
                )}
            </TableContainer>
        </>
    );
};

export default StockInventory;

