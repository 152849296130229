import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Menu,
    MenuItem,
    IconButton,
    Avatar,
    Typography,
    Pagination,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Swal from 'sweetalert2';
import { post_data, serverURL } from '../../api'; // Ensure serverURL is correctly imported
import { useNavigate } from 'react-router-dom';
import TableLoader from './TableLoader';
import EmptyPage from '../EmptyPage';
import { primaryColor } from '../../constant';
import { Toaster, toast } from 'react-hot-toast';
import ModelsModals from '../models/ModelsModals';
import AddInstallerModal from '../models/AddInstallerModal';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
    },
    stageButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        '&.closedOwn': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.analysis': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.qualification': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.closedLost': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
        '&.true': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});

const InstallerTable = ({ data, tableHeader, getAllInstallers, setLoading, totalData, loading, currentPage, setCurrentPage, itemsPerPage, totalPages, refresh, setRefresh }) => {

    const classes = useStyles();
    const navigate = useNavigate();
    const [rowsData, setRowsData] = useState(data || []);
    const [editModelOpen, setEditModelOpen] = useState(false);
    const [editModelData, setEditModelData] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);

    const handleCheckboxToggle = (index) => {
        const updatedRows = [...rowsData];
        updatedRows[index].checked = !updatedRows[index].checked;
        setRowsData(updatedRows);
    };

    // Open menu on click of more options icon
    const handleMenuOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(index);
    };

    // Close the options menu
    const handleMenuClose = (row) => {
        setAnchorEl(null);
        setCurrentRow(null);
    };

    const handleEdit = (row) => {
        setEditModelData(row)
        setEditModelOpen(true)

        setAnchorEl(null);
        setCurrentRow(null);
    };
    // Delete a product after confirmation
    const handleDelete = async (row) => {
        setAnchorEl(null);
        setCurrentRow(null);

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: primaryColor,
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = await post_data(`installer/delete-installer/${row?._id}`, {})
                    if (data.status) {
                        toast.success("Installer Deleted Successfully");
                        getAllInstallers();
                    }
                    else {
                        toast.error("Something Went Wrong");
                    }
                } catch (error) {
                }
            }
        });

    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);

    return (
        <>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
                <Table className={classes.root}>
                    <TableHead>
                        <TableRow>
                            {tableHeader?.map((item, idx) => (
                                <TableCell
                                    key={idx}
                                    style={{
                                        borderRight: '1px solid gainsboro',
                                        padding: '16px 10px',
                                        width: idx === 0 ? '5%' : '20%', // Adjust widths as needed
                                    }}
                                >
                                    {item}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {loading ? (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={tableHeader.length} style={{ textAlign: 'center' }}>
                                    <TableLoader />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ) : (
                        <TableBody>
                            {data?.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell
                                        style={{
                                            borderRight: '1px solid gainsboro',
                                            textAlign: 'center',
                                            padding: '0px',
                                            width: '5%',
                                        }}
                                    >
                                        {startEntry + index}
                                    </TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro', width: '30%' }}>
                                        {row?.name}
                                    </TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro', width: '30%' }}>
                                        {row?.phone}
                                    </TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro', width: '30%' }}>
                                        {`${row?.address?.street} ${row?.address?.city}  ${row?.address?.state} ${row?.address?.zipCode}`}
                                    </TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro', width: '10%' }}>
                                        <IconButton
                                            className={classes.moreIcon}
                                            onClick={(event) => handleMenuOpen(event, index)}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>

                                        {/* Options menu */}
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl) && currentRow === index}
                                            onClose={handleMenuClose}
                                        >
                                            <MenuItem onClick={() => handleEdit(row)}>Edit</MenuItem>
                                            <MenuItem onClick={() => handleDelete(row)}>Delete</MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>

                    )}
                </Table>
                <AddInstallerModal editModelOpen={editModelOpen} setEditModelOpen={setEditModelOpen} editModelData={editModelData} refresh={refresh} setRefresh={setRefresh} isUpdate={true} />
                {data?.length > 0 && (
                    <div
                        style={{
                            borderTop: "1px solid gainsboro",
                            padding: "2%",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            style={{
                                fontWeight: 500,
                                fontSize: 15,
                                color: "black",
                            }}
                        >{`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}</Typography>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </div>
                )}

            </TableContainer>
        </>
    );
};

export default InstallerTable;
