import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { State, City, Country } from 'country-state-city';
import { IoMdAdd } from "react-icons/io";
import { createButtonBg, primaryColor, primaryColorHover } from '../constant';
import { Autocomplete, Button, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Toaster, toast } from 'react-hot-toast';
import { get_data, post_data } from '../api';
import { useSelector } from 'react-redux';

export default function AddVendorModal({
    title,
    handleVendorSubmit,
    isUpdate,
    setVendorUpdateOpen,
    vendorUpdateOpen,
    updateData,
    setPasswordChangOpen,
    getAllVendors,
    currentPage
}) {

    const { user_data } = useSelector(state => state.user);
    const [excelData, setExcelData] = useState([]);
    const [fileName, setFileName] = useState('');
    const [addVendorModalOpen, setAddVendorModalOpen] = useState(false);
    const [vendorName, setVendorName] = useState(updateData?.name || '');
    const [street, setStreet] = useState(updateData?.address?.street || '');
    const [countryCode] = useState('IN');
    const [countryList, setCountryList] = useState([]);
    const [country, setCountry] = useState(updateData?.address?.country || '');
    const [stateList, setStateList] = useState([]);
    const [state, setState] = useState(updateData?.address?.state || '');
    const [cityList, setCityList] = useState([]);
    const [city, setCity] = useState(updateData?.address?.city || '');
    const [zipCode, setZipCode] = useState(updateData?.address?.zipCode || '');
    const [errors, setErrors] = useState({});
    const [phone, setPhone] = useState(updateData?.phone || '')
    const [email, setEmail] = useState(updateData?.email || '')
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    // const [companyId, setCompanyId] = useState(null);
    const [contactPerson, setContactPerson] = useState(updateData?.contactPerson || '');
    const [selectedCompanyIds, setSelectedCompanyIds] = useState([])
    const fetchAllCompany = async () => {
        const result = await get_data(`client/get-all-clients-for-options`);
        setCompanyList(result?.data || []);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        bgcolor: 'background.paper',
        borderRadius: '7px',
        border: '0px solid #fff',
        p: 4,
        overflow: 'auto',
        maxHeight: '70vh',
    };
    let vendorId = localStorage.getItem('vendorId')

    useEffect(() => {
        fetchAllCompany();
    }, []);

    useEffect(() => {
        setVendorName(updateData?.name || '')
        setPhone(updateData?.phone || '')
        setContactPerson(updateData?.contactPerson || '')
        setEmail(updateData?.email || '')
        setCountry(updateData?.address?.country || '')
        setStreet(updateData?.address?.street || '')
        setState(updateData?.address?.state || '')
        setCity(updateData?.address?.city || '')
        setZipCode(updateData?.address?.zipCode || '')
        setSelectedCompanyIds(updateData?.clientId?.map((item) => item?._id) || []);
    }, [updateData]);
    // console.log("updateData", companyId)
    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const validation = () => {

        let error = false;

        // if (!companyId && !user_data?.isClient) {
        //     error = true
        //     handleError('Please Select Company Name...', 'companyId');
        // }

        if (!vendorName) {
            error = true;
            handleError('Please input Vendor Name...', 'vendorName');
        }
        if (!street) {
            error = true;
            handleError('Please input Street...', 'street');
        }
        if (!state) {
            error = true;
            handleError('Please input State...', 'state');
        }
        if (!city) {
            error = true;
            handleError('Please input City...', 'city');
        }
        if (!zipCode) {
            error = true;
            handleError('Please input Zip Code...', 'zipCode');
        }
        if (!phone) {
            error = true;
            handleError('Please input Phone number...', 'phone');
        }
        if (!email) {
            error = true;
            handleError('Please input Email Id...', 'email');
        }
        if (!isUpdate) {
            if (!password) {
                error = true
                setPasswordError("Please Input Password")
            }
            if (!confirmPassword) {
                error = true
                setConfirmPasswordError("Please Input Confirm Password")
            }
            if (password != confirmPassword) {
                error = true
                setConfirmPasswordError("Please Input Correct Password")
            }
        }
        return error;
    };
    console.log("DATADATADATA", vendorName, phone, email, state, city, zipCode)
    const handleVendorModal = () => {
        isUpdate ? setVendorUpdateOpen(true) : setAddVendorModalOpen(true);
    };

    const handleClose = () => {
        isUpdate ? setVendorUpdateOpen(false) : setAddVendorModalOpen(false);
        setAddVendorModalOpen(false)

        if (title === 'vendor' ? setAddVendorModalOpen(false) : '')
            setErrors({});
    };

    useEffect(() => {
        setStateList(State.getStatesOfCountry(countryCode));
    }, [countryCode]);

    useEffect(() => {
        if (updateData?.address?.state) {
            setCityList(City.getCitiesOfState(countryCode, updateData?.address?.state));
        }
    }, [updateData?.address?.state, countryCode]);


    const handleSubmit = async () => {
        const error = validation();
        if (!error) {
            let data
            if (isUpdate) {
                if (title === 'ClientVendors') {
                    if (user_data?.isSubClient) {
                        handleVendorSubmit({ name: vendorName, contactPerson, address: { country, street, state, city, zipCode }, phone, email, clientId: [user_data?.clientId], subClientId: [user_data?._id], raisedBy: "subClient", }, isUpdate)
                        handleClose();
                    } else {
                        handleVendorSubmit({ name: vendorName, address: { country, street, state, city, zipCode }, phone, email, contactPerson, clientId: [user_data?._id], approved: true, password: password, }, isUpdate)
                        handleClose();
                    }

                } else if (title === 'AdminVendors') {
                    handleVendorSubmit({
                        clientId: selectedCompanyIds, name: vendorName, contactPerson, phone, email, password, address: { street, state, city, zipCode },
                    }, isUpdate)

                    setVendorName(''); setSelectedCompanyIds([]); setContactPerson(''); setStreet(''); setState(''); setCity(''); setZipCode(''); setPhone(''); setEmail(''); setPassword(''); setConfirmPassword(''); handleClose();
                }
            }
            else if (title === 'AdminVendors' && isUpdate === false) {
                data = await post_data(`vendor/create-vendor`, { clientId: selectedCompanyIds, name: vendorName, contactPerson, phone, email, password, address: { country, street, state, city, zipCode }, isVendor: false, isSubVendor: true })
                if (data.status === true) {
                    setVendorName('')
                    setSelectedCompanyIds([])
                    setContactPerson('')
                    setStreet('')
                    setState('')
                    setCity('')
                    setZipCode('')
                    setPhone('')
                    setEmail('')
                    getAllVendors(currentPage)
                    handleClose();
                }

            }
            else if (title === 'ClientVendors') {

                if (user_data?.isSubClient) {
                    handleVendorSubmit({ name: vendorName, contactPerson, address: { country, street, state, city, zipCode }, phone, email, clientId: [user_data?._id], subClientId: [user_data?._id], password: password, raisedBy: "subClient" }, isUpdate)
                } else {
                    handleVendorSubmit({ name: vendorName, address: { country, street, state, city, zipCode }, phone, email, contactPerson, clientId: [user_data?._id], approved: true, password: password, }, isUpdate)
                }
                setVendorName('')
                setStreet('')
                setState('')
                setCity('')
                setZipCode('')
                setPhone('')
                setEmail('')
                setPassword('')
                setConfirmPassword('')
                handleClose();

            }

        }
    };

    useEffect(function () {
        setCountryList(Country.getAllCountries())
    }, [])

    const handleCountryChange = (event, newValue) => {
        if (newValue) {
            const countryCode = newValue.isoCode;
            const fetchedStates = State.getStatesOfCountry(countryCode);
            setStateList(fetchedStates);
            setCityList([]);
            setCountry(countryCode)
            setState('')
            setCity('')
        }
    };

    const handleStateChange = (event, newValue) => {
        if (newValue) {
            setState(newValue.isoCode);
            const fetchedCities = City.getCitiesOfState(countryCode, newValue.isoCode || state);
            setCityList(fetchedCities);
            setCity(''); // Reset city when state changes
        }
    };

    const handleCityChange = (event, newValue) => {
        if (newValue) {
            setCity(newValue.name);
        }
    };
    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
            maxHeight: '70vh',
        },
        rightSide: {
            backgroundColor: '#fff',
            width: '100%'
        },
        loginHeading: {
            fontSize: '1.5rem',
            color: primaryColor,
            marginBottom: '20px',
            fontWeight: 500,
            textAlign: 'center'
        },
        loginText: {
            textAlign: 'center',
            fontSize: '1rem',
            marginTop: 0,
            color: '#2a2a2a',
            marginBottom: '20px',
        },
        input: {
            marginBottom: '10px',
        },
        signInButton: {
            padding: '9px 20px',
            backgroundColor: primaryColor,
            color: '#fff',
            borderRadius: 0,
            width: '100%',
            marginTop: '4%',
            boxShadow: 'none',
            fontSize: '1rem',
            fontWeight: 400,
            cursor: 'pointer',
        },
    };

    return (
        <div >
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            {title === 'ClientVendors' && !isUpdate ? <>
                {<Button
                    startIcon={<IoMdAdd style={{ color: 'white' }} />}
                    variant="contained"
                    onClick={handleVendorModal}
                    style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                >
                    Create
                </Button>}
            </> : <>{title === 'AdminVendors' && !isUpdate ? (
                <Button
                    startIcon={<IoMdAdd style={{ color: 'white' }} />}
                    variant="contained"
                    onClick={handleVendorModal}
                    style={{ borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                >
                    Create
                </Button>
            ) : (isUpdate ?
                <div onClick={handleVendorModal}></div> :
                <Typography color={primaryColor} onClick={handleVendorModal} style={{ cursor: 'pointer', fontSize: 14, marginLeft: 10 }}>
                    Add Sub Vendor
                </Typography>
            )}</>}

            <Modal open={isUpdate ? vendorUpdateOpen : addVendorModalOpen} onClose={handleClose} style={{ height: '100vh' }} >
                <Box sx={style}>
                    <Typography variant="h6" component="h2">
                        {isUpdate ? "Update Vendor" : "Add Vendor"}
                    </Typography>
                    <Grid container spacing={2} style={{ marginTop: 10 }}>
                            { title === 'AdminVendors' ? <Grid item xs={12}>
                                 <Autocomplete
                                    fullWidth
                                    multiple // Enable multiple selections
                                    options={companyList}
                                    value={companyList?.filter((company) => selectedCompanyIds.includes(company._id)) || []} // Filter selected companies
                                    onChange={(event, newValue) => setSelectedCompanyIds(newValue.map((company) => company._id))} // Update the selected IDs array
                                    autoHighlight
                                    getOptionLabel={(option) => option.companyName || ''}
                                    renderInput={(params) => <TextField {...params} label="Select Companies" fullWidth />}
                                />
                            </Grid> : ''}

                        <Grid item xs={12}><TextField error={Boolean(errors.vendorName)} helperText={errors.vendorName} onFocus={() => handleError('', 'vendorName')} value={vendorName || ''} label="Vendor Name" onChange={(e) => setVendorName(e.target.value)} fullWidth />  </Grid>
                        <Grid item xs={12}><TextField error={Boolean(errors.contactPerson)} helperText={errors.contactPerson} onFocus={() => handleError('', 'contactPerson')} value={contactPerson || ''} label="Contact Person" onChange={(e) => setContactPerson(e.target.value)} fullWidth /></Grid>
                        <Grid item xs={12}><TextField error={Boolean(errors.phone)} helperText={errors.phone} onFocus={() => handleError('', 'phone')} value={phone || ''} label="Phone Number" onChange={(e) => {
                            const newValue = e.target.value; if (/^\d*$/.test(newValue)) {
                                const newValue = e.target.value.replace(/\D/g, '').slice(0, 10); setPhone(newValue); setErrors((prev) => ({ ...prev, phone: '' }));
                            } else {
                                setErrors((prev) => ({ ...prev, phone: 'Only numbers are allowed' }));
                            }
                        }}
                            fullWidth />
                        </Grid>
                        <Grid item xs={12}> <TextField error={Boolean(errors.email)} helperText={errors.email} onFocus={() => handleError('', 'email')} value={email || ''} label="Email Id" onChange={(e) => setEmail(e.target.value)} fullWidth /></Grid>
                        <Grid item xs={12}>
                            <Typography component="h1">Address</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="country-select"
                                options={countryList}
                                value={countryList.find((s) => s.isoCode === country) || null}
                                onChange={handleCountryChange}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField
                                    error={Boolean(errors.country)}
                                    helperText={errors.country}
                                    onFocus={() => handleError('', 'country')}
                                    {...params} label="Select Country" fullWidth />}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                id="state-select"
                                options={stateList}
                                value={stateList.find((s) => s.isoCode === state) || null}
                                onChange={handleStateChange}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField
                                    error={Boolean(errors.state)}
                                    helperText={errors.state}
                                    onFocus={() => handleError('', 'state')}
                                    {...params} label="Select State" fullWidth />}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                id="city-select"
                                options={cityList}
                                value={cityList.find((c) => c.name === city) || null}
                                onChange={handleCityChange}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField
                                    error={Boolean(errors.city)}
                                    helperText={errors.city}
                                    onFocus={() => handleError('', 'city')}
                                    {...params} label="Select City" fullWidth />}
                            />
                        </Grid>
                        <Grid item xs={12}><TextField label="Street" error={Boolean(errors.street)} helperText={errors.street} onFocus={() => handleError('', 'street')} value={street || ''} onChange={(e) => setStreet(e.target.value)} fullWidth /></Grid>
                        <Grid item xs={12}><TextField label="Zip Code" value={zipCode || ''} error={Boolean(errors.zipCode)} helperText={errors.zipCode}
                            onFocus={() => handleError('', 'zipCode')}
                            onChange={(e) => {
                                const newValue = e.target.value; if (/^\d*$/.test(newValue)) {
                                    const newValue = e.target.value.replace(/\D/g, '').slice(0, 10)
                                    setZipCode(newValue)
                                    setErrors((prev) => ({ ...prev, zipCode: '' }));
                                } else {
                                    setErrors((prev) => ({ ...prev, zipCode: 'Only numbers are allowed' }));
                                }
                            }}
                            fullWidth />
                        </Grid>
                        {isUpdate ? <Grid item xs={12}>
                            <Typography id="modal-title" variant="h6" onClick={() => (
                                localStorage.setItem('vendorId', updateData?._id),
                                setPasswordChangOpen(true),
                                handleClose()
                            )} style={{ fontSize: '14px', color: primaryColor, cursor: 'pointer', marginBottom: '10px' }}>
                                Change Password
                            </Typography>
                        </Grid> : <></>}

                        {isUpdate === false ? <> <Grid item xs={12}>
                            <TextField
                                label="Password"
                                variant="outlined"
                                type={showPassword ? "text" : "password"}
                                fullWidth
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setPasswordError("");
                                }}
                                error={Boolean(errors.password)}
                                helperText={errors.password}
                                onFocus={() => handleError('', 'password')}
                                style={{ ...styles.input, marginTop: '3%' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Confirm Password"
                                    variant="outlined"
                                    type={showConfirmPassword ? "text" : "password"}
                                    fullWidth
                                    value={confirmPassword}
                                    error={Boolean(errors.confirmPassword)}
                                    helperText={errors.confirmPassword}
                                    onFocus={() => handleError('', 'confirmPassword')}
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value);
                                        setConfirmPasswordError("");
                                    }}
                                    style={styles.input}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid> </> : <></>}


                        <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
                            <Button variant="outlined" style={{ textTransform: 'capitalize', color: primaryColor, border: `1px solid ${primaryColor}`, borderRadius: 0 }} fullWidth onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button style={{ textTransform: 'capitalize', color: '#fff', background: primaryColor, borderRadius: 0 }} onClick={handleSubmit} fullWidth>
                                {isUpdate ? "Update" : 'Add'}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
