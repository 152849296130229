import ContactForm from "../../components/ContactForm";
import Footer from "../../components/Footer";
import GoogleMapCard from "../../components/GoogleMapCard";
import Header from "../../components/Header";

export default function Contact() {
    return (
        <>
            <Header />
            <ContactForm />
            <GoogleMapCard />
            <Footer />
        </>
    )
}