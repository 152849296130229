import { useState } from 'react';
import { TextField, Button, Collapse, Alert, useMediaQuery, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { post_data } from '../../../api';
import { primaryColor } from '../../../constant';
import { Toaster, toast } from 'react-hot-toast';
import { login } from "../../../redux/slices/user-slice";
import { useNavigate } from 'react-router-dom';


function AdminLogin() {
    const navigate = useNavigate()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState('');
    const [loader, setLoader] = useState(false);
    const [success, setSuccess] = useState(false);
    const isDesktop = useMediaQuery('(min-width: 768px)');

    const containerStyle = {
        width: '100vw',
        height: '100vh',
    };

    const formSectionStyle = {
        width: isDesktop ? '30%' : '90%',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '40px',
        boxSizing: 'border-box',
    };

    const imageSectionStyle = {
        flex: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#f0f0f0',
    };

    const titleStyle = {
        marginBottom: '1px',
        fontSize: '24px',
        fontWeight: '600',
        textAlign: 'center',
    };

    const subtitleStyle = {
        marginBottom: '20px',
        fontSize: '14px',
        color: '#666',
        textAlign: 'center',
    };

    const checkboxContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '20px',
    };

    const linkStyle = {
        fontSize: '14px',
        color: '#6c63ff',
        cursor: 'pointer',
        textDecoration: 'none',
    };

    const bottomLinksStyle = {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
    };

    const loginBox = {
        display: 'flex',
        width: '100%',
        height: '100%',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
    };

    const dispatch = useDispatch();

    const handleError = (label, value) => {
        setErrors((prev) => ({ ...prev, [label]: value }));
    };

    const validate = () => {
        let error = false;
        if (email.length === 0) {
            error = true;
            handleError('email', 'Email is Required');
        }
        if (password.length === 0) {
            error = true;
            handleError('password', 'Password is Required');
        }
        return error;
    };

    const handleSubmit = async () => {
        const error = validate();
        if (!error) {
            try {
                setLoader(true);
                const data = await post_data("super-admin/super-admin-login", { email, password });
                if (data?.status === true) {
                    dispatch(login(data?.data?.user));
                    localStorage.setItem("authToken", data?.data?.token);
                    toast.success('Login Successfully', {
                        duration: 4000,
                        position: 'top-center',
                        // style: {
                        //     background: '#333',
                        //     color: '#fff',
                        // },
                    });
                    setEmail('');
                    setPassword('');
                    setLoader(false);
                    navigate('/admin/dashboard')
                } else {
                    if (data.response.data.message === "Super Admin does not exist") {
                        setLoader(false);
                        // toast.error("Super Admin does not exist")
                        toast.error(data.response.data.message, {
                            duration: 4000,
                            position: 'top-center',
                            // style: {
                            //     background: '#333',
                            //     color: '#fff',
                            // },
                        });
                    }
                    else {
                        if (data.response.data.message === "Sub Admin does not exist") {
                            setLoader(false);
                           
                            toast.error(data.response.data.message, {
                                duration: 4000,
                                position: 'top-center',
                                // style: {
                                //     background: '#333',
                                //     color: '#fff',
                                // },
                            });
                        } else if (data.response.data.message === 'Password Incorrect') {
                            setLoader(false);
                            toast.error(data.response.data.message, {
                                duration: 4000,
                                position: 'top-center',
                                // style: {
                                //     background: '#333',
                                //     color: '#fff',
                                // },
                            });
                        }
                    }
                }
            } catch (error) {
                setLoader(false);
            }
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div style={containerStyle}>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <div style={loginBox}>
                <div style={formSectionStyle}>
                    <h2 style={titleStyle}>Welcome Admin!</h2>
                    <p style={subtitleStyle}>Please enter your email and password to Login to your Dashboard</p>
                    <TextField
                        type="email"
                        value={email}
                        label="Email Address"
                        className={errors.email ? "errorborder" : ""}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        onFocus={() => {
                            setErrors({ ...errors, email: '' });
                        }}
                    />
                    <small style={{ marginBottom: '20px', color: 'red' }}>{errors.email}</small>

                    <TextField
                        type={showPassword ? "text" : "password"}
                        value={password}
                        label="Password"
                        className={errors.password ? "errorborder" : ""}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                        onFocus={() => {
                            setErrors({ ...errors, password: '' });
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={togglePasswordVisibility}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <small style={{ marginBottom: '10px', color: 'red' }}>{errors.password}</small>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', }}>
                        <p onClick={() => navigate('/admin/forget-password')} style={{ fontSize: '0.875rem', cursor: 'pointer', color: primaryColor, textDecoration: 'none' }}>
                            Forget Password?
                        </p>
                    </div>
                    <Button
                        variant="contained"
                        style={{
                            boxShadow: 'none',
                            padding: '3% 0',
                            borderRadius: 0,
                            background: primaryColor,
                            '&:hover': { backgroundColor: primaryColor },
                        }}
                        disabled={loader}
                        onClick={handleSubmit}
                    >
                        Login
                    </Button>

                    <div style={{ marginTop: 10 }}>
                        <Collapse in={success}>
                            <Alert severity="success" onClose={() => setSuccess(false)}>
                                Reset password link sent successfully.
                            </Alert>
                        </Collapse>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminLogin;
