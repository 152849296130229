
import { Button, Grid } from "@mui/material";
import { createButtonBg } from "../../../constant";
import { IoMdAdd } from "react-icons/io";

import InvoicesTable from "../../../components/tables/InvoicesTable";

export default function Invoices() {

    const page = { width: '100%', marginBottom: '2%' };

    const data = [
        {
            dealName: 'Car Purchase - Honda Civic',
            amount: '$20,000',
            stage: 'Closed Own',
            closingDate: '2024-09-15',
            contactName: 'John Doe',
            owner: {
                name: 'Jane Smith',
                img: 'https://randomuser.me/api/portraits/men/1.jpg'
            },
            checked: false
        },
        {
            dealName: 'Bike Lease - Yamaha FZ',
            amount: '$1,500',
            stage: 'Qualification',
            closingDate: '2024-09-30',
            contactName: 'Emily White',
            owner: {
                name: 'Michael Brown',
                img: 'https://randomuser.me/api/portraits/men/1.jpg'
            },
            checked: false
        },
        {
            dealName: 'Truck Sale - Ford F-150',
            amount: '$35,000',
            stage: 'Analysis',
            closingDate: '2024-10-05',
            contactName: 'Samuel Green',
            owner: {
                name: 'Sophia Lee',
                img: 'https://randomuser.me/api/portraits/men/1.jpg'
            },
            checked: false
        },
        {
            dealName: 'Bike Purchase - Royal Enfield',
            amount: '$2,800',
            stage: 'Closed Lost',
            closingDate: '2024-09-20',
            contactName: 'Alice Black',
            owner: {
                name: 'John Johnson',
                img: 'https://randomuser.me/api/portraits/men/1.jpg'
            },
            checked: false
        },
        {
            dealName: 'Car Lease - Tesla Model 3',
            amount: '$25,000',
            stage: 'Qualification',
            closingDate: '2024-10-12',
            contactName: 'David Grey',
            owner: {
                name: 'Sara Wilson',
                img: 'https://randomuser.me/api/portraits/men/1.jpg'
            },
            checked: false
        }
    ];

    const tableHeader = [
        "S No.", "Deal Name", "Amount", "Stage", " Date", "Contact", "Owner"
    ]

    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <div style={{ marginLeft: 'auto', gap: 10, display: 'flex', justifyContent: 'right', marginBottom: '1%' }}>
                    {/* <AddRequestsModal title={'Requests'} /> */}
                </div>

                <InvoicesTable data={data} tableHeader={tableHeader} />
            </Grid>
        </>
    )
}