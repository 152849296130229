import { TextField, Grid, Switch, FormControlLabel, Button } from '@mui/material';
import { primaryColor } from '../constant';
import { get_data, post_data } from '../api';
import RequestsDetailsTable from './RequestDetailsTable';
import { useState } from 'react';
import RequestDetails from './RequestDetails';

export default function RecordForm() {

    const [requestId, setRequestId] = useState('');
    const [requestData, setRequestData] = useState('');
    const [loader, setLoader] = useState(false);
    const handleSubmit = (event) => {
        event.preventDefault();
        // handle form submit logic
    };

    const button = {
        borderColor: primaryColor,
        color: 'white',
        background: primaryColor,
        textTransform: 'capitalize',
        borderRadius: 0,
        fontWeight: 400,
        padding: '1% 3.5%',
        boxShadow: 'none',
        '&:hover': {
            borderColor: primaryColor,
            boxShadow: 'none',
        },
        '&:focus': {
            outline: 'none',
            boxShadow: 'none',
            borderColor: primaryColor,
        }
    }

    const fetchRequestData = async () => {
        setLoader(true)
        const result = await get_data(`request/search-record-by-vehicle-number/${requestId}`);

        setLoader(false)
        if (result?.status) {
            setRequestData(result?.data)
        }
    }

    return (
        <>
            <Grid container spacing={2} style={{ padding: '3% 7%' }}>
                <Grid item md={6}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h2>Record</h2>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="Vehicle no."
                                    name="request_id"
                                    fullWidth
                                    type="text"
                                    onChange={(e) => setRequestId(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} style={{ display: 'flex', alignItems: 'center', }}>
                                <Button onClick={() => { fetchRequestData() }}
                                    disabled={loader}
                                    variant='outlined'
                                    style={{ color: primaryColor, borderColor: primaryColor, fontSize: 12, textTransform: 'capitalize' }}>
                                    {loader ? 'Loading...' : "Get Record"}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
                <Grid item md={6}>
                    {requestData && <RequestDetails requestData={requestData} />}
                </Grid>
            </Grid>
        </>
    )
}