import { Button, Grid } from "@mui/material";
import ActivitiesTabl from "../../../components/tables/ActivitiesTable";
import AddActivitiesModal from "../../../components/AddActivitiesModal";
import SearchComponent from "../../../components/SearchComponent";
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../../api";
import moment from "moment";

export default function Activities() {
    const page = { width: '100%', marginBottom: '2%' };

    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [value, setValue] = useState('')
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [open, setOpen] = useState(false)
    const [fromDate, setFromDate] = useState([])
    const [toDate, setToDate] = useState([])
    const [claimsData, setClaimsData] = useState([])

    const itemsPerPage = 15;
    const count = data?.length;

    const getActivitiesData = async () => {
        try {
            const data = await get_data(`activity/get-all-activities-with-pagination?pageNumber=${currentPage}`);
            if (data.status) {
                setLoading(false);
                setData(data?.data?.activities);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalActivities);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getActivitiesData(currentPage);
        }
    }, [currentPage])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }, []);

    const handleSearch = async () => {
        // setLoading(true);
        // if (value.length > 0) {
        //     const data = await get_data(`claim/search-claim/${value}?pageNumber=${currentPage}`)
        //     if (data.status) {
        //         setData(data?.data?.claims)
        //         setTotalData(data?.data?.totalClaims)
        //         setTotalPages(data?.data?.totalPages)
        //     }
        // } else {
        //     getClaimData(currentPage);
        // }
        // setLoading(false);
    }


    // const handleDateChange = (dates) => {
    //     if (dates && dates?.length === 2) {
    //         setDateRange(dates);
    //     }
    // };

    // const fetchExportData = async () => {
    //     try {

    //         const fromDate = moment(startDate).toISOString();
    //         const toDate = moment(endDate).toISOString();

    //         let response = await post_data('claim/get-claims-by-date', {
    //             from: fromDate,
    //             to: toDate
    //         })

    //         if (response?.status) {
    //             setClaimsData(response?.data?.claim)
    //             setOpen(true)
    //             setFromDate(response?.data?.fromDate)
    //             setToDate(response?.data?.toDate)
    //         } else {

    //         }
    //     } catch (error) {
    //     }
    // };


    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>

                <div style={{ display: 'flex', marginBottom: '1%', width: '100%' }}>
                    {/* <div>
                        <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                    </div> */}
                    {/* <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        <AddActivitiesModal title={'AddActivitiesModal'} />
                    </div> */}
                </div>

                <ActivitiesTabl
                    data={data}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    count={count}
                    totalData={totalData}
                    totalPages={totalPages}
                    loading={loading}
                    setLoading={setLoading}
                    getActivitiesData={getActivitiesData}

                />
            </Grid>
        </>
    )
}