import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IconButton, useMediaQuery } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ProductCard from "./ProductCard";
import { post_data, get_data } from '../api';
import ProductCardSkeleton from "./skeleton/ProductCardSkeleton";

const ProductsGrid = () => {

    const theme = useTheme();
    const matches1 = useMediaQuery(theme.breakpoints.down(1100))
    const matches2 = useMediaQuery(theme.breakpoints.down(800));
    const matches3 = useMediaQuery(theme.breakpoints.down(500));
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])

    const styles = {
        arrow: {
            // backgroundColor: "#ccc",
            borderRadius: "50%",
            // padding: matches3 ? "20px" : "10px",
            cursor: "pointer",
            zIndex: 1,
            // display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center',

        },
    }

    const NextArrow = (props) => {
        const { onClick } = props;
        return (
            <IconButton
                onClick={onClick}
                style={{ ...styles.arrow, right: matches3 ? "-40px" : "-50px", position: "absolute", top: '45%' }}
            >
                <ArrowForwardIosIcon style={{ fontSize: '2.2rem' }} />
            </IconButton>
        );
    };

    const PrevArrow = (props) => {
        const { onClick } = props;
        return (
            <IconButton
                onClick={onClick}
                style={{ ...styles.arrow, left: matches3 ? "-30px" : "-50px", position: "absolute", top: '45%' }}
            >
                <ArrowBackIosIcon style={{ fontSize: '2.2rem' }} />
            </IconButton>
        );
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 1200,
        autoplay: true,
        slidesToShow: matches3 ? 1 : matches2 ? 1 : matches1 ? 3 : 5,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        arrows: true,
    };


    const slide = {
        padding: '0 7px',
        textAlign: 'left',
        // display: 'flex',
        // width: '80%',
        // background: 'red',
        // justifyContent: 'center', alignItems: 'center'

    }

    const getAllProducts = async () => {
        try {
            const data = await get_data(`product/get-all-products-with-pagination?pageNumber=${1}`);
            console.log('XproductX', data)
            if (data.status === true) {
                setLoading(false);
                setData(data?.data?.products);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        getAllProducts()
    }, [])


    const showSlider = () => {
        return data?.map((item, index) => {
            return (<div key={index} >
                <div style={slide}>
                    <ProductCard item={item} />
                </div>
            </div>)
        })
    }

    return (
        <>
            <div style={{ padding: '1% 5% 5%', marginTop: '3%', width: matches3 ? '78%' : '' }}>
                {
                    loading ?
                        <>
                            <ProductCardSkeleton />
                        </>
                        :
                        <>
                            <h2 style={{ fontWeight: 500, fontSize: matches3 ? 22 : '', marginBottom: matches_md ? '5%' : '2%' }}>
                                Powerfull connections GPS
                            </h2>
                            <Slider {...settings}>
                                {showSlider()}
                            </Slider>
                        </>
                }
            </div>
        </>
    )
}

export default ProductsGrid;