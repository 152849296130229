import { Autocomplete, Box, Button, Dialog, DialogContent, DialogContentText, Divider, FormControlLabel, FormGroup, Grid, List, ListItem, Modal, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useLocation } from "react-router-dom";
import { get_data, post_data } from "../api";

import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { City, State } from "country-state-city";
import { createButtonBg, primaryColor } from "../constant";
import Swal from "sweetalert2";


export default function ClientVehicleDetails() {
    const { isLoggedIn, user_data } = useSelector(state => state.user);
    const location = useLocation();
    const requests = location?.state?.row
    const [toggleDetailStatus, setToggleDetailStatus] = useState([])
    const [reason, setReason] = useState('');
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([[]]);
    const [countryCode] = useState('IN');
    const [errors, setErrors] = useState({});
    const [deviceModelList, setDeviceModelList] = useState([]);
    const [driverCityList, setDriverCityList] = useState([[]]);
    const [modelList, setModelList] = useState([]);
    const [index, setIndex] = useState(0);
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [reasonList, setReasonList] = useState([]);
    const [sno, setSno] = useState(0);

    const [formData, setFormData] = useState({
        detail: requests?.detail?.map((detailData) => ({
            driverDetails: {
                fullName: detailData?.driverDetails?.fullName || '',
                pan: detailData?.driverDetails?.pan || '',
                aadhar: detailData?.driverDetails?.aadhar || '',
                badgeNo: detailData?.driverDetails?.badgeNo || '',
                shift: detailData?.driverDetails?.shift || '',
                contactNo: detailData?.driverDetails?.contactNo || '',
                resContactNo: detailData?.driverDetails?.resContactNo || '',
                address: {
                    street: detailData?.driverDetails?.address?.street || '',
                    zipCode: detailData?.driverDetails?.address?.zipCode || '',
                    landmark: detailData?.driverDetails?.address?.landmark || '',
                    city: detailData?.driverDetails?.address?.city || '',
                    state: detailData?.driverDetails?.address?.state || ''
                },
                fatherName: detailData?.driverDetails?.fatherName || '',
            },
            vehicleDetails: {
                // brand: detailData?.vehicleDetails?.brand || '',
                // model: detailData?.vehicleDetails?.model || '',
                regnNumber: detailData?.vehicleDetails?.regnNumber || '',
                plate: detailData?.vehicleDetails?.plate || '',
                regnOwnerName: detailData?.vehicleDetails?.regnOwnerName || '',
                typeOfVehicle: detailData?.vehicleDetails?.typeOfVehicle || '',
                vehicleClass: detailData?.vehicleDetails?.vehicleClass || '',
            },
        }))
        // || [{
        //     deviceDetails: {
        //         network: { airtel: false, vi: false, bsnl: false, jio: false },
        //         otherDetails: { new: false, rental: false, renew: false, repair: false }
        //     },
        //     driverDetails: { contactNo: '', resContactNo: '', address: { street: '', zipCode: '', landmark:'', state:'', city: '' }, fatherName: '' },
        //     vehicleDetails: { brand: '', model: '', regnNumber: '', plate: '', regnOwnerName: '', typeOfVehicle: '', vehicleClass: '' },
        // }],

    });

    const handleClose = () => {
        setReason('');
        setOpen(false);
    }
    console.log("****************", requests)
    useEffect(function () {
        setToggleDetailStatus((prev) => {
            let updatedDetailStatus = requests?.detail?.map((item, idx) => {
                if (!item?.reason) {
                    return [...prev, false]
                }
                return [...prev, item?.detailStatus]
            })
            return updatedDetailStatus
        })

    }, [requests])

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    // const handleChange = async (value, index) => {
    //     if (value === "Not Installed") {
    //         setOpen(true);
    //         setSno(index);
    //         return;
    //     }

    //     let result = await post_data(`request/change-gps-status/${requests?._id}`,
    //         {
    //             index: index,
    //             detailStatus: value === "Installed" ? "Installed" : "Not Installed",
    //             reason: reason
    //         });

    //     if (result?.status == true) {
    //         toast.success(result?.message)

    //         setToggleDetailStatus((prev) => {
    //             let updatedDetailStatus = prev?.map((item, idx) => {
    //                 if (index == idx) {
    //                     return [
    //                         value === "Installed" ? "Installed" : "Not Installed"
    //                     ]
    //                 }
    //                 return item
    //             })
    //             return updatedDetailStatus
    //         })

    //         handleClose()

    //     } else {
    //         toast.error(result?.message)
    //     }
    // }

    useEffect(() => {
        setStateList(State.getStatesOfCountry(countryCode));
    }, [countryCode]);

    const fetchCityList = async () => {

    }

    useEffect(() => {

        if (requests.detail) {
            requests?.detail?.map((item, index) => {
                if (item?.driverDetails?.address?.state) {
                    let fetchedCities = City.getCitiesOfState(countryCode, item?.driverDetails?.address?.state)
                    setDriverCityList((prev) => {
                        let oldData = [...prev]
                        oldData[index] = fetchedCities
                        return oldData
                    })
                }
            })

        }
    }, [])


    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };
    // Fetch device model list
    const fetchdeviceModelList = async () => {
        try {
            const data = await get_data(`product/get-all-products-for-options`);
            setDeviceModelList(data?.data || []);
        } catch (error) {
        }
    };

    // Fetch IMEI based on selected device model
    const fetchIMEINo = (deviceModel) => {
        const device = deviceModelList.find((d) => d._id === deviceModel);
        return device ? device.imeiNo : '';
    };

    useEffect(() => {
        fetchdeviceModelList();
        // fetchVendorsList();
        // fetchBrands();
    }, []);
    ;

    const handleDriverInputChange = (index, field, value) => {
        setFormData((prevState) => {
            const updatedDetail = prevState.detail.map((form, idx) => {
                if (index === idx) {
                    if (['street', 'landmark', 'city', 'state', 'zipCode'].includes(field)) {
                        // Update address inside driverDetails
                        return {
                            ...form, driverDetails: { ...form.driverDetails, address: { ...form.driverDetails.address, [field]: value, } },
                        };
                    } else {

                        return {
                            ...form, driverDetails: { ...form.driverDetails, [field]: value }
                        };
                    }
                }
                return form; // Return unchanged form for other indices
            });
            return { ...prevState, detail: updatedDetail, };
        });
    };

    // console.log('requests', requests);
    // console.log('formdata,', formData);

    const handleDriverStateChange = (index, event, newValue) => {
        if (newValue) {
            const stateCode = newValue.isoCode;
            const countryCode = 'IN'; // Replace with actual country code

            // Fetch cities based on selected state
            const fetchedCities = City.getCitiesOfState(countryCode, stateCode);
            setDriverCityList(fetchedCities); // Update city list based on state
            setDriverCityList((prev) => {
                let oldData = [...prev]
                oldData[index] = fetchedCities
                return oldData
            })

            setFormData(prevState => {
                const updatedDetail = prevState.detail.map((form, idx) => {
                    if (index === idx) {
                        return {
                            ...form,
                            driverDetails: {
                                ...form.driverDetails,
                                // driverState: stateCode, // Update the state
                                // driverCity: '', // Reset city when state changes
                                address: {
                                    ...form?.driverDetails?.address,
                                    state: stateCode,
                                    city: ''
                                }
                            },
                        };
                    }
                    return form; // Return unchanged form for other indices
                });

                return {
                    ...prevState,
                    detail: updatedDetail,
                };
            });
        }
    };

    // Handle city change
    const handleDriverCityChange = (index, event, newValue) => {
        if (newValue) {
            setFormData(prevState => {
                const updatedDetail = prevState.detail.map((form, idx) => {
                    if (index === idx) {
                        return {
                            ...form,
                            driverDetails: {
                                ...form.driverDetails,
                                // driverCity: newValue.name, // Update the driverCity correctly
                                address: {
                                    ...form?.driverDetails?.address,
                                    city: newValue?.name
                                }
                            },
                        };
                    }
                    return form; // Return unchanged form for other indices
                });

                return {
                    ...prevState,
                    detail: updatedDetail,
                };
            });
        }
    };

    const handleVehicleInputChange = (index, field, value) => {
        setFormData((prevState) => {
            const updatedDetail = prevState.detail.map((form, idx) => {
                if (index === idx) {
                    // Update the vehicleDetails field dynamically
                    return {
                        ...form,
                        vehicleDetails: {
                            ...form.vehicleDetails,
                            [field]: value, // Dynamically update the vehicle field
                        },
                    };
                }
                return form; // Return unchanged form for other indices
            });

            return {
                ...prevState,
                detail: updatedDetail, // Update the  array in formData
            };
        });

        if (field == 'brand') {
            getAllModels(index, value)
        }
    };

    const getAllModels = async (index, value) => {
        let result = await get_data(`model/get-all-model-by-brand/${value}`)
        if (result?.status == true) {

            setModelList((prev) => {
                let updatedModelList = formData?.detail?.map((item, idx) => {
                    if (index == idx) {
                        return result?.data
                    }
                    return modelList?.[idx]
                })
                return updatedModelList
            })
        } else {
            toast.error(result?.message)
        }
    }
    const handleEdit = async (event) => {
        event.preventDefault();
        setLoader(true)

        const respons = await post_data(`vehicles/update-vehicle/${requests?._id}`, formData)
        if (respons.status === true) {
            Toast.fire({
                title: "Vehicle Updated Successfully",
                icon: "success",
                color: "green"
            })
        }
        else {
            Toast.fire({
                title: "Something Went Wrong",
                icon: "error",
                color: "red"
            })
        }

        setLoader(false)
    }
    console.log("REQUASTE DATA:", requests)

    const fetchAllReason = async () => {
        const data = await get_data(`reason/get-all-reasons`)
        console.log("REQUASTE DATA:", data)
        if (data.status === true) {
            setReasonList(data.data.reasons)
        }
    }

    useEffect(() => {
        fetchAllReason()
    }, [requests])

    console.log("driverCityList.find(c => c.name === (formData?.detail?.[index]?.driverDetails?.driverCity || item?.driverDetails?.address?.city))", driverCityList.find(c => c.name === (formData?.detail?.[0]?.driverDetails?.driverCity)))
    console.log("formData?.detail?.[0]?.driverDetails?.driverCity", formData, requests)
    return (
        <div style={{ background: 'white', padding: 20, borderRadius: '5px' }}>
            <Toaster position="top-right" reverseOrder={false} />
            <Typography variant='h6' style={{
                display: 'flex',
                width: '100%',
                // justifyContent: 'space-between',
                marginLeft: 'auto',
            }}>
                {/* <div>Customer Details</div> */}

                <Button style={{
                    backgroundColor: primaryColor,
                    color: '#ffff',
                    cursor: 'pointer',
                    marginLeft: 'auto'
                }}
                    variant="contained"
                    onClick={handleEdit}
                >
                    {loader ? 'Editing' : 'Edit'}
                </Button>

            </Typography>
            {/* Customer Details */}
            {/* <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                <List sx={{ border: '1px solid gainsboro' }}>
                    <React.Fragment >
                        <ListItem>
                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}> Company Name  </div>
                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                {requests?.clientId?.companyName}
                            </div>
                        </ListItem>
                    </React.Fragment>
                </List>
            </Typography> */}

            <Typography variant='h6' style={{ margin: '10px 0px' }}>Vehicle Details</Typography>
            {requests?.detail?.map((item, index) => {
                return (<div style={{ marginTop: 10, gap: '10px', width: '100%', display: 'flex' }}>
                    <Accordion style={{ width: '100%' }} defaultExpanded={index === 0}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography>Vehicle {index + 1}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                            <Typography variant='h6' style={{ margin: '10px 0px' }}>Driver Details</Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                                <List sx={{ border: '1px solid gainsboro' }}>
                                    <React.Fragment >
                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Full Name   </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input
                                                    type='text'
                                                    value={formData?.detail[index]?.driverDetails?.fullName || item?.driverDetails?.fullName}
                                                    name="fullName"
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        color: "#666666"
                                                    }}
                                                    onChange={(e) => handleDriverInputChange(index, 'fullName', e.target.value)}
                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>Father Name </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input
                                                    type='text'
                                                    value={formData?.detail[index]?.driverDetails?.fatherName || item.driverDetails?.fatherName}
                                                    name="fatherName"
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        color: "#666666"
                                                    }}
                                                    onChange={(e) => handleDriverInputChange(index, 'fatherName', e.target.value)}
                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Contact No </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input

                                                    value={formData?.detail[index]?.driverDetails?.contactNo || item.driverDetails?.contactNo}
                                                    name="contactNo"
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        color: "#666666"
                                                    }}
                                                    onChange={(e) => handleDriverInputChange(index, 'contactNo', e.target.value)}
                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Residential Contact No  </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>

                                                <input

                                                    value={formData?.detail[index]?.driverDetails?.resContactNo || item?.driverDetails?.resContactNo}
                                                    name="resContactNo"
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        color: "#666666"
                                                    }}
                                                    onChange={(e) => handleDriverInputChange(index, 'resContactNo', e.target.value)}
                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        {/* <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}> address </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}> {item?.driverDetails?.address?.street + ' ' + item?.driverDetails?.address?.landmark + ' ' + item?.driverDetails?.address?.state + ' ' + item?.driverDetails?.address?.city + ' ' + item?.driverDetails?.address?.zipCode || '-'}</div>
                                        </ListItem>
                                        <Divider /> */}

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Street  </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData?.detail[index]?.driverDetails?.address?.street || item?.driverDetails?.address?.street}
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        color: "#666666"
                                                    }}
                                                    onChange={(e) => handleDriverInputChange(index, 'street', e.target.value)}
                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Landmark  </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text'
                                                    value={formData?.detail[index]?.driverDetails?.address?.landmark || item.driverDetails?.address?.landmark}
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        color: "#666666"
                                                    }}
                                                    onChange={(e) => handleDriverInputChange(index, 'landmark', e.target.value)}
                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  State  </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <Autocomplete
                                                    id="state-select"
                                                    options={stateList}
                                                    value={stateList.find(s => s.isoCode === (formData?.detail?.[index]?.driverDetails?.address?.state || item?.driverDetails?.address?.state)) || null}
                                                    onChange={(event, newValue) => handleDriverStateChange(index, event, newValue)}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            error={!!errors.driverState}
                                                            helperText={errors.driverState}
                                                            onFocus={() => handleError('', 'state')}
                                                            {...params}
                                                            label="Select State"
                                                            fullWidth
                                                            variant="standard"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  City  </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <Autocomplete
                                                    id="city-select"
                                                    options={driverCityList}
                                                    value={driverCityList?.[index].find(c => c.name === (formData?.detail?.[index]?.driverDetails?.address?.city || item?.driverDetails?.address?.city)) || null}
                                                    onChange={(event, newValue) => handleDriverCityChange(index, event, newValue)}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            error={!!errors.driverCity}
                                                            helperText={errors.driverCity}
                                                            onFocus={() => handleError('', 'driverCity')}
                                                            label="Select City"
                                                            fullWidth
                                                            variant="standard"
                                                        />
                                                    )}
                                                />

                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Zip Code  </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData?.detail[index]?.driverDetails?.address?.zipCode || item?.driverDetails?.address?.zipCode}
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        color: "#666666"
                                                    }}
                                                    onChange={(e) => handleDriverInputChange(index, 'zipCode', e.target.value)}
                                                />
                                            </div>
                                        </ListItem>
                                    </React.Fragment>
                                </List>
                            </Typography>


                            <Typography variant='h6' style={{ margin: '10px 0px' }}>Vehicle Details</Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                                <List sx={{ border: '1px solid gainsboro' }}>
                                    <React.Fragment >
                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Owner Name </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.detail[index]?.vehicleDetails?.regnOwnerName || item?.vehicleDetails?.regnOwnerName} placeholder='regnOwnerName'
                                                    name="regnOwnerName"
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        color: "#666666"
                                                    }}

                                                    onChange={(e) => handleVehicleInputChange(index, 'regnOwnerName', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Registration Number   </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.detail[index]?.vehicleDetails?.regnNumber || item?.vehicleDetails?.regnNumber} placeholder='regnNumber'
                                                    name="regnNumber"
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        color: "#666666"
                                                    }}

                                                    onChange={(e) => handleVehicleInputChange(index, 'regnNumber', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>Plate </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.detail[index]?.vehicleDetails?.plate || item?.vehicleDetails?.plate} placeholder='plate'
                                                    name="plate"
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        color: "#666666"
                                                    }}

                                                    onChange={(e) => handleVehicleInputChange(index, 'plate', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}>  Type Of Vehicle</div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData.detail[index]?.vehicleDetails?.typeOfVehicle || item?.vehicleDetails?.typeOfVehicle} placeholder='typeOfVehicle'
                                                    name="typeOfVehicle"
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        color: "#666666"
                                                    }}

                                                    onChange={(e) => handleVehicleInputChange(index, 'typeOfVehicle', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}> Vehicle Class </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input type='text' value={formData?.detail[index]?.vehicleDetails?.vehicleClass || item?.vehicleDetails?.vehicleClass} placeholder='vehicleClass'
                                                    name="vehicleClass"
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        color: "#666666"
                                                    }}

                                                    onChange={(e) => handleVehicleInputChange(index, 'vehicleClass', e.target.value)}

                                                />
                                            </div>
                                        </ListItem>
                                        <Divider />

                                        <ListItem>
                                            <div style={{ width: '40%', borderRight: '1px solid gainsboro' }}> Chassis Number </div>
                                            <div style={{ width: '60%', marginLeft: '10px' }}>
                                                <input
                                                    type='text'
                                                    label="Chassis Number"
                                                    name="chassisNumber"
                                                    style={{
                                                        border: 'none',
                                                        outline: 'none',
                                                        color: "#666666"
                                                    }}
                                                    value={formData?.detail[index]?.vehicleDetails?.chassisNumber || item?.vehicleDetails?.chassisNumber}
                                                    onChange={(e) => handleVehicleInputChange(index, 'chassisNumber', e.target.value)}
                                                    fullWidth
                                                />
                                            </div>
                                        </ListItem>
                                    </React.Fragment>
                                </List>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>



                    {/* <ToggleButtonGroup
                        color="primary"
                        value={toggleDetailStatus?.[index]}
                        exclusive
                        onChange={(e) => { handleChange(e.target.value, index) }}
                        aria-label="Platform"
                        style={{ marginLeft: 'auto', height: '50px' }}
                    >
                        <ToggleButton value="Installed" style={{ fontSize: '.7rem' }}>Installed</ToggleButton>
                        <ToggleButton value="Not Installed" style={{ fontSize: '.7rem' }}>Not Installed</ToggleButton>
                    </ToggleButtonGroup> */}

                    {/* <Modal
                        open={open}
                        onClose={handleClose}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                border: '2px solid #000',
                                boxShadow: 24,
                                p: 4,
                                borderRadius: '10px'
                            }}
                        >
                          
                            <Autocomplete
                                // id="state-select"
                                options={reasonList}
                                value={reasonList.find(s => s.name === reason) || null}
                                onChange={(e) => setReason(e.target.value)}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        {...props}
                                        value={option.name}
                                        key={option.name}
                                    >
                                        {option.name}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Reason"
                                        fullWidth
                                    />
                                )}
                            />
                            <Button variant="contained"
                                fullWidth
                                style={{ marginTop: '10px', borderRadius: 0, background: createButtonBg, boxShadow: 'none' }}
                                onClick={() => handleChange("Not Installeded", sno)}>Submit</Button>
                        </Box>
                    </Modal> */}
                </div>
                )
            })}
        </div>
    );
}
