import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Avatar,
    Button,
    Typography,
    Menu,
    MenuItem,
    IconButton,
    Pagination,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddRequestsModal from '../AddRequestsModal';
import { useNavigate } from 'react-router-dom';
import TableLoader from '../tables/TableLoader';
import { formatDate } from '../../constant';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
        '& .MuiCheckbox-root': {
            color: '#a3a3a3',
        },
    },
    stageButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        '&.closedOwn': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.analysis': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.qualification': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.closedLost': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
    },
    avatarGroup: {
        display: 'flex',
        alignItems: 'center',
    },
    avatarName: {
        marginLeft: '8px',
        fontSize: 14
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});

const ClientRequestTabel = ({
    data,
    tableHeader,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    count,
    totalData,
    totalPages,
    loading,
    setLoading,
    getAllRequests,
}) => {

    const classes = useStyles();
    const navigate = useNavigate()
    const [rowsData, setRowsData] = useState(data || []);
    const [addRequestsModalOpen, setAddRequestsModalOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);

    useEffect(() => { setRowsData(rowsData) }, [data])

    const handleCheckboxToggle = (index) => {
        const updatedRows = [...rowsData];
        updatedRows[index].checked = !updatedRows[index].checked;
        setRowsData(updatedRows);
    };

    const handleMenuOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(index);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentRow(null);
    };

    const handleModalOpne = (row) => {

        setAddRequestsModalOpen(true)
        handleMenuClose()
    }
    const handleModalClose = (row) => {
        setAddRequestsModalOpen(false)
    }

    const handletable = (row) => {
        navigate('/company/dashboard/clientrequestdetails', { state: { row } })
        handleMenuClose()
    }
    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    // console.log("data", rowsData)
    return (<TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
        <Table className={classes.root}>
            <TableHead>
                <TableRow>
                    {
                        tableHeader?.map((item) => {
                            return (
                                <TableCell style={{ borderRight: '1px solid gainsboro', padding: '16px 10px' }}>{item}</TableCell>
                            )
                        })
                    }
                </TableRow>
            </TableHead>
            {loading ?
                <div>
                    <TableLoader />
                </div>
                :
                <TableBody>
                    {data?.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell style={{ borderRight: '1px solid gainsboro', textAlign: 'center', width: '5%' }}>{startEntry + index}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro', width: '15%' }}>{row?.uniqueRequestId}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro', width: '10%' }}>{row?.gps?.length || '-'}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro', width: '15%' }}>
                                {row?.gps?.map((item) => <div>{item?.deviceDetails?.uniqueId}</div>) || '-'}
                            </TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro', width: '15%' }}>
                                {row?.gps?.map((item) => <div>{item?.vehicleDetails?.regnNumber}</div>) || '-'}
                            </TableCell>

                            <TableCell style={{ borderRight: '1px solid gainsboro', width: '15%' }}>
                                {row?.gps?.map((item) => item?.driverDetails?.fullName).join(', ') || '-'}
                            </TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro', width: '15%' }}>{row?.installerDetails?.name || '-'}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro', width: '15%' }}>{formatDate(row?.createdAt)}</TableCell>

                            <TableCell style={{ borderRight: '1px solid gainsboro', width: '5%' }}>
                                <IconButton
                                    className={classes.moreIcon}
                                    onClick={(event) => handleMenuOpen(event, index)}
                                >
                                    <MoreVertIcon />
                                </IconButton>

                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl) && currentRow === index}
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem onClick={() => handletable(row)}>View Details</MenuItem>
                                </Menu>
                            </TableCell>

                            <AddRequestsModal addRequestsModalOpen={addRequestsModalOpen} handleModal={handleModalOpne} handleModalClose={handleModalClose} title={'Requests-View-Details '} row={row} />

                        </TableRow>

                    ))}
                </TableBody>
            }
        </Table>

        {data?.length > 0 && (
            <div
                style={{
                    borderTop: "1px solid gainsboro",
                    padding: "2%",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Typography
                    style={{
                        fontWeight: 500,
                        fontSize: 15,
                        color: "black",
                    }}
                >{`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}</Typography>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                />
            </div>
        )}
    </TableContainer>
    );
};

export default ClientRequestTabel;
