import { Box, Card, CardContent, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../api";
import { Toaster, toast } from 'react-hot-toast';

const DefaultNetwork = () => {

    const [network, setNetwork] = useState('');
    const [loading, setLoading] = useState(true);
    const [updatedId, setUpdatedId] = useState(null);

    const fetchNetwork = async () => {
        const result = await get_data('network/get-network')

        if (result.status) {
            setNetwork(result?.data[0]?.name)
            setUpdatedId(result?.data[0]?._id)
        }

        setLoading(false)
    }

    const handleChange = async (event) => {
        setLoading(true)
        const result = await post_data(`network/set-network/${updatedId}`, { name: event.target.value })

        if (result.status) {
            setNetwork(result?.data?.name)
            setUpdatedId(result?.data?._id)
            toast.success("Network Updated")
        } else {
            toast.error("Something went wrong")
        }
        setLoading(false)

    };

    useEffect(() => {
        fetchNetwork();
    }, []);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Box>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <Card>
                <CardContent>
                    <FormControl>
                        <FormLabel>Default Network</FormLabel>
                        <RadioGroup
                            value={network}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="airtel" control={<Radio />} label="Airtel" />
                            <FormControlLabel value="jio" control={<Radio />} label="Jio" />
                            <FormControlLabel value="vi" control={<Radio />} label="Vi" />
                            <FormControlLabel value="bsnl" control={<Radio />} label="Bsnl" />
                        </RadioGroup>
                    </FormControl>
                </CardContent>
            </Card>
        </Box>
    )
}

export default DefaultNetwork;