import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid, TextField } from '@mui/material';
import { useState, useEffect } from "react";
import { createButtonBg, primaryColor } from '../../constant';
import { post_data } from '../../api';
import { Toaster, toast } from 'react-hot-toast';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
};

export default function ReasonModal({ setOpen, open, editData, isUpdate, getAllReasons }) {
    const [name, setName] = useState('');
    const [errors, setErrors] = useState({});

    useEffect(() => {
        // Sync state with `editeBrandData` when it's passed or changes
        if (isUpdate && editData) {
            setName(editData?.name || '');
        } else {
            setName('');
        }
    }, [editData, isUpdate]);

    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const validation = () => {
        let error = false;
        if (!name) {
            error = true;
            handleError('Please input Brand Name...', 'name');
        }
        return error;
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        const hasError = validation();
        if (!hasError) {
            try {
                let data;
                if (isUpdate) {
                    data = await post_data(`reason/update-reason/${editData?._id}`, { name });
                    setOpen(false);
                } else {
                    data = await post_data(`reason/create-reason`, { name });
                    setOpen(false);
                }
                // Display success or error toast based on the API response
                if (data?.status === true) {
                    setName(''); // Clear the form
                    getAllReasons()
                    toast.success(data?.message || 'Operation successful!');
                } else {
                    toast.error('Something went wrong!');
                }
            } catch (error) {
                toast.error('An error occurred. Please try again.');
            }
        }
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            {isUpdate ? 'Edit GPS UnInstall Reason' : 'Create Reason'}
                        </Typography>
                        <Grid container spacing={2} style={{ marginTop: 10 }}>
                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.name)}
                                    helperText={errors.name}
                                    onFocus={() => handleError('', 'name')}
                                    value={name}
                                    label="Reason Name"
                                    onChange={(e) => setName(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
                                <Button
                                    variant="outlined"
                                    style={{
                                        textTransform: 'capitalize',
                                        color: primaryColor,
                                        border: `1px solid ${primaryColor}`,
                                        borderRadius: 0
                                    }}
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    style={{
                                        textTransform: 'capitalize',
                                        color: '#fff',
                                        background: primaryColor,
                                        borderRadius: 0
                                    }}
                                    onClick={handleSubmit}
                                    fullWidth
                                >
                                    {isUpdate ? 'Edit' : 'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
            {/* <Toaster /> */}
        </div>
    );
}
