import { TextField, Grid, Switch, Checkbox, FormControlLabel, Button, FormGroup, Typography, Autocomplete, FormHelperText, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { formatDate, formatPrice, primaryColor, primaryColorHover } from '../constant';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Country, State, City } from 'country-state-city';
import VendorsTableName from './VanderTableName';
import { get_data, post_data, serverURL } from '../api';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Swal from 'sweetalert2';
import AddSubVendorModal from './AddSubVendorModel';
import { useSelector } from 'react-redux';

import * as XLSX from 'xlsx';
import ClientSitesForm from './models/ClientSitesForm';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
});

export default function CreateVendorVehicles() {

    const { isLoggedIn, user_data } = useSelector(state => state.user);
    const location = useLocation()
    const vehicleData = location?.state?.row
    const title = location?.state?.title
    const [driverCityList, setDriverCityList] = useState([[]]);
    const [companyList, setCompanyList] = useState([]);
    const [deviceModelList, setDeviceModelList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [data, setData] = useState([]);
    const [vandorTableOpen, setVandorTableOpen] = useState({});
    const [stateList, setStateList] = useState([[]]);
    const [brandList, setBrandList] = useState([[]]);
    const [modelList, setModelList] = useState([]);
    const [excelData, setExcelData] = useState([]);
    const [fileName, setFileName] = useState('');
    const [openSite, setOpenSite] = useState(false);
    const [formData, setFormData] = useState({
        vendorId: user_data?._id,
        status: 'pending',
        clientId: '',
        detail: [{
            driverDetails: { fullName: vehicleData?.detail?.driverDetails?.fullName || '', fatherName: '', contactNo: '', resContactNo: '', aadhar: '', pan: '', badgeNo: '', shift: '', address: { country: '', street: '', landmark: '', city: '', state: '', zipCode: '' } },
            vehicleDetails: { brand: '', model: '', regnNumber: '', plate: '', regnOwnerName: '', regnOwnerContactNo: '', typeOfVehicle: '', vehicleClass: '', chassisNumber: '', noOfSeats: '' },
        }],
    });
    const [errors, setErrors] = useState({});
    const [countryCode] = useState('IN');
    const [vendorList, setVendorList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [index, setIndex] = useState(0);
    const [defaultnetwork, setDefaultnetwork] = useState('');
    const [siteList, setSiteList] = useState([]);

    const [countryList, setCountryList] = useState([])
    const [vehicleList, setVehicleList] = useState(['Car', 'Bus', 'Cab'])
    const [noOfSeats, setNoOfSeats] = useState(['5 Seater', '7 Seater', '22 Seater'])
    // const [isSubmitLoading,setIsSubmitLoading] = useState(false)
    const navigate = useNavigate()
    useEffect(() => {
        if (title === 'update' && vehicleData?.detail) {
            console.log("vehicleData?.detail", vehicleData?.detail)
            const updatedDetails = vehicleData?.detail?.map(item => ({
                driverDetails: {
                    fullName: item?.driverDetails?.fullName || '',
                    fatherName: item?.driverDetails?.fatherName || '',
                    contactNo: item?.driverDetails?.contactNo || '',
                    resContactNo: item?.driverDetails?.resContactNo || '',
                    aadhar: item?.driverDetails?.aadhar || '',
                    pan: item?.driverDetails?.pan || '',
                    badgeNo: item?.driverDetails?.badgeNo || '',
                    shift: item?.driverDetails?.shift || '',
                    address: {
                        country: item?.driverDetails?.address?.country || '',
                        street: item?.driverDetails?.address?.street || '',
                        landmark: item?.driverDetails?.address?.landmark || '',
                        city: item?.driverDetails?.address?.city || '',
                        state: item?.driverDetails?.address?.state || '',
                        zipCode: item?.driverDetails?.address?.zipCode || '',
                    },
                },
                vehicleDetails: {
                    brand: item?.vehicleDetails?.brand || '',
                    model: item?.vehicleDetails?.model || '',
                    regnNumber: item?.vehicleDetails?.regnNumber || '',
                    plate: item?.vehicleDetails?.plate || '',
                    regnOwnerName: item?.vehicleDetails?.regnOwnerName || '',
                    regnOwnerContactNo: item?.vehicleDetails?.regnOwnerContactNo || '',
                    typeOfVehicle: item?.vehicleDetails?.typeOfVehicle || '',
                    vehicleClass: item?.vehicleDetails?.vehicleClass || '',
                    chassisNumber: item?.vehicleDetails?.chassisNumber || '',
                    noOfSeats: item?.vehicleDetails?.noOfSeats || '',
                },
            }));

            vehicleData?.detail?.map((item, index) => {
                const fetchedState = State.getStatesOfCountry(item?.driverDetails?.address?.country)
                // setStateList(fetchedState)
                setStateList((prevStateList) => {
                    const updatedList = [...prevStateList]; // Clone the existing list.
                    updatedList[index] = fetchedState; // Update the specific index.
                    return updatedList;
                });

                const fetchedCities = City.getCitiesOfState(item?.driverDetails?.address?.country, item?.driverDetails?.address?.state);
                // setDriverCityList(fetchedCities); // Update city list based on state
                setDriverCityList((prevStateList) => {
                    const updatedList = [...prevStateList]; // Clone the existing list.
                    updatedList[index] = fetchedCities; // Update the specific index.
                    return updatedList;
                })
            })

            setFormData(prevState => ({
                ...prevState,
                detail: updatedDetails,
            }));
            setFormData(prevState => ({
                ...prevState,
                clientId: vehicleData?.clientId,
            }));
        }
    }, [title, vehicleData]);

    const handleVehicleInputChange = (index, field, value) => {
        setFormData((prevState) => {
            const updatedDetail = prevState.detail.map((form, idx) => {
                if (index === idx) {
                    return { ...form, vehicleDetails: { ...form.vehicleDetails, [field]: value, }, };
                }
                return form;
            });

            return {
                ...prevState,
                detail: updatedDetail,
            };
        });

        if (field == 'brand') {
            getAllModels(index, value)
        }
    };

    const handleDriverInputChange = (index, field, value) => {
        setFormData((prevState) => {
            const updatedDetail = prevState.detail.map((form, idx) => {
                if (index === idx) {
                    if (['street', 'landmark', 'city', 'state', 'zipCode'].includes(field)) {

                        return {
                            ...form, driverDetails: { ...form.driverDetails, address: { ...form.driverDetails.address, [field]: value, } },
                        };
                    } else {

                        return {
                            ...form, driverDetails: { ...form.driverDetails, [field]: value }
                        };
                    }
                }
                return form;
            });
            return { ...prevState, detail: updatedDetail, };
        });
    };
    const addForm = () => {
        setIndex(index + 1);
        setFormData(prevState => ({
            ...prevState,
            detail: [
                ...prevState.detail,
                {
                    driverDetails: { fullName: '', fatherName: '', contactNo: '', resContactNo: '', aadhar: '', pan: '', badgeNo: '', shift: '', address: { street: '', landmark: '', city: '', state: '', zipCode: '', country: '' } },
                    vehicleDetails: { brand: '', model: '', regnNumber: '', plate: '', regnOwnerName: '', regnOwnerContactNo: '', typeOfVehicle: '', chassisNumber: '', noOfSeats },
                }
            ]
        }));
    };

    const removeDetailForm = (index) => {
        setIndex(index - 1);
        setFormData(prevState => ({
            ...prevState,
            detail: prevState.detail.filter((_, i) => i !== index)
        }));
    };

    // Fetch device model list
    const fetchdeviceModelList = async () => {
        try {
            const data = await get_data(`product/get-all-products-for-options`);
            setDeviceModelList(data?.data || []);
        } catch (error) {
        }
    };

    const fetchCompanyList = async () => {
        const result = await get_data(`vendor/get-client-of-vendor`);
        console.log("xxxxxxxxxxxx", result)
        if (result?.status) {
            setCompanyList(result?.data || [{ name: 'aasib', _id: 12345678 }, { name: 'san', _id: 87654321 }]);
        }
    }
    console.log("formData", formData)
    const fetchBrands = async () => {
        const result = await get_data(`brand/get-all-brands-for-options`);
        if (result?.status) {
            setBrandList(result?.data || []);

        }
    }

    const getAllModels = async (index, value) => {
        let result = await get_data(`model/get-all-model-by-brand/${value}`)
        // console.log("formData", result)
        if (result?.status == true) {
            setModelList((prev) => {
                let updatedModelList = formData?.detail?.map((item, idx) => {
                    if (index == idx) {
                        return result?.data
                    }
                    return modelList?.[idx]
                })
                return updatedModelList
            })
        } else {
            toast.error(result?.message)
        }
    }
    useEffect(() => {
        fetchdeviceModelList();
        fetchCompanyList();
        fetchBrands();
    }, []);

    // const handleError2 = (error, label) => {
    //     setErrors((prev) => ({ ...prev, [label]: error }));
    // };

    const handleError = (error, label, index) => {
        setErrors((prev) => {
            const updatedErrors = { ...prev };
            if (!updatedErrors[index]) {
                updatedErrors[index] = {};
            }
            updatedErrors[index][label] = error;
            return updatedErrors;
        });
    };

    const validation = () => {
        let hasError = false;

        if (!formData?.clientId) {
            // handleError('Please select Client', 'clientId');
            setErrors((prev) => {
                const updatedErrors = { ...prev };
                if (!updatedErrors) {
                    updatedErrors = {};
                }
                updatedErrors['company'] = 'Please select Company';
                return updatedErrors;
            });
            hasError = true;
        }

        formData?.detail?.forEach((form, index) => {
            if (!form?.driverDetails?.fullName) {
                handleError('Please enter Full Name', 'fullName', index);
                hasError = true;
            }
            if (!form?.driverDetails?.fatherName) {
                handleError('Please enter Father\'s Name', 'fatherName', index);
                hasError = true;
            }
            if (!form?.driverDetails?.contactNo) {
                handleError('Please enter Contact No', 'contactNo', index);
                hasError = true;
            }

            if (!form?.driverDetails?.resContactNo) {
                handleError('Please enter res Contact No', 'resContactNo', index);
                hasError = true;
            }
            if (!form?.driverDetails?.aadhar) {
                handleError('Please enter aadhar', 'aadhar', index);
                hasError = true;
            }
            if (!form?.driverDetails?.pan) {
                handleError('Please enter pan', 'pan', index);
                hasError = true;
            }
            if (!form?.driverDetails?.badgeNo) {
                handleError('Please enter badge No', 'badgeNo', index);
                hasError = true;
            }
            if (!form?.driverDetails?.shift) {
                handleError('Please enter shift', 'shift', index);
                hasError = true;
            }
            if (!form?.driverDetails?.address?.country) {
                handleError('Please enter country', 'country', index);
                hasError = true;
            }

            if (!form?.driverDetails?.address?.state) {
                handleError('Please enter state', 'state', index);
                hasError = true;
            }
            if (!form?.driverDetails?.address?.city) {
                handleError('Please enter City', 'city', index);
                hasError = true;
            }
            if (!form?.driverDetails?.address?.street) {
                handleError('Please enter street', 'street', index);
                hasError = true;
            }
            if (!form?.driverDetails?.address?.zipCode) {
                handleError('Please enter zipCode', 'zipCode', index);
                hasError = true;
            }
            if (!form?.driverDetails?.address?.landmark) {
                handleError('Please enter Landmark', 'landmark', index);
                hasError = true;
            }
            if (!form?.vehicleDetails?.brand) {
                handleError('Please Select Brand', 'brand', index);
                hasError = true;
            }
            if (!form?.vehicleDetails?.model) {
                handleError('Please Select model', 'model', index);
                hasError = true;
            }
            if (!form?.vehicleDetails?.regnNumber) {
                handleError('Please Registration Number', 'regnNumber', index);
                hasError = true;
            }
            if (!form?.vehicleDetails?.regnOwnerName) {
                handleError('Please Enter Registration Number', 'regnOwnerName', index);
                hasError = true;
            }
            if (!form?.vehicleDetails?.regnOwnerContactNo) {
                handleError('Please Enter Owner Contact Number', 'regnOwnerContactNo', index);
                hasError = true;
            }
            if (!form?.vehicleDetails?.plate) {
                handleError('Please Select Plate', 'plate', index);
                hasError = true;
            }
            if (!form?.vehicleDetails?.typeOfVehicle) {
                handleError('Please Select Type Of Vehicle', 'typeOfVehicle', index);
                hasError = true;
            }
            if (!form?.vehicleDetails?.noOfSeats) {
                handleError('Please Select Number Of Seats', 'noOfSeats', index);
                hasError = true;
            }
            if (!form?.vehicleDetails?.chassisNumber) {

                handleError('Please Enter Chassis Number', 'chassisNumber', index);
                hasError = true;
            }

        });

        return hasError;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log("formData: ", formData)


        if (!validation()) {
            setLoader(true)
            let respons
            {
                title === 'update' ? respons = await post_data(`vehicles/update-vehicle/${vehicleData._id}`, formData)
                    :
                    respons = await post_data(`vehicles/create-vehicle`, formData)
            }
            console.log("xxxxxxxxxxx", respons)
            if (respons.status === true) {
                toast.success("Vehicle Register Successfully")
                navigate('/vendor/dashboard/vehiclegpsrequest')
            }
            else {
                toast.error("Something Went Wrong")
            }
            setLoader(false)
        }

    }

    useEffect(() => {
        // setStateList(State.getStatesOfCountry(countryCode));
        setCountryList(Country.getAllCountries())
    }, []);

    useEffect(() => {
        formData?.detail?.forEach((form, index) => {
            if (form.driverDetails?.address?.state) {
                setCityList(City.getCitiesOfState(form.driverDetails?.address?.country, form.driverDetails?.address?.state));
            }
        });
    }, [formData?.detail]);

    const handleDriverCountryChange = (index, event, newValue) => {
        if (newValue) {
            const countryCode = newValue.isoCode;

            const fetchedState = State.getStatesOfCountry(countryCode)
            // setStateList(fetchedState)
            setStateList((prevStateList) => {
                const updatedList = [...prevStateList]; // Clone the existing list.
                updatedList[index] = fetchedState; // Update the specific index.
                return updatedList;
            });

            setFormData(prevState => {
                const updatedDetail = prevState.detail.map((form, idx) => {
                    if (index === idx) {
                        return {
                            ...form,
                            driverDetails: {
                                ...form.driverDetails, address: { ...form.driverDetails.address, country: countryCode, state: '', city: '' }

                            },
                        };
                    }
                    return form;
                });

                return {
                    ...prevState,
                    detail: updatedDetail,
                };
            });

        }
    }

    const handleDriverStateChange = (index, event, newValue) => {
        if (newValue) {
            const stateCode = newValue.isoCode;
            const countryCode = formData?.detail[index]?.driverDetails?.address?.country; // Replace with actual country code

            const fetchedCities = City.getCitiesOfState(countryCode, stateCode);
            // setDriverCityList(fetchedCities); // Update city list based on state
            setDriverCityList((prevStateList) => {
                const updatedList = [...prevStateList]; // Clone the existing list.
                updatedList[index] = fetchedCities; // Update the specific index.
                return updatedList;
            })
            setFormData(prevState => {
                const updatedDetail = prevState.detail.map((form, idx) => {
                    if (index === idx) {
                        return {
                            ...form,
                            driverDetails: {
                                ...form.driverDetails, address: { ...form.driverDetails.address, state: stateCode, city: '', }

                            },
                        };
                    }
                    return form;
                });

                return {
                    ...prevState,
                    detail: updatedDetail,
                };
            });
        }
    };

    // Handle city change
    const handleDriverCityChange = (index, event, newValue) => {
        if (newValue) {
            setFormData(prevState => {
                const updatedDetail = prevState.detail.map((form, idx) => {
                    if (index === idx) {
                        return {
                            ...form,

                            driverDetails: { ...form.driverDetails, address: { ...form.driverDetails.address, city: newValue.name, } },
                        };
                    }
                    return form;
                });

                return {
                    ...prevState,
                    detail: updatedDetail,
                };
            });
        }
    };

    const buttonStyles = {
        borderColor: primaryColor,
        color: 'white',
        background: primaryColor,
        textTransform: 'capitalize',
        borderRadius: 0,
        fontWeight: 400,
        padding: '1% 3.5%',
        boxShadow: 'none',
        '&:hover': {
            borderColor: primaryColor,
            boxShadow: 'none',
        },
        '&:focus': {
            outline: 'none',
            boxShadow: 'none',
            borderColor: primaryColor,
        }
    };


    const addMoreButton = {
        background: 'white',
        color: primaryColor,
        border: '1px dashed ' + primaryColor,
        borderRadius: 0,
        fontWeight: 400,
        padding: '1% 3.5%',
        boxShadow: 'none',
        '&:hover': {
            borderColor: primaryColor,
            boxShadow: 'none',
        },
        '&:focus': {
            outline: 'none',
            boxShadow: 'none',
            borderColor: primaryColor,
        }
    };

    useEffect(() => {
        formData?.detail?.forEach((form, index) => {
            // console.log("formData?.detail.Model", form?.vehicleDetails?.brand?._id)
            if (form.vehicleDetails.brand) {
                getAllModels(index, form?.vehicleDetails?.brand?._id);
            }
        });
    }, [formData?.detail]);


    return (
        <Grid container spacing={2} style={{ padding: '3% 7%' }}>

            <Grid item md={12}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        {/* Customer Details */}
                        <Grid item xs={12}><Typography variant="h6">Company Details</Typography></Grid>
                        <Grid item xs={12} sm={6}>
                            < FormControl fullWidth error={errors?.company}>
                                <Autocomplete
                                    disablePortal
                                    options={companyList}
                                    getOptionLabel={(option) => option?.companyName}
                                    sx={{ width: 'auto' }}
                                    required
                                    value={companyList?.find((company) => company?._id === formData?.clientId) || null}
                                    onChange={(event, newValue) =>
                                        setFormData((prevState) => ({ ...prevState, clientId: newValue?._id || '' }))
                                    }
                                    renderInput={(params) => {
                                        return <TextField {...params} error={!!errors?.company}
                                            helperText={errors?.company}
                                            onFocus={() => {
                                                setErrors((prev) => {
                                                    const updatedErrors = { ...prev };
                                                    if (!updatedErrors) {
                                                        updatedErrors = {};
                                                    }
                                                    updatedErrors['company'] = '';
                                                    return updatedErrors;
                                                });
                                            }} label="Select company" />;
                                    }}
                                />
                                {/* <FormHelperText sx={{ color: '#D32f2f' }}>{errors?.company}</FormHelperText> */}
                            </FormControl>

                        </Grid>

                        <Grid item xs={12}>
                            {formData?.detail?.map((form, index) => {

                                return (
                                    <Accordion style={{ marginTop: 10, marginBottom: 10 }} key={index} defaultExpanded={index === 0} >
                                        <AccordionSummary
                                            expandIcon={<ArrowDownwardIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <Typography variant="h6">Vehicle Details {index + 1}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2} style={{ boxShadow: '1px solid gray' }}>
                                                {/* Driver Details */}
                                                <Grid item xs={12}><Typography variant="h6">Driver Details</Typography></Grid>
                                                <Grid item xs={12} sm={6}><TextField label="Full Name" name="fullName" error={!!errors[index]?.fullName} helperText={errors[index]?.fullName} onFocus={() => handleError('', 'fullName', index)} value={formData?.detail[index]?.driverDetails?.fullName || ''} onChange={(e) => handleDriverInputChange(index, 'fullName', e.target.value)} fullWidth /></Grid>
                                                <Grid item xs={12} sm={6}><TextField label="Father's Name" name="fatherName" error={!!errors[index]?.fatherName} helperText={errors[index]?.fatherName} onFocus={() => handleError('', 'fatherName', index)} value={formData?.detail[index]?.driverDetails?.fatherName || ''} onChange={(e) => handleDriverInputChange(index, 'fatherName', e.target.value)} fullWidth /></Grid>
                                                <Grid item xs={12} sm={6}><TextField label="Contact No" name="contactNo" error={!!errors[index]?.contactNo} helperText={errors[index]?.contactNo} onFocus={() => handleError('', 'contactNo', index)} value={formData?.detail[index]?.driverDetails?.contactNo || ''} onChange={(e) => handleDriverInputChange(index, 'contactNo', e.target.value)} fullWidth /></Grid>
                                                <Grid item xs={12} sm={6}><TextField label="Residential Contact No" name=" resContactNo" error={!!errors[index]?.resContactNo} helperText={errors[index]?.resContactNo} onFocus={() => handleError('', 'resContactNo', index)} value={formData?.detail[index]?.driverDetails?.resContactNo || ''} onChange={(e) => handleDriverInputChange(index, 'resContactNo', e.target.value)} fullWidth /></Grid>
                                                <Grid item xs={12} sm={6}><TextField label="Aadhaar No" name="aadhaar" error={!!errors[index]?.aadhar} helperText={errors[index]?.aadhar} onFocus={() => handleError('', 'aadhar', index)} value={formData?.detail[index]?.driverDetails?.aadhar || ''} onChange={(e) => handleDriverInputChange(index, 'aadhar', e.target.value)} fullWidth /></Grid>
                                                <Grid item xs={12} sm={6}><TextField label="Pan Card No" name="pan" error={!!errors[index]?.pan} helperText={errors[index]?.pan} onFocus={() => handleError('', 'pan', index)} value={formData?.detail[index]?.driverDetails?.pan || ''} onChange={(e) => handleDriverInputChange(index, 'pan', e.target.value)} fullWidth /></Grid>
                                                <Grid item xs={12} sm={6}><TextField label="Badge No" name="badgeNo" error={!!errors[index]?.badgeNo} helperText={errors[index]?.badgeNo} onFocus={() => handleError('', 'badgeNo', index)} value={formData?.detail[index]?.driverDetails?.badgeNo || ''} onChange={(e) => handleDriverInputChange(index, 'badgeNo', e.target.value)} fullWidth /></Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Shift</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            error={!!errors[index]?.shift} helperText={errors[index]?.shift} onFocus={() => handleError('', 'shift', index)}
                                                            value={formData?.detail[index]?.driverDetails?.shift || ''}
                                                            label="Shift"
                                                            name="shift"
                                                            onChange={(e) => handleDriverInputChange(index, 'shift', e.target.value)}
                                                        >
                                                            <MenuItem value="Day">Day</MenuItem>
                                                            <MenuItem value="Night">Night</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <FormHelperText sx={{ color: '#D32f2f' }}>{errors[index]?.shift}</FormHelperText>
                                                </Grid>
                                                <Grid item xs={12}><Typography variant="h6"> Driver Address</Typography></Grid>

                                                <Grid item xs={12} sm={4}>
                                                    <Autocomplete
                                                        id="country-select"
                                                        options={countryList}
                                                        value={countryList.find(s => s.isoCode === formData?.detail[index]?.driverDetails?.address?.country) || null}
                                                        onChange={(event, newValue) => handleDriverCountryChange(index, event, newValue)}
                                                        getOptionLabel={(option) => option.name}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                error={!!errors[index]?.country} helperText={errors[index]?.country} onFocus={() => handleError('', 'country', index)}
                                                                {...params}
                                                                label="Select Country"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                    {/* <FormHelperText sx={{ color: '#D32f2f' }}>{errors[index]?.state}</FormHelperText> */}
                                                </Grid>

                                                <Grid item xs={12} sm={4}>
                                                    <Autocomplete
                                                        id="state-select"
                                                        options={stateList[index]}
                                                        value={stateList?.[index]?.find(s => s.isoCode === formData?.detail[index]?.driverDetails?.address?.state) || null}
                                                        onChange={(event, newValue) => handleDriverStateChange(index, event, newValue)}
                                                        getOptionLabel={(option) => option.name}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                error={!!errors[index]?.state} helperText={errors[index]?.state} onFocus={() => handleError('', 'state', index)}
                                                                {...params}
                                                                label="Select State"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                    {/* <FormHelperText sx={{ color: '#D32f2f' }}>{errors[index]?.state}</FormHelperText> */}
                                                </Grid>

                                                <Grid item xs={12} sm={4}>
                                                    <Autocomplete
                                                        id="city-select"
                                                        options={driverCityList[index]}
                                                        value={driverCityList?.[index]?.find(c => c.name === formData.detail[index].driverDetails?.address?.city) || null}
                                                        onChange={(event, newValue) => handleDriverCityChange(index, event, newValue)}
                                                        getOptionLabel={(option) => option.name}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                error={!!errors[index]?.city} helperText={errors[index]?.city} onFocus={() => handleError('', 'city', index)}
                                                                label="Select City"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                    {/* <FormHelperText sx={{ color: '#D32f2f' }}>{errors[index]?.city}</FormHelperText> */}
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        label='Zip Code'

                                                        name='zipCode'
                                                        value={formData?.detail[index]?.driverDetails?.address?.zipCode || vehicleData?.detail[index]?.driverDetails?.address?.zipCode || ''}
                                                        error={!!errors[index]?.zipCode} helperText={errors[index]?.zipCode} onFocus={() => handleError('', 'zipCode', index)}

                                                        onChange={(e) => handleDriverInputChange(index, 'zipCode', e.target.value)}
                                                        fullWidth
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <TextField label="Street"
                                                        error={!!errors[index]?.street} helperText={errors[index]?.street} onFocus={() => handleError('', 'street', index)}
                                                        value={formData?.detail[index]?.driverDetails?.address?.street || vehicleData?.detail[index]?.driverDetails?.address?.street || ''}
                                                        onChange={(e) => handleDriverInputChange(index, 'street', e.target.value)}
                                                        name="street"
                                                        fullWidth />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField label="Landmark"
                                                        error={!!errors[index]?.landmark}
                                                        helperText={errors[index]?.landmark}
                                                        onFocus={() => handleError('', 'landmark', index)}
                                                        value={formData?.detail[index]?.driverDetails?.address?.landmark || vehicleData?.detail[index]?.driverDetails?.address?.landmark || ''}
                                                        onChange={(e) => handleDriverInputChange(index, 'landmark', e.target.value)}
                                                        name="landmark"
                                                        fullWidth />
                                                </Grid>


                                                {/* Vehicle Details */}

                                                <Grid item xs={12}><Typography variant="h6">Vehicle Details</Typography></Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <FormControl fullWidth error={errors?.brand}>
                                                        <Autocomplete
                                                            disablePortal
                                                            options={brandList}
                                                            getOptionLabel={(option) => option?.name}
                                                            sx={{ width: 'auto' }}
                                                            required
                                                            value={
                                                                brandList?.find((brand) => brand?._id === formData?.detail[index]?.vehicleDetails?.brand || vehicleData?.detail[index]?.vehicleDetails?.brand) || null
                                                            }
                                                            onChange={(event, newValue) => handleVehicleInputChange(index, 'brand', newValue?._id)}
                                                            renderInput={(params) => {
                                                                return <TextField {...params} error={errors[index]?.brand}
                                                                    helperText={errors[index]?.brand}
                                                                    onFocus={() => handleError('', 'brand', index)} label="Select Brands" />;
                                                            }}

                                                        />
                                                        {/* <FormHelperText sx={{ color: '#D32f2f' }}>{errors[index].brand}</FormHelperText> */}
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <FormControl fullWidth error={errors?.model}>
                                                        <Autocomplete
                                                            disablePortal
                                                            options={modelList?.[index] || []}
                                                            getOptionLabel={(option) => option?.name}
                                                            sx={{ width: 'auto' }}
                                                            value={modelList?.[index]?.find((model) => model?._id === formData?.detail[index]?.vehicleDetails?.model || vehicleData?.detail[index]?.vehicleDetails?.model) || null}
                                                            required
                                                            onChange={(event, newValue) => handleVehicleInputChange(index, 'model', newValue?._id)}

                                                            renderInput={(params) => {
                                                                return <TextField {...params} error={!!errors[index]?.model}
                                                                    helperText={errors[index]?.model}
                                                                    onFocus={() => handleError('', 'model', index)} label="Select Models" />
                                                            }}

                                                        />
                                                        {/* <FormHelperText sx={{ color: '#D32f2f' }}>{errors[index]?.model}</FormHelperText> */}
                                                    </FormControl>
                                                </Grid >

                                                <Grid item xs={12} sm={6}><TextField label="Registration Number" name="regnNumber" value={formData?.detail[index]?.vehicleDetails?.regnNumber || vehicleData?.detail[index]?.vehicleDetails?.regnNumber || ''} onChange={(e) => handleVehicleInputChange(index, 'regnNumber', e.target.value)} error={!!errors[index]?.regnNumber}
                                                    helperText={errors[index]?.regnNumber}
                                                    onFocus={() => handleError('', 'regnNumber', index)} fullWidth />
                                                    {/* <FormHelperText sx={{ color: '#D32f2f' }}>{errors[index]?.regnNumber}</FormHelperText> */}
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id={`plate-select-label-${index}`}>Plate</InputLabel>
                                                        <Select
                                                            label="Plate"
                                                            labelId={`plate-select-label-${index}`}
                                                            value={formData?.detail[index]?.vehicleDetails?.plate || vehicleData?.detail[index]?.vehicleDetails?.plate || ''}
                                                            onChange={(e) => handleVehicleInputChange(index, 'plate', e.target.value)}
                                                            error={!!errors[index]?.plate}
                                                            helperText={errors[index]?.plate}
                                                            onFocus={() => handleError('', 'plate', index)}
                                                        >
                                                            <MenuItem value="Yellow">Yellow</MenuItem>
                                                            <MenuItem value="Green">Green</MenuItem>
                                                            <MenuItem value="Black">Black</MenuItem>
                                                        </Select>
                                                        <FormHelperText sx={{ color: '#D32f2f' }}>{errors[index]?.plate}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6}><TextField label="Owner Name" name="regnOwnerName" value={formData?.detail[index]?.vehicleDetails?.regnOwnerName || vehicleData?.detail[index]?.vehicleDetails?.regnOwnerName || ''}
                                                    error={!!errors[index]?.regnOwnerName}
                                                    helperText={errors[index]?.regnOwnerName}
                                                    onFocus={() => handleError('', 'regnOwnerName', index)}
                                                    onChange={(e) => handleVehicleInputChange(index, 'regnOwnerName', e.target.value)} fullWidth />
                                                    {/* <FormHelperText sx={{ color: '#D32f2f' }}>{errors[index]?.regnOwnerName}</FormHelperText> */}
                                                </Grid>

                                                <Grid item xs={12} sm={6}><TextField label="Owner Contact Number" name="regnOwnerContactNo" value={formData?.detail[index]?.vehicleDetails?.regnOwnerContactNo || vehicleData?.detail[index]?.vehicleDetails?.regnOwnerContactNo || ''}
                                                    error={!!errors[index]?.regnOwnerContactNo}
                                                    helperText={errors[index]?.regnOwnerContactNo}
                                                    onFocus={() => handleError('', 'regnOwnerContactNo', index)}
                                                    onChange={(e) => handleVehicleInputChange(index, 'regnOwnerContactNo', e.target.value)} fullWidth />
                                                    {/* <FormHelperText sx={{ color: '#D32f2f' }}>{errors[index]?.regnOwnerContactNo}</FormHelperText> */}
                                                </Grid>

                                                {/* <Grid item xs={12} sm={4}><TextField label="Type of Vehicle" name="typeOfVehicle" value={formData?.detail[index]?.vehicleDetails?.typeOfVehicle || vehicleData?.detail[index]?.vehicleDetails?.typeOfVehicle || ''} onChange={(e) => handleVehicleInputChange(index, 'typeOfVehicle', e.target.value)} fullWidth /></Grid> */}

                                                <Grid item xs={12} sm={4}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id={`type-select-label-${index}`}>Type Of Vehicle</InputLabel>
                                                        <Select
                                                            label="Type Of Vehicle"
                                                            labelId={`type-of-vehicle-select-label-${index}`}
                                                            value={formData?.detail[index]?.vehicleDetails?.typeOfVehicle || vehicleData?.detail[index]?.vehicleDetails?.typeOfVehicle || ''}
                                                            onChange={(e) => handleVehicleInputChange(index, 'typeOfVehicle', e.target.value)}
                                                            error={!!errors[index]?.typeOfVehicle}
                                                            helperText={errors[index]?.typeOfVehicle}
                                                            onFocus={() => handleError('', 'typeOfVehicle', index)}
                                                        >
                                                            {vehicleList?.map((item) => <MenuItem value={item}>{item}</MenuItem>)}

                                                        </Select>
                                                        <FormHelperText sx={{ color: '#D32f2f' }}>{errors[index]?.typeOfVehicle}</FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={4}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id={`noOfSeats-select-label-${index}`}>Number Of Seats</InputLabel>
                                                        <Select
                                                            label="Number Of Seats"
                                                            labelId={`noOfSeats-select-label-${index}`}
                                                            value={formData?.detail[index]?.vehicleDetails?.noOfSeats || vehicleData?.detail[index]?.vehicleDetails?.noOfSeats || ''}
                                                            onChange={(e) => handleVehicleInputChange(index, 'noOfSeats', e.target.value)}
                                                            error={!!errors[index]?.noOfSeats}
                                                            helperText={errors[index]?.noOfSeats}
                                                            onFocus={() => handleError('', 'noOfSeats', index)}
                                                        >
                                                            {noOfSeats?.map((item) => <MenuItem value={item}>{item}</MenuItem>)}

                                                        </Select>
                                                        <FormHelperText sx={{ color: '#D32f2f' }}>{errors[index]?.noOfSeats}</FormHelperText>
                                                    </FormControl>
                                                </Grid>


                                                {/* <Grid item xs={12} sm={4}><TextField label="Vehicle Class" name="vehicleClass" value={formData?.detail[index]?.vehicleDetails?.vehicleClass || vehicleData?.detail[index]?.vehicleDetails?.vehicleClass || ''} onChange={(e) => handleVehicleInputChange(index, 'vehicleClass', e.target.value)} fullWidth /></Grid> */}

                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        label="Chassis Number"
                                                        name="chassisNumber"
                                                        value={formData?.detail[index]?.vehicleDetails?.chassisNumber || vehicleData?.detail[index]?.vehicleDetails?.chassisNumber || ''}
                                                        onChange={(e) => handleVehicleInputChange(index, 'chassisNumber', e.target.value)}
                                                        fullWidth
                                                        error={!!errors[index]?.chassisNumber}
                                                        helperText={errors[index]?.chassisNumber}
                                                        onFocus={() => handleError('', 'chassisNumber', index)}
                                                    />
                                                    {/* <FormHelperText sx={{ color: '#D32f2f' }}>{errors[index]?.chassisNumber}</FormHelperText> */}
                                                </Grid>

                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>)
                            })}

                            <Grid item xs={12} sx={{ textAlign: 'right' }}>
                                {index > 0 && <Button onClick={() => removeDetailForm(index)}
                                    variant="outlined"
                                    style={{
                                        borderColor: primaryColor,
                                        color: primaryColor,
                                        border: `2px solid ${primaryColor}`,
                                        textTransform: 'capitalize',
                                        padding: '1% 3.5%',
                                        textAlign: 'center',
                                        fontWeight: '500',
                                        cursor: 'pointer',
                                        marginRight: "10px"
                                    }}
                                >
                                    Remove
                                </Button>}
                                <Button onClick={addForm} variant="contained" style={addMoreButton}>
                                    Add More
                                </Button>
                            </Grid>
                        </Grid>


                        {/* Submit Button */}
                        <Grid item xs={12}>
                            <Button variant="contained" type="submit" style={buttonStyles}>
                                {title === 'update' ? loader ? 'Updating...' : "Update" : loader ? 'Submiting...' : "Submit"}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid >

        </Grid >
    );
}


