import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { TextField, Grid, Switch, Checkbox, FormControlLabel, Button, IconButton, FormGroup, Typography, Autocomplete, FormHelperText, Table, TableHead, TableBody, TableRow, TableCell, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import { useState, useEffect } from "react";
import { createButtonBg, primaryColor } from '../../constant';
import { post_data } from '../../api';
import { Toaster, toast } from 'react-hot-toast';
import { Country, State, City } from 'country-state-city';
import AdminChangPasswordModals from '../tables/AdminChangPasswordModals';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
};

export default function AdminUserModal({ editData, isUpdate, open, setOpen, getAllUsers }) {
    const [cityList, setCityList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfrimPassword, setShowConfrimPassword] = useState(false);
    const [formData, setFormData] = useState({
        name: "", email: "", phone: "", password: "",
        address: { street: "", city: "", state: "", zipCode: "", country: "", },
        uniqueUserId: "", isActive: true, isUser: true,
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (isUpdate && editData) {
            setFormData({
                name: editData?.name,
                phone: editData?.phone,
                email: editData?.email,
                address: {
                    street: editData?.address?.street,
                    zipCode: editData?.address?.zipCode,
                    country: editData?.address?.country,
                    state: editData?.address?.state,
                    city: editData?.address?.city,
                }
            })
        }
    }, [editData, isUpdate]);

    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const validation = () => {
        let error = false;
        if (!formData?.name) {
            error = true;
            handleError('Please input Name...', 'name');
        }
        if (!formData?.address?.country) {
            handleError('Please select country', 'country');
            error = true;;
        }
        if (!formData?.address?.state) {
            handleError('Please Select state', 'state');
            error = true;;
        }
        if (!formData?.address?.city) {
            handleError('Please Select', 'city');
            error = true;;
        }
        if (!formData?.address?.street) {
            handleError('Please Enter street', 'street');
            error = true;;
        }
        if (!formData?.address?.zipCode) {
            handleError('Please Enter zip_code', 'zipCode');
            error = true;;
        }
        if (isUpdate === false) {
            if (!formData?.password) {
                handleError('Please Enter password', 'password');
                error = true;;
            }
            if (!formData?.confirmPassword) {
                handleError('Please Enter confirmPassword', 'confirmPassword');
                error = true;;
            }
            if (formData?.confirmPassword != formData?.password) {
                error = true;
            }
        }
        return error;
    };

    const handleClose = () => {
        setOpen(false)
    };

    const handleSubmit = async () => {
        const hasError = validation();
        if (!hasError) {
            try {
                setLoader(true)
                let data;
                if (isUpdate) {
                    data = await post_data(`user/update-user/${editData?._id}`, { ...formData });
                } else {
                    data = await post_data(`user/create-user`, { ...formData });
                }
                // Display success or error toast based on the API response
                if (data?.status === true) {
                    setOpen(false)
                    setLoader(false)
                    getAllUsers()
                    setFormData('');
                    toast.success(data?.message || 'Operation successful!');
                } else {
                    setLoader(false)
                    toast.error('Something went wrong!');
                }
            } catch (error) {
                setLoader(false)
                toast.error('An error occurred. Please try again.');
            }
        }
    };

    const handleInputChange = (field, value) => {
        if ('street' === field || 'zipCode' === field) {
            setFormData({ ...formData, address: { ...formData.address, [field]: value } })
        } else {
            setFormData({ ...formData, [field]: value })
        }
    }


    //////////////////////COUNTRY LIST///////////////////////
    useEffect(function () {
        setCountryList(Country.getAllCountries())
    }, [])

    const handleCountryChange = (event, newValue) => {
        if (newValue) {
            const countryCode = newValue.isoCode;
            const fetchedStates = State.getStatesOfCountry(countryCode);
            setStateList(fetchedStates);
            setCityList([]);
            setFormData({ ...formData, address: { ...formData?.address, country: countryCode, state: '', city: '' } });
        }
    };

    const handleStateChange = (event, newValue) => {
        if (newValue) {
            const stateCode = newValue.isoCode;
            const countryCode = formData?.address?.country;
            const fetchedCities = City.getCitiesOfState(countryCode, stateCode);
            setCityList(fetchedCities);
            setFormData({ ...formData, address: { ...formData?.address, state: stateCode, city: '' } });
        }
    };

    const handleCityChange = (event, newValue) => {
        if (newValue) {
            setFormData({ ...formData, address: { ...formData?.address, city: newValue.name } });
        }
    };

    ///////////////////////////////////////////////////////////////////

    /////////////////// AUTOFILL COUNTRY , STATE , CITY //////////////////
    useEffect(() => {
        if (formData?.address?.country) {
            const fetchedStates = State.getStatesOfCountry(formData?.address?.country);
            setStateList(fetchedStates);
        }
    }, [formData?.address?.country]);

    useEffect(() => {
        if (formData?.address?.state) {
            const fetchedCities = City.getCitiesOfState(formData?.address?.country, formData?.address?.state);
            setCityList(fetchedCities);
        }
    }, [formData?.address?.state]);
    /////////////////////////////////////////////////////////////////////
    //////////////////////////PASSWORD///////////////////////////////
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfrimPassword = () => setShowConfrimPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    //////////////////////////////////////////////////////////////////////////


    console.log("FORMDATA", formData)
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            {isUpdate ? 'Edit user' : 'Create user'}
                        </Typography>
                        <Grid container spacing={2} style={{ marginTop: 10 }}>
                            <Grid item xs={12}><TextField error={Boolean(errors.name)} helperText={errors.name} onFocus={() => handleError('', 'name')} value={formData?.name || ''} label="Name" onChange={(e) => handleInputChange('name', e.target.value)} fullWidth /></Grid>
                            <Grid item xs={12}><TextField error={Boolean(errors.email)} helperText={errors.email} onFocus={() => handleError('', 'email')} value={formData?.email || ''} label="Email" onChange={(e) => handleInputChange('email', e.target.value)} fullWidth /></Grid>
                            <Grid item xs={12}><TextField error={Boolean(errors.phone)} helperText={errors.phone} onFocus={() => handleError('', 'phone')} value={formData?.phone || ''} label="Phone Number" onChange={(e) => handleInputChange('phone', e.target.value)} fullWidth /></Grid>

                            {isUpdate ? <></> :
                                <Grid item xs={12} sm={6} style={{ display: 'flex', gap: 15 }}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel>Password</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            label="Password"
                                            name='password'
                                            placeholder="Enter Your Password"
                                            type={showPassword ? 'text' : 'password'}
                                            value={formData?.password}
                                            onChange={(e) => handleInputChange('password', e.target.value)}
                                            error={Boolean(errors?.password)}
                                            helperText={errors?.password}
                                            onFocus={() => handleError('', 'password')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        <FormHelperText style={{ color: "#D32F2f" }} id="outlined-weight-helper-text">{errors?.password}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            }
                            {isUpdate ? <></> : <Grid item xs={12} sm={6} style={{ display: 'flex', gap: 15 }}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Confirm  Password</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        label="Confirm Password"
                                        name='confirmPassword'
                                        variant="outlined"
                                        placeholder="Confrim Your Password"
                                        type={showConfrimPassword ? 'text' : 'password'}
                                        value={formData?.confirmPassword}
                                        error={Boolean(errors?.confirmPassword)}
                                        helperText={errors?.confirmPassword}
                                        onFocus={() => handleError('', 'confirmPassword')}
                                        onChange={(e) => handleInputChange('confirmPassword', e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowConfrimPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showConfrimPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText style={{ color: "#D32F2f" }}>{errors?.confirmPassword}</FormHelperText>
                                </FormControl>
                            </Grid>
                            }

                            <Grid item xs={12}><Typography variant="h6" gutterBottom>Address</Typography></Grid>
                            <Grid item xs={12} sm={4}>
                                <Autocomplete
                                    id="country-select"
                                    options={countryList}
                                    value={countryList.find(c => c.isoCode === formData?.address?.country || '') || null}
                                    onChange={handleCountryChange}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            {...props}
                                            value={option.isoCode}
                                            key={option.isoCode}
                                        >
                                            {option.name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Country"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>

                            {/* Owner Name */}
                            <Grid item xs={12} sm={4}>
                                <Autocomplete
                                    id="state-select"
                                    options={stateList}
                                    value={stateList.find(s => s.isoCode === formData?.address?.state || '') || null}
                                    onChange={handleStateChange}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            {...props}
                                            value={option.isoCode}
                                            key={option.isoCode}
                                        >
                                            {option.name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select State"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>

                            {/* Owner Contact Number */}
                            <Grid item xs={12} sm={4}>
                                <Autocomplete
                                    id="city-select"
                                    options={cityList}
                                    value={cityList.find(c => c.name === formData?.address?.city) || null}
                                    onChange={handleCityChange}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            {...props}
                                            value={option.name}
                                            key={option.name}
                                        >
                                            {option.name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={errors?.address?.city}
                                            onFocus={() => handleError('', 'address?.city')}
                                            helperText={errors?.address?.city}
                                            label="Select City"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="Street"
                                    name="street"
                                    value={formData?.address?.street || ''}
                                    onChange={(e) => handleInputChange('street', e.target.value)}
                                    fullWidth
                                    error={errors?.street}
                                    onFocus={() => handleError('', 'street')}
                                    helperText={errors?.street}
                                />

                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="Zip Code"
                                    name="zipCode"
                                    value={formData?.address?.zipCode || ''}
                                    onChange={(e) => handleInputChange('zipCode', e.target.value)}
                                    fullWidth
                                    error={errors?.zipCode}
                                    onFocus={() => handleError('', 'zipCode')}
                                    helperText={errors?.zipCode}
                                />
                            </Grid>

                            {isUpdate ? <Grid item xs={12} sm={6}>
                                <AdminChangPasswordModals isUpdate={false} title={'users'} Data={editData} />
                            </Grid> : <></>}


                            <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
                                <Button
                                    variant="outlined"
                                    style={{
                                        textTransform: 'capitalize',
                                        color: primaryColor,
                                        border: `1px solid ${primaryColor}`,
                                        borderRadius: 0
                                    }}
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    style={{
                                        textTransform: 'capitalize',
                                        color: '#fff',
                                        background: primaryColor,
                                        borderRadius: 0
                                    }}
                                    onClick={handleSubmit}
                                    fullWidth
                                >
                                    {loader ? isUpdate ? 'Edit...' : 'Save...' : isUpdate ? 'Edit' : 'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
            {/* <Toaster /> */}
        </div>
    );
}
