import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Avatar,
    Button,
    Typography,
    Menu,
    MenuItem,
    IconButton,
    Pagination,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClaimDetailModel from '../ClaimDetailModel';
import { formatDate, primaryColor } from '../../constant';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { post_data } from '../../api';
import AdminInventorysModal from '../models/AdminInventoryModal';
import AdminInventoryDetail from '../models/AdminInventoryDetail';
import VendorDetailModal from '../VendorDetailModal';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
        '& .MuiCheckbox-root': {
            color: '#a3a3a3',
        },
    },
    stageButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        '&.active': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.non-active': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.stock': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.no data': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
    },
    avatarGroup: {
        display: 'flex',
        alignItems: 'center',
    },
    avatarName: {
        marginLeft: '8px',
        fontSize: 14
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});

const AdminActiveInventoryTable = ({
    data, getInvenrotyData,
    setLoading, totalData, loading, currentPage, setCurrentPage, itemsPerPage, totalPages
}) => {


    const tableHeader = [
        "S No.", "Company Name", "Vehicle Number", "IMEI Number", " SIM Number", "Sim Network", "Device Unique Id", "Vendor", "Gps Status"
    ]
    const classes = useStyles();
    const [rowsData, setRowsData] = useState(data || []);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const [open, setOpen] = useState(false)
    const [editData, setEditData] = useState([]);
    const [vendorOpen, setVendorOpen] = useState(false);


    useEffect(() => { setRowsData(data) }, [data])


    const handleMenuOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(index);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentRow(null);
    };
    const handleDelete = async (row) => {
        setAnchorEl(null);
        setCurrentRow(null);

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: primaryColor,
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = await post_data(`admin-inventory/delete-admin-inventory/${row?._id}`, {})
                    if (data.status) {
                        toast.success("Inventory Deleted Successfully");
                        getInvenrotyData();
                    }
                    else {
                        toast.error("Something Went Wrong");
                    }
                } catch (error) {
                }
            }
        });

    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleShowVendor = (row) => {
        setVendorOpen(true);
        setCurrentRow(row)
    }

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);


    return (
        <>
            <VendorDetailModal open={vendorOpen} setOpen={setVendorOpen} data={currentRow} />
            <TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
                <Table className={classes.root}>
                    <TableHead>
                        <TableRow>
                            {
                                tableHeader?.map((item) => {
                                    return (
                                        <TableCell style={{ borderRight: '1px solid gainsboro', padding: '16px 10px' }}>{item}</TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowsData?.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ borderRight: '1px solid gainsboro', textAlign: 'center', width: '5%' }}>{startEntry + index}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.clientId?.companyName}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.vehicleNumber}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.imeiNumber || '-'}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.simNumber || '-'}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.network || '-'}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.deviceUniqueId || '-'}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro', cursor: 'pointer' }} onClick={() => handleShowVendor(row?.vendorId)}>
                                    <span style={{ color: primaryColor, fontSize: 11, fontWeight: 500, textDecoration: 'underline' }}>View Detail</span>
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                    <Button style={{ fontSize: 11, fontWeight: 500, borderRadius: 30 }}
                                        className={`${classes.stageButton} ${row?.gpsStatus?.toLowerCase()?.replace(' ', '')}`}
                                    >
                                        {row?.gpsStatus || '-'}
                                    </Button>
                                </TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                    <IconButton
                                        className={classes.moreIcon}
                                        onClick={(event) => handleMenuOpen(event, index)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>

                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl) && currentRow === index}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem onClick={() => {
                                            setEditData(row)
                                            setOpen(true)
                                            handleMenuClose()
                                        }}>View Details</MenuItem>
                                        {/* <MenuItem onClick={() => {
                                        setEditData(row)
                                        setOpen(true)
                                        handleMenuClose()
                                    }}> Update </MenuItem>
                                    <MenuItem onClick={() => {
                                        handleDelete(row)
                                        handleMenuClose()
                                    }}>Delete</MenuItem> */}
                                    </Menu>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                    <AdminInventoryDetail open={open} data={editData} setOpen={setOpen} />
                </Table>
                {rowsData?.length >= 0 && (
                    <div
                        style={{
                            borderTop: "1px solid gainsboro",
                            padding: "2%",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            style={{
                                fontWeight: 500,
                                fontSize: 15,
                                color: "black",
                            }}
                        >{`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}</Typography>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </div>
                )}
            </TableContainer>
        </>
    );
};

export default AdminActiveInventoryTable;
